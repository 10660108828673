import React from 'react';
import style from './style.module.scss';
import AbstractInfoCard from './abstract';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import { ICityInformation } from '../../models';

interface IProps {
    cityInfo: ICityInformation;
}

const PoliticalInfoCard: React.FunctionComponent<IProps> = (props) => {
    const {cityInfo} = props;

    return <AbstractInfoCard title={"Political climate"}
        description={cityInfo.peopleText}
        className={style.political}
    >
        <div className={style.politRelations}>
            <ScoreProgressBar color={ScoreProgressBarColor.orange}
                title={'Relations with Society'}
                value={cityInfo.people}
                unit={"%"}
            />
            <ScoreProgressBar color={ScoreProgressBarColor.orange}
                title={'Relations with Business'}
                value={cityInfo.business}
                unit={"%"}
            />
        </div>
    </AbstractInfoCard>
}


export default PoliticalInfoCard;
