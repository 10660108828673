import { Action } from "redux";
import { OPEN_SCOPE_FORM, IOpenScopeAction, CLOSE_SCOPE_FORM } from "../../actions/forms/openScope";
export interface IState {
    isOpen: boolean;
    scopeId: number;
}

export const initial: IState = {
    isOpen: false,
    scopeId: -1
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_SCOPE_FORM:
            const { scopeId } = action as IOpenScopeAction;
            return {
                ...state,
                scopeId,
                isOpen: true
            };

        case CLOSE_SCOPE_FORM:
            return {
                ...state,
                isOpen: false,
                scopeId: -1,
            };

        default:
            return state;
    }
}