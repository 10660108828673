import { IMoney } from "../../../models";
import { Action } from "redux";

export const MONEY_CHANGE = 'MONEY_CHANGE';

export interface IMoneyChange extends Action {
    money: IMoney;
}

export interface IMoneyQplusChange extends Action {
    moneyQplus: number;
}

export interface IMoneyQminusChange extends Action {
    moneyQminus: number;
}

export function moneyChange(money: IMoney): IMoneyChange {
    return { type: MONEY_CHANGE, money};
}