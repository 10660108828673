import { Action } from "redux";
import { IScope } from "../../../models";

export const SCOPES_CHANGE = 'SCOPES_CHANGE';

export interface IScopesChangeAction extends Action {
    scopeId: number
    scope: IScope
}

export function scopesChange(scopeId: number, scope: IScope) {
    return { type: SCOPES_CHANGE, scopeId, scope } as IScopesChangeAction;
}