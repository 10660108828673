import React from 'react';
import cn from 'classnames'
import style from './style.module.scss';

interface IProps {
    title: string;
    value: number | string;
    inRow?: boolean;
    className?: any;
}
const ImplIndicator: React.FunctionComponent<IProps> = (props) => {

    const { title, value, inRow=false, className} = props;

    return <div className={cn(style.indicator, inRow ? style.inRow : '', className)}>
            <div className={style.title}>{title}</div>
            <div className={style.value}>{value}</div>
    </div>
}

export default ImplIndicator;