import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import gamePoints from '../gamePoints';
import { useSelector } from 'react-redux';
import useScopes from '../../../hooks/useScopes';
import data from '../../../data';
import { getCurrentTeamMembers, getSelectedSystems, getTimer } from '../../../redux/selectors';
import { calcDirectionDigitalTransformationRate } from '../../../service/calcDigitalTransformRate';
import { ISystemProgress } from '../../RoundProgressBar';
import { calcTimeLeft } from '../../../service/calcTimeLeft';
import GamePoint from '../GamePoint';
import { IGamePoint } from '../../../models';
import { useWindowSize } from '../../../hooks';
import { defineScopeName } from '../../../service/scopeService';

const scopePoints = gamePoints.filter(p => p.type === 'Scope');
const dirPoints = gamePoints.filter(p => p.type === 'Direction');

interface IPoint {
    x: number;
    y: number;
}

interface ILine {
    p1: IPoint;
    p2: IPoint;
    color: string;
}

const calcPoint = (elementId: string, side: 'left' | 'right') => {
    const scope = document.getElementById(elementId);
    if (scope) {
        var rect = scope.getBoundingClientRect();
        // console.log(rect.top, rect.right, rect.bottom, rect.left);

        const point = { x: rect.x, y: rect.y + rect.height / 2 };

        if (side === 'right') {
            point.x = rect.right;
        }
        return point;
    }

    return { x: 0, y: 0 };
}

export default function MindMapView() {

    const timer = useSelector(getTimer);
    const scopes = useScopes();
    const team = useSelector(getCurrentTeamMembers);
    const selectedSystems = useSelector(getSelectedSystems);

    const [lines, setLines] = useState<ILine[][]>(scopePoints.map(() => [] as ILine[]));

    const calcRelations = () => {

        let linesTemp: ILine[][] = scopePoints.map(() => [] as ILine[]);

        scopePoints.forEach((sp) => {

            const scope = document.getElementById('scope' + sp.scopeId.toString());
            let dx = 0;
            let dy = 0;
            if (scope) {
                var rect = scope.getBoundingClientRect();
                dx = rect.x;
                dy = rect.y;
            }

            const dirsOfScope = dirPoints.filter(dp => dp.scopeId === sp.scopeId);

            dirsOfScope.forEach((dp) => {
                if (dp.directionId) {
                    const p1 = calcPoint(sp.scopeId.toString(), 'right');
                    const p2 = calcPoint(dp.directionId?.toString(), 'left');

                    p1.x = p1.x - dx;
                    p2.x = p2.x - dx;

                    p1.y = p1.y - dy;
                    p2.y = p2.y - dy;

                    linesTemp[sp.scopeId - 1] = [...linesTemp[sp.scopeId - 1], { p1, p2, color: sp.color }];
                }
            });
        });

        setLines(linesTemp);

    }

    useEffect(() => {
        calcRelations();
    }, []); // <-- empty array means 'run once'

    useWindowSize(calcRelations);

    const renderPoints = () => scopePoints.map((p, i) => {

        const dirsOfScope = dirPoints.filter(dp => dp.scopeId === p.scopeId);

        const point = {
            scopeId: p.scopeId,
            color: p.color,
            directionId: p.directionId,
            type: p.type
        } as IGamePoint;

        return <div id={'scope' + p.scopeId} key={i} className={style.scopeContainer}>
            <div className={style.scopeContainerWrapper}>            
                <div>
                    <div className={style.scopeTitle}>{defineScopeName(p.scopeId)}</div>
                    <GamePoint id={p.scopeId.toString()} point={point} isPulse={false}
                        className={style.mindMapPoint}
                        isHighlight={false}
                        fromMap={true} />
                </div>

                <div className={style.directions}>
                    {renderDirectionsPoints(dirsOfScope)}
                </div>

                <svg className={style.relations}>
                    {lines[p.scopeId - 1].map((l, i) => <path key={i} d={`M${l.p1.x} ${l.p1.y} Q ${l.p1.x + (l.p2.x - l.p1.x) / 2} ${l.p1.y},  ${l.p2.x} ${l.p2.y}`} style={{ strokeWidth: '1px', stroke: l.color }} fill={'transparent'} />)}
                </svg>
            </div>
        </div>;
    });

    const renderDirectionsPoints = (dirsPoints: IGamePoint[]) => dirsPoints.map((x, index) => {
        const scope = scopes.find(r => r.scopeId === x.scopeId);
        const systems = data.systems.filter(s => s.directionId === x.directionId);
        const direction = scope?.directions?.find(r => r.directionId === x.directionId);
        const dtr = direction ? calcDirectionDigitalTransformationRate(direction, selectedSystems) * 100 : 0;

        const systemsImpl = selectedSystems.filter(x => systems.findIndex(s => s.id === x.systemId) >= 0 && (x.isProgress || x.startDate) && !x.fistStageDone);

        const systemsProgress: ISystemProgress[] = systemsImpl.map(s => {
            const timeLeft = calcTimeLeft(systems.find(r => r.id === s.systemId), s, timer, team);
            const maxDay = s.startUpgradeDate ? s.startUpgradeDate : s.startDate;
            const timeMax = calcTimeLeft(systems.find(r => r.id === s.systemId), s, { startDate: maxDay ?? timer.startDate, curDate: maxDay ?? timer.startDate, launch: false, nextQuaterDate: timer.nextQuaterDate }, team);
            return {
                value: timeLeft,
                initialValue: timeMax,
                color: x.color
            }
        });

        return <GamePoint id={x.directionId?.toString()} key={index} point={x} isPulse={false}
            isHighlight={false}
            className={style.mindMapPoint}
            directionName={direction?.title}
            dtrDirection={dtr}
            fromMap={true}
            systemsProgress={systemsProgress} />

    });

    return <div className={style.mindMapView}>
        <div className={style.pointsContainer}>
            {renderPoints()}
        </div>
    </div>;
}
