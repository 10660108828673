import React from 'react';
import style from './style.module.scss';
import "react-input-range/lib/css/index.css"
import { PointsColorEnum } from '../../models';

export interface ISystemProgress {
    value: number,
    initialValue: number,
    color: PointsColorEnum
}

interface IProps {
    systemProgress: ISystemProgress[]
}

const RoundProgressBar: React.FunctionComponent<IProps> = (props) => {
    const { systemProgress } = props;

    const createCircles = () => {
        const r = 15;
        const cx = 20;
        const cy = 20;
        const circleLength = 2 * r * Math.PI;

        const totalLength = systemProgress.reduce((a, b) => a + b.initialValue, 0);

        
        let cornerDelta = -90;
        return systemProgress.map((x, i) => {

            const sectorLength = circleLength * x.initialValue / totalLength;

            const currCorner = (sectorLength * 180) / (Math.PI * r);
            cornerDelta += currCorner;
            
            const strokeDasharray = systemProgress.length > 1 ? circleLength - 4 : circleLength;
            const strokeDashoffset = x.value > 5 ? circleLength * (1 - x.value / totalLength) : circleLength  * (1 - 5 / totalLength);
            return strokeDasharray < strokeDashoffset ? null : 
                <circle key={i} transform={`rotate(${i > 0 ? cornerDelta - currCorner : -90} ${cx} ${cy})`} r={r} cx={cx} cy={cy}
                    stroke={x.color} style={{ strokeLinecap: "round", strokeDasharray: strokeDasharray, strokeDashoffset:strokeDashoffset }} />
        })
    }

    const renderRange = () => {
        return <div className={style.circle}>
            <svg width="40" height="40" className={style.gCircles}>
                {createCircles()}
            </svg>
        </div>
    }

    return renderRange();
}

export default RoundProgressBar;
