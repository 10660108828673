import { Action } from "redux";
import { GameStageEnum } from "../../../models";

export const CHANGE_STAGE = 'CHANGE_STAGE';

export interface IGameChangeStage extends Action {
    stage: GameStageEnum
}

export function gameStageChange(stage: GameStageEnum) {
    return { type: CHANGE_STAGE, stage } as IGameChangeStage;
}

