import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTimer, getCurrentCityEvent, getHappenedCityEventIdArr, getCurrentCityEventDate, getLearningCompleted } from '../../redux/selectors';
import { timerPause, currentCityEventSet, currentCityEventIdSet } from '../../redux/actions';
import data from '../../data';
import { isNowNthDay } from './helper';
import CityEvent from './CityEvent';
import { store } from '../../store/configureStore';

export const CMeventlag = 75;
export const CMeventP = 0.5;

const CityEvents = () => {

  const timer = useSelector(getTimer);
  const dispatch = useDispatch();
 
  const firedEventNow = useRef<boolean>(false);
  const curCityEvent = useSelector(getCurrentCityEvent);
  const curCityEventDate = useSelector(getCurrentCityEventDate);
  const learningCompleted = useSelector(getLearningCompleted);

  const firedEventsIds = useSelector(getHappenedCityEventIdArr);// Id случившихся событий

  const cityEventsIds: number[] = [...data.cityEvents.map(cityEvent => cityEvent.id)];

  useEffect(() => {
    // логика для проверки, что прошло N-дней и можно запускать событие
    const curDate = timer.curDate;
    const startDate = timer.startDate;

    const { isOpen: openedSystemEventForm } = store.getState().openSystemEvent;
    
    if (!curCityEvent && (curDate > curCityEventDate || !curCityEventDate) && learningCompleted && !openedSystemEventForm) {
      if (isNowNthDay(startDate, curDate, CMeventlag)) {
        const probability = Math.random() < CMeventP;

        if (probability) {
          //1. сгенерировали  случайное событие curCityEvent

          let notFiredCityEventsIds: number[] = [];
          if (firedEventsIds && firedEventsIds.length > 0) {
            notFiredCityEventsIds = cityEventsIds.filter(e => firedEventsIds.indexOf(e) < 0);
          } else {
            notFiredCityEventsIds = cityEventsIds;
          }

          notFiredCityEventsIds.sort(() => Math.random() - 0.5);
          const randomCityEvent = data.cityEvents.find(e => e.id === notFiredCityEventsIds[0]);

          if (randomCityEvent) {
            firedEventNow.current = true;
            // 2. остановили таймер
            dispatch(timerPause());
            dispatch(currentCityEventSet(randomCityEvent, curDate));
          }

        }
      }
    }
  }, [timer.curDate, timer.startDate, curCityEvent, firedEventsIds, cityEventsIds, curCityEventDate, dispatch, learningCompleted])

  const handleUserReactsOnEvent = () => {
    // curEvent = null, чтобы убрать с экрана 
    dispatch(currentCityEventSet(null, curCityEventDate));
  }

  const handleUserConfirmEvent = () => {
    // добавить ID события в список появившихся уже событий
    if (curCityEvent) {
      firedEventNow.current = true;
      const currentEventId = curCityEvent.id;
      dispatch(currentCityEventIdSet(currentEventId));
    }
  }

  return firedEventNow.current && curCityEvent ? <CityEvent cityEvent={curCityEvent} handleOkClick={handleUserReactsOnEvent} handleConfirmEvent={handleUserConfirmEvent} /> : null;
}

export default CityEvents;
