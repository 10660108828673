import { Action } from "redux";

export const OPEN_HEADS_FORM = 'OPEN_HEADS_FORM';
export const CLOSE_HEADS_FORM = 'CLOSE_HEADS_FORM';

export interface IOpenHeadsAction extends Action {
    scopeId: number
}

export function openHeadslForm(scopeId: number) {
    return { type: OPEN_HEADS_FORM, scopeId };
}

export function closeHeadsForm() {
    return { type: CLOSE_HEADS_FORM } ;
}
