import { httpGet } from './common';


export interface IUserDto {
    id: number,
    email: string;
    name: string;
}

export function getCurrentUser() {
    return httpGet<IUserDto>(`/api/users/current`, { authorize: true });
}