import { Action } from "redux";
import { ITarget } from "../../../models";
import { TARGETS_CHANGE, ITargetsChangeAction } from "../../actions/game";

export const initial: ITarget[] = [];

export const reducer = (state: ITarget[] = initial, action: Action): ITarget[] => {
    switch (action.type) {
        case TARGETS_CHANGE:
           
            const { targets } = action as ITargetsChangeAction;
            return [...targets];

        default:
            return state;
    }
}