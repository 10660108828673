export const OPEN_LOCAL_CITYINFO_FORM = 'OPEN_LOCAL_CITYINFO_FORM';
export const CLOSE_LOCAL_CITYINFO_FORM = 'CLOSE_LOCAL_CITYINFO_FORM';

export function openLocalCityInfoForm() {
    return { type: OPEN_LOCAL_CITYINFO_FORM };
}

export function closeLocalCityInfoForm() {
    return { type: CLOSE_LOCAL_CITYINFO_FORM } ;
}
