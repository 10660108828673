import { Action } from "redux";
import { IErrorResponse, IPageResult } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { IGamesFilter, IGameDto } from "../../../api/game";

export const GET_GAMES_INIT = 'GET_GAMES_INIT';
export const GET_GAMES_DONE = 'GET_GAMES_DONE';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export interface IGetGamesInitAction extends Action {
    filter: IGamesFilter | null
}

export interface IGetGamesDoneAction extends Action {
    games: IPageResult<IGameDto>;
}

export function getGamesInit(filter: IGamesFilter | null) {
    return { type: GET_GAMES_INIT, filter } as IGetGamesInitAction;
}

export function getGamesDone(games: IPageResult<IGameDto>) {
    return { type: GET_GAMES_DONE, games } as IGetGamesDoneAction;
}

export function getGamesFail(error: IErrorResponse) {
    return { type: GET_GAMES_FAIL, error } as IFailAction;
}