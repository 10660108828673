import { Action } from "redux";
import { printErrorResponse } from "../../api/common";
import { IFailAction } from "../../models/common";
import { IUserDto } from "../../api/user";
import { GET_CURRENT_USER_INIT, GET_CURRENT_USER_DONE, ICurrenUserDoneAction, GET_CURRENT_USER_FAIL } from "../actions";

export interface IState {
    user: IUserDto | null;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    user: null,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case GET_CURRENT_USER_INIT:
            return {
                ...state,
                user: null,
                inProgress: true,
                error: null
            };

        case GET_CURRENT_USER_DONE:

            const { user } = action as ICurrenUserDoneAction;
            return {
                ...state,
                user,
                inProgress: false,
                error: null
            };

        case GET_CURRENT_USER_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                user: null,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}