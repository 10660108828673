import { ISystem, ICommonTarget, IFinMetric, getCurrentMN, IScope, ICityInformation } from "../../../models";
import data from '../../../data';
import { setReputation } from "../../../redux/actions";

export interface ICommonScores {
    peopleDelta: number,
    businessDelta: number,
    totalQminus: number,
    totalQplus: number
}

export interface IReputationScores {
    changePeople: number,
    changeBusiness: number
}


export function calcCommonScores(cityId: number, systems: ISystem[], finMetrics: IFinMetric[], commonTargets: (ICommonTarget | undefined)[]) {
    let peopleDelta = 0;
    let businessDelta = 0;
    systems.forEach(s => {

        const ct = commonTargets.find(si => si?.systemId === s.systemId);
        if (!ct || !s.kpis) return null;

        const systemKpiInfo = data.kpiCoeffs.find(si => si.systemId === s.systemId);

        if (!systemKpiInfo) return;

        // console.log(`Current state of system ${s.systemId}:`, s, systemKpiInfo);

        peopleDelta = peopleDelta + s.kpis.cov / 100 * systemKpiInfo.usabilityxCSI * ct.priorityobjective[cityId - 1];
        businessDelta = peopleDelta + s.kpis.cov / 100 * systemKpiInfo.usabilityxFCR * ct.priorityobjective[cityId - 1];

    });

    finMetrics.forEach(fm => {
        const currentMN = getCurrentMN(fm, systems);
        peopleDelta = peopleDelta + fm.coefpeopleMN * currentMN;
        businessDelta = businessDelta + fm.coefbusinessMN * currentMN;
    });

    return {
        peopleDelta: peopleDelta,
        businessDelta: businessDelta
    };
}

export function calcReputation(cityInfo: ICityInformation, systems: ISystem[], finMetrics: IFinMetric[], scopes: IScope[], dispatch: any): IReputationScores {
    const commonTargets = scopes.flatMap(sc => sc.directions)?.flatMap(d => d?.commonTargets);

    if (!cityInfo || !commonTargets) return { changePeople: 0, changeBusiness: 0 };

    const { peopleDelta, businessDelta } = calcCommonScores(cityInfo.cityId, systems, finMetrics, commonTargets);

    if (cityInfo.people !== cityInfo.peopleBase + peopleDelta || cityInfo.business !== cityInfo.businessBase + businessDelta) {
        const peopleCurrent = cityInfo.people;
        const businessCurrent = cityInfo.business;
        const peopleNew = Math.min(cityInfo.peopleBase + peopleDelta, 100);
        const businessNew = Math.min(cityInfo.businessBase + businessDelta, 100);
        if (peopleNew !== peopleCurrent || businessNew !== businessCurrent) {
            dispatch(setReputation(peopleNew, businessNew));
        }

        const changePeople = Math.round(peopleNew - peopleCurrent);
        const changeBusiness = Math.round(businessNew - businessCurrent);

        return { changePeople: changePeople, changeBusiness: changeBusiness }
    }

    return { changePeople: 0, changeBusiness: 0 }
}
