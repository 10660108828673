import { IMoney, ITarget, ITeamMember, ISystem } from ".";
import { ICityInformation } from "./cityInfo";
import { IScope } from "./scope";
import { IScopesContaractor } from "./contractor";
import { ITestMode } from "./testMode";
import { ICityEvent, IDTEvent } from "./event";
import { IFinMetric } from "./finMetric";
import { ILearningProgress } from "../redux/reducers/game/learningProgress";

export type GameView = 'MindMap' | 'CityMap';

/**
 * Текущее состояние игры
 */
export interface IGame {
    id: number,
    view: GameView,
    timer: ITimer,
    stage: GameStageEnum,
    learningCompleted: boolean,
    learningProgress: ILearningProgress;
    cityInfo?: ICityInformation,
    money: IMoney,
    education: IScope,
    health: IScope,
    transport: IScope,
    eService: IScope,
    eHousing: IScope,
    security: IScope,
    testModes: ITestMode[],
    /** Выбранные цели */
    targets: ITarget[],
    /** Выбранные системы */
    systems: ISystem[],
    notification?: INotification[],
    teamMembers: ITeamMember[],
    contractors?: IScopesContaractor[]

    currentCityEvent: ICityEvent | null;
    currentCityEventDate: number;
    happenedCityEventsIds: number[];

    currentDTEvent: IDTEvent | null;
    currentDTEventDate: number;
    happenedDTEventsIds: number[];

    finMetrics: IFinMetric[];

    finished: boolean;
}

export enum GameStageEnum {
    STAGE_1_LEARNING = "STAGE_1_LEARNING",
    STAGE_2_CITY_INFO = "STAGE_2_CITY_INFO",
    STAGE_3_GOALS = "STAGE_3_GOALS",
    STAGE_4_GAME = "STAGE_4_GAME"
}

export interface ITimer {
    startDate: number,
    curDate: number,
    nextQuaterDate: number,
    launch: boolean
}

/**
 * Уведомление
 */
export interface INotification {
    type: NotificationEnum,
    title: string,
    // action: 
}

export enum NotificationEnum {

}

