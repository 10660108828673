import React, { useState } from 'react';
import { IDTEvent } from '../../../models/event';
import AbstractEvent from '../../System/AbstractEvent';
import data from '../../../data';
import useScopes from '../../../hooks/useScopes';
import { changeMoneyService } from '../../../service/changeMoneyService';
import { useDispatch, useSelector } from 'react-redux';
import { getMoney, getSelectedSystemById } from '../../../redux/selectors';
import { moneyChange, cityInfoPeopleImpact, cityInfoBusinessImpact, systemChange } from '../../../redux/actions';
import { IState } from '../../../redux/reducers';
import GameForm from '../../UiKit/GameForm';
import style from './style.module.scss';

interface IProps {
  DTEvent: IDTEvent;
  handleOkClick: () => void;
  handleConfirmEvent: () => void;
}

const rndCoeff = Math.random() - 0.5;

const DTEvent: React.FunctionComponent<IProps> = (props) => {
  const { DTEvent, handleOkClick, handleConfirmEvent } = props;
 
  const defaultSystem = data.systems.find(s => s.id === DTEvent.id);
  const system = useSelector(s => getSelectedSystemById(s as IState, DTEvent.id));

  const dispatch = useDispatch();
  const money = useSelector(getMoney)
  const scopes = useScopes();
  const [income, setIncome] = useState<number>(0);

  if (!system || !defaultSystem) return null;

  const handleEvent = (index: number) => {
    const result = DTEvent.options[index];

    setIncome(result.eRevenueXnYnZn);

    const newMoney = changeMoneyService(money, -Math.abs(result.eventCost), 0, 0);
    dispatch(moneyChange(newMoney));
    dispatch(cityInfoPeopleImpact(result.ePeopleBase));
    dispatch(cityInfoBusinessImpact(result.eBusinessBase));
    dispatch(systemChange({...system, 
      revenueBase: system.revenueBase + result.eRevenueXnYnZn, 
      kpisImpact: {
        nps: result.kpiNPS, 
        csi: result.kpiCSI, 
        fcr: result.kpiFCR, 
        er: result.kpiER, 
        cov: result.kpiCOV
      }}));

      handleConfirmEvent();
  }

  const scope = scopes.find(s => s.scopeId === defaultSystem.scopeId);
  if(!scope) return null;

  const header = data.heads.find(h => h.headId === scope?.headId);
  if(!header) return null;
  
  return <GameForm close={() => { }} className={style.form_to_up}>
          <AbstractEvent
            event={DTEvent}
            options={DTEvent.options}
            handleConfirm={handleEvent}
            handleClose={handleOkClick}
            scopeName={defaultSystem.title}
            scopeHeader={header.name}
            avatarPath={header.avatar}
            Income={income}
            Expenses={0}
            rndCoeff={rndCoeff}
          />
      </GameForm>;
}

export default DTEvent;
