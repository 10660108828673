import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ISaveGameInitAction, saveGameFail, SAVE_GAME_INIT, saveGameDone } from '../../actions/gameAPI/saveGame';
import { IErrorResponse } from '../../../api/common';
import { save } from '../../../api/game';

export function* saveGameHandler(action: Action) {
    try {
        const { id, game } = action as ISaveGameInitAction;
        
        yield call(save, id, game);     
        yield put(saveGameDone()); 
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(saveGameFail(c))
    }
}

export function* saveGameSaga() {
    yield takeLatest(SAVE_GAME_INIT, saveGameHandler);
}
