import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';

interface IProps {
    label: string;
    handleClick: () => void;
    classes?: string;
}

const LinkButton: React.FunctionComponent<IProps> = (props) => {

    const { label, handleClick, classes } = props;

    return <div className={cn(style.link, classes)} onClick={handleClick}>{label}</div>
}

export default LinkButton;