import { Action } from "redux";
import { printErrorResponse, IPageResult } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { IGamesFilter, IGameDto } from "../../../api/game";
import { GET_GAMES_INIT, IGetGamesInitAction, GET_GAMES_DONE, IGetGamesDoneAction, GET_GAMES_FAIL } from "../../actions/gameAPI/getGames";

export interface IState {
    filter: IGamesFilter | null;
    games: IPageResult<IGameDto> | null;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    filter: null,
    games: null,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case GET_GAMES_INIT:
            const { filter } = action as IGetGamesInitAction;
            return {
                ...state,
                filter,
                games: null,
                inProgress: true,
                error: null
            };

        case GET_GAMES_DONE:

            const { games } = action as IGetGamesDoneAction;
            return {
                ...state,
                filter: null,
                games,
                inProgress: false,
                error: null
            };

        case GET_GAMES_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                filter: null,
                games: null,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}