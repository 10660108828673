import React, { useState, useEffect } from 'react';
import { ITarget } from '../../../models/scope';
import { useDispatch, useSelector } from 'react-redux';
import { getTargets, getLearningCompleted } from '../../../redux/selectors';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import style from './style.module.scss';
import BackButton from '../../UiKit/Buttons/BackButton';
import ScopeSelection from './ScopeSelection';
import DirectionSelection from './DirectionSelection';
import cn from 'classnames';
import DigitalTargetSelection from './DigitalTargetSelection';
import { targetsChange, gameStageChange, closeGoalsForm, learningProgressInit } from '../../../redux/actions';
import { ColorButton } from '../../UiKit/Buttons/common';
import GoalsList from './GoalsList';
import { GameStageEnum } from '../../../models';
import useScopes from '../../../hooks/useScopes';
import { learningProgressStart, learningProgressPause, learningProgressNext } from '../../../redux/actions/game/learningProgress';

interface IProps {
  backToWelcome: () => void;
  setProgress: (value: number) => void;
}

const goalNumToText = (step: number) => {

  if (step === 5) return '5th';
  if (step === 4) return '4th';
  if (step === 3) return '3rd';
  if (step === 2) return '2nd';

  return '1st';
}

enum SelectionStage {
  Scope,
  Direction,
  DigitalTarget,
  GoalsList
}

enum ButtonData {
  Scope_button = "Scope_button",
  Direction_button = "Direction_button",
  DigitalTarget_button = "DigitalTarget_button",
  Finish_button = "Finish_button"
}

const GoalSelection: React.FunctionComponent<IProps> = (props) => {

  const { backToWelcome, setProgress } = props;

  const dispatch = useDispatch()

  const targets = useSelector(getTargets);
  const scopes = useScopes();

  const [selectionStage, setSelectionStage] = useState<SelectionStage>(SelectionStage.Scope);
  const [currentScopeId, setCurrentScopeId] = useState<number>(0);
  const [commonTargetId, setCommonTargetId] = useState<number>(0);
  const [digitalTargetId, setDigitalTargetId] = useState<number>(0);
  const learningCompleate = useSelector(getLearningCompleted);
  const [buttonData, setButtonData] = useState<string>(ButtonData.Scope_button);

  useEffect(() => {
    const progressVal = (targets.length * 3 + selectionStage + 1) * 6.666;
    if (selectionStage === SelectionStage.GoalsList) setProgress(100);
    else setProgress(progressVal);

  }, [targets, selectionStage, setProgress]);

  useEffect(() => {
    setCommonTargetId(0);
    setDigitalTargetId(0);
  }, [currentScopeId]);

  const backAction = () => {
    if (selectionStage === SelectionStage.DigitalTarget) setSelectionStage(SelectionStage.Direction);

    if (selectionStage === SelectionStage.GoalsList || selectionStage === SelectionStage.Direction) setSelectionStage(SelectionStage.Scope);

    if (selectionStage === SelectionStage.Scope) backToWelcome();

  };

  const nextActionDisabled = () => {

    if (selectionStage === SelectionStage.GoalsList && targets.length >= 2) return false;

    if (selectionStage === SelectionStage.DigitalTarget && currentScopeId > 0 && commonTargetId > 0 && digitalTargetId > 0) return false;

    if (selectionStage === SelectionStage.Direction && currentScopeId > 0 && commonTargetId > 0) return false;

    if (selectionStage === SelectionStage.Scope && currentScopeId > 0) return false;

    return true;
  }

  const addTarget = (scopeId: number, commonTargetId: number, digitalTargetId: number) => {
    const scope = scopes.find(s => s.scopeId === currentScopeId);
    if (!scope) return;

    const direction = scope.directions?.find(d => d.commonTargets.findIndex(ct => ct.commonTargetId === commonTargetId) >= 0);
    if (!direction) return;

    const target: ITarget = { scopeId: scopeId, directionId: direction.directionId, commonTargetId: commonTargetId, digitalTargetId: digitalTargetId };

    const newTargets = [...targets, target];
    dispatch(targetsChange(newTargets));

    if (newTargets.length >= 2 && !learningCompleate) {
      dispatch(learningProgressInit(7));
      dispatch(learningProgressStart());
    } else {
      dispatch(learningProgressPause());
    }
    setCurrentScopeId(0)
    setSelectionStage(SelectionStage.Scope);
  }

  const nextAction = () => {
    if (selectionStage === SelectionStage.GoalsList && targets.length >= 2) {
      dispatch(gameStageChange(GameStageEnum.STAGE_4_GAME));
      !learningCompleate && dispatch(learningProgressNext());
      !learningCompleate && dispatch(learningProgressStart());
      dispatch(closeGoalsForm());
    }
    else if (selectionStage === SelectionStage.DigitalTarget && currentScopeId > 0 && commonTargetId > 0 && digitalTargetId > 0) {
      !learningCompleate && dispatch(learningProgressInit(1))
      setButtonData(ButtonData.Scope_button);
      addTarget(currentScopeId, commonTargetId, digitalTargetId);
    }
    else if (selectionStage === SelectionStage.Direction && currentScopeId > 0 && commonTargetId > 0) {
      !learningCompleate && dispatch(learningProgressNext());
      setSelectionStage(SelectionStage.DigitalTarget);
      setButtonData(ButtonData.DigitalTarget_button.toString())
    }
    else if (selectionStage === SelectionStage.Scope && currentScopeId > 0) {
      !learningCompleate && dispatch(learningProgressNext());
      setButtonData(ButtonData.Direction_button.toString())
      setSelectionStage(SelectionStage.Direction);
    }
  }

  const disabledDigitalTarget = (digitalTargetId: number) => {

    if (targets.findIndex(t => t.scopeId === currentScopeId && t.commonTargetId === commonTargetId && t.digitalTargetId === digitalTargetId) >= 0) return true;

    return false;
  }

  const disabledCommonTarget = (commonTargetId: number) => {

    if (targets.findIndex(t => t.scopeId === currentScopeId && t.commonTargetId === commonTargetId) >= 0) return true;

    return false;
  }

  const deleteTarget = (id: number) => {
    const newTargets = [...targets];
    newTargets.splice(id, 1);
    dispatch(targetsChange(newTargets));
  }

  const editTarget = (id: number) => {
    deleteTarget(id);
    setSelectionStage(SelectionStage.Scope);
  }

  const handleFinishClick = () => {
    setSelectionStage(SelectionStage.GoalsList);
    !learningCompleate && dispatch(learningProgressNext());
  }

  const headerText = () => {
    if (selectionStage === SelectionStage.GoalsList) return 'Approve your global goals';

    else if (targets.length === 5) return 'You defined 5 of 5 global goals';

    return `Define your ${goalNumToText(targets.length + 1)} of 5 global goals`;
  }

  const nextBtnLabel = () => {

    if (selectionStage === SelectionStage.DigitalTarget) return `Add ${goalNumToText(targets.length + 1)} goal`;
    else if (selectionStage === SelectionStage.GoalsList) return 'Approve goals';

    return 'Next step';
  }

  const handleScopeClick = (currentScopeId: number) => {
    setCurrentScopeId(currentScopeId);
    !learningCompleate && dispatch(learningProgressNext());
  }

  const handleDirectionClick = (commonTargetId: number) => {
    setCommonTargetId(commonTargetId);
    !learningCompleate && dispatch(learningProgressNext());
  }

  const handleTargetClick = (digitalTargetId: number) => {
    setDigitalTargetId(digitalTargetId);
    !learningCompleate && dispatch(learningProgressNext());
  }

  return (
    <div className={style.form} data-tut={"Approve_goals"}>
      <div className={style.title}>{headerText()}</div>
      {selectionStage === SelectionStage.Scope && <p className={style.text}>Choose the sphere and the direction for your first of five goals of city digital transformation</p>}

      {selectionStage === SelectionStage.Scope && <ScopeSelection scopes={scopes} setCurrentScopeId={handleScopeClick} currentScopeId={currentScopeId} />}
      {selectionStage === SelectionStage.Direction && <DirectionSelection scopes={scopes} setCurrentScopeId={(x) => { setCurrentScopeId(x); }} commonTargetId={commonTargetId} setCommonTargetId={handleDirectionClick} currentScopeId={currentScopeId} disabledCommonTargetId={disabledCommonTarget} />}
      {selectionStage === SelectionStage.DigitalTarget && <DigitalTargetSelection scope={scopes.find(s => s.scopeId === currentScopeId)} commonTargetId={commonTargetId} digitalTargetId={digitalTargetId} setDigitalTargetId={handleTargetClick} disabledDigitalTargetId={disabledDigitalTarget} />}
      {selectionStage === SelectionStage.GoalsList && <GoalsList scopes={scopes} targets={targets} handelDeleteItem={deleteTarget} handelEditItem={editTarget} />}

      <div className={cn(style.footer, selectionStage === SelectionStage.Direction || selectionStage === SelectionStage.DigitalTarget ? style.footer003c80 : '')}>
        <div className={style.footerContent}>
          {<BackButton label={'Back'} handleClick={backAction} />}

          <div className={style.footerButtons}>
            {targets.length >= 2 && selectionStage !== SelectionStage.GoalsList && <ClassicButton label={'Finish'} color={ColorButton.azure} handleClick={handleFinishClick} classes={style.finishButton} dataTut={ButtonData.Finish_button} />}
            {(targets.length < 5 || selectionStage === SelectionStage.GoalsList) && <ClassicButton label={nextBtnLabel()} disabled={nextActionDisabled()} handleClick={nextAction} dataTut={nextBtnLabel() === "Approve goals" ? "Approve_goals_button" : buttonData} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoalSelection;
