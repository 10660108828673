import { Action } from "redux";
import { ITarget } from "../../../models";

export const TARGETS_CHANGE = 'TARGETS_CHANGE';

export interface ITargetsChangeAction extends Action {
    targets: ITarget[]
}

export function targetsChange(targets: ITarget[]) {
    return { type: TARGETS_CHANGE, targets } as ITargetsChangeAction;
}
