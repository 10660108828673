
import { ICityInformation, IScope, IScopeHead, ITeamMember, IDefaultSystem, IScopesContaractor, IEmployeeTraining, IFinMetric } from '../models';
import cityInfoJson from './cityInfo.json';
import targetsEduJson from './1.targets.education.json';
import targetsHealthcareJson from './2.targets.healthcare.json';
import targetsTransportJson from './3.targets.transport.json';
import targetsEhousingJson from './4.targets.ehousing.json';
import targetsEservicesJson from './5.targets.eservices.json';
import targetsSecuritySafetyJson from './6.targets.security_safety.json';
import headsJson from './heads.json';
import teamJson from './team.json'; // VII. Команда и подрядчики
import systemJson from './system.json'; // VIV. Системы и их типы
import cityEventsJson from './cityEvents.json';
import techEventsJson from './techEvents.json';
import contractorJson from './contractor.json'; // XI Поставщики
import employeeTrainingJson from './employee.training.json'; // XIII. 04, 05 и 06
import userAnalysisJson from './user.analysis.json'; // XIII. 04, 05 и 06
import agencyJson from './agency.json'; // X
import infrastructureJson from './infrastructure.json'; // XII
import administrativeJson from './administrative.json'; // XII
import testJson from './tests.json'; // XII
import finMetricsJson from './finMetrics.json'; // VI
import kpiCoeffsJson from './kpiCoeffs.json'; // XVIII. KPIs
import { IUserAnalysis } from '../models/userAnalysis';
import { IScopeAgency } from '../models/agency';
import { IScopeExpert } from '../models/option';
import { ITestMode } from '../models/testMode';
import { ICityEvent, IDTEvent } from '../models/event';
import { IKpiCoeff } from '../models/kpiCoeffs';


export interface IData {
  cityInfo: ICityInformation[],
  education: IScope,
  health: IScope,
  transport: IScope,
  eHousing: IScope,
  eService: IScope,
  security: IScope,
  heads: IScopeHead[],
  teamMembers: ITeamMember[],
  systems: IDefaultSystem[],
  scopesContractor: IScopesContaractor[],
  employeeTraining: IEmployeeTraining[],
  userAnalysis: IUserAnalysis,
  scopeAgencies: IScopeAgency[],
  administrativeExperts: IScopeExpert[],
  infrastuctureExpers: IScopeExpert[],
  testModes: ITestMode[]
  cityEvents: ICityEvent[];
  DTEvents: IDTEvent[];
  finMetrics: IFinMetric[];
  kpiCoeffs: IKpiCoeff[];
}

const finMetrics = finMetricsJson as IFinMetric[];
const cityInfo = (cityInfoJson as any) as ICityInformation[];
const targetsEdu = targetsEduJson as IScope;
const targetsHealthcare = targetsHealthcareJson as IScope;
const targetsTransport = targetsTransportJson as IScope;
const targetsEhousing = targetsEhousingJson as IScope;
const targetsEservices = targetsEservicesJson as IScope;
const targetsSecuritySafety = targetsSecuritySafetyJson as IScope;
const heads = headsJson as IScopeHead[];
const team = teamJson as ITeamMember[];
const systems = systemJson as IDefaultSystem[];
const scopesContractor = contractorJson as IScopesContaractor[];
const employeeTraining = employeeTrainingJson as IEmployeeTraining[];
const userAnalysis = userAnalysisJson as IUserAnalysis;
const scopeAgencies = agencyJson as IScopeAgency[];
const administrative = administrativeJson as IScopeExpert[];
const infrastructure = infrastructureJson as IScopeExpert[]
const testModes = testJson as ITestMode[]
const cityEvents = cityEventsJson as ICityEvent[];
const DTEvents = techEventsJson as IDTEvent[];
const kpiCoeffs = kpiCoeffsJson as IKpiCoeff[];

const data: IData = {
  cityInfo: cityInfo,
  education: targetsEdu,
  health: targetsHealthcare,
  transport: targetsTransport,
  eHousing: targetsEhousing,
  eService: targetsEservices,
  security: targetsSecuritySafety,
  heads: heads,
  teamMembers: team,
  systems: systems,
  scopesContractor: scopesContractor,
  employeeTraining: employeeTraining,
  userAnalysis: userAnalysis,
  scopeAgencies: scopeAgencies,
  administrativeExperts: administrative,
  infrastuctureExpers: infrastructure,
  testModes: testModes,
  cityEvents: cityEvents,
  DTEvents: DTEvents,
  finMetrics: finMetrics,
  kpiCoeffs: kpiCoeffs
}

export default data;