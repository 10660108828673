import { Action } from "redux";
import { IErrorResponse } from "../../api/common";
import { IFailAction } from "../../models/common";
import { IUserDto } from "../../api/user";

export const GET_CURRENT_USER_INIT = 'GET_CURRENT_USER_INIT';
export const GET_CURRENT_USER_DONE = 'GET_CURRENT_USER_DONE';
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL';

export interface ICurrenUserDoneAction extends Action {
    user: IUserDto;
}

export function getCurrentUserInit() {
    return { type: GET_CURRENT_USER_INIT };
}

export function getCurrentUserDone(user: IUserDto) {
    return { type: GET_CURRENT_USER_DONE, user } as ICurrenUserDoneAction;
}

export function getCurrentUserFail(error: IErrorResponse) {
    return { type: GET_CURRENT_USER_FAIL, error } as IFailAction;
}