
export const OPEN_TEST_MODE_FORM = 'OPEN_TEST_MODE_FORM';
export const CLOSE_TEST_MODE_FORM = 'CLOSE_TEST_MODE_FORM';

export function openTestModeForm() {
    return { type: OPEN_TEST_MODE_FORM };
}

export function closeTestModeForm() {
    return { type: CLOSE_TEST_MODE_FORM };
}
