
import React, { useEffect, useState } from 'react';
import { getOpenGameDashboard } from '../../../redux/selectors/forms/screens/openScreen';
import { useSelector, useDispatch } from 'react-redux';
import GameForm from '../../UiKit/GameForm';
import { Dashboard } from './Dashboard';
import { closeGameDashboard } from '../../../redux/actions/forms/openGameDashboard';
import { getLearningCompleted } from '../../../redux/selectors/game';
import { useScrollComponent } from '../../../hooks/useScrollComponent';


export default function GameDashboard() {

    const { isOpen, currentBoard } = useSelector(getOpenGameDashboard);
    const dispatch = useDispatch();
    const learningCompleted = useSelector(getLearningCompleted);
    const [scrollComponent, setScrollComponent] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            const scrollComponent = document.getElementById("scroll");
            setScrollComponent(scrollComponent);
        }
    }, [isOpen])
    useScrollComponent(learningCompleted, scrollComponent);

    return isOpen
        ? <GameForm close={() => { dispatch(closeGameDashboard()) }} scrollId="scroll">
            <Dashboard currentBoard={currentBoard} />
        </GameForm>
        : null;
}

