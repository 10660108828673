import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { CloseFormIcon } from '../../../icons';
import Scrollbars from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';

interface IProps {
  headerProgressBar?: React.ReactNode;
  close: (event: any) => void;
  className?: string;
  dataTut?: any;
  formClassName?: string;
  scrollId?: string;
}

const GameForm: React.FunctionComponent<IProps> = (props) => {

  const { headerProgressBar, close, className, dataTut, formClassName, scrollId } = props;

  return (
    <div className={cn(style.wrapper, className)}>
      <div className={style.container} style={headerProgressBar ?  { height: 'calc(100% - 180px)' } : { height: 'calc(100% - 170px)' }} data-tut={dataTut}>
        {headerProgressBar && headerProgressBar}
        <div className={cn(style.form, formClassName)}>
          <div className={style.header}>
            <CloseFormIcon data-tut={"Close"} onClick={(e: any) => close(e)} />
          </div>
          <Scrollbars style={{ height: '100%' }} onScroll={()=>ReactTooltip.hide()} id={scrollId}>
            {props.children}
          </Scrollbars >
        </div>
      </div>
    </div>
  );
}

export default GameForm;