import React, { useState } from 'react';
import style from './style.module.scss';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../../../UiKit/ScoreProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import { getTargets, getSelectedSystems } from '../../../../../redux/selectors';
import useScopes from '../../../../../hooks/useScopes';
import { ExpandIcon } from '../../../../../icons';
import { getKpiUnit, ISystem } from '../../../../../models'
import { calcGoalAchievementRate } from '../../../../../service/calcGoalsAchievementRate';
import gamePoints from '../../../gamePoints';
import { hightlightPointInit, openDirectionForm, openSystemImplForm, systemsChange } from '../../../../../redux/actions';
import { openScopeForm } from '../../../../../redux/actions/forms/openScope';
import { closeGameDashboard, openGameDashboard } from '../../../../../redux/actions/forms/openGameDashboard';
import { formatKpiDisplayValue } from '../../../../../service/calcDisplayKpi';

export function GoalsProgress() {

    const targets = useSelector(getTargets); // цели, которые выбрал пользователь
    const scopes = useScopes(); // все имеющиеся сферы
    const selectedSystems = useSelector(getSelectedSystems) // внедряемые в данный момент системы
    const dispatch = useDispatch();

    const [collapseState, setCollapseState] = useState<boolean[]>(targets.map(() => false));

    const handleCollapse = (index: number) => {
        collapseState[index] = !collapseState[index];
        setCollapseState([...collapseState]);
    }

    const handleDirectionClick = (scopeId: number, directionId: number) => {
        dispatch(closeGameDashboard());
        const point = gamePoints.find(x => x.scopeId === scopeId && x.directionId === directionId);
        dispatch(hightlightPointInit(point));
        setTimeout(() => {
            dispatch(openDirectionForm(scopeId, directionId, () => dispatch(openGameDashboard())));
        }, 2000);
    }

    const handleSystemClick = (scopeId: number) => {
        dispatch(closeGameDashboard());
        const point = gamePoints.find(x => x.scopeId === scopeId);
        dispatch(hightlightPointInit(point))
        setTimeout(() => {
            dispatch(openScopeForm(scopeId));
        }, 2000);
    }

    const handleSystemImplClick = (systemId: number, scopeId: number, directionId: number) => {
        dispatch(closeGameDashboard());
        const point = gamePoints.find(x => x.scopeId === scopeId && x.directionId === directionId);
        if (selectedSystems.findIndex(ss => ss.systemId === systemId) < 0) {
            const newSystem = { systemId: systemId, revenue: 0, success: 0, usedSystemTypes: [] as string[], usedUpgradeEvents: [] as number[] } as ISystem;
            dispatch(systemsChange([...selectedSystems, newSystem]));
        }

        dispatch(hightlightPointInit(point))
        setTimeout(() => {
            dispatch(openSystemImplForm(systemId, scopeId, () => dispatch(openGameDashboard(2))))
        }, 2000);
    }

    return <>
        <div className={style.subTitle}>Goals achievment progress</div>

        {targets.map((goal, index) => {

            const goalSys = scopes.find(si => si.scopeId === goal.scopeId);
            if (!goalSys) return '';

            const goalDirection = goalSys.directions?.find(dir => dir.directionId === goal.directionId);
            if (!goalDirection) return '';

            const commonTarget = goalDirection.commonTargets.find(ct => ct.commonTargetId === goal.commonTargetId);
            if (!commonTarget) return '';

            const digitalTarget = commonTarget.digitalTargets.find(dt => dt.digitalTargetId === goal.digitalTargetId);
            if (!digitalTarget) return '';

            const success = calcGoalAchievementRate(goal, scopes, selectedSystems) * 100;
            const idx = commonTarget.description.indexOf('of ');
            const description = commonTarget.description.substring(0, idx);
            const systemeName = commonTarget.description.substring(idx + 3, commonTarget.description.length);

            return (<div key={index} className={style.goal}>
                <div className={style.goalHeader}>
                    <div>
                        <div className={style.goalTitle}>{`Goal ${index + 1}:`}</div>
                        <div>
                            <span>{description}</span>
                            <span className={style.underline} onClick={() => handleSystemImplClick(commonTarget.systemId, goalSys.scopeId, goalDirection.directionId)}>
                                {systemeName}
                            </span>
                        </div>
                    </div>
                    <div>
                        <ExpandIcon className={collapseState[index] ? style.rollout : ''}
                            onClick={() => handleCollapse(index)} />
                    </div>

                </div>
                {collapseState[index] && <>
                    <div className={style.detail}>Sphere:
                        {<span className={style.underline} onClick={() => handleSystemClick(goalSys.scopeId)}>{goalSys?.title}</span>}&nbsp; {`/`}{<span className={style.underline} onClick={() => handleDirectionClick(goalSys.scopeId, goalDirection.directionId)}>{goalDirection?.title}</span>}
                    </div>
                    <div className={style.detail}>Digital transformation goal:
                        <span>{commonTarget?.title}</span>
                    </div>
                    <div className={style.detail}>KPI:
                        <span>{digitalTarget.title}</span>
                    </div>
                    <div className={style.detail}>KPI Goal:
                       <span>
                            {formatKpiDisplayValue(digitalTarget.kpi)}
                            {getKpiUnit(digitalTarget.kpi.code)}
                        </span>
                    </div>
                </>
                }
                <ScoreProgressBar color={ScoreProgressBarColor.yellow} containerStyle={style.goalProgress}
                    title={digitalTarget.kpi.code} value={success}
                    unit={getKpiUnit(digitalTarget.kpi.code)} />
            </div>
            );
        })}
    </>;
}