export const getCurrentDay = (startTime: number, now: number): number => Math.trunc((now - startTime) / (24 * 60 * 60 * 1000));

// предикат, является ли сегодняшнее число(now) - nthDay(каждым 3, 4, ..т) по счету или нет
export const isNowNthDay: (startTime: number, now: number, nthDay: number) => boolean = (startTime, now, nthDay) => {
  const day = getCurrentDay(startTime, now);
  if (startTime > 0 && now > 0 && day > 0) {
    return day % nthDay === 0;
  }

  return false;
}

