import { IMoney } from "../models";

export const changeMoneyService = (oldMoney: IMoney, oddsBuget: number = 0, moneyQplus: number = 0, moneyQminus: number = 0) : IMoney => {
    const newMoney: IMoney = {
        budget: oldMoney.budget + oddsBuget,
        moneyQminus: oldMoney.moneyQminus + moneyQminus,
        moneyQplus: oldMoney.moneyQplus + moneyQplus
    }
    
    return newMoney
}