import React, {  } from 'react';
import style from './style.module.scss';
import { Indicator } from './Indicator';
import { useSelector } from 'react-redux';
import { getSelectedSystems, getLaunchTimer, getFinMetrics } from '../../../../redux/selectors';

export const FinancialIndicators = () => {
    const systems = useSelector(getSelectedSystems);
    const finMetrics = useSelector(getFinMetrics);
    const timerLaunched = useSelector(getLaunchTimer);

    const renderIndicators = () => {
        return finMetrics.map((x, index) => {
            return <Indicator key={index} dataTut={index === 0 ? "FinancialIndicatorsMesuare" : ""} timerLaunched={timerLaunched} finMetric={x} systems={systems} className={style.indicator}
            />
        })
    }

    return <div className={style.financialIndicators}>
        <div className={style.description}></div>
        <div className={style.indicators}>
            {renderIndicators()}
        </div>
    </div>
}