import { ProcedureState } from ".";
import { useSelector } from "react-redux";
import { getCurrentTeamMembers } from "../../../redux/selectors";

export function useEmployeeTrainingProcedure(){
    
    const team = useSelector(getCurrentTeamMembers);
    let state: ProcedureState = 'Default';
    if (team.findIndex(t => t.id === 3) >= 0) {
        state = 'Advanced';
    }
    return state;
}