import React from 'react';
import style from './style.module.scss';

interface IProp {
    title: string;
    description: string;
    className?: string;
}

const AbstractInfoCard: React.FunctionComponent<IProp> = (props) => {
    const { title, description, children, className } = props;

    return <div className={className}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>{description}</div>
        <div className={style.body}>
            {children}
        </div>
    </div>
}


export default AbstractInfoCard;
