import { Action } from "redux";
import { ICityEvent } from "../../../models/event";

export const CURRENT_CITY_EVENT = 'CURRENT_CITY_EVENT';

export interface ISetCurrentCityEventAction extends Action {
    currentCityEvent: ICityEvent | null;
    eventDate: number;
}

export function currentCityEventSet(currentCityEvent: ICityEvent | null, eventDate: number) {
    return { type: CURRENT_CITY_EVENT, currentCityEvent, eventDate };
}
