import React from 'react';
import style from './style.module.scss';
import { ITeamMember } from '../../../../../models';
import { avatar } from '../../../../../helpers';

interface ISpecialistInfoProsps {
    specialist: ITeamMember;
}

const SpecialistView: React.FunctionComponent<ISpecialistInfoProsps> = (props) => {
    const { specialist } = props;

    return (
        <div className={style.specialistInfo}>
            <div className={style.avatar}>
                <img src={avatar(specialist.avatar)} alt="Specialist avatar" />
            </div>
            <div className={style.specialistInfoLeft}>
                <h1>{specialist.position}</h1>
                <div className={style.infoContainter}>
                    <div className={style.quarterlyWage}>
                        <div className={style.quarterlyWageName}>
                            Quarterly Wage:
                        </div>
                        <div className={style.quarterlyWageValue}>
                            {specialist.quarterlyWage}
                        </div>
                    </div>
                    <div className={style.descriptionHired}>
                        {specialist.description_hired}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpecialistView;
