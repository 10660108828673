import { Action } from "redux";
import { CHANGE_STAGE, IGameChangeStage } from "../../actions/game";
import { GameStageEnum } from "../../../models";

export const initial: GameStageEnum = GameStageEnum.STAGE_2_CITY_INFO;

export const reducer = (action: Action): GameStageEnum => {
    switch (action.type) {
        case CHANGE_STAGE:
            const { stage } = action as IGameChangeStage;
            return stage;   

        default:
            return initial;
    }
};