import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';
import { IContractRevenueMax, contractTypeToHumanView, ContractModelEnum } from '../../../models';
import ImplIndicator from '../ImplIndicator';
import InfoFooter from '../../UiKit/InfoFooter';
import { convertMoneyToString, convertTimeToString } from '../../../service/convertorToString';


const BIZCONCESSION = 80; // III. Переменные, стр. 78
const BIZSUPPORT = 70; // III. Переменные, стр. 79

interface IImplStepCard {
    businessReputation: number; 
    contract: IContractRevenueMax;
    handleClick: () => void;
}

function minTimeCost(contract: IContractRevenueMax) {
    const min = Math.min(...contract.contractors.map(c =>c.timecost));
    return convertTimeToString(min)
}

function minImplCost(contract: IContractRevenueMax) {
    const min = Math.min(...contract.contractors.map(c =>c.moneyMinus));
    return convertMoneyToString(min)
}

const ContractCard: React.FunctionComponent<IImplStepCard> = (props) => {

    const { contract, handleClick, businessReputation } = props;

    const info = contractTypeToHumanView(contract.type);

    const disabled = () => {
        if(contract.type === ContractModelEnum.Concession && businessReputation < BIZCONCESSION)
            return true;

        if(contract.type === ContractModelEnum.BusinessProjectSupport && businessReputation < BIZSUPPORT)
            return true;

        return false;
    }

    return <AbstractCard className={style.card} selected={false} clickAndGo={false} handleClick={handleClick} disabled={disabled()}>
            <div className={style.contract}>
                <div className={style.title}>{info.title}</div>     
                <div className={style.subTitle}>{info.desc}</div>   

                <div className={style.scores}>
                    <ImplIndicator title={'Maximum quarterly revenue'} value={convertMoneyToString(contract.revenueMax)}/>

                    <div>
                        <ImplIndicator title={'Minimum implementation cost'} value={minImplCost(contract)}/>
                        <ImplIndicator title={'Minimum time costs'} value={minTimeCost(contract)}/>
                    </div>
                </div>  

                {disabled() && <InfoFooter className={style.infoFooter}>{`Reach Business ${contract.type === ContractModelEnum.Concession ? BIZCONCESSION : BIZSUPPORT} to be able to apply this model`}</InfoFooter>}    
            </div>           
        </AbstractCard>;
}

export default ContractCard;