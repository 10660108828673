import React from 'react';
import style from './style.module.scss';
import { Switch, Route } from 'react-router-dom';
import MainPage from '../components/MainPage';
import Profile from '../components/Profile';

import Game from '../components/Game';

export function App() {
  return (
    <div className={style.app}>
      <Switch>
        <Route exact={true} path={['/', '/demo/login']} component={MainPage} />
        <Route exact={true} path={'/profile'} component={Profile} />
        <Route path={'/game/:id'} component={Game} />
      </Switch>
    </div>
  );
}

export default App;
