import { INameValue, getCurrentMN } from "../models";
import data from "../data";
import useScopes from "./useScopes";
import { useSelector } from "react-redux";
import { getSelectedSystems, getFinMetrics, getCurrentTeamMembers, getMoney } from "../redux/selectors";



export function useQuaterlyMoney() {

    const scopes = useScopes();
    const money = useSelector(getMoney);
    const finMetrics = useSelector(getFinMetrics);
    const systems = useSelector(getSelectedSystems);
    const team = useSelector(getCurrentTeamMembers);
    
    let revenueItems: INameValue[] = [];
    let spendingItems: INameValue[] = [];
    let totalQminus = 0;
    let totalQplus = 0;

    revenueItems = [...revenueItems, {name: 'Fin indicators:' }];
    spendingItems = [...spendingItems, {name: 'Fin indicators:' }];

    finMetrics.forEach(fm => {
        const currentMN = getCurrentMN(fm, systems);
        totalQplus = totalQplus + fm.coefmoneyQplusMN * currentMN;
        totalQminus = totalQminus + fm.coefmoneyQminusMN * currentMN;
        revenueItems = [...revenueItems, {name: fm.name, value: fm.coefmoneyQplusMN * currentMN}];
        spendingItems = [...spendingItems, {name: fm.name, value: fm.coefmoneyQminusMN * currentMN}];
    });

    spendingItems = [...spendingItems, {name: 'Sphere heads:' }];

    scopes.forEach(sc => {
        const head = data.heads.find(h => h.headId === sc.headId);
        if(!head) return;
        totalQminus = totalQminus + head.moneyQminus;
        spendingItems = [...spendingItems, {name: head.title, value: head.moneyQminus}];
    });

    spendingItems = [...spendingItems, {name: 'Permanent Team:' }];

    team.forEach(tm => {
        totalQminus = totalQminus + tm.quarterlyWage;
        spendingItems = [...spendingItems, {name: tm.position, value: tm.quarterlyWage}];
    });

    revenueItems = [...revenueItems, {name: 'Systems:' }];
    spendingItems = [...spendingItems, {name: 'Systems:' }];

    systems.forEach(s => {
        const sys = data.systems.find(ss => ss.id === s.systemId);
        if(s.fistStageDone && sys) {
            totalQplus = totalQplus + s.revenue;
            totalQminus = totalQminus + s.moneyQminus;

            revenueItems = [...revenueItems, {name: sys?.title, value: s.revenue}];
            spendingItems = [...spendingItems, {name: sys?.title, value: s.moneyQminus}];
        }
    });   

    // console.log(`Currently quaterly money: plus ${totalQplus}, minus ${totalQminus}`);

    return {
        totalQminus: money.moneyQminus + totalQminus,
        totalQplus: money.moneyQplus + totalQplus,
        revenueItems,
        spendingItems
    }
}