import React from 'react';
import cn from 'classnames';
import { SpiderIcon, CircleIcon } from '../../../../icons';
import './style.scss';

const ROUND_LENGTH: number = 128.74;
const SPEDER_LEGS = [2, 4, 6, 18, 20, 22, 27, 29, 31, 43, 45, 47, 52, 54, 56, 68, 70, 72, 78, 79, 81, 93, 95, 97];

interface IProps {
    value: number
}

export function Spider(props: IProps) {

    const className = `spider-${Date.now()}`;
    
    const createLineClass = () => {
        
        let i = 0;
        SPEDER_LEGS.forEach(x => {
            var style = document.createElement('style');
            style.type = 'text/css';
            if(props.value >= x) {
                style.innerHTML = `.${className} > * > .line-${i + 1} { stroke: #ffcc00; }`;
            }           
            else {
                style.innerHTML = `.${className} > * > .line-${i + 1} { stroke: #0F5499; }`;
            } 
            document.getElementsByTagName('head')[0].appendChild(style);
            i++;
        })
    }

    const createBar = () => {
        const bar = ROUND_LENGTH * props.value / 100;
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `.${className} > * > .bar { stroke: #ffcc00 !important; stroke-dashoffset: calc(${ROUND_LENGTH}px - ${bar}px) !important;}`;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createLineClass();
    createBar();
    return (
        <div className={cn('spider', className)}>
            <CircleIcon className="round" />
            <SpiderIcon className={"line"} />
        </div>
    );
}

export default Spider;