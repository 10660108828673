import React from 'react';
import data from '../../../data';
import AbstractProcedures from './AbstractProcedures';
import TestCard from '../TestCard'
import { ITestMode } from '../../../models/testMode';
import { useTestProcedures } from './useTestProcedures';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../UiKit/Buttons/common';

interface IProceduresProps {
    breadcrumbs: string[];
    currentTests: ITestMode[];
    testClick: (test: ITestMode) => void;
    gotItClick: () => void;
    backClick: () => void;
}

export const TestProcedures: React.FunctionComponent<IProceduresProps> = (props) => {

    const { breadcrumbs, testClick, gotItClick, currentTests, backClick } = props;
    const testModes = data.testModes;

    const state = useTestProcedures();

    return <AbstractProcedures
        headerTitle={"Tests"}
        breadcrumbs={breadcrumbs}
        state={state}
        gotItClick={gotItClick}
        backClick={backClick}
    >
        {testModes.map((item, i) =>
            <TestCard key={i}
                selected={currentTests.findIndex(t => t.id === item.id) >= 0}
                lable={item.name}
                time={item.timeCost}
                money={item.moneyMinus}
                handleClick={() => testClick(item)}
            />
        )}

        <ClassicButton dataTut={"TestsDone"} handleClick={gotItClick} size={SizeButton.large} label={"Done"} />
    </AbstractProcedures>
}