import React from 'react';
import cn from 'classnames';
import { IDigitalTarget } from '../../../../../models';
import style from './style.module.scss';
import { RadioOnIcon, RadioOffIcon } from '../../../../../icons';

interface IProps {
    checked: boolean;
    target: IDigitalTarget;
    setTargetId: (id: number) => void;
    disabled: (id: number) => boolean;
    dataTut: any;
}

const DigitalTargetItem: React.FunctionComponent<IProps> = (props) => {

    const { checked, target, setTargetId, disabled, dataTut } = props;

    const isDisabled = disabled(target.digitalTargetId);

    const setChecked = () => {
        if (!isDisabled) setTargetId(target.digitalTargetId);
    }

    return <div data-tut={dataTut} className={cn(style.digitalTarget, isDisabled ? style.disabled : '')} onClick={() => setChecked()}>
        <div style={{ height: '20px' }}>{checked || isDisabled ? <RadioOnIcon /> : <RadioOffIcon />}</div>
        <div>{target.title}</div>
    </div>;
}
export default DigitalTargetItem;