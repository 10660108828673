import { IKpi, KpiCodeEnum } from "../models"

export const calcDisplayCsi = (csi: number) => {
    const result = (csi) / 10
    return result < 1 ? 1 : result
}

export const calcDisplayNps = (nps: number) => {
    return nps < 1 ? 1 : nps
}

export const formatKpiDisplayValue = (kpi: IKpi) => { 
    if(kpi.code === KpiCodeEnum.CSI) {
        return calcDisplayCsi(kpi.value);
    }
    if(kpi.code === KpiCodeEnum.NPS) { 
        return calcDisplayCsi(kpi.value);
    }

    return kpi.value;
}