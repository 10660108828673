import React from 'react';
import cn from 'classnames';
import { SwipeUpIcon } from '../../../../icons';

interface IProps {
    className?: any,
    onClick: () => void
}

export function SwipeUp(props: IProps) {
    return (
        <div id={'statisticSwipeUp'} className={cn(props.className)} onClick={props.onClick}>
            <SwipeUpIcon/>
        </div>
    );
}

export default SwipeUp;