export const convertMoneyToString = (money: number | undefined) => {
    if (!money) {
        return "-"
    }

    const value = Math.round(money)
    return `${value}K`
}

export const convertTimeToString = (time: number | undefined) => {
    if (!time) {
        return "-"
    }

    return `${time} days`
}