import React, { useState } from 'react';
import style from './style.module.scss';
import InfoFooter from '../../UiKit/InfoFooter';
import Header from '../Header';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../UiKit/Buttons/common';
import BackButton from '../../UiKit/Buttons/BackButton';
import PermanentTeamNotice from '../../ProcessesByTimer/PermanentTeamNotice';

interface IProceduresProps {
    breadcrumbs: string[];
    headerTitle: string;
    state: 'Default' | 'Advanced';
    gotItClick: () => void;
    backClick: () => void;
}

const AbstractProcedures: React.FunctionComponent<IProceduresProps> = (props) => {

    const { breadcrumbs, state, gotItClick, children, headerTitle, backClick } = props;
    const [isStarted, setIsStarted] = useState<boolean>(false);

    const handleStartClick = () => {
        setIsStarted(true);
    }

    const annotation = <div className={style.annotation}>
        <p className={style.text}>
            {`In this part of the preparation, you should decide whether to pay
            an expert to check your project for the ${headerTitle.toLowerCase().replace(' changes','')} changes needed.`}
        </p>
        <p className={style.text}>
            If you agree, choose the option that suits you.
        </p>

        <InfoFooter><PermanentTeamNotice closeParent={() => {}}/></InfoFooter>
        <div className={style.action}>
            <BackButton label={'Back'} handleClick={backClick}/>
            <ClassicButton size={SizeButton.large} label={'start'} handleClick={handleStartClick} />
        </div>
    </div>;

    const cardListView = <div className={style.specialistView}>
        <div className={style.expertList}>
            {children}
        </div>
        <InfoFooter><PermanentTeamNotice closeParent={() => {}}/></InfoFooter>
        <BackButton label={'Back'} handleClick={backClick}/>
    </div>;

    const advancedView = <div className={style.advancedView}>
        <p className={style.text}>
            A member of your team is already checking if some administrative procedures changes needed.
        </p>

        <ClassicButton handleClick={gotItClick} size={SizeButton.large} label={"Got it"} />
    </div >;


    return <div className={style.procedures}>
        <Header breadcrumbs={breadcrumbs} headerTitle={headerTitle} />

        {state === 'Advanced' 
        ? advancedView 
        : isStarted 
            ? cardListView 
            : annotation}
    </div>
}

export default AbstractProcedures;