import React from 'react';
import style from './style.module.scss';
import { ITarget, IScope, getKpiUnit } from '../../../../../models';
import GoalInfo from './GoalInfo';
import ProgressBar from '../../../ProgressBar';
import ClassicButton from '../../../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../../../UiKit/Buttons/common';
import { formatKpiDisplayValue } from '../../../../../service/calcDisplayKpi';

interface IProps {
    id: number;
    target: ITarget;
    scopes: IScope[];
    handelEdit: (id: number) => void;
    handelDelete: (id: number) => void;
}

const GoalItem: React.FunctionComponent<IProps> = (props) => {

    const { id, scopes, target, handelEdit, handelDelete } = props;

    const scope = scopes.find(s => s.scopeId === target.scopeId);
    if(!scope || !scope.directions) return null;

    const direction = scope.directions.find(d => d.directionId === target.directionId);
    if(!direction) return null;

    const commonTarget = direction.commonTargets.find(t => t.commonTargetId === target.commonTargetId);
    if(!commonTarget) return null;

    const digitalTarget = commonTarget.digitalTargets.find(t => t.digitalTargetId === target.digitalTargetId);
    if(!digitalTarget) return null;

    return <div className={style.goalItem}>
            <div className={style.header}>
                <div className={style.title}>Goal #{id+1}</div>
                <div>
                    <ClassicButton size={SizeButton.small} label={'Edit'} handleClick={() => handelEdit(id)} classes={style.btn} />
                    <ClassicButton size={SizeButton.small} label={'Delete'} handleClick={() => handelDelete(id)} classes={style.btn} />
                </div>
            </div>
        <div className={style.description}>{commonTarget.description}</div>
        <GoalInfo title={'Direction:'} subTitle={`${scope.title} / ${direction.title}`} bold={true} />
        <GoalInfo title={'Goal:'} subTitle={commonTarget.title} />
        <GoalInfo title={'KPI:'} subTitle={digitalTarget.title} />

        <div className={style.kpi}>
            <GoalInfo title={'KPI target:'} subTitle={digitalTarget.kpi.code.toString()} />
            <div className={style.kpiValue}>{formatKpiDisplayValue(digitalTarget.kpi)}{getKpiUnit(digitalTarget.kpi.code)}</div>
        </div>

        <ProgressBar completedValue={digitalTarget.kpi.value} background={style.progressBarBg} backgroundCompleted={style.progressBarCompletedBg}/>
        

    </div>
}

export default GoalItem;