import { ProcedureState } from ".";
import { useSelector } from "react-redux";
import { getCurrentTeamMembers } from "../../../redux/selectors";

export function useTestProcedures(){
    
    const team = useSelector(getCurrentTeamMembers);
    let state:ProcedureState = 'Default';
    if(team.findIndex(t => t.id === 4)>=0) {
        state = 'Advanced';
    }
    return state;
}