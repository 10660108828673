import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getGameInit } from "../../redux/actions";

export function useContinueGame() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getGameInit(Number.parseInt(id)));
  }, [dispatch, id]);
}