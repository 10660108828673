import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTimer, getCurrentDTEvent, getHappenedDTEventIdArr, getSelectedSystems, getCurrentDTEventDate, getLearningCompleted } from '../../redux/selectors';
import { timerPause, currentDTEventSet, currentDTEventIdSet } from '../../redux/actions';
import data from '../../data';
import { isNowNthDay } from '../CityEvents/helper';
import DTEvent from './DTEvent';
import { store } from '../../store/configureStore';

export const DTeventlag = 30;
export const DTeventP = 0.67;

const DTEvents = () => {

  const selectedSystem = useSelector(getSelectedSystems);
  const timer = useSelector(getTimer);
  const dispatch = useDispatch();

  const firedEventNow = useRef<boolean>(false);
  const curDTEvent = useSelector(getCurrentDTEvent);
  const curDTEventDate = useSelector(getCurrentDTEventDate);
  const learningCompleted = useSelector(getLearningCompleted);

  const firedEventsIds = useSelector(getHappenedDTEventIdArr);// Id случившихся событий

  const DTEventsIds: number[] = [...data.DTEvents.map(DTEvent => DTEvent.id)];

  useEffect(() => {
    // логика для проверки, что прошло N-дней и можно запускать событие
    const curDate = timer.curDate;
    const startDate = timer.startDate;

    const { isOpen: openedSystemEventForm } = store.getState().openSystemEvent;

    if (!curDTEvent && (curDate > curDTEventDate || !curDTEventDate) && learningCompleted && !openedSystemEventForm) {
      if (isNowNthDay(startDate, curDate, DTeventlag)) {
        const probability = Math.random() < DTeventP;

        if (probability) {

          //1. сгенерировали  случайное событие curDTEvent

          let notFiredDTEventsIds: number[] = [];
          if (firedEventsIds && firedEventsIds.length > 0) {
            notFiredDTEventsIds = DTEventsIds.filter(e => firedEventsIds.indexOf(e) < 0);
          } else {
            notFiredDTEventsIds = DTEventsIds;
          }

          notFiredDTEventsIds.sort(() => Math.random() - 0.5);
          const randomDTEvent = data.DTEvents.find(e => e.id === notFiredDTEventsIds[0]);

          if (randomDTEvent && selectedSystem.findIndex(s => s.fistStageDone && s.systemId === randomDTEvent.id) >= 0) {
            firedEventNow.current = true;
            // 2. остановили таймер
            dispatch(timerPause());
            dispatch(currentDTEventSet(randomDTEvent, curDate));
          }

        }
      }
    }
  }, [timer.curDate, selectedSystem, timer.startDate, curDTEvent, firedEventsIds, curDTEventDate, DTEventsIds, dispatch, learningCompleted])

  const handleUserReactsOnEvent = () => {
    // curEvent = null, чтобы убрать с экрана 
    dispatch(currentDTEventSet(null, curDTEventDate));
  }

  const handleUserConfirmEvent = () => {
    // добавить ID события в список появившихся уже событий
    if (curDTEvent) {
      firedEventNow.current = true;
      const currentEventId = curDTEvent.id;
      dispatch(currentDTEventIdSet(currentEventId));
    }
  }

  return firedEventNow.current && curDTEvent ? <DTEvent DTEvent={curDTEvent} handleOkClick={handleUserReactsOnEvent} handleConfirmEvent={handleUserConfirmEvent} /> : null;
}

export default DTEvents;
