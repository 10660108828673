import React from 'react'
import { NoneIcon, UpIcon, DownIcon } from '../../../icons'
import style from './style.module.scss';

interface IProps {
    peopleDelta: number;
    businessDelta: number; 
}

const ReputationChangeMessage: React.FunctionComponent<IProps> = (props) => {

    const { peopleDelta, businessDelta } = props;

    const icon = (value: number) => {
        if (value === 0)
            return <NoneIcon />
        else if (value > 0)
            return <UpIcon />
        else
            return <DownIcon />
    }
    
    return  <div className={style.resultBlocks}>
                <div className={style.block}>
                    {icon(peopleDelta)}
                    <span className={style.label}>Society:</span>
                    <span className={style.value}>{peopleDelta}</span>
                </div>
                <div className={style.block}>
                    {icon(businessDelta)}
                    <span className={style.label}>Business:</span>
                    <span className={style.value}>{businessDelta}</span>
                </div>
            </div>
}

export default ReputationChangeMessage;