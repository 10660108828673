import { IState } from "../../../reducers";

export const getIsOpenLearning = (state: IState) => state.openLearning.isOpen;
export const getIsOpenCityInfo = (state: IState) => state.openCityInfo;
export const getIsOpenGoals = (state: IState) => state.openGoals.isOpen;
export const getDirectionForm = (state: IState) => state.openDirection;
export const getScopeForm = (state: IState) => state.openScope;
export const getIsOpenSystemsScores = (state: IState) => state.openSystemsScopes.isOpen;
export const getScopeIdSystemsScores = (state: IState) => state.openSystemsScopes.scopeId;
export const getSystemIdSystemsScores = (state: IState) => state.openSystemsScopes.systemId;
export const getIsOpenAdministrative = (state: IState) => state.openAdministrative.isOpen;
export const getSystemImplForm = (state: IState) => state.openSystemImpl;
// export const getIsOpenSystemInfo = (state:IState) => state.openSystemInfo.isOpen;
// export const getSystemIdSystemInfo = (state:IState) => state.openSystemInfo.systemId;
// export const getScopeIdSystemInfo = (state:IState) => state.openSystemInfo.scopeId;
export const getIsOpenInfrastructure = (state: IState) => state.openInfrastructure.isOpen;
export const getIsOpenEmployeeTraining = (state: IState) => state.openEmployeeTraining.isOpen;
export const getIsOpenTestMode = (state: IState) => state.openTestMode.isOpen;
export const getIsOpenAnalysis = (state: IState) => state.openUserAnalysis.isOpen;
export const getIsOpenTeam = (state: IState) => state.openTeam.isOpen;
export const getIsOpenKpi = (state: IState) => state.openKpi.isOpen;
export const getSystemIdKpi = (state: IState) => state.openKpi.systemId;
export const getScopeIdKpi = (state: IState) => state.openKpi.scopeId;
export const getResultForm = (state:IState) => state.openResult;
export const getHeadsForm = (state: IState) => state.openHeads;
export const getUpgradeForm = (state:IState) => state.openUpgrade;
export const getOpenGameDashboard = (state: IState) => state.openGameDashboard;
export const getSystemEvent = (state: IState) => state.openSystemEvent;
