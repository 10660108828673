import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { SizeButton, ColorButton } from '../common';

interface IButtonProps {
    label: string;
    size?: SizeButton;
    color?: ColorButton;
    disabled?: boolean;
    handleClick: () => void;
    classes?: string;
    dataTut?: any;
}

const getStyle = (s: SizeButton) => {
    if (s === SizeButton.small) return style.small;
    if (s === SizeButton.large) return style.large;

    return style.normal;
}

const ClassicButton: React.FunctionComponent<IButtonProps> = (props) => {

    const { label, size = SizeButton.normal, handleClick, classes, disabled = false, color = ColorButton.default, dataTut } = props;
    
    return <button data-tut={dataTut} disabled={disabled} className={cn(getStyle(size), color === ColorButton.azure ? style.azure : '', classes)} onClick={handleClick}>
        {label}
    </button>
}

export default ClassicButton;