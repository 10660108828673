
export const OPEN_USER_ANALYSIS_FORM = 'OPEN_USER_ANALYSIS_FORM';
export const CLOSE_USER_ANALYSIS_FORM = 'CLOSE_USER_ANALYSIS_FORM';

export function openUserAnalysiseForm() {
    return { type: OPEN_USER_ANALYSIS_FORM };
}

export function closeUserAnalysisForm() {
    return { type: CLOSE_USER_ANALYSIS_FORM };
}
