import React from 'react';
import style from './style.module.scss';
import { useDispatch } from 'react-redux';
import { openGameDashboard } from '../../../redux/actions/forms/openGameDashboard';

interface IProps {
    closeParent: () => void;
}

const PermanentTeamNotice: React.FunctionComponent<IProps> = (props) => {

    const { closeParent } = props;

    const dispatch = useDispatch();
    
    const openPermamentTeam = () => {        
        dispatch(openGameDashboard(4));
        closeParent();
    }

    const linkButton = <span className={style.link} onClick={()=>openPermamentTeam()}>Permanent Team & Contractors</span>

    return <>In order not to pay for this part every time, you can hire a permanent specialist in your team. You can do this in the {linkButton} section.</>
}

export default PermanentTeamNotice;