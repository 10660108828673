import React from 'react';
import style from './style.module.scss';
import Timer from '../../Timer';
import { Link } from 'react-router-dom';
import ChangeViewButton from '../ChangeViewButton';

export function HeaderGame() {
  const renderTimer = () => {
    return <div className={style.timer}>
      <Timer />
    </div>
  }

  return (
    <div className={style.header}>
      <div className={style.inside}>
        <Link to={'/profile'}>
          <div className={style.icon} />
        </Link>

        <div className={style.rightPanel}>
          <ChangeViewButton />
          {renderTimer()}
        </div>
      </div>
    </div>
  );
}

export default HeaderGame;