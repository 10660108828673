import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFinMetrics, getSelectedSystems, getCityInfo } from '../../../redux/selectors';
import { systemChange, assistantNotifyAdd } from '../../../redux/actions';
import data from '../../../data';
import useScopes from '../../../hooks/useScopes';
import { calcRevenueMax, calcGameKpi } from '../../../service/calcGameScores';
import { getKpiFromList } from '../../../models';
import { calcReputation } from './scores';
import ReputationChangeMessage from '../../AssistantNotify/ReputationChangeMessage';
import moment from "moment";


export default function GameScoresCalc() {

    const dispatch = useDispatch();
    const scopes = useScopes();
    const cityInfo = useSelector(getCityInfo);
    const systems = useSelector(getSelectedSystems)
    const finMetrics = useSelector(getFinMetrics);

    useEffect(() => {
        if (!cityInfo) return;

        const { changePeople, changeBusiness } = calcReputation(cityInfo, systems, finMetrics, scopes, dispatch);

        if (cityInfo.business > 0 && cityInfo.people > 0 && (changePeople !== 0 || changeBusiness !== 0))
            dispatch(assistantNotifyAdd({ message: <ReputationChangeMessage businessDelta={changeBusiness} peopleDelta={changePeople} />, notifyId: moment().valueOf() }));


    }, [dispatch, cityInfo, scopes, finMetrics, systems])

    useEffect(() => {
        systems.map(s => {
            const kpis = calcGameKpi(s);
            if (s.fistStageDone && (s.kpis === undefined || (kpis.cov !== s.kpis.cov || kpis.csi !== s.kpis.csi
                || kpis.er !== s.kpis.er || kpis.fcr !== s.kpis.fcr
                || kpis.fcr !== s.kpis.fcr || kpis.nps !== s.kpis.nps))) {
                // console.log('Считаем kpi');
                dispatch(systemChange({ ...s, kpis: { ...kpis } }));
            }
            return kpis;
        })
    }, [dispatch, systems]);

    useEffect(() => {

        // Расчет показателей по система, KPI по системам
        systems.forEach(s => {

            const systemInfo = data.systems.find(df => df.id === s.systemId);
            if (!systemInfo) return;

            const mainKpi = systemInfo.systemKpies.find(sk => sk.isMain);

            if (s.kpis && mainKpi) {
                // Основные показатели системы
                const success = Math.min(getKpiFromList(s.kpis, mainKpi.code) / mainKpi.targetValue, 1);
                const { revenue } = calcRevenueMax(s, s.kpis?.cov, s.kpis?.er);

                // console.log('System change', mainKpi, s.kpis, success, revenue, s.revenue, s.success)

                if (s.revenue !== revenue && s.success !== success)
                    dispatch(systemChange({ ...s, revenue: revenue, success: success }));
                else if (s.revenue !== revenue)
                    dispatch(systemChange({ ...s, revenue: revenue }));
                else if (s.success !== success)
                    dispatch(systemChange({ ...s, success: success }));
            }

        });

    }, [dispatch, finMetrics, systems]);

    return <div />;
}