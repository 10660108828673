import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';

interface IImplStepCard {
   title: string;
   subTitle: string;
   selected?: boolean;
   disabled?: boolean;
   handleClick: () => void;
   dataTut?: any;
}

const NextStepCard: React.FunctionComponent<IImplStepCard> = (props) => {

    const { title, subTitle, handleClick, selected = false, disabled = false, children, dataTut } = props;

    return <AbstractCard className={style.card} disabled={disabled} selected={selected} clickAndGo={false} handleClick={handleClick} dataTut={dataTut}>
            <div className={style.implStep}>
                <div className={style.title}>{title}</div>     
                <div className={style.subTitle}>{subTitle}</div>     
                {children}    
            </div>           
        </AbstractCard>;
}

export default NextStepCard;