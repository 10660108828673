import React from 'react';
import style from './style.module.scss';
import Breadcrumps from '../../UiKit/CustomBreadcrumps';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../UiKit/ScoreProgressBar';
import { convertMoneyToString } from '../../../service/convertorToString';

interface IProps {
    breadcrumbs: string[];
    headerTitle: string;
    description?: string;
    digitalTransformationRate?: number;
    revenuePerQuater?: number;
}

const Header: React.FunctionComponent<IProps> = (props) => {

    const { breadcrumbs, headerTitle, description, revenuePerQuater, digitalTransformationRate } = props;
    return <div className={style.header}>
        <Breadcrumps path={breadcrumbs} />
        <div className={style.title}>{headerTitle}</div>
        {description && <div className={style.description}>{description}</div>}
        {digitalTransformationRate !== undefined && revenuePerQuater !== undefined && <div className={style.info}>
            <div className={style.revenue}>
                <div>Systems Revenue per Quarter</div>
                <div className={style.value}>{convertMoneyToString(Math.round(revenuePerQuater))}</div>
            </div>
            <ScoreProgressBar color={ScoreProgressBarColor.yellow} title={'Digital Transformaion Rate'} titleStyle={style.dtrTitle} value={digitalTransformationRate} valueStyle={style.dtrValue} unit='%' />
        </div>}
    </div>;
}

export default Header;