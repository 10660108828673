import { Action } from "redux";
import { OPEN_SYSTEMS_SCORES_FORM, IOpenSystemsScoresAction, CLOSE_SYSTEMS_SCORES_FORM } from "../../actions";
export interface IState {
    isOpen: boolean;
    scopeId: number;
    systemId: number;
}

export const initial: IState = {
    isOpen: false,
    scopeId: 0,
    systemId: 0
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_SYSTEMS_SCORES_FORM:
            const {scopeId, systemId} = action as IOpenSystemsScoresAction
            return {
                ...state,
                isOpen: true,
                systemId,
                scopeId
            };

        case CLOSE_SYSTEMS_SCORES_FORM:
            return {
                ...state,
                isOpen: false,
            };

        default:
            return state;
    }
}