import { Action } from "redux";
import { IScope, IGame, ScopeEnum } from "../../../models";
import { SCOPES_CHANGE, IScopesChangeAction } from "../../actions";
import data from "../../../data";

export const initial: IScope[] = [];

export const initialEducation: IScope = {
    scopeId: data.education.scopeId,
    headId: data.education.headId,
    fireHeads: [],
    title: data.education.title,
    directions: data.education.directions
}

export const initialHealth: IScope = {
    scopeId: data.health.scopeId,
    headId: data.health.headId,    
    title: data.health.title,
    fireHeads: [],
    directions: data.health.directions
}

export const initialEhousing: IScope = {
    scopeId: data.eHousing.scopeId,
    headId: data.eHousing.headId,
    title: data.eHousing.title,
    fireHeads: [],
    directions: data.eHousing.directions
}

export const initialEservice: IScope = {
    scopeId: data.eService.scopeId,
    headId: data.eService.headId,
    title: data.eService.title,
    fireHeads: [],
    directions: data.eService.directions
}

export const initialTransport: IScope = {
    scopeId: data.transport.scopeId,
    headId: data.transport.headId,
    title: data.transport.title,
    fireHeads: [],
    directions: data.transport.directions
}

export const initialSecurity: IScope = {
    scopeId: data.security.scopeId,
    headId: data.security.headId,
    title: data.security.title,
    fireHeads: [],
    directions: data.security.directions
}

export const reducer = (state: IGame, action: Action) => {
    switch (action.type) {
        case SCOPES_CHANGE: {
            const { scopeId, scope } = action as IScopesChangeAction
            switch (scopeId) {
                case ScopeEnum.EDUCATION: {
                    return { ...state, education: scope }
                }
                case ScopeEnum.HEALTH: {
                    return { ...state, health: scope }
                }
                case ScopeEnum.TRANSPORT: {
                    return { ...state, transport: scope }
                }
                case ScopeEnum.E_HOUSING: {
                    return { ...state, eHousing: scope }
                }
                case ScopeEnum.E_SERVICES: {
                    return { ...state, eService: scope }
                }
                case ScopeEnum.SECURITY: {
                    return { ...state, security: scope }
                }
                default: {
                    return state;
                }
            }
        }
        default:
            return state;
    }
}