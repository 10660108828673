import { useSelector, useDispatch } from "react-redux";
import { getGameStage } from "../../redux/selectors";
import { useEffect, useCallback } from "react";
import { GameStageEnum } from "../../models";
import { openLearningForm, openCityInfoForm, openGoalsForm, closeLearningForm, closeCityInfoForm, closeGoalsForm, closeResultForm } from '../../redux/actions/forms';

export function useDefineGameStage() {
  const gameStage = useSelector(getGameStage);
  const dispatch = useDispatch();

  const defineGameStage = useCallback((stage: GameStageEnum) => {
    switch (stage) {
      case GameStageEnum.STAGE_1_LEARNING:
        dispatch(openLearningForm())
        break;
      case GameStageEnum.STAGE_2_CITY_INFO:
        dispatch(closeLearningForm())
        dispatch(openCityInfoForm())
        break;
      case GameStageEnum.STAGE_3_GOALS:
        dispatch(closeCityInfoForm())
        dispatch(openGoalsForm())
        break;
      case GameStageEnum.STAGE_4_GAME:
        dispatch(closeCityInfoForm())
        dispatch(closeLearningForm())
        dispatch(closeGoalsForm())
        dispatch(closeResultForm())
        break;
      default: break;
    }
  }, [dispatch])

  useEffect(() => {
    defineGameStage(gameStage)
  }, [gameStage, defineGameStage])
}