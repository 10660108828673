import React from 'react'
import { IDirection } from '../../../../../models'
import CommonTarget from './CommonTarget';
import style from './style.module.scss';

interface IProps {
    direction: IDirection;
    currentCommonTargetId: number;
    setCommonTargetId: (commonTargetId: number) => void;
    disabledCommonTargetId: (targetId: number) => boolean;
}

const DirectionItem: React.FunctionComponent<IProps> = (props) => {

    const { direction, setCommonTargetId, currentCommonTargetId, disabledCommonTargetId } = props;

    const targets = direction.commonTargets.map((t, i) => <CommonTarget dataTut={t.commonTargetId === 24 ? "Direction" : ""} key={i} checked={currentCommonTargetId === t.commonTargetId} disabled={disabledCommonTargetId(t.commonTargetId)} target={t} setTargetId={setCommonTargetId} />);

    return <div className={style.direction}>
        <div className={style.title}>{direction.title}</div>
        {targets}
    </div>
}
export default DirectionItem;