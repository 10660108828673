import React from 'react';
import style from './style.module.scss';
import { GoToIcon } from './../../../../../icons';
import { ISystem } from '../../../../../models';
import data from '../../../../../data';
import { useSelector, useDispatch } from 'react-redux';
import { getTimer, getCurrentTeamMembers } from '../../../../../redux/selectors';
import moment from "moment";
import { TIME_COST_ADVANCED } from '../../../../ProcessesByTimer';
import { openSystemImplForm, openUpgradeForm } from '../../../../../redux/actions';

interface IProps {
    system: ISystem;
}

const SystemProcess: React.FunctionComponent<IProps> = (props) => {
    const { system } = props;

    const team = useSelector(getCurrentTeamMembers);
    const timer = useSelector(getTimer);

    const defaultSystem = data.systems.find(x => x.id === system.systemId);
    const curTime = moment(timer.curDate);
    const SIGNING_THE_CONTRACT = 'Signing the contract';
    const ADMINISTRATIVE_PROCEDURES = 'Administrative procedures';
    const INFRASTRUCTURE_CHANGES = 'Infrastructure changes';
    const DEVELOPMENT = 'Development';
    const UPGRADE = 'Upgrade';
    const MARKETING = 'Marketing campaign';

    const dispatch = useDispatch();
    let implStepName = '';
    let timeLeft = 0;
    let timeLeftLable: any;

    if (!defaultSystem) return null;

    const handleClick = () => {
        if (!system.fistStageDone)
            dispatch(openSystemImplForm(system.systemId, defaultSystem.scopeId));
        else
            dispatch(openUpgradeForm(system.systemId, defaultSystem.scopeId));
    }


    const systemName = defaultSystem.title;

    // если у системы  !startDate || !startUpgradeDate, то выводим информацию:    
    const isRender = system.isProgress || system.startUpgradeDate !== undefined || system.marketingRunning;
    // console.log(isRender, system)
    if (isRender) {
        // Если !fistStageDone && !required01Done, то пишем в подзаголовке: Signing the contract, в Time left: s.contractor?.timecost * 0.33
        if (!system.fistStageDone && !system.required01Done) {
            implStepName = SIGNING_THE_CONTRACT;
            timeLeft = system.contractor && system.contractor.timecost ? system.contractor.timecost * 0.33 : 0;
            timeLeft = timeLeft - curTime.diff(system.startDate, "days")
        }
        // Если !fistStageDone && !s.required02Done, то в подзаголовке: Administrative procedures,
        else if (defaultSystem.required02 && !system.fistStageDone && !system.required02Done) {
            implStepName = ADMINISTRATIVE_PROCEDURES;
            // в Time left:  
            // если s.adminExpert, то s.adminExpert.expert.timeCost - curTime.diff(s.lastStepDate, "days")
            if (system.adminExpert) {
                timeLeft = system.adminExpert.expert.timeCost - curTime.diff(system.lastStepDate, "days");
            }
            // если team.findIndex(t => t.id === 1) >= 0, то TIME_COST_ADVANCED - curTime.diff(s.lastStepDate, "days")
            else if (team.findIndex(t => t.id === 1) >= 0) {
                timeLeft = TIME_COST_ADVANCED - curTime.diff(system.lastStepDate, "days");
            }
        }
        // Если !fistStageDone && !s.required03Done, то в подзаголовке: Infrastructure changes, в Time left: 
        else if (defaultSystem.required03 && !system.fistStageDone && !system.required03Done) {
            implStepName = INFRASTRUCTURE_CHANGES;
            // в Time left:  
            // если s.infraExpert, то s.infraExpert.expert.timeCost - curTime.diff(s.lastStepDate, "days")
            if (system.infraExpert) {
                timeLeft = system.infraExpert.expert.timeCost - curTime.diff(system.lastStepDate, "days")
            }
            // если team.findIndex(t => t.id === 2) >= 0, то TIME_COST_ADVANCED - curTime.diff(s.lastStepDate, "days")
            else if (team.findIndex(t => t.id === 2) >= 0) {
                timeLeft = TIME_COST_ADVANCED - curTime.diff(system.lastStepDate, "days");
            }
        }
        // Иначе если !fistStageDone  в подзаголовке: Development, в Time left: s.contractor?.timecost * 0.67 
        else if (!system.fistStageDone) {
            implStepName = DEVELOPMENT;
            timeLeft = system.contractor && system.contractor.timecost ? system.contractor.timecost * 0.67 : 0;
            timeLeft = timeLeft - curTime.diff(system.lastStepDate, "days");
        }
        // Запущено обновление системы
        else if (system.startUpgradeDate) {
            implStepName = UPGRADE;
            const upgradeTimeCost = system.upgradeEvents?.flatMap(x => x.timeCost).reduce((a, b) => a + b, 0);
            const upgradeSystemTypeTimeCost = system.newSystemType?.scores.timecostuptype

            const startUpgrade = moment(system.startUpgradeDate);
            const timeFromUpdateStart = curTime.diff(startUpgrade, "days");

            if (upgradeTimeCost) timeLeft = upgradeTimeCost - timeFromUpdateStart;
            else if (upgradeSystemTypeTimeCost) timeLeft = upgradeSystemTypeTimeCost - timeFromUpdateStart;
        }

        // Запущена маркетинговая компания
        else if (system.marketingRunning) {
            implStepName = MARKETING;
            timeLeft = -1;
            timeLeftLable = <span>&#8734;</span>;
        }
    }

    return (isRender ?
        <div className={style.systemProcess}>
            <div className={style.row}>
                <div className={style.info}>
                    <div className={style.systemIdName}>{systemName}</div>
                    <div className={style.implStepName}>{implStepName}</div>
                </div>
                <div className={style.openBtn}><GoToIcon onClick={handleClick} /></div>
            </div>

            <div className={style.ndRow}>
                <div className={style.timeLeftName}>Time Left:   </div>
                <div className={style.timeLeftValue}>{timeLeft >= 0 ? Math.round(timeLeft) : timeLeftLable}</div>
            </div>
        </div> : null);
}

export default SystemProcess;