import React from 'react';
import style from '../style.module.scss';
import { IContractRevenueMax } from '../../../../models';
import ContractCard from '../../ContractCard';

interface IProps {
    businessReputation: number; 
    contracts: IContractRevenueMax[]
    handleSelectedModel: (st: IContractRevenueMax) => void;
}

const ContractModelStep: React.FunctionComponent<IProps> = (props) => {

    const { contracts, handleSelectedModel, businessReputation } = props;

    return <div className={style.formCenter} data-tut={"ContractModelStep"}>
        <p className={style.textInfoPadding}>Step 2. Choose the type of contractual model for system implementation </p>

        {contracts.map((t,i)=><ContractCard key={i} contract={t} businessReputation={businessReputation} handleClick={()=>handleSelectedModel(t)} />)}

    </div>
}

export default ContractModelStep;