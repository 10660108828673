import { Action } from "redux";
import { OPEN_SYSTEM_EVENT_FORM, CLOSE_SYSTEM_EVENT_FORM, ISystemsEventAction } from "../../actions";

export interface IState {
    isOpen: boolean;
    type: 'Quarter' | 'EndOfSystem'
    text: string;
    systemTitle: string;
    systemId: number;
    scopeId: number;
    income: number;
    expenses: number;
    reputationSociety: number;
    reputationBusiness: number;
}

export const initial: IState = {
    isOpen: false,
    text: "",
    systemTitle: "",
    income: 0,
    expenses: 0,
    systemId: 0,
    scopeId: 0,
    reputationSociety: 0,
    reputationBusiness: 0,
    type: 'Quarter'
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_SYSTEM_EVENT_FORM:
            const { text, income, expenses, reputationBusiness, reputationSociety, typeEvent, systemTitle, systemId, scopeId } = action as ISystemsEventAction
            return {
                ...state,
                isOpen: true,
                text,
                income, 
                expenses,
                reputationSociety,
                reputationBusiness,
                systemTitle,
                scopeId,
                systemId,
                type: typeEvent
            };

        case CLOSE_SYSTEM_EVENT_FORM:
            return {
                ...state,
                isOpen: false,
                text: "",
                income: 0,
                expenses: 0,
                reputationBusiness: 0,
                reputationSociety: 0,
                systemId: 0,
                scopeId: 0,
                systemTitle: "",
                type: 'Quarter'
            };

        default:
            return state;
    }
}