import React, { useState, useRef } from 'react';
import style from './style.module.scss';
import { FinancialIndicators } from './FinancialIndicators';
import PermanentTeam from './PermanentTeam';
import YourProcesses from './YourProcesses';
import { Progress } from './Progress';
import Scrollbars from 'react-custom-scrollbars';
import BudgetReport from './BudgetReport';
import BubblesSlider, { IBubble } from '../../Slider';
import { useSelector, useDispatch } from 'react-redux';
import { getLearningCompleted } from '../../../redux/selectors';
import { learningProgressNext } from '../../../redux/actions/game/learningProgress';

interface IProps {
    currentBoard?: number;
}

export const Dashboard: React.FunctionComponent<IProps> = (props) => {

    const { currentBoard = 0 } = props;
    const learningCompleate = useSelector(getLearningCompleted);
    const dispatch = useDispatch();
    const ref = useRef<Scrollbars>(null);

    const boardList = [
        { idx: 0, title: 'Budget Report', component: <BudgetReport /> },
        { idx: 1, title: 'Financial Indicators', component: <FinancialIndicators /> },
        { idx: 2, title: 'Progress', component: <Progress /> },
        { idx: 3, title: 'YourProcesses', component: <YourProcesses /> },
        { idx: 4, title: 'PermanentTeam', component: <PermanentTeam /> }
    ];
    const [selectionBoard, setSelectionBoard] = useState<number>(currentBoard);

    const index = boardList.findIndex(s => s.idx === selectionBoard);

    const prevIndex = index === 0 ? boardList.length - 1 : index - 1;

    const tail = [...boardList.filter(s => s.idx > boardList[index].idx)];
    const shadowBoardList = [boardList[prevIndex], boardList[index], ...tail,
    ...boardList.filter(s => s.idx !== boardList[index].idx && s.idx !== boardList[prevIndex].idx && !tail.includes(s)).sort((s1, s2) => s1.idx - s2.idx)];


    const renderBoard = (): IBubble[] => {
        return shadowBoardList.map((board) => {
            const section: IBubble = {
                isSelected: board.idx === selectionBoard,
                handleClick: () => {
                    setSelectionBoard(board.idx);
                    if (ref.current) {
                        ref.current.scrollToTop();
                    }
                    !learningCompleate && dispatch(learningProgressNext());
                },
                title: board.title
            }

            return section;
        })
    };

    return <div className={style.form} data-tut={"DashBoard"}>
        <div className={style.title}>{'Smart City Dashboard'}</div>
        <BubblesSlider bubbles={renderBoard()} withShadow={false} startDelta={175} dataTut={"DashBoardBubble"} />

        <Scrollbars style={{ height: '100%' }} ref={ref} data-tut={boardList[selectionBoard].title + "Block"}>
            {boardList[selectionBoard].component}
        </Scrollbars>
    </div>
}