import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gameViewChange } from '../../../redux/actions';
import { getGameView } from '../../../redux/selectors';
import style from './style.module.scss';

export default function ChangeViewButton () {

    const dispatch = useDispatch();
    const currentView = useSelector(getGameView)

    const changeView = () => {
        if(currentView === 'CityMap') {
            dispatch(gameViewChange('MindMap'))
        }
        else {
            dispatch(gameViewChange('CityMap'));
        }
    }

    return <div className={style.changeViewButton} onClick={changeView}>
        {currentView === 'CityMap' ? 
            <>
                <span className={style.title}>Mind Map</span>
                <img src={require('../../../icons/mind_map.svg')} alt='cm' />                
            </> :
            <>
                <span className={style.title}>City Map</span>
                <img src={require('../../../icons/city_map.svg')} alt='mm' />                
            </>}
    </div>
}