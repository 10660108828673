import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCityInfoForm, openGoalsForm } from '../../redux/actions/forms';
import { getIsOpenCityInfo } from '../../redux/selectors/forms/screens/openScreen';
import { getCityInfo } from '../../redux/selectors';
import { gameStageChange, moneyChange, cityInfoSet, metricsChange } from '../../redux/actions';
import { GameStageEnum } from '../../models';
import data from '../../data';
import CityInfoCard from './cityInfo';
import GameForm from '../UiKit/GameForm';
import ProgressBar from '../Goals/ProgressBar';

export default function CityInfo() {
  const cityInfo = useSelector(getCityInfo);
  const [progress, setProgress] = useState<number>(20);
  const {isOpen, isOpenAgain } = useSelector(getIsOpenCityInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cityInfo || cityInfo.cityId === -1) {
      const rndIndex = Math.floor(Math.random() * data.cityInfo.length);
      const randomCity = data.cityInfo[rndIndex];
      dispatch(cityInfoSet(randomCity));
      dispatch(metricsChange(data.finMetrics));
      dispatch(moneyChange(randomCity.money))
    }
  }, [dispatch, cityInfo])

  if (!cityInfo || cityInfo.cityId === -1) {
    return null;
  }

  const handleSubmit = () => {
    dispatch(gameStageChange(GameStageEnum.STAGE_3_GOALS));    
    dispatch(closeCityInfoForm());
    dispatch(openGoalsForm());
  };

  const handleCloseClick = () => {
    handleSubmit();
  };

  return isOpen ? <GameForm close={handleCloseClick} headerProgressBar={<ProgressBar completedValue={progress} />}>
                    <CityInfoCard handleSubmit={handleSubmit} cityInfo={cityInfo} setProgress={setProgress} isOpenAgain={isOpenAgain} />
                  </GameForm>
                : null;
}
