import { all, fork } from 'redux-saga/effects'
import { demoAuthSaga } from './demoAuth';
import { createGameSaga } from './gameAPI/createGame';
import { scoreGameSaga } from './gameAPI/scoreGame';
import { saveGameSaga } from './gameAPI/saveGame';
import { getGameSaga } from './gameAPI/getGame';
import { getGamesSaga } from './gameAPI/getGames';
import { getCurrenUserSaga } from './currentUser';
import { addGameStatsSaga } from './gameAPI/addGameStats';

export function* configureSaga() {
    yield all([
        fork(demoAuthSaga),
        fork(createGameSaga),
        fork(scoreGameSaga),
        fork(saveGameSaga),
        fork(getGameSaga),
        fork(getGamesSaga),
        fork(getCurrenUserSaga),
        fork(addGameStatsSaga),
    ]);
}
