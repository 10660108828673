import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import { Provider } from 'react-redux';
import { store, history } from './store/configureStore';
import { Router } from 'react-router-dom';

ReactDOM.render(<Provider store={store}>
  <Router history={history}>
      <App/>
  </Router>
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
