import React from 'react';
import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ScopeEnum, ICoord } from '../../../../models';
import { openScopeForm } from '../../../../redux/actions/forms/openScope';
import ScopeIcon from '../../ScopeIcon';
import cn from 'classnames';
import { getLearningCompleted} from '../../../../redux/selectors';
import { learningProgressNext } from '../../../../redux/actions/game/learningProgress';

interface IGamePointProps {
    id?: string; 
    scopeId: ScopeEnum;
    coord?: ICoord;
    isHighlight?: boolean;
    isPulse: boolean;
    className?: any;
    fromMap?: boolean;
}

const GameScopesPoint: React.FunctionComponent<IGamePointProps> = (props) => {

    const { id, scopeId, coord, isHighlight, className, isPulse, fromMap } = props;
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);

    const handleClick = () => {
        scopeId === 5 && !learningCompleate && dispatch(learningProgressNext());
        fromMap && dispatch(openScopeForm(scopeId))
    }

    return <div className={cn(style.container, className, isPulse ? style.iconPulse : null)} style={coord && { top: coord.y, left: coord.x }} onClick={handleClick} data-tut={scopeId === 5 ? "ScopeDirection" : ""}>
        {isPulse && <div className={cn(style.circle2, isHighlight ? style.circle2Highlight : null)} />}
        {isPulse && <div className={cn(style.circle1, isHighlight ? style.circle1Highlight : null)} />}
        <ScopeIcon  id={id} scopeId={scopeId} iconClassName={cn(style.icon, 
            isHighlight ? style.circle1Highlight : null,
            isPulse ? null : style.stopPulse)} isShowTitle={false} />
    </div>
}

export default GameScopesPoint;