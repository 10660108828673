import { Action } from 'redux';
import { IGame } from '../../../models';

export const GAME_INIT = 'GAME_INIT';

export interface IGameInitAction extends Action {
    game: IGame;
}

export function gameInit(game: IGame) {
    return { type: GAME_INIT, game};
}