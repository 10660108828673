import { Action } from "redux";
import { ASSISTANT_NOTIFY_ADD, ASSISTANT_NOTIFY_REMOVE, IAssistantNotifyAddAction, IAssistantNotifyRemoveAction, IAssistantNotify } from "../actions";

export interface IState {
    assistantNotify: IAssistantNotify[]
}

export const initial: IState = {
    assistantNotify: []
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ASSISTANT_NOTIFY_ADD:
            const { assistantNotifyAdd } = action as IAssistantNotifyAddAction;
            let assistantNotify = [...state.assistantNotify];
            if (assistantNotify.length === 5) {
                assistantNotify.shift();
            }
            
            assistantNotify.push(assistantNotifyAdd);

            return {
                ...state,
                assistantNotify
            };

        case ASSISTANT_NOTIFY_REMOVE:
            const { notifyId } = action as IAssistantNotifyRemoveAction;
            const createdAssistantNotify = state.assistantNotify.filter(x => x.notifyId !== notifyId);

            return {
                ...state,
                assistantNotify: [...createdAssistantNotify]
            };

        default:
            return state;
    }
}