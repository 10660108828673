import React from 'react';
import style from './style.module.scss';
import { IGameDto } from '../../../../api/game';
import { Link } from 'react-router-dom';
import { GameDate } from '../GameDate';
import DigitalTransformaionRate from '../../../Game/Statistic/Digital Transformaion Rate';
import Society from '../../../Game/Statistic/Society';
import Business from '../../../Game/Statistic/Business';
import Budget from '../../../Game/Statistic/Budget';
import { IGame } from '../../../../models';
import { calcDigitalTransformationRate } from '../../../../service/calcDigitalTransformRate';

interface IProps {
  game: IGameDto
}

const GameCard: React.FunctionComponent<IProps> = (props) => {

  const { game } = props;

  const gameState = JSON.parse(game.state) as IGame;
  const systems = gameState.systems;
  const budget = gameState.money.budget ? gameState.money.budget : 0;
  const societyReputaiton = gameState.cityInfo ? gameState.cityInfo.people : 0;
  const businessReputaiton = gameState.cityInfo ? gameState.cityInfo.business : 0;
  const scopes = [gameState.education, gameState.health, gameState.transport, gameState.eHousing, gameState.eService, gameState.security];
  const success = systems && systems.length > 0 ? calcDigitalTransformationRate(scopes, systems) * 100 : 0;

  const dates = <>
    <GameDate title={'Game started'} date={game.beginDate} />
    <GameDate title={'Last visited'} date={game.lastActionDate} />
  </>;

  return <div className={style.gameCard}>
    <div className={style.header}>
      <div className={style.name}>Game #{game.id}</div>
      <div className={style.line} />
      <div className={style.datesTop}>
        {dates}
      </div>
    </div>

    <div className={style.stats}>
      <DigitalTransformaionRate className={style.digitalTransRate} rate={success} spiderOnLeft={true} iconStyle={style.iconStyle} withSpider={true} />
      <div className={style.mainStats}>
        <Society societyReputaiton={societyReputaiton} onRight={true} />
        <Business businessReputaiton={businessReputaiton} onRight={true} />
        <Budget budget={Math.round(budget)} onRight={true} />
      </div>
    </div>

    <div className={style.datesBottom}>
      {dates}
    </div>

    <Link className={style.button} to={`/game/${props.game.id}`}>Continue game</Link>
  </div>;
}

export default GameCard;
