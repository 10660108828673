import React from 'react';
import GameForm from '../../UiKit/GameForm';
import ProgressBar from '../ProgressBar';
import style from './style.module.scss';

interface IProps {
    propgressCompletedValue: number;
    close: (event: any) => void;
}

const FormContainer: React.FunctionComponent<IProps> = (props) => {

    const { propgressCompletedValue, close } = props;

    return <GameForm close={close} formClassName={style.fullheight} headerProgressBar={<ProgressBar completedValue={propgressCompletedValue} />}>
        {props.children}
    </GameForm>
}

export default FormContainer;