import { IScope, ISystem, IDirection } from "../models";
import data from '../data';

export function calcDigitalTransformationRate(scopes: IScope[], systems: ISystem[]) {
    let success = 0;
    scopes.forEach(s => {
        success += calcScopeDigitalTransformationRate(s, systems);
    });
    success = Math.min(success / scopes.length, 1);
    // console.log(`Total DTR: ${success}`);
    return  success;
}

export function calcScopeDigitalTransformationRate(scope: IScope, systems: ISystem[]) {
    let success = 0;
    
    if(scope && scope.directions) {
        scope.directions.forEach(d => {
            success += calcDirectionDigitalTransformationRate(d, systems);
        });
        
        success = Math.min(success / scope.directions.length, 1);
    }

    // console.log(`Scope #${scope.scopeId} DTR: ${success}`);
    
    return success;
}

export function calcDirectionDigitalTransformationRate(direction: IDirection, selectedSystems: ISystem[]) {
    let success = 0;

    const systemsIds = data.systems.filter(s => s.directionId === direction.directionId).map(s => s.id);
   
    const systemsByDirection = selectedSystems.filter(s => systemsIds.findIndex(id => id === s.systemId) >= 0);
    if(systemsByDirection.length > 0) {
        systemsByDirection.forEach(s => {
            success += s.success;
        });
        
        success =  Math.min(success / systemsIds.length);
    }        

    // console.log(`Direction #${direction.directionId} DTR: ${success}`);
    
    return success;
}
