import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { GET_GAME_INIT, IGetGameInitAction, GET_GAME_FAIL } from "../../actions/gameAPI/getGame";

export interface IState {
    inProgress: boolean;
    error: string | null;
    id: number
}

export const initial: IState = {
    id: 0,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case GET_GAME_INIT:
            const { id } = action as IGetGameInitAction;
            return {
                ...state,
                id,
                inProgress: true,
                error: null
            };

        case GET_GAME_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                id: 0,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}