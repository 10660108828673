import { Action } from "redux";
import { GameView } from "../../../models";

export const CHANGE_VIEW = 'CHANGE_VIEW';

export interface IGameViewChange extends Action {
    view: GameView
}

export function gameViewChange(view: GameView) {
    return { type: CHANGE_VIEW, view } as IGameViewChange;
}

