import { Action } from "redux";
import { ITeamMember } from "../../../models";

export const TEAM_CHANGE = 'TEAMS_CHANGE';

export interface ITeamChangeAction extends Action {
    team: ITeamMember[]
}

export function teamChange(team: ITeamMember[]) {
    return { type: TEAM_CHANGE, team } as ITeamChangeAction;
}
