import { Action } from "redux";
import { SET_CITY_EVENT_ID, ISetCurrentCityEventIdAction } from "../../actions";

export const initial: number[] = [];

export const reducer = (state: number[] = initial, action: Action): number[] => {
    switch (action.type) {
        case SET_CITY_EVENT_ID: {
            const { currentCityEventId } = action as ISetCurrentCityEventIdAction;
            return [...state, currentCityEventId];
        }
        default:
            return state;
    }
}