import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import detail from '../../../../image/detail.png';
import { useDispatch } from 'react-redux';
import { openGameDashboard } from '../../../../redux/actions/forms/openGameDashboard';

interface IProps {
    className: any
}

export function Detail(props: IProps) {
    const dispatch = useDispatch();

    return (
        <div className={cn(style.detail, props.className)} onClick={() => { dispatch(openGameDashboard()) }} data-tut={"Details"}>
            <div className={style.title}>Detail</div>
            <div className={style.icon}>
                <img alt={detail} src={detail} />
            </div>
        </div>
    );
}

export default Detail;