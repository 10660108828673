import { Action } from "redux";
import { IFinMetric } from "../../../models";

export const FIN_METRICS_CHANGE = 'FIN_METRICS_CHANGE';
export const FIN_METRIC_CHANGE = 'FIN_METRIC_CHANGE';

export interface IFinMetricsChangeAction extends Action {
    metrics: IFinMetric[]
}

export interface IFinMetricChangeAction extends Action {
    metric: IFinMetric
}

export function metricsChange(metrics: IFinMetric[]) {
    return { type: FIN_METRICS_CHANGE, metrics } as IFinMetricsChangeAction;
}

export function metricChange(metric: IFinMetric) {
    return { type: FIN_METRIC_CHANGE, metric } as IFinMetricChangeAction;
}