import React from 'react';
import style from './style.module.scss';
import { avatar } from '../../helpers';
import { IAssistantNotify } from '../../redux/actions';
import { CloseFormIcon } from '../../icons';

interface IProps {
    assistantNotify: IAssistantNotify;
    handleRemoveClick: (id: number) => void;
}

const AssistantNotifyItem: React.FunctionComponent<IProps> = (props) => {

    const { assistantNotify, handleRemoveClick } = props;

    return <div className={style.card}>
                <div className={style.wrapper}>
                    <img src={avatar('/images/chief_assistant_Sasha.png')} alt="avatar" />
                    <div className={style.info}>
                        <div className={style.name}>Chief assistant:</div>
                        <div className={style.message}>{assistantNotify.message}</div>
                    </div>
                </div>
                <div onClick={() => { handleRemoveClick(assistantNotify.notifyId) }} className={style.close}>
                    <CloseFormIcon />
                </div>                    
            </div>
}

export default AssistantNotifyItem;