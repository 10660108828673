import { Action } from "redux";
import { IScopesContaractor } from "../../../models";
import { CONTRACTOR_CHANGE, IContractorChangeAction } from "../../actions";

export const initial: IScopesContaractor[] = [];

export const reducer = (state: IScopesContaractor[] = initial, action: Action): IScopesContaractor[] => {
    switch (action.type) {
        case CONTRACTOR_CHANGE: {
            const { contractor } = action as IContractorChangeAction
            return [...contractor]
        }
        default:
            return state;
    }
}