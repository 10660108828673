import React from 'react';
import { IAgency, ISystemAgency } from '../../../../models/agency';
import style from './style.module.scss';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../../UiKit/ScoreProgressBar';
import ClassicButton from '../../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../../UiKit/Buttons/common';
import { ISystem } from '../../../../models';
import IconButton, { IconPosition } from '../../../UiKit/Buttons/IconButton';
import { SelectedIcon } from '../../../../icons';
import { useDispatch } from 'react-redux';
import { systemChange } from '../../../../redux/actions';
import RangeSliderBudget from '../RangeSliderBudget';
import { convertMoneyToString } from '../../../../service/convertorToString';

interface IAgencyCard {
    system: ISystem;
    agency: IAgency;
    isSelected: boolean;
    isShowBuget: boolean;
    qualityMarketingMax: number;
}

const AgencyCard: React.FunctionComponent<IAgencyCard> = (props) => {
    const { agency, isSelected, isShowBuget, qualityMarketingMax, system } = props;
    const dispatch = useDispatch();

    const handleMakeOrderClick = () => {
        changeAgency();
    }

    const changeAgency = () => {
        const systemAgency: ISystemAgency = {
            id: agency.id,
            name: agency.name,
            moneyQMinus: agency.wageMarketing,
            qualityMarketing: agency.qualityMarketing,
            qualityMarketingMax: qualityMarketingMax
        }

        dispatch(systemChange({ ...system, agency: systemAgency }));
    }


    const renderButton = () => {
        return <div className={style.button}>
            {
                isSelected ? <IconButton label="HIRED" icon={<SelectedIcon />} iconPosition={IconPosition.left} handleClick={() => { }} classes={style.normal} /> :
                    <ClassicButton dataTut={"MakeOrder"} label={'MAKE ORDER'} size={SizeButton.normal} handleClick={handleMakeOrderClick} classes={style.normal} />}
        </div>
    }


    const renderAgenciesScrollBar = () => {
        return <div className={style.agencyScrollbar}>
            <div className={style.card}>
                <div className={style.title}>{agency.name}</div>
                <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={style.progress} color={ScoreProgressBarColor.yellow} title={"Quality"} value={agency.qualityMarketing*10} />
                <div className={style.serviceCost}>
                    <div className={style.title}>Service Cost:</div>
                    <div className={style.value}>{convertMoneyToString(agency.wageMarketing)}</div>
                </div>
                {isShowBuget && <RangeSliderBudget system={system} title="Set a Budget" />}
                {renderButton()}
            </div>
        </div>
    }

    return renderAgenciesScrollBar();
}

export default AgencyCard;
