import React from 'react';
import style from './style.module.scss';
import data from '../../../../data';
import ClassicButton from '../../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../../UiKit/Buttons/common';
import { AgancyCardScroll } from '../AgancyCardScroll';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTeamMembers, getLearningCompleted} from '../../../../redux/selectors';
import InfoFooter from '../../../UiKit/InfoFooter';
import { ISystem } from '../../../../models';
import { ProcedureState } from '../../../System/Procedures';
import RangeSliderBudget from '../RangeSliderBudget';
import { learningProgressNext } from '../../../../redux/actions/game/learningProgress';

interface IProps {
    scopeId: number
    system: ISystem
    onRunClick: () => void
}

export const MarketingSelect = (props: IProps) => {
    const { system, onRunClick } = props
    const team = useSelector(getCurrentTeamMembers);
    const dispatch = useDispatch();
    const agencies = data.scopeAgencies.find(x => x.scopeId === props.scopeId)?.agencies;
    const learningCompleate = useSelector(getLearningCompleted);

    let state:ProcedureState = 'Default';
    if(team.findIndex(t => t.id === 7)>=0) {
        state = 'Advanced'
    }

    const renderAgancyCardScroll = () => {
        return agencies && system && <AgancyCardScroll agencies={agencies} agencyId={system?.agency?.id} system={system} qualityMarketingMax={system?.agency?.qualityMarketingMax ? system?.agency?.qualityMarketingMax : 0}/>
    }

    const renderAction = () => {
        return <ClassicButton dataTut={"RunCampaign"} label={'RUN CAMPAIGN'} size={SizeButton.normal} handleClick={() => { onRunClick(); }} classes={style.normal} />
    }

    const renderFooter = (text: string) => {
        return <InfoFooter>
                <>
                    {text}  &nbsp;
                    <span className={style.underline}>Permanent Team & Contractors</span> section.
                </>
            </InfoFooter>
    }

    const renderBuget = () => {
        return (system?.agency || state === 'Advanced')  && <div className={style.range}>
            <RangeSliderBudget system={system} title="Set a Budget" isShowAction={true} />
        </div>
    }

    return (
        <>
            <div className={style.description}>
                To promote the system, select a marketing agency then set a budget for the campaign.
            </div>
            {!system?.agency && state !== 'Advanced' ? renderAgancyCardScroll() : null}
            {renderBuget()}
            {renderAction()}
            {renderFooter("In order not to choose a marketing agency for every project, you can hire a permanent agency in your team. You can do this in the")}

        </>
    );
}

export default MarketingSelect;