import { Action } from "redux";
import { ITestMode } from "../../../models/testMode";

export const TEST_MODE_CHANGE = 'TEST_MODE_CHANGE';

export interface ITestModeChangeAction extends Action {
    testMode: ITestMode[]
}

export function testModeChange(testMode: ITestMode[]) {
    return { type: TEST_MODE_CHANGE, testMode } as ITestModeChangeAction;
}