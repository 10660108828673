import React, { useEffect, useState, useRef, useCallback } from 'react';
import style from './style.module.scss';
import map from '../../../image/game-map.jpg'
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useEventListener } from '../../../hooks/useEventListener';
import { IGamePoint, GameStageEnum } from '../../../models';
import GamePoint from '../GamePoint';
import gamePoints from '../gamePoints';
import { useSelector } from 'react-redux';
import { getHightlightPoint, getSelectedSystems, getGameStage, getCurrentTeamMembers, getTimer } from '../../../redux/selectors';
import { getIsOpenGoals } from '../../../redux/selectors/forms/screens/openScreen';
import useScopes from '../../../hooks/useScopes';
import { calcDirectionDigitalTransformationRate } from '../../../service/calcDigitalTransformRate';
import data from '../../../data';
import { ISystemProgress } from '../../RoundProgressBar';
import { calcTimeLeft } from '../../../service/calcTimeLeft';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { DeviceSize } from '../../../models/deviceSize';

const GameCanvas = () => {
  const [coeff, setCoeff] = useState<number>(0);
  const goalsFormOpen = useSelector(getIsOpenGoals);
  const gameStage = useSelector(getGameStage);
  const team = useSelector(getCurrentTeamMembers);
  const timer = useSelector(getTimer);
  const scopes = useScopes();
  const selectedSystems = useSelector(getSelectedSystems);
  const scrollPoint = useSelector(getHightlightPoint);
  const [imageWidth, setImageWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();

  const [startWidth, setOriginWidth] = useState<number>(0);
  const [startHeight, setOriginHeight] = useState<number>(0);

  const [points, setPoints] = useState<IGamePoint[]>(gamePoints);
  const devicesize = useDeviceSize();

  const startWidthRef = useRef({});
  startWidthRef.current = startWidth;

  const startHeightRef = useRef({});
  startHeightRef.current = startHeight;

  const coeffRef = useRef({});
  coeffRef.current = coeff;
  const imageComponent = useRef<HTMLImageElement>(null);
  const gameComponent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (imageWidth) {
      gameComponent.current?.scrollTo(imageWidth / 2 + (devicesize === DeviceSize.XS ? 250 : 0), 0);
    }
  }, [imageWidth])

  useEffect(() => {
    if (scrollPoint) {
      const coord = points.find(x => x.scopeId === scrollPoint.scopeId && x.directionId === scrollPoint.directionId);
      scroll(coord);
    }
  }, [scrollPoint, points])

  const scroll = (point: IGamePoint | undefined) => {
    const currentWidth = gameComponent.current?.clientWidth;
    const scrollLeft = gameComponent.current?.scrollLeft === 0 ? currentWidth : gameComponent.current?.scrollLeft;
    if (point && currentWidth && scrollLeft) {
      let x = point.coord.x;
      let y = point.coord.y;
      if ((x > scrollLeft && x - scrollLeft + currentWidth > currentWidth - 300) ||
        (x < scrollLeft && scrollLeft + currentWidth - x > currentWidth - 300)) {
        x = x - currentWidth / 2;
      } else {
        return;
      }

      gameComponent.current?.scrollTo(x, y);
    }
  }

  const defineMapSize = () => {
    if (imageComponent && imageComponent.current) {
      const originalHeight = imageComponent.current.clientHeight;
      setOriginHeight(originalHeight);
      const originalWidth = imageComponent.current.clientWidth;
      setOriginWidth(originalWidth);
      if (originalHeight !== 0 || originalWidth !== 0) {
        const coeff = originalWidth / originalHeight;
        setCoeff(coeff)
      }
    }
  }

  const calulatePointPosition = useCallback((imageHeight: number, imageWidth: number) => {
    const newPoints = points.map(gp => {
      if (gp.coord === undefined)
        return { ...gp, coord: { x: 0, y: 0 } };

      const coeffHeight = gp.coord.y / Number(startHeightRef.current);
      let yy = imageHeight * coeffHeight;
      const coeffWidth = gp.coord.x / Number(startWidthRef.current);
      const xx = imageWidth * coeffWidth;

      if (yy + 60 > imageHeight) yy = imageHeight - 60;
      return { ...gp, coord: { x: xx, y: yy } };
    });

    setPoints(newPoints);
    // eslint-disable-next-line
  }, [])

  const calculateSize = useCallback(() => {
    if (gameComponent && gameComponent.current && coeffRef.current > 0) {
      const statisticContainer = document.getElementById('statisticContainer');
      let heightDelta = 0;
      if (statisticContainer) {
        heightDelta = statisticContainer.offsetHeight;
        // statisticSwipeUp
        const statisticSwipeUp = document.getElementById('statisticSwipeUp');
        if (statisticSwipeUp) heightDelta -= 18;
      }

      const height = gameComponent.current.offsetHeight - heightDelta;
      const width = height * Number(coeffRef.current);

      setImageHeight(height);
      setImageWidth(width);
      calulatePointPosition(height, width);
    }
  }, [calulatePointPosition])


  useEffect(() => {
    calculateSize();
  }, [coeff, calculateSize, goalsFormOpen]);

  useWindowSize(calculateSize);

  const onImageLoad = () => {
    defineMapSize();
  }

  const renderPoint = () => {
    if (imageHeight === 0 || imageWidth === 0) return null;
    return points.map((x, index) => {
      const scope = scopes.find(r => r.scopeId === x.scopeId);
      const systems = data.systems.filter(s => s.directionId === x.directionId);
      const direction = scope?.directions?.find(r => r.directionId === x.directionId);
      const dtr = direction ? calcDirectionDigitalTransformationRate(direction, selectedSystems) * 100 : 0;

      const systemsImpl = selectedSystems.filter(x => systems.findIndex(s => s.id === x.systemId) >= 0 && (x.isProgress || x.startDate) && !x.fistStageDone);

      const systemsProgress: ISystemProgress[] = systemsImpl.map(s => {
        const timeLeft = calcTimeLeft(systems.find(r => r.id === s.systemId), s, timer, team);
        const maxDay = s.startUpgradeDate ? s.startUpgradeDate : s.startDate;
        const timeMax = calcTimeLeft(systems.find(r => r.id === s.systemId), s, { startDate: maxDay ?? timer.startDate, curDate: maxDay ?? timer.startDate, launch: false, nextQuaterDate: timer.nextQuaterDate }, team);
        return {
          value: timeLeft,
          initialValue: timeMax,
          color: x.color
        }
      })
      return <GamePoint key={index} point={x} isPulse={gameStage === GameStageEnum.STAGE_4_GAME}
        isHighlight={x.scopeId === scrollPoint?.scopeId && x.directionId === scrollPoint.directionId}
        directionName={direction?.title}
        dtrDirection={dtr}
        fromMap={true}
        systemsProgress={systemsProgress} />
    });
  }

  // Логика для захвата карты и перемещения
  const isDown = useRef<boolean>(false);
  const scrollLeft = useRef<number>(0);
  const startX = useRef<number>(0);

  const handlerMousedown = useCallback((e: any) => {
    if (gameComponent.current === null) return;

    isDown.current = true;

    startX.current = e.pageX - gameComponent.current.offsetLeft;
    scrollLeft.current = gameComponent.current.scrollLeft;
  }, [gameComponent]);

  const handlerMousemove = useCallback((e: any) => {
    if (!isDown.current || gameComponent.current == null) return;
    e.preventDefault();
    const x = e.pageX - gameComponent.current.offsetLeft;
    const walk = (x - startX.current) * 3; //scroll-fast
    gameComponent.current.scrollLeft = scrollLeft.current - walk;
  }, [gameComponent]);

  useEventListener('mousedown', handlerMousedown, gameComponent.current);
  useEventListener('mouseleave', () => { isDown.current = false }, gameComponent.current);
  useEventListener('mouseup', () => { isDown.current = false }, gameComponent.current);
  useEventListener('mousemove', handlerMousemove, gameComponent.current);
  useEventListener('dragstart', (e: any) => { e.preventDefault(); }, gameComponent.current);

  return (
    <div className={style.gameCanvasContainer} data-tut={"Welcome"}>
      <div className={style.gameCanvas} ref={gameComponent} style={imageHeight === 0 || imageWidth === 0 ? {} : { height: '100%' }}>
        <img className={style.image} src={map} ref={imageComponent} alt="" onLoad={onImageLoad} style={imageHeight === 0 || imageWidth === 0 ? {} : { height: imageHeight, width: imageWidth }} />
        {renderPoint()}
      </div>
    </div>
  );
}

export default GameCanvas;


