import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { BubbleTailIcon } from '../../../../icons';

interface IBubleProps {
    title: string;
    subTitle?: string; 
    handleClick?: () => void;   
    classes?: string;
    dataTut?: any;
}

const BubbleWithTail: React.FunctionComponent<IBubleProps> = (props) => {

    const { title, subTitle, classes, handleClick, dataTut } = props;

    return <div className={cn(style.bubble, subTitle ? style.withSubtitle : '', classes)} onMouseDown={handleClick} data-tut={dataTut}>
                <div className={style.title}>{title}</div>
                {subTitle && <div className={style.subTitle}>{subTitle}</div>}
                <BubbleTailIcon />
            </div>
}

export default BubbleWithTail;