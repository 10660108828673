import React, { useState } from 'react';
import style from './style.module.scss';
import data from '../../../data';
import NextStepCard from '../../System/NextStepCard';
import { useSelector, useDispatch } from 'react-redux';
import { getMoney, getTimer, getLearningCompleted } from '../../../redux/selectors';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../UiKit/Buttons/common';
import { IUpgradeEvent, IEvent, ISystemType, ISystem } from '../../../models';
import { systemChange, moneyChange, closeUpgradeForm } from '../../../redux/actions';
import { changeMoneyService } from '../../../service/changeMoneyService';
import { convertTimeToString, convertMoneyToString } from '../../../service/convertorToString';
import { learningProgressNext } from '../../../redux/actions/game/learningProgress';

interface IProps {
    system: ISystem;
    onSelectItem?: () => void;
}

const UpgradeActivity = (props: IProps) => {
    const { system, onSelectItem } = props
    const dispatch = useDispatch();
    const money = useSelector(getMoney);
    const timer = useSelector(getTimer);
    const events = data.systems.find(x => x.id === system.systemId)?.events;
    const systemTypes = data.systems.find(x => x.id === system.systemId)?.types;
    const [selectedUpgrades, setUpgrade] = useState<IEvent[]>([])
    const [selectedSystemType, setSystemType] = useState<ISystemType>()
    const learningCompleted = useSelector(getLearningCompleted);

    const checkRunUpgradeDisable = (): boolean => {
        return ((selectedUpgrades === undefined || selectedUpgrades.length === 0) && selectedSystemType === undefined) || system.startUpgradeDate !== undefined;
    }

    const handleRunUpgrade = () => {
        if (selectedUpgrades && system) {
            const upgrades = selectedUpgrades.map(x => {
                const upgrade: IUpgradeEvent = {
                    id: x.id,
                    name: x.name,
                    upgradeCost: x.upgradeCost,
                    timeCost: x.timeCostUpgrade,
                    upUsability: x.upUsability,
                    upCoverage: x.upCoverage
                }
                return upgrade;
            })

            dispatch(systemChange({ ...system, upgradeEvents: upgrades, startUpgradeDate: timer.curDate }));
            changeBuget(upgrades.flatMap(x => x.upgradeCost))
        }

        if (selectedSystemType && system) {
            dispatch(systemChange({ ...system, newSystemType: selectedSystemType, startUpgradeDate: timer.curDate }));
            changeBuget([selectedSystemType.scores.uptypecost])
        }

        if (!learningCompleted) {
            dispatch(learningProgressNext()); 
            dispatch(closeUpgradeForm());
        }
    }

    const handleSelectedUpgrade = (upgrade: IEvent) => {
        const getUpgrade = (): IEvent[] => {
            if (selectedUpgrades.flatMap(x => x.id).includes(upgrade.id)) {
                return selectedUpgrades.filter(x => x.id !== upgrade.id);
            } else {
                const upgrades = [...selectedUpgrades];
                upgrades.push(upgrade);
                onSelectItem && onSelectItem(); 
                return upgrades;
            }
        }

        if (system.startUpgradeDate !== undefined) return;

        const upgrades = getUpgrade();
        setUpgrade(upgrades);
        setSystemType(undefined);
    }

    const handleSelectedSystemType = (systemType: ISystemType) => {
        if (system.startUpgradeDate !== undefined) return;

        setSystemType(systemType);
        setUpgrade([]);
    }

    const changeBuget = (oddsBuget: number[]) => {
        const oddsBugetSum = oddsBuget.reduce((a, b) => a + b, 0);
        const newMoney = changeMoneyService(money, -oddsBugetSum, 0, 0);
        dispatch(moneyChange(newMoney))
    }

    const renderCosts = (cost: number, timeCost: number) => {
        return <div className={style.costs}>
            {renderCost("Update Cost:", convertMoneyToString(cost))}
            {renderCost("Time Cost:", convertTimeToString(timeCost))}
        </div>
    }

    const renderCost = (title: string, value: string) => {
        return <div className={style.cost}>
            <span className={style.title}>{title} &nbsp;</span>
            <span className={style.value}>{value}</span>
        </div>
    }

    const renderActivity = () => {
        return <div className={style.stepCard}>
            {events && events.map((x, index) => <NextStepCard key={index} dataTut={index === 0 ? "UpgradeBlock" : ""} title={x.name} subTitle={x.description} disabled={system.usedUpgradeEvents.includes(x.id) || system.upgradeEvents?.flatMap(r => r.id).includes(x.id) } handleClick={() => { handleSelectedUpgrade(x) }} selected={selectedUpgrades.flatMap(r => r.id).includes(x.id)}>
                {renderCosts(x.upgradeCost, x.timeCostUpgrade)}
            </NextStepCard>)}
        </div>
    }

    const renderUpgradeSystemType = () => {
        const allowUpgradeTypes = system.systemType?.allowUpgradeTypes;
        const upgradeSystemTypes = systemTypes && systemTypes.filter(x => allowUpgradeTypes?.includes(x.id));
        return upgradeSystemTypes && upgradeSystemTypes.length > 0 && <>
            <div className={style.types}>
                Upgrade Type of System
            </div>
            <div className={style.stepCard}>
                {upgradeSystemTypes.map((x, index) =>
                    <NextStepCard key={index} title={x.title} subTitle={x.description} disabled={system.newSystemType?.title === x.title} handleClick={() => { handleSelectedSystemType(x) }} selected={x.title === selectedSystemType?.title}>
                        {renderCosts(x.scores.uptypecost, x.scores.timecostuptype)}
                    </NextStepCard>)}
            </div>
        </>
    }

    return <div className={style.upgradeActivity}>
        <div className={style.description}>
            To improve the system's KPIs, you can carry out activities to upgrade it or choose another type.
         </div>
        {renderActivity()}
        {renderUpgradeSystemType()}
        <ClassicButton dataTut={"RunUpgrade"} label={'RUN UPGRADE'} size={SizeButton.normal} handleClick={handleRunUpgrade} classes={style.normal} disabled={checkRunUpgradeDisable()} />
    </div>
}

export default UpgradeActivity;
