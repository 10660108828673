import { Action } from "redux";

export const OPEN_KPI_FORM= 'OPEN_KPI_FORM';
export const CLOSE_KPI_FORM = 'CLOSE_KPI_FORM';

export interface IOpenKpiAction extends Action {
    systemId: number;
    scopeId: number
}

export function openKpiForm(systemId: number, scopeId: number) {
    return { type: OPEN_KPI_FORM, systemId, scopeId } as IOpenKpiAction;
}

export function closeKpiForm() {
    return { type: CLOSE_KPI_FORM } ;
}
