import React, { useState } from 'react';
import style from './style.module.scss';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { SizeButton, ColorButton } from '../../UiKit/Buttons/common';
import { IRandomEvent, IOptionCommon } from '../../../models/event';
import Header from '../Header';
import { QuotesIcon, RadioOnIcon, RadioOffIcon, BubbleTailEventIcon, UpIcon, DownIcon, NoneIcon } from '../../../icons';
import { avatar } from '../../../helpers';
import BubbleWithoutTail from '../../UiKit/Bubbles/BubbleWithoutTail';
import { convertMoneyToString } from '../../../service/convertorToString';
import { timerStart } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

interface IOptionCommonWithId {
    option: IOptionCommon;
    id: number;
}

interface IEventProps {
    event: IRandomEvent;
    options: IOptionCommon[];
    handleConfirm: (index: number) => void;
    handleClose: () => void;
    scopeName: string;
    scopeHeader: string;
    avatarPath: string;
    Income?: number;
    Expenses?: number;
    rndCoeff: number;
}

const AbstractEvent: React.FunctionComponent<IEventProps> = (props) => {

    const { event, options, rndCoeff, avatarPath, handleConfirm, handleClose, scopeName: systemTitle, scopeHeader: teamMemberName, Income = 0, Expenses = 0 } = props;
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [selectedIndex, setIndex] = useState<number>(-1);
    const [selectedOption, setOption] = useState<IOptionCommon>({} as IOptionCommon);
    const dispatch = useDispatch();

    const handleCloseClick = () => {
        dispatch(timerStart());
        handleClose();
    }

    const confirmClick = () => {
        setIsConfirm(true);
        handleConfirm(selectedIndex);

        if(selectedOption.result === undefined) handleCloseClick();
    }

    const handleDecision = (index: number) => {
        setIndex(index);
        setOption(options[index]);        
    }

    const optionsWithIdsRnd = options.map((option: IOptionCommon, i: number) => {
        return {option: option, id: i} as IOptionCommonWithId
    }).sort(() => rndCoeff);

    const optionsRnd = optionsWithIdsRnd.map((option) =>
            <BubbleWithoutTail key={option.id} clicked={selectedIndex === option.id} handleClick={() => handleDecision(option.id)} >
                {selectedIndex === option.id
                    ? <RadioOnIcon />
                    : <RadioOffIcon />}
                <span>{option.option.option}</span>
            </BubbleWithoutTail>);

    const decision = <div className={style.body}>
        <div className={style.message}>
            <QuotesIcon className={style.quotesOpen} />
            <div>
                <span>{event.eventText}</span>
                <QuotesIcon className={style.quotesClose} />
            </div>
        </div>

        <div className={style.decision} >You must make a decision</div>

        <div className={style.decisions} >
            {optionsRnd}
        </div>


    </div>;

    const icon = (value: number) => {
        if (value === 0)
            return <NoneIcon />
        else if (value > 0)
            return <UpIcon />
        else
            return <DownIcon />
    }


    const result = <div className={style.body}>
        <div className={style.message}>
            <QuotesIcon className={style.quotesOpen} />
            <div>
                <span>{selectedOption.result}</span>
                <QuotesIcon className={style.quotesClose} />
            </div>
        </div>
        <div className={style.dash}></div>
        <div className={style.decision}>Result</div>
        <div className={style.resultBlocks}>
            <div className={style.block}>
                {icon(selectedOption.ePeopleBase)}
                <span className={style.label}>Society:</span>
                <span className={style.value}>{selectedOption.ePeopleBase}</span>
            </div>
            <div className={style.block}>
                {icon(selectedOption.eBusinessBase)}
                <span className={style.label}>Business:</span>
                <span className={style.value}>{selectedOption.eBusinessBase}</span>
            </div>
            <div className={style.block}>
                {icon(Income)}
                <span className={style.label}>Income:</span>
                <span className={style.value}>{convertMoneyToString(Income)}</span>
            </div>
            <div className={style.block}>
                {icon(-Math.abs(Expenses))}
                <span className={style.label}>Expenses:</span>
                <span className={style.value}>{convertMoneyToString(Expenses)}</span>
            </div>
        </div>

    </div>;

    return <div className={style.Event}>
        <Header breadcrumbs={[]}
            headerTitle={isConfirm ? "Congratulations!" : "We've got a situation!"} />

        {isConfirm && selectedOption.result ? result : decision}

        <div className={style.footer}>
            {isConfirm
                ? <ClassicButton handleClick={handleCloseClick} size={SizeButton.large} label={"Close"} color={ColorButton.azure} classes={'margin'} />
                : <ClassicButton handleClick={confirmClick} disabled={selectedIndex === -1} size={SizeButton.large} label={"Confirm"} />}

            <div className={style.manager}>
                <div className={style.label}>
                    <BubbleTailEventIcon />
                    <span className={style.name} >{teamMemberName}</span>
                    <span className={style.system}>{systemTitle}</span>
                </div>
                <img className={style.img} src={avatar(avatarPath)} alt="avatar" />
            </div>
        </div>
    </div>

}

export default AbstractEvent;