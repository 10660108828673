import { ScopeEnum, PointsColorEnum } from "../models";
const education = require('../icons/education.svg');
const health = require('../icons/healthcare.svg');
const transport = require('../icons/transport.svg');
const eservice = require('../icons/e-services.svg');
const ehousing = require('../icons/e-housing.svg');
const safety_security = require('../icons/safety-security.svg');

export const defineScopesLogo = (scope: ScopeEnum): any => {
    switch (scope) {
        case ScopeEnum.EDUCATION: {
            return education;
        }
        case ScopeEnum.HEALTH: {
            return health;
        }
        case ScopeEnum.TRANSPORT: {
            return transport;
        }
        case ScopeEnum.E_SERVICES: {
            return eservice;
        }
        case ScopeEnum.E_HOUSING: {
            return ehousing;
        }
        default: {
            return safety_security;
        }
    }
}

export const defineScopePointsColor = (scope: ScopeEnum): PointsColorEnum => {
    switch (scope) {
        case ScopeEnum.EDUCATION: {
            return PointsColorEnum.Education
        }
        case ScopeEnum.HEALTH: {
            return PointsColorEnum.Health
        }
        case ScopeEnum.TRANSPORT: {
            return PointsColorEnum.Transport
        }
        case ScopeEnum.E_SERVICES: {
            return PointsColorEnum.EServices
        }
        case ScopeEnum.E_HOUSING: {
            return PointsColorEnum.EHousing
        }
        default: {
            return PointsColorEnum.Security
        }
    }
}

export const defineScopeName = (scope: ScopeEnum): string => {
    switch (scope) {
        case ScopeEnum.EDUCATION: {
            return "Education"
        }
        case ScopeEnum.HEALTH: {
            return "Health"
        }
        case ScopeEnum.TRANSPORT: {
            return "Transport"
        }
        case ScopeEnum.E_SERVICES: {
            return "E-Services"
        }
        case ScopeEnum.E_HOUSING: {
            return "E-Housing"
        }
        default: {
            return "Security"
        }
    }
}