import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ICreateGameInitAction, createGameFail, CREATE_GAME_INIT, createGameDone } from '../../actions/gameAPI/createGame';
import { IErrorResponse } from '../../../api/common';
import { create, IGameDto } from '../../../api/game';
import { push } from 'react-router-redux';

export function* createGameHandler(action: Action) {
    try {
        const { game } = action as ICreateGameInitAction;
        const result: IGameDto =  yield call(create, game);
        yield put(createGameDone(result));
        yield put(push(`/game/${result.id}`))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(createGameFail(c))
    }
}

export function* createGameSaga() {
    yield takeLatest(CREATE_GAME_INIT, createGameHandler);
}
