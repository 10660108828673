import { Action } from "redux";
import { CURRENT_CITY_EVENT, ISetCurrentCityEventAction } from "../../actions";
import { ICityEvent } from "../../../models";

interface ICurrentCityEvent {
    currentCityEvent: ICityEvent | null;
    eventDate: number
}

export const initial = {
    currentCityEvent: null,
    eventDate: 0
}

export const reducer = (action: Action): ICurrentCityEvent => {
    switch (action.type) {
        case CURRENT_CITY_EVENT: {
            const event = action as ISetCurrentCityEventAction;
            return event;
        }
        default:
            return initial;
    }
}