import { Action } from "redux";
import { OPEN_UPGRADE_FORM, CLOSE_UPGRADE_FORM, IOpenUpgradeAction } from "../../actions/forms/openUpgrade";

export interface IState {
    isOpen: boolean;
    systemId: number;
    scopeId: number
}

export const initial: IState = {
    isOpen: false,
    systemId: -1,
    scopeId: -1
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_UPGRADE_FORM:
            const { systemId, scopeId } = action as IOpenUpgradeAction
            return {
                ...state,
                isOpen: true,
                systemId,
                scopeId
            };

        case CLOSE_UPGRADE_FORM:
            return {
                ...state,
                isOpen: false,
                systemId: 0,
                scopeId: 0
            };

        default:
            return state;
    }
}