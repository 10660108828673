import React from 'react';
import data from '../../../data';
import { IExpert } from '../../../models/option';
import ExpertCard from '../ExpertCard';
import AbstractProcedures from './AbstractProcedures';
import { useSelector } from 'react-redux';
import { getCurrentTeamMembers } from '../../../redux/selectors';
import { ProcedureState } from '.';


interface IProceduresProps {
    breadcrumbs: string[];
    scopeId: number;
    expertClick: (expert: IExpert) => void;
    gotItClick: () => void;
    backClick: () => void;
}

export const AdministrativeProcedures: React.FunctionComponent<IProceduresProps> = (props) => {

    const { breadcrumbs, scopeId, expertClick, gotItClick, backClick } = props;


    const team = useSelector(getCurrentTeamMembers);
    let state: ProcedureState = 'Default';
    if (team.findIndex(t => t.id === 1) >= 0) {
        state = 'Advanced';
    }

    const scopeExperts = data.administrativeExperts.find(ae => ae.scopeId === scopeId);
    if (!scopeExperts) return null;

    const experts = scopeExperts.option;

    return <AbstractProcedures
        headerTitle={"Administrative Procedures"}
        breadcrumbs={breadcrumbs}
        state={state}
        gotItClick={gotItClick}
        backClick={backClick}
    >
        {experts.map((expert, i) =>
            <ExpertCard key={i}
                expert={expert}
                handleClick={() => expertClick(expert)} />)
        }
    </AbstractProcedures>
}