import React, { useState } from 'react';
import style from './style.module.scss';
import ScoreProgressBar from '../../../../UiKit/ScoreProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedSystems } from '../../../../../redux/selectors';
import { CollapseIcon, BulletIcon } from '../../../../../icons';
import { calcDirectionDigitalTransformationRate } from '../../../../../service/calcDigitalTransformRate';
import gamePoints from '../../../gamePoints';
import { hightlightPointInit, openDirectionForm } from '../../../../../redux/actions';
import { defineScopePointsColor } from '../../../../../service/scopeService';
import { IDirection } from '../../../../../models';
import cn from 'classnames';


interface ICollapseState {
    id: number;
    collapse: boolean;
    childs: ICollapseState[];
}


interface IProps {
    scopeId: number;
    directions: IDirection[];
    className?: string;
    closeCommonForm: () => void;
    openCommonForm: () => void;
}

export const DirectionsProgress = (props: IProps) => {
    const { scopeId, directions, className, closeCommonForm, openCommonForm } = props;
    const implSystems = useSelector(getSelectedSystems); // внедряемые в данный момент системы
    const dispatch = useDispatch();

    const handleDirectionClick = (scopeId: number, directionId: number) => {
        closeCommonForm();
        const point = gamePoints.find(x => x.scopeId === scopeId && x.directionId === directionId);
        dispatch(hightlightPointInit(point));
        setTimeout(() => {
            dispatch(openDirectionForm(scopeId, directionId, () => openCommonForm()));
        }, 2000);
    }

    const [collapseState, setCollapseState] = useState<ICollapseState[]>(
        directions.map((direction) => {
            return {
                id: direction.directionId,
                collapse: false,
                childs: []
            }
        })
    );

    const handlerScopeCollapse = (directionIdx: number) => {
        collapseState[directionIdx].collapse = !collapseState[directionIdx].collapse;
        setCollapseState([...collapseState]);
    }

    const getSystemScore = (systemId: number) => {
        const implSys = implSystems.find(s => s.systemId === systemId);
        if (!implSys)
            return 0;
        else
            return implSys.success * 100;
    }

    const progressColor = defineScopePointsColor(scopeId);

    return <>
        {directions?.map((direction, directionIdx) => {
            const directionCollapse = collapseState[directionIdx].collapse;
            const directionSum = calcDirectionDigitalTransformationRate(direction, implSystems) * 100;

            return <div key={direction.directionId}>
                <div className={cn(style.direction, className)}>
                    <CollapseIcon className={directionCollapse ? style.rollout : ''} onClick={() => { handlerScopeCollapse(directionIdx) }} />
                    <div className={style.directionName} onClick={() => { handleDirectionClick(scopeId, direction.directionId) }}>{direction.title}</div>
                    {directionSum >= 0 &&
                        <ScoreProgressBar containerStyle={style.directionProgress}
                            color={progressColor}
                            isReverse={false}
                            value={directionSum} />}
                </div>
                {directionCollapse &&
                    direction.commonTargets.map((target, indexT) => {
                        const successSystems = getSystemScore(target.systemId);

                        return successSystems >= 0
                            ? <div key={indexT} >
                                <div className={style.target}>
                                    <BulletIcon />
                                    <div className={style.targetName} onClick={() => { handleDirectionClick(scopeId, direction.directionId) }}>{target.title}</div>
                                    <ScoreProgressBar containerStyle={style.targetProgress}
                                        color={progressColor}
                                        isReverse={false}
                                        value={successSystems} />
                                </div>
                            </div>
                            : ''
                    })}
            </div>
        })}</>
}
