import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { GET_GAME_INIT, getGameFail, IGetGameInitAction } from '../../actions/gameAPI/getGame';
import { IErrorResponse } from '../../../api/common';
import { IGameDto, getById } from '../../../api/game';
import { IGame } from '../../../models';
import { gameInit } from '../../actions';

export function* getGameHandler(action: Action) {
    try {
        const { id } = action as IGetGameInitAction;
        const result: IGameDto =  yield call(getById, id);        
        const game: IGame = JSON.parse(result.state);
        game.id = id;
        game.timer.launch = false;
        yield put(gameInit(game));

    } catch (e) {
        const c = e as IErrorResponse;
        yield put(getGameFail(c))
    }
}

export function* getGameSaga() {
    yield takeLatest(GET_GAME_INIT, getGameHandler);
}
