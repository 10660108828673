import { useSelector } from "react-redux";
import { getEducation, getHealth, getTransport, getEHousing, getEServices, getSecurity } from "../redux/selectors";
import { IScope } from "../models";

export default function useScopes(){
    const education = useSelector(getEducation);
    const health = useSelector(getHealth);
    const transport = useSelector(getTransport);
    const ehousing = useSelector(getEHousing);
    const eservices = useSelector(getEServices);
    const security = useSelector(getSecurity);
    const scopes: IScope[] = [education, health, transport, ehousing, eservices, security];
    
    return scopes;
}