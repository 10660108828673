export const OPEN_TEAM_FORM = 'OPEN_TEAM_FORM';
export const CLOSE_TEAM_FORM = 'CLOSE_TEAM_FORM';

export function openTeamForm() {
    return { type: OPEN_TEAM_FORM };
}

export function closeTeamForm() {
    return { type: CLOSE_TEAM_FORM };
}
