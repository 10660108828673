import { useSelector } from "react-redux"
import { getEducation, getHealth, getTransport, getEHousing, getEServices, getSecurity } from "../redux/selectors"
import { IScope } from "../models/scope"

export const useDefineScope = (scopeId: number): IScope => {
    let type: any 
    switch (scopeId) {
        case 1:
            type = getEducation
            break;
        case 2:
            type = getHealth
            break;
        case 3:
            type = getTransport
            break;
        case 4:
            type = getEHousing
            break;
        case 5:
            type = getEServices
            break;
        default:
            type = getSecurity
            break;
    }

    return useSelector(type)
}