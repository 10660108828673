import React from 'react';
import data from '../../../data';
import AbstractProcedures from './AbstractProcedures';
import TestCard from '../TestCard'
import { IEmployeeTraining } from '../../../models';
import { useEmployeeTrainingProcedure } from './useEmployeeTraining';

interface IProceduresProps {
    breadcrumbs: string[];
    trainingClick: (expert: IEmployeeTraining) => void;
    gotItClick: () => void;
    backClick: () => void;
}

export const EmployeeTraining: React.FunctionComponent<IProceduresProps> = (props) => {

    const { breadcrumbs, trainingClick, gotItClick, backClick } = props;
    const trainings = data.employeeTraining;

    const state = useEmployeeTrainingProcedure();

    return <AbstractProcedures
        headerTitle={"Employee training"}
        breadcrumbs={breadcrumbs}
        state={state}
        gotItClick={gotItClick}
        backClick={backClick}
    >
        {trainings.map((item, i) =>
            <TestCard key={i}
                selected={false}
                lable={item.name}
                money={item.scores.moneyMinus}
                time={item.scores.timeCost}
                efficiency={item.scores.userEfficiency}
                handleClick={() => trainingClick(item)}
            />)
        }
    </AbstractProcedures>
}