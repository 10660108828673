import { Action } from "redux";

export const OPEN_SCOPE_FORM = 'OPEN_SCOPE_FORM';
export const CLOSE_SCOPE_FORM = 'CLOSE_SCOPE_FORM';

export interface IOpenScopeAction extends Action {
    scopeId: number;
}

export function openScopeForm(scopeId: number) {
    return { type: OPEN_SCOPE_FORM, scopeId };
}

export function closeScopeForm() {
    return { type: CLOSE_SCOPE_FORM } ;
}
