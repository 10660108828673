import {  call, put, takeLatest } from 'redux-saga/effects'
import { getGameFail, getCurrentUserDone, GET_CURRENT_USER_INIT } from '../actions';
import { IErrorResponse } from '../../api/common';
import { getCurrentUser, IUserDto } from '../../api/user';

export function* getCurrenUserHandler() {
    try {
        const user: IUserDto =  yield call(getCurrentUser);
        yield put(getCurrentUserDone(user));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(getGameFail(c))
    }
}

export function* getCurrenUserSaga() {
    yield takeLatest(GET_CURRENT_USER_INIT, getCurrenUserHandler);
}
