/**
 * Характеристики подрядчика
 */
export interface IScopesContaractor {
    /** Идентификатор сферы */
    scopeId: number,

    /* Подрядчик */
    contractors: IContractor[]
}

/**
 * Тип контрактной модели
 */
export enum ContractModelEnum {
    Classic = "Classic",
    Lifecycle = "Lifecycle",
    Service = "Service",
    Concession = "Concession",
    BusinessProjectSupport = "BusinessProjectSupport"
}

export interface IContractor {
    /** Идентификатор подрядчика */
    id: number,

    name: string,

    /**
     * Показатель надежности, определяется на основе генерерирования с.в. от 0 до 1
     */
    supplieroff?: number,

    /**
     * Качество работ
     */
    quality: number,

    /**
     * Репутация
     */
    reputation: number
}

export function contractTypeToHumanView(model: ContractModelEnum) {

    switch(model){
        case ContractModelEnum.Lifecycle:
            return {
                title: 'Lifecycle',
                desc: 'More expensive, fewer time costs, more flexible'
            };
        case ContractModelEnum.Service:
            return {
                title: 'Service',
                desc: 'No implementation cost, high service cost'
            };
        case ContractModelEnum.Concession:
            return {
                title: 'Concession',
                desc: 'No implementation costs, no revenue, no cost'
            };
        case ContractModelEnum.BusinessProjectSupport:
            return {
                title: 'Business project support',
                desc: 'No implementation costs, minimal revenue, low service costs'
            };
        default:
            return {
                title: 'Classic',
                desc: 'Cheaper, more time costs, inflexible'
            }
    }

}