import { Action } from "redux";
import { FIN_METRICS_CHANGE, FIN_METRIC_CHANGE, IFinMetricsChangeAction, IFinMetricChangeAction } from "../../actions";
import { IFinMetric } from "../../../models";

export const initial: IFinMetric[] = []

export const reducer = (state: IFinMetric[] = initial, action: Action): IFinMetric[] => {
    switch (action.type) {
        case FIN_METRICS_CHANGE: {
            const { metrics } = action as IFinMetricsChangeAction;
            return [...metrics]
        }

        case FIN_METRIC_CHANGE: {
            const { metric } = action as IFinMetricChangeAction;

            return state.map(item => {
                if (item.id !== metric.id) {
                  return item
                }
                return {
                  ...item,
                  ...metric
                }
              });
        }
        default:
            return state;
    }
}