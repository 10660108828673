import React from 'react';
import AbstractInfoCard from './abstract';
import { ICityInformation } from '../../models';

interface IProps {
    cityInfo: ICityInformation;
}

const AboutInfoCard: React.FunctionComponent<IProps> = (props) => {
    const {cityInfo} = props;

    return <AbstractInfoCard title={"About the city"}
        description={cityInfo.history}>
    </AbstractInfoCard >
}


export default AboutInfoCard;
