import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

interface IBubbleProps {
    clicked: boolean;
    handleClick: () => void;
    classes?: string;
    dataTut?: any;
}

const BubbleWithoutTail: React.FunctionComponent<IBubbleProps> = (props) => {

    const {clicked, classes, handleClick, dataTut} = props;

    return <div className={cn(style.bubble, clicked ? style.clicked : '', classes)} onClick={handleClick} data-tut={dataTut}>
            {props.children}
          </div>
}

export default BubbleWithoutTail;