import { Action } from "redux";
import { IHichtlightPointInitAction, HIGHTLIGHT_POINT_INIT, HIGHTLIGHT_POINT_DONE } from "../actions";
import { IGamePoint } from "../../models";

export interface IState {
    point: IGamePoint | undefined
}

export const initial: IState = {
    point: undefined
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case HIGHTLIGHT_POINT_INIT:
            const { point } = action as IHichtlightPointInitAction
            return {
                ...state,
                point
            };
        case HIGHTLIGHT_POINT_DONE:
            return {
                ...state,
                point: undefined
            };
        default:
            return state;
    }
}