import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthError } from '../../redux/selectors';
import { loginInit, closeLoginForm, openLoginForm } from '../../redux/actions';
import { useFormField } from '../../hooks/useFormField';
import { getIsOpenAuth } from '../../redux/selectors/forms/openLogin';
import Form from '../Form';

export function DemoAuthForm() {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsOpenAuth)
  const userName = useFormField('');
  const password = useFormField('');
  const errorAuth = useSelector(getAuthError);

  useEffect(() => {
    dispatch(openLoginForm())
  }, [dispatch])

  const handleSubmit = (event: any) => {
    if (userName.value !== "" && password.value !== "") {
      dispatch(loginInit(userName.value, password.value));
    }    

    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseClick = (event: any) => {
    dispatch(closeLoginForm());
  };

   return (
        isOpen ?
          <Form close={handleCloseClick} onSubmit={handleSubmit} error ={errorAuth}>
            <input required type="text" placeholder="Имя пользователя" {...userName} />
            <input required type="password" placeholder="Пароль"  {...password} />
            <button type="submit">Войти</button>
          </Form> : 
        null
  );
}

export default DemoAuthForm;