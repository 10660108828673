import { Action } from "redux";
import { IErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { ICreateGame } from "../../../api/game";

export const SAVE_GAME_INIT = 'SAVE_GAME_INIT';
export const SAVE_GAME_DONE = 'SAVE_GAME_DONE';
export const SAVE_GAME_FAIL = 'SAVE_GAME_FAIL';

export interface ISaveGameInitAction extends Action {
    id: number
    game: ICreateGame
}

export function saveGameInit(id: number, game: ICreateGame) {
    return { type: SAVE_GAME_INIT, id, game } as ISaveGameInitAction;
}

export function saveGameDone() {
    return { type: SAVE_GAME_DONE };
}

export function saveGameFail(error: IErrorResponse) {
    return { type: SAVE_GAME_FAIL, error } as IFailAction;
}