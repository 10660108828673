import { store } from "../store/configureStore";
import { getSaveStatsProgress } from "./../redux/selectors";
import { TIMER_PAUSE, TIMER_START, TIMER_UPDATE, SAVE_GAME_DONE, SAVE_GAME_INIT, SAVE_GAME_FAIL, addGameStatsInit, ADD_GAME_STATS_DONE, ADD_GAME_STATS_INIT, ADD_GAME_STATS_FAIL } from "../redux/actions";
import { IGameStatistic } from "../api/game";
export * from './deep-copy';

export const avatar = (path: string) => {
    if (process.env.STORYBOOK_ENVIRONMENT) return path;
    return process.env.PUBLIC_URL + path;
}
// export function logger() {
//     return (next: (arg0: any) => any) => {
//         return (action: any) => {
//             // console.log('Will dispatch', action);

//             // Call the next dispatch method in the middleware chain.
//             const returnValue = next(action);

//             // This will likely be the action itself, unless
//             // a middleware further in chain changed it.
//             return returnValue;
//         };
//     }
// }
export function saveStats() {
    return (next: any) => (action: any) => {
        const game = store.getState().game
        const inProgress = getSaveStatsProgress(store.getState())
        if (!inProgress && game.id > 0 && action.type !== TIMER_PAUSE && action.type !== TIMER_START && action.type !== TIMER_UPDATE
            && action.type !== SAVE_GAME_DONE && action.type !== SAVE_GAME_INIT && action.type !== SAVE_GAME_FAIL
            && action.type !== ADD_GAME_STATS_DONE && action.type !== ADD_GAME_STATS_INIT && action.type !== ADD_GAME_STATS_FAIL) {

            // Call the next dispatch method in the middleware chain.
            const returnValue = next(action)
            const curGameStats: IGameStatistic = {
                action: action.type,
                stateBefore: '',
                stateAfter: JSON.stringify(game),
            }
            store.dispatch(addGameStatsInit(game.id, curGameStats));

            // This will likely be the action itself, unless
            // a middleware further in chain changed it.
            return returnValue;
        } else
            return next(action);
    }
}