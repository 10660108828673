import { Action } from "redux";
import { ITeamMember } from "../../../models";
import { TEAM_CHANGE, ITeamChangeAction } from "../../actions";


export const initial: ITeamMember[] = [];

export const reducer = (state: ITeamMember[] = initial, action: Action): ITeamMember[] => {
    switch (action.type) {
        case TEAM_CHANGE:
            const { team } = action as ITeamChangeAction;
            return [...team];

        default:
            return state;
    }
}