import React from 'react';
import style from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getScopeForm } from '../../redux/selectors/forms/screens/openScreen';
import GameForm from '../UiKit/GameForm';
import { closeScopeForm, openScopeForm } from '../../redux/actions/forms/openScope';
import ScopeIcon from '../Game/ScopeIcon';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import data from '../../data';
import { useDefineScope } from '../../service/defineScope';
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../UiKit/Buttons/common';
import { openHeadslForm, hightlightPointDone } from '../../redux/actions';
import { avatar } from '../../helpers';
import ImplIndicator from '../System/ImplIndicator';
import { getSelectedSystems, getHightlightPoint, getLearningCompleted} from '../../redux/selectors';
import { convertMoneyToString } from '../../service/convertorToString';
import { calcScopeDigitalTransformationRate } from '../../service/calcDigitalTransformRate';
import { openGameDashboard } from '../../redux/actions/forms/openGameDashboard';
import BackButton from '../UiKit/Buttons/BackButton';
import { DirectionsProgress } from '../Game/Dashboard/Progress/DirectionsProgress';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';

export const Scope = () => {
    const { scopeId, isOpen } = useSelector(getScopeForm)
    const scope = useDefineScope(scopeId);
    const selectedSystems = useSelector(getSelectedSystems);
    const point = useSelector(getHightlightPoint);
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);


    const handleCloseClick = () => {
        dispatch(closeScopeForm())
    }

    const handleBackClick = () => {
        dispatch(closeScopeForm());
        dispatch(hightlightPointDone());
        dispatch(openGameDashboard());
    }

    const renderHeader = () => {
        return <ScopeIcon scopeId={scopeId} isShowTitle={true} iconClassName={style.header} />
    }

    const renderCommonInfo = () => {
        let revenue = 0;

        if (scope.directions) {
            const systemsIds = data.systems.filter(s => s.scopeId === scopeId).map(s => s.id);
            const systemsByScope = selectedSystems.filter(s => systemsIds.findIndex(id => id === s.systemId) >= 0);
            revenue = systemsByScope.map(s => s.revenue).reduce((a, b) => a + b, 0) / scope.directions.length;
        }

        const success = calcScopeDigitalTransformationRate(scope, selectedSystems) * 100;

        return <div className={style.common}>
            <ImplIndicator title={'Systems Revenue per Quarter'} value={convertMoneyToString(Math.round(revenue))} />
            <br />
            <ScoreProgressBar containerStyle={style.progress} color={ScoreProgressBarColor.yellow} title={"Digital transformation rate"} value={success} unit={'%'} />
        </div>
    }

    const renderDirection = () => {
        const directions = scope.directions
        if (directions) {
            return <div className={style.directions}>
                <div className={style.title}>Directions</div>
                <DirectionsProgress scopeId={scopeId} directions={directions} className={style.progress} closeCommonForm={() => dispatch(closeScopeForm())} openCommonForm={() => dispatch(openScopeForm(scopeId))} />
            </div>
        } else {
            return null;
        }
    }

    const renderHeads = () => {
        const head = data.heads.find(x => x.headId === scope.headId);

        return <div className={style.head} data-tut={"ScopeForm"}>
            <div className={style.title}>{`Head of ${scope.title}`}</div>
            <div className={style.description}>
                <div className={style.headDetail}>
                    <img src={avatar(head?.avatar)} alt='Head Avatar' />
                    <div className={style.information}>
                        <div className={style.name}>{head?.name}</div>
                        <div className={style.rate}>
                            <div className={style.ratePart}>
                                <div className={style.parametr}>Reputation: </div>
                                <div className={style.value}>{head?.reputation}</div>
                            </div>
                            <div className={style.ratePart}>
                                <div className={style.parametr}>Expertise: </div>
                                <div className={style.value}>{head?.expertise}</div>
                            </div>
                            <div className={style.ratePart}>
                                <div className={style.parametr}>Wage: </div>
                                <div className={style.value}>{convertMoneyToString(head?.moneyQminus)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ClassicButton dataTut={"Manage"} label={'Manage'} size={SizeButton.normal} handleClick={() => { dispatch(openHeadslForm(scopeId)); !learningCompleate && dispatch(learningProgressNext()); }} />
            </div>

        </div>
    }

    return (
        isOpen ?
            <GameForm close={handleCloseClick}>
                <div className={style.scope}>
                    <div className={style.topContainer}>
                        {renderHeader()}
                        {renderCommonInfo()}
                    </div>
                    <div className={style.bottomContainer}>
                        {renderHeads()}
                        <div className={style.line}></div>
                        {renderDirection()}
                        {point && <BackButton label="BACK" handleClick={handleBackClick} classes={style.back} />}
                    </div>
                </div>
            </GameForm> : null
    )
}

export default Scope;
