import { Action } from "redux";

export const OPEN_DIRECTION_FORM = 'OPEN_DIRECTION_FORM';
export const CLOSE_DIRECTION_FORM = 'CLOSE_DIRECTION_FORM';

export interface IOpenDirectionForm extends Action  {
    scopeId: number;
    directionId: number;
    onClose?: () => void;
}

export function openDirectionForm(scopeId: number, directionId: number, onClose?: () => void) {
    return { type: OPEN_DIRECTION_FORM, scopeId, directionId, onClose } as IOpenDirectionForm;
}

export function closeDirectionForm() {
    return { type: CLOSE_DIRECTION_FORM };
}
