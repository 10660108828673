import { combineReducers } from 'redux';
import { IGame } from '../../models';
import * as game from './game';
import * as auth from './auth';
import * as openLogin from './forms/openLogin';
import * as createGame from './gameAPI/createGame';
import * as scoreGame from './gameAPI/scoreGame';
import * as saveGame from './gameAPI/saveGame';
import * as getGame from './gameAPI/getGame';
import * as getGames from './gameAPI/getGames';
import * as addGameStats from './gameAPI/addGameStats';
import * as openCityInfo from './forms/openCityInfo';
import * as openLearning from './forms/openLearning';
import * as openGoals from './forms/openGoals';
import * as openDirection from './forms/openDirection';
import * as openScope from './forms/openScope';
import * as openLocalCityInfo from './forms/openLocalCityInfo';
import * as openSystemsScopes from './forms/openSystemsScores';
import * as openAdministrative from './forms/openAdministrative';
import * as openInfrastructure from './forms/openInfrastructure';
import * as openEmployeeTraining from './forms/openEmployeeTraining';
import * as openHeads from './forms/openHeads';
import * as openTestMode from './forms/openTestMode';
import * as openUserAnalysis from './forms/openUserAnalysis';
import * as openTeam from './forms/openTeam';
import * as openSystemImpl from './forms/openSystemImpl';
import * as currentUser from './currentUser';
import * as openKpi from './forms/openKpi';
import * as openGameDashboard from './forms/openGameDashboard';
import * as openUpgrade from './forms/openUpgrade';
import * as openResult from './forms/openResult';
import * as assistantNotify from './assistantNotify';
import * as hightlightPoint from './hightlightPoint';
import * as openSystemEvent from './forms/openSystemEvent';


export interface IState {
    game: IGame;
    auth: auth.IState;
    openLogin: openLogin.IState;
    createGame: createGame.IState,
    scoreGame: scoreGame.IState,
    saveGame: saveGame.IState,
    getGame: getGame.IState,
    getGames: getGames.IState,
    openCityInfo: openCityInfo.IState,
    openLearning: openLearning.IState,
    openGoals: openGoals.IState,
    openDirection: openDirection.IState,
    openLocalCityInfo: openLocalCityInfo.IState,
    currentUser: currentUser.IState,
    openScope: openScope.IState,
    openSystemsScopes: openSystemsScopes.IState, // TODO: удалить?
    openAdministrative: openAdministrative.IState,
    openInfrastructure: openInfrastructure.IState,
    openEmployeeTraining: openEmployeeTraining.IState,
    openTestMode: openTestMode.IState,
    openUserAnalysis: openUserAnalysis.IState,
    openTeam: openTeam.IState,
    openSystemImpl: openSystemImpl.IState,
    openKpi: openKpi.IState,
    openResult: openResult.IState,
    openHeads: openHeads.IState,
    addGameStats: addGameStats.IState,
    openGameDashboard: openGameDashboard.IState,
    openUpgrade: openUpgrade.IState,
    assistantNotify: assistantNotify.IState,
    hightlightPoint: hightlightPoint.IState,
    openSystemEvent: openSystemEvent.IState
}

export const initial: IState = {
    game: game.initial,
    auth: auth.initial,
    openLogin: openLogin.initial,
    createGame: createGame.initial,
    scoreGame: scoreGame.initial,
    saveGame: saveGame.initial,
    getGame: getGame.initial,
    getGames: getGames.initial,
    openCityInfo: openCityInfo.initial,
    openLearning: openLearning.initial,
    openGoals: openGoals.initial,
    openDirection: openDirection.initial,
    openLocalCityInfo: openLocalCityInfo.initial,
    currentUser: currentUser.initial,
    openScope: openScope.initial,
    openSystemsScopes: openSystemsScopes.initial,
    openAdministrative: openAdministrative.initial,
    openInfrastructure: openInfrastructure.initial,
    openEmployeeTraining: openEmployeeTraining.initial,
    openTestMode: openTestMode.initial,
    openUserAnalysis: openUserAnalysis.initial,
    openTeam: openTeam.initial,
    openSystemImpl: openSystemImpl.initial,
    openKpi: openKpi.initial,
    openResult: openResult.initial,
    openHeads: openHeads.initial,
    addGameStats: addGameStats.initial,
    openGameDashboard: openGameDashboard.initial,
    openUpgrade: openUpgrade.initial,
    assistantNotify: assistantNotify.initial,
    hightlightPoint: hightlightPoint.initial,
    openSystemEvent: openSystemEvent.initial
}

export const appReducer = combineReducers({
    game: game.reducer,
    auth: auth.reducer,
    openLogin: openLogin.reducer,
    createGame: createGame.reducer,
    scoreGame: scoreGame.reducer,
    saveGame: saveGame.reducer,
    getGame: getGame.reducer,
    getGames: getGames.reducer,
    openCityInfo: openCityInfo.reducer,
    openLearning: openLearning.reducer,
    openGoals: openGoals.reducer,
    openDirection: openDirection.reducer,
    openLocalCityInfo: openLocalCityInfo.reducer,
    currentUser: currentUser.reducer,
    openScope: openScope.reducer,
    openSystemsScopes: openSystemsScopes.reducer,
    openAdministrative: openAdministrative.reducer,
    openInfrastructure: openInfrastructure.reducer,
    openEmployeeTraining: openEmployeeTraining.reducer,
    openTestMode: openTestMode.reducer,
    openUserAnalysis: openUserAnalysis.reducer,
    openTeam: openTeam.reducer,
    openSystemImpl: openSystemImpl.reducer,
    openKpi: openKpi.reducer,
    openResult: openResult.reducer,
    openHeads: openHeads.reducer,
    addGameStats: addGameStats.reducer,
    openGameDashboard: openGameDashboard.reducer,
    openUpgrade: openUpgrade.reducer,
    assistantNotify: assistantNotify.reducer,
    hightlightPoint: hightlightPoint.reducer,
    openSystemEvent: openSystemEvent.reducer
})