import { ScopeEnum } from "./scope";

export interface ICoord {
  x: number;
  y: number
}

export interface IGamePoint {
  scopeId: ScopeEnum;
  directionId?: number;
  coord: ICoord;
  type: 'Scope' | 'Direction'
  color: PointsColorEnum
}

export enum PointsColorEnum {
  Education = "#af40ff",
  Health = "#40bf00",
  Transport = "#00e4ff",
  EHousing = "#ffb300",
  EServices = "#00d6b2",
  Security = "#f2462c",
}
