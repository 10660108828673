import { Action } from "redux";

export const OPEN_GAME_DASHBOARD = 'OPEN_GAME_DASHBOARD';
export const CLOSE_GAME_DASHBOARD = 'CLOSE_GAME_DASHBOARD';

export interface IOpenGameDashboardForm extends Action  {
    currentBoard?: number
}

export function openGameDashboard(currentBoard?: number) {
    return { type: OPEN_GAME_DASHBOARD, currentBoard } as IOpenGameDashboardForm;
}

export function closeGameDashboard() {
    return { type: CLOSE_GAME_DASHBOARD };
}
