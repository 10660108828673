import { Action } from "redux";
import { TEST_MODE_CHANGE, ITestModeChangeAction } from "../../actions";
import { ITestMode } from "../../../models/testMode";

export const initial: ITestMode[] = [];

export const reducer = (state: ITestMode[] = initial, action: Action): ITestMode[] => {
    switch (action.type) {
        case TEST_MODE_CHANGE: {
            const { testMode } = action as ITestModeChangeAction
            return [...testMode]
        }
        default:
            return state;
    }
}