import { IContractRevenueMax, ISystemType, IContracorCost, IKpiList } from ".";
import { IExpert } from "./option";
import { IEmployeeTraining } from "./employeeTraining";
import { ITestMode } from "./testMode";
import { ISystemAgency } from "./agency";
import { IUpgradeEvent } from "./defaultSystem";

/**
 * Информации о внедряемой системе (изменение)
 */
export interface ISystem {
    startDate?: number,
    lastStepDate?: number,
    startUpgradeDate?: number,
    timeLeft: number,

    systemId: number,

    systemType?: ISystemType,
    implSystemType?: ISystemType,

    contractModel?: IContractRevenueMax,

    contractor?: IContracorCost,
    contractorFaultDelay?: number,
    contractorSuccess?: boolean,

    adminExpert?: ISystemOption,

    infraExpert?: ISystemOption,

    employeeTraining?: IEmployeeTraining,

    tests?: ITestMode[],

    agency?: ISystemAgency,

    upgradeEvents?: IUpgradeEvent[],
    usedUpgradeEvents: number[],

    newSystemType?: ISystemType,
    usedSystemTypes: string[],

    /** Текущий доход */
    revenue: number,
    revenueBase: number,
    /** Успех от внедрения системы */
    success: number,
    /** Usability */
    usability: number,
    /** Система в процессе внедрения */
    isProgress?: boolean,

    /** Затраты на внедрения */
    moneyminus: number;

    /** Квартальные затраты */
    moneyQminus: number;

    /** Первый этап внедрения завершен */
    fistStageDone?: boolean,

    required01Done?: boolean,
    required02Done?: boolean,
    required03Done?: boolean,

    kpisImpact?: IKpiList,
    kpis: IKpiList

    /** Маркетинговые мероприятия */
    marketingRunning?: boolean;
    mperformance?: number;
    moneyMarketingBudget?: number,
}

export interface ISystemScore {
    time: number,
}

export enum SystemScoreEnum {
    ADMINISTRATIVE = 1,
    INFRASTRUCTURE = 2,
    EMPLOYEE_TRAINING = 3,
    TEST_MEMBER = 4,
    USER_ANALYSIS_MEMBER = 5,
    KPI = 6,
    AGENCY = 7
}

export interface ISystemOption {
    expert: IExpert
    isEdit: boolean
}