import { Action } from "redux";
import { CLOSE_CITYINFO_FORM, OPEN_CITYINFO_FORM, IOpenCityForm } from "../../actions";

export interface IState {
    isOpen: boolean;
    isOpenAgain: boolean;
}

export const initial: IState = {
    isOpen: false,
    isOpenAgain: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_CITYINFO_FORM:
            const { isOpenAgain } = action as IOpenCityForm
            return {
                ...state,
                isOpen: true,
                isOpenAgain: isOpenAgain ?? false
            };

        case CLOSE_CITYINFO_FORM:
            return {
                ...state,
                isOpen: false,
                isOpenAgain: false
            };

        default:
            return state;
    }
}