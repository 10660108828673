import React from 'react';
import style from './style.module.scss';
import Summary from './Summary';
import { IncomeIcon, SpendingIcon } from './../../../../icons';
import { useQuaterlyMoney } from '../../../../hooks/useQuaterlyMoney';


const BudgetReport: React.FunctionComponent = () => {
    
    const {totalQminus, totalQplus, revenueItems, spendingItems} = useQuaterlyMoney();

    return (
        <div className={style.budgetReport} data-tut={"BudgetReportBlock"}>
            <div className={style.budgetRevenues}>
                <div className={style.header}>
                    <div className={style.row}>
                        <div className={style.title}>
                            Budget Revenues
                        </div>
                       
                        <div className={style.icon}><IncomeIcon /></div>

                    </div>
                    <div className={style.row}>
                        <div className={style.quarterly}>( Quarterly )</div>
                    </div>
                </div>
                <Summary data={revenueItems} summary={totalQplus} />
            </div>
            <div className={style.budgetSpending}>
                
                <div className={style.row}>
                    <div className={style.title}>
                        Budget Spending
                        </div>

                    <div className={style.icon}><SpendingIcon /></div>
                </div>
                <div className={style.row}>
                    <div className={style.quarterly}>( Quarterly )</div>
                </div>
               
                <Summary data={spendingItems} summary={totalQminus} />
            </div>
        </div>);
}
export default BudgetReport;