import { Action } from "redux";

export const OPEN_SYSTEM_IMPL_FORM = 'OPEN_SYSTEM_IMPL_FORM';
export const CLOSE_SYSTEM_IMPL_FORM = 'CLOSE_SYSTEM_IMPL_FORM';

export interface IOpenSystemsImplAction extends Action {
    systemId: number;
    scopeId: number;
    onClose?: () => void;
}

export function openSystemImplForm(systemId: number, scopeId: number, onClose?: () => void) {
    return { type: OPEN_SYSTEM_IMPL_FORM, systemId, scopeId, onClose };
}

export function closeSystemImplForm() {
    return { type: CLOSE_SYSTEM_IMPL_FORM } ;
}
