import React, { useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { IScope } from '../../../../models';
import style from './style.module.scss';
import DirectionItem from './DirectionItem';
import BubblesSlider, { IBubble } from '../../../Slider';
import cn from 'classnames';
import { useScrollComponent } from '../../../../hooks/useScrollComponent';
import { useSelector } from 'react-redux';
import { getLearningCompleted } from '../../../../redux/selectors';

interface IProps {
    commonTargetId: number;
    currentScopeId: number;
    scopes: IScope[];
    setCurrentScopeId: (id: number) => void;
    setCommonTargetId: (targetId: number) => void;
    disabledCommonTargetId: (targetId: number) => boolean;
}

const DirectionSelection: React.FunctionComponent<IProps> = (props) => {

    const { scopes, setCurrentScopeId, currentScopeId, setCommonTargetId, commonTargetId, disabledCommonTargetId } = props;
    const scrollComponent = document.getElementById("scroll");
    const learningCompleted = useSelector(getLearningCompleted);
    useScrollComponent(learningCompleted, scrollComponent);

    const index = scopes.findIndex(s => s.scopeId === currentScopeId);

    const prevIndex = index === 0 ? scopes.length - 1 : index - 1;

    const tail = [...scopes.filter(s => s.scopeId > scopes[index].scopeId)];
    const scopesCopy = [scopes[prevIndex], scopes[index], ...tail,
    ...scopes.filter(s => s.scopeId !== scopes[index].scopeId && s.scopeId !== scopes[prevIndex].scopeId && !tail.includes(s)).sort((s1, s2) => s1.scopeId - s2.scopeId)];

    const renderScopes = (): IBubble[] => {
        return scopesCopy.map((x) => {
            const section: IBubble = {
                isSelected: x.scopeId === currentScopeId,
                handleClick: () => setCurrentScopeId(x.scopeId),
                title: x.title
            }

            return section;
        })
    };

    const dirs = scopes[index].directions?.map((d, i) => <DirectionItem key={i} currentCommonTargetId={commonTargetId} direction={d} setCommonTargetId={setCommonTargetId} disabledCommonTargetId={disabledCommonTargetId} />)


    return <>
        <BubblesSlider bubbles={renderScopes()} withShadow={true} startDelta={150} />
        <Scrollbars style={{ height: '100%' }} className={style.hidden} id="scroll">
            <div className={style.directions}>
                {dirs}
            </div>
        </Scrollbars>

        <div className={cn(style.directions, style.show)}>
            {dirs}
        </div>

    </>
}

export default DirectionSelection;