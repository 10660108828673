import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { SCORE_GAME_INIT, SCORE_GAME_DONE, SCORE_GAME_FAIL } from "../../actions/gameAPI/scoreGame";

export interface IState {
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case SCORE_GAME_INIT:
            return {
                ...state,
                inProgress: true,
                error: null
            };

        case SCORE_GAME_DONE:
            return {
                ...state,
                inProgress: false,
                error: null
            };

        case SCORE_GAME_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}