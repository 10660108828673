import React from 'react';
import style from './style.module.scss';
import MarketingRunning from './MarketingRunning';
import MarketingSelect from './MarketingSelect';
import { ISystem } from '../../../models';
import { useDispatch } from 'react-redux';
import { systemChange } from '../../../redux/actions';

interface IProps {
    scopeId: number
    system: ISystem
}

export const Marketing = (props: IProps) => {
    const { scopeId, system } = props;
    const dispatch = useDispatch();

    const renderRunCompany = () => {
        return <MarketingRunning scopeId={scopeId} system={system} onStopClick={() => { 
            dispatch(systemChange({...system, marketingRunning: false}));
        }} />
    }

    const renderStopCompany = () => {
        return <MarketingSelect scopeId={scopeId} system={system} onRunClick={() => { 
                dispatch(systemChange({...system, marketingRunning: true}));
            }} />
    }

    return (
        <div className={style.marketing}>
            {system.marketingRunning ? renderRunCompany() : renderStopCompany()}
        </div>
    );
}

export default Marketing;