import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.scss';
import { closeUpgradeForm, openDirectionForm } from '../../redux/actions';
import { getUpgradeForm } from '../../redux/selectors/forms/screens/openScreen';
import GameForm from '../UiKit/GameForm';
import { useDefineScope } from '../../service/defineScope';
import data from '../../data';
import Header from '../System/Header';
import { IBubble } from '../Slider';
import BubblesSlider from '../Slider';
import KpisDashboard from './KpisDashboard';
import Marketing from './Marketing';
import UpgradeActivity from './UpgradeActivity';
import { getSelectedSystemById, getLearningCompleted } from '../../redux/selectors';
import { IState } from '../../redux/reducers';
import ImplIndicator from '../System/ImplIndicator';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import { convertMoneyToString } from '../../service/convertorToString';
import { getMainKpiProgress } from '../../models';
import BackButton from '../UiKit/Buttons/BackButton';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';
import { useScrollComponent } from '../../hooks/useScrollComponent';

export const Upgrade = () => {
    const { isOpen, scopeId, systemId } = useSelector(getUpgradeForm)
    const [selectedBlock, setBlock] = useState<'Kpi' | 'Marketing' | 'Upgrade'>("Kpi")
    const scope = useDefineScope(scopeId);
    const system = useSelector(s => getSelectedSystemById(s as IState, systemId));
    const learningCompleated = useSelector(getLearningCompleted);
    const defaultSystem = data.systems.find(x => x.id === systemId);
    const direction = scope?.directions?.find(d => d.directionId === defaultSystem?.directionId);
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const learningCompleted = useSelector(getLearningCompleted);
    const [scrollComponent, setScrollComponent] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            const scrollComponent = document.getElementById("scroll");
            setScrollComponent(scrollComponent);
        }
    }, [isOpen])
    useScrollComponent(learningCompleted, scrollComponent);

    useEffect(() => {
        if (!learningCompleated && ref && ref.current) {
            ref.current.scrollTop = 0;
            dispatch(learningProgressNext());
        }
    }, [system?.marketingRunning, system?.agency])

    useEffect(() => {
        if (!learningCompleated && ref && ref.current && (selectedBlock === 'Marketing' || selectedBlock === 'Upgrade')) {
            ref.current.scrollTop = ref.current.scrollTop + 200;
            dispatch(learningProgressNext());
        }
    }, [ref, selectedBlock, learningCompleated, dispatch])

    if (!scope || !direction || !defaultSystem || !system) return null;

    const breadcrumbs = [scope.title, direction.title];
    const digitalTransformationRate = system.success * 100;
    const revenuePerQuater = system.revenue;
    const moneyMinus = system.moneyQminus;
    const mainKpi = defaultSystem.systemKpies.find(sk => sk.isMain);

    const handleSelectUpgradeItem = () => {
        if (ref && ref.current) {
            ref.current.scrollTop = ref.current.scrollTop + 550;
            dispatch(learningProgressNext());
        }
    }

    const handleCloseClick = () => {
        dispatch(closeUpgradeForm());
        if (ref && ref.current) {
            ref.current.scrollTop = 0;
        }
    };

    const backActionHandler = () => {
        dispatch(closeUpgradeForm());
        dispatch(openDirectionForm(scope.scopeId, direction.directionId));
    }

    const getSection = () => {
        const sections: IBubble[] = [{
            isSelected: selectedBlock === 'Kpi',
            handleClick: () => { setBlock('Kpi'); !learningCompleated && dispatch(learningProgressNext()); },
            title: "KPIs Dashboard",
        }, {
            isSelected: selectedBlock === 'Marketing',
            handleClick: () => { setBlock('Marketing'); },
            title: "Marketing"
        }, {
            isSelected: selectedBlock === 'Upgrade',
            handleClick: () => { setBlock('Upgrade'); },
            title: "Upgrade"
        }]

        return sections;
    }

    let containerStyle = { height: '100%', marginRight: "0px" } as React.CSSProperties;
    if(!learningCompleated) {
        containerStyle = { ...containerStyle, marginRight: "-17px", overflowY: "scroll" };
    }

    return (isOpen ?
        <GameForm close={handleCloseClick} scrollId="scroll">
            <div className={style.upgrade} ref={ref} style={containerStyle}>
                <Header breadcrumbs={breadcrumbs}
                    headerTitle={defaultSystem?.title}
                    description={defaultSystem.description} />

                <div data-tut={"SystemUpgradeScores"}>
                    <div className={style.mainScores}>
                        <ImplIndicator title={'Systems Revenue per Quarter'} value={convertMoneyToString(revenuePerQuater)} inRow={true} className={style.indicator} />
                        {mainKpi && <ImplIndicator title={mainKpi.code} value={getMainKpiProgress(system.kpis, mainKpi)} inRow={true} className={style.indicator} />}
                    </div>
                    <div className={style.mainScores}>
                        <ImplIndicator title={'System Costs per Quater'} value={convertMoneyToString(moneyMinus)} inRow={true} className={style.indicator} />
                        <ScoreProgressBar containerStyle={style.progressBar} title={'Digital Transformaion Rate'} value={digitalTransformationRate} color={ScoreProgressBarColor.yellow} unit='%' />
                    </div>
                </div>

                <div className={style.topContainer}>
                    <div className={style.slider}>
                        <BubblesSlider bubbles={getSection()} />
                    </div>
                </div>
                <div className={style.bottomContainer}>
                    {selectedBlock === 'Kpi' && <KpisDashboard system={system} />}
                    {selectedBlock === 'Marketing' && <Marketing scopeId={scopeId} system={system} />}
                    {selectedBlock === 'Upgrade' && <UpgradeActivity system={system} onSelectItem={!learningCompleated ? () => handleSelectUpgradeItem() : undefined} />}
                </div>
                <div className={style.footer}>
                    <BackButton label={'Back'} handleClick={backActionHandler} />
                </div>
            </div>
        </GameForm> : null
    );
}

export default Upgrade;
