import { Action } from "redux";

export const OPEN_SYSTEM_EVENT_FORM = 'OPEN_SYSTEM_EVENT_FORM';
export const CLOSE_SYSTEM_EVENT_FORM = 'CLOSE_SYSTEM_EVENT_FORM';

export interface ISystemsEventAction extends Action {
    text: string;
    income: number;
    expenses: number;
    reputationSociety: number;
    reputationBusiness: number;
    typeEvent: 'Quarter' | 'EndOfSystem';
    systemTitle: string;
    systemId: number;
    scopeId: number;
}

export function openSystemEventForm(text: string, income: number, expenses: number, reputationSociety: number, reputationBusiness: number, typeEvent: 'Quarter' | 'EndOfSystem', systemTitle: string, systemId: number, scopeId: number) {
    return { type: OPEN_SYSTEM_EVENT_FORM, text, income, expenses, typeEvent, systemTitle, systemId, scopeId, reputationBusiness, reputationSociety} as ISystemsEventAction;
}

export function closeSystemEventForm() {
    return { type: CLOSE_SYSTEM_EVENT_FORM };
}
