import { Action } from "redux";
import { ISystem } from "../../../models/system";

export const SYSTEMS_CHANGE = 'SYSTEMS_CHANGE';
export const SYSTEM_CHANGE = 'SYSTEM_CHANGE';

export interface ISystemsChangeAction extends Action {
    systems: ISystem[]
}

export interface ISystemChangeAction extends Action {
    system: ISystem
}

export function systemsChange(systems: ISystem[]) {
    return { type: SYSTEMS_CHANGE, systems } as ISystemsChangeAction;
}

export function systemChange(system: ISystem) {
    return { type: SYSTEM_CHANGE, system } as ISystemChangeAction;
}