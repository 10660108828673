import { Action } from "redux";
import { IScopesContaractor } from "../../../models";

export const CONTRACTOR_CHANGE = 'CONTRACTOR_CHANGE';

export interface IContractorChangeAction extends Action {
    contractor: IScopesContaractor[]
}

export function contractorChange(contractor: IScopesContaractor[]) {
    return { type: CONTRACTOR_CHANGE, contractor } as IContractorChangeAction;
}