import React from 'react';
import { ICommonTarget } from '../../../../../models';
import style from './style.module.scss';
import { RadioOnIcon, RadioOffIcon } from '../../../../../icons';
import BubbleWithoutTail from '../../../../UiKit/Bubbles/BubbleWithoutTail';

interface IProps {
    checked: boolean;
    target: ICommonTarget;
    disabled?: boolean;
    setTargetId: (id: number) => void;
    dataTut: any;
}

const CommonTarget: React.FunctionComponent<IProps> = (props) => {

    const { checked, target, setTargetId, dataTut, disabled = false } = props;

    const title = <>
        <div style={{ height: '20px' }}>{checked ? <RadioOnIcon /> : <RadioOffIcon />}</div>
        <div>{target.title}</div>
    </>;

    return <BubbleWithoutTail  dataTut={dataTut} clicked={checked || disabled} classes={style.commonTarget} handleClick={() => !disabled && setTargetId(target.commonTargetId)}>
        {title}
    </BubbleWithoutTail>;
}

export default CommonTarget;