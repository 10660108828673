import React from "react";
import style from './style.module.scss';
import moment from "moment";

interface IGameDateProps {
    title: string;
    date: Date;
}

export const GameDate: React.FunctionComponent<IGameDateProps> = (props) => {

    return <div className={style.date}>
                <span className={style.title}>{props.title}:</span>
                <span className={style.value}>{moment(props.date, 'DD.MM.YYYY').format('DD.MM.YYYY')}</span>
          </div>
}