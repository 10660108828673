export const OPEN_EMPLOYEE_TRAINING_FORM= 'OPEN_EMPLOYEE_TRAINING_FORM';
export const CLOSE_EMPLOYEE_TRAINING_FORM = 'CLOSE_EMPLOYEE_TRAINING_FORM';

export function openEmployeeTrainingForm() {
    return { type: OPEN_EMPLOYEE_TRAINING_FORM };
}

export function closeEmployeeTrainingForm() {
    return { type: CLOSE_EMPLOYEE_TRAINING_FORM } ;
}
