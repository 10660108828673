import StepTour from "../components/Game/StepTour"
import React from 'react';
import { ReactourStep } from "reactour";
import { DeviceSize } from "../models/deviceSize";

export const getSteps = (devicesize: DeviceSize, windowSize: any, onNext: () => void, onFinish: () => void): ReactourStep[] => {
  const steps: ReactourStep[] = [
    {
      selector: '[data-tut="None"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"You have just been elected mayor of a big city. The essence of your election campaign was the digital transformation of the city. People have been waiting for so long to enter the era of modern technology, don't let them down. Now you have to familiarize yourself with the city situation and set the key goals of your digital transformation program."} />,
      stepInteraction: true,
      position: [0, 0]
    },
    {
      selector: '[data-tut="Scope"]',
      content: <StepTour onFinish={onFinish} text={"We’ve come to the goals section. Let's start by choosing just one goal. To define your first global goal and digital transformation goal you need to choose the sphere first."} />,
      stepInteraction: true,
      position: [0, 0]
    },
    {
      selector: '[data-tut="Scope_button"]',
      content: <StepTour onFinish={onFinish} text={"Go to next step"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Direction"]',
      content: <StepTour onFinish={onFinish} text={"Now set your first global goal — it connects with one of the directions in the selected sphere."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Direction_button"]',
      content: <StepTour onFinish={onFinish} text={"Go to next step"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="DigitalTarget"]',
      content: <StepTour onFinish={onFinish} text={"The last stage is the choice of which KPI we will focus on to determine the stage of achieving the goal. There are 1 to 5 KPIs available for each goal."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="DigitalTarget_button"]',
      content: <StepTour onFinish={onFinish} text={"Add Goals"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Finish_button"]',
      content: <StepTour onFinish={onFinish} text={"For now, we will focus on one goal and continue."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Approve_goals"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"As you can see, in order to achieve our goal, you need to implement the E-procurement, document circulation & open budget system and reach CSI 8."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : [50, 630]
    },
    {
      selector: '[data-tut="Approve_goals_button"]',
      content: <StepTour onFinish={onFinish} text={"As you can see, in order to achieve our goal, you need to implement the E-procurement, document circulation & open budget system and reach CSI 8."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Welcome"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Welcome! Let’s take a quick look at the main elements of the screen."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Statistic"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can see your main indicators. “Society” represents you current level of city residents loyalty, as “Business” shows how loyal local entrepreneurs are. If these numbers go too low, you risk retirement — take care of your constituents! You can also see the amount of the budget that you have at your disposal right now."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: devicesize === DeviceSize.XS ? '[data-tut="DigitalMobile"]' : '[data-tut="Digital"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can see your digital transformation rate. In addition to achieving your goals, your aim is to maximize this indicator. It reflects how many systems you have successfully implemented and promoted in the city."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: devicesize === DeviceSize.XS ? '[data-tut="DigitalMobile"]' : '[data-tut="Details"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"The Details section gives you access to screens for tracking the current situation and making global decisions about the life of the city. We'll go through it a little later."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="ScopeDirection"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"To dive into the digital transformation process, let's implement our first system. The most important part is the map with spheres and directions of digital transformation. Large icons represent 6 spheres, and the dots represent their directions. You can jump to the direction either from the sphere section or by clicking on a dot."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SelectDirection"]',
      content: <StepTour onFinish={onFinish} text={"Let's start by clicking on the E-government direction."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Systems"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Each direction has a number of systems that you can implement"} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SystemScores"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"The more systems you implement and upgrade, the more revenue they bring and the higher the rate of direction. This indicator reflects the local progress within the direction and affects the overall progress."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SelectedSystem"]',
      content: <StepTour onFinish={onFinish} text={"Let's pick the first system and move on to preparing for its implementation."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SelectedSystemAllSteps"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Preparation for the implementation of each system includes 6 steps. Each step can affect the success of the system implementation and its usability for future users. At the bottom of the section, you can find the main indicators of system implementation, which change with each step."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : [50, 600]
    },
    {
      selector: '[data-tut="SystemContractor"]',
      content: <StepTour onFinish={onFinish} text={"The first and only required step is choosing the type of system, type of contract and contractor."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Start"]',
      content: <StepTour onFinish={onFinish} text={"Click to Start"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SystemTypeStep"]',
      content: <StepTour onFinish={onFinish} text={"Select System Type"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="ContractModelStep"]',
      content: <StepTour onFinish={onFinish} text={"Select Contractual Model"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Contractor"]',
      content: <StepTour onFinish={onFinish} text={"Select Contarctor"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : [30, 600]
    },
    {
      selector: '[data-tut="UserAnalysis"]',
      content: <StepTour onFinish={onFinish} text={"If you want to research which type of system would be most preferred by users, conduct a user analysis. Based on the results of the analysis, you can change decisions in the first step, or even carry it out first in the future."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Administrative"]',
      content: <StepTour onFinish={onFinish} text={"System implementation may require administrative changes. If you think this concerns the current system, arrange for a review. Ignoring such changes can lead to stopping the implementation process at the most inopportune moment."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Infrastructure"]',
      content: <StepTour onFinish={onFinish} text={"System implementation may require infrastructural changes. If you think this concerns the current system, arrange for a review. Ignoring such changes can lead to stopping the implementation process at the most inopportune moment."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Employee"]',
      content: <StepTour onFinish={onFinish} text={"Finally, there are two optional items that can affect the usability and success of the system. The decision to use them is entirely up to you."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Tests"]',
      content: <StepTour onFinish={onFinish} text={"Finally, there are two optional items that can affect the usability and success of the system. The decision to use them is entirely up to you."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="StartImplementation"]',
      content: <StepTour onFinish={onFinish} text={"If you think you have made all the necessary decisions, click Start Implementation."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Welcome"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"The system is ready for implementation. But this process takes time."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Timer"]',
      content: <StepTour onFinish={onFinish} text={"While the time is counting down, you can implement or upgrade other systems and take any action. The number of simultaneous processes is unlimited. We will now jump to the point where the implementation of the system is complete."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 50] : "top"
    },
    {
      selector: '[data-tut="Event"]',
      content: <StepTour onFinish={onFinish} text={"Every time the system is implemented, you will receive a notification with the results. Let's click on the name of the implemented system and see what else we can do with it."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SystemUpgradeScores"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can see the key indicators of the system. Digital Transformation Rate of the system depends on the level of achievement of its main KPI — in this case it is СSI."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="KPI"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can evaluate the efficiency and success of the system according to the relevant KPIs. The usability and prevalence of systems have a direct impact on your relationships with the public and business."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Marketing"]',
      content: <StepTour onFinish={onFinish} text={"You can also promote the system or information about it through marketing campaigns. Select a contractor and set a budget to increase the system's KPI and / or public awareness of it"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="MarketingBlock"]',
      content: <StepTour onFinish={onFinish} text={"Let's pick an agency and launch a campaign for any budget"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="RunCampaign"]',
      content: <StepTour onFinish={onFinish} text={"Run Campaign"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Upgrade"]',
      content: <StepTour onFinish={onFinish} text={" In this section, you can select and launch a system upgrade that will increase its KPI. You can also change the system type if possible in the current configuration. Remember that upgrades may not always have a positive impact."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="UpgradeBlock"]',
      content: <StepTour onFinish={onFinish} text={"Let's launch an upgrade that should boost our target KPI."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="RunUpgrade"]',
      content: <StepTour onFinish={onFinish} text={"Run Upgrade"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="SelectDirection"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"You can observe a change in progress in a direction, as well as a change in overall progress."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="ScopeDirection"]',
      content: <StepTour onFinish={onFinish} text={"If you click on the icon of the corresponding color, you will be taken to a section of one of the 6 spheres"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="ScopeForm"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can see the progress in the directions of the sphere, as well as change its head. A head's reputation affects the consequences of unforeseen events. Expertise in digital transformation - on the usability of the systems being implemented."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Manage"]',
      content: <StepTour onFinish={onFinish} text={"In order to continue the implementation of new systems in this sphere more successfully, let's see if we can hire a more qualified head of the sphere."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="Head"]',
      content: <StepTour onFinish={onFinish} text={"Select Head"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="HeadConfirm"]',
      content: <StepTour onFinish={onFinish} text={"Confirm Choise"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: devicesize === DeviceSize.XS ? '[data-tut="DigitalMobile"]' : '[data-tut="Digital"]',
      content: <StepTour onFinish={onFinish} text={"To assess the current progress by goals and areas of digital transformation, click on Digital transformation rate button."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    },
    {
      selector: '[data-tut="ProgressBlock"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can remember which KPIs in which system you need to have in order for the goal to be considered achieved. You can see how the implementation of the system affected the achievement of one of the goals. You can also see a dashboard on progress in each of the 6 areas, as well as in their directions and systems. Remember, the more systems you implement and improve, the higher your Digital transformation rate is."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : [500, 5]
    },
    {
      selector: '[data-tut="DashBoardBubble"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Now let's go over the remaining sections. You can also access them by clicking on the Detail button on the main screen."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, windowSize.height - 250] : "top"
    },
    {
      selector: '[data-tut="YourProcesses"]',
      content: <StepTour onFinish={onFinish} text={"This section displays all current processes - whether it is the implementation of the system or upgrade or the change in financial metrics"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, windowSize.height - 250] : "top"
    },
    {
      selector: '[data-tut="YourProcessesBlock"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"This section displays all current processes - whether it is the implementation of the system or upgrade or the change in financial metrics"} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : [500, 0]
    },
    {
      selector: '[data-tut="PermanentTeam"]',
      content: <StepTour onFinish={onFinish} text={"This section allows you to hire additional staff for your permanent team. Each of the team members takes on one of the important functions of working with systems - from checking the need for administrative changes to conducting a marketing campaign. Expanding your permanent team will allow you to develop your city faster."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, windowSize.height - 250] : "top"
    },
    {
      selector: '[data-tut="PermanentTeamFirst"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"Here you can see information about the administrative procedures specialist."} />,
      stepInteraction: false,
      position: devicesize === DeviceSize.XS ? [0, 0] : [50, 600]
    },
    {
      selector: '[data-tut="PermanentTeamHire"]',
      content: <StepTour onFinish={onFinish} text={"Now you don't need to worry about administrative procedures when implementing any system. But don't forget to keep track of your spending!"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : [50, 600]
    },
    {
      selector: '[data-tut="Budget Report"]',
      content: <StepTour onFinish={onFinish} text={"This section allows you to see where the money comes from and where it goes every quarter."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, windowSize.height - 250] : "top"
    },
    {
      selector: '[data-tut="Budget ReportBlock"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"This section allows you to see where the money comes from and where it goes every quarter."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : [30, 0]
    },
    {
      selector: '[data-tut="Financial Indicators"]',
      content: <StepTour onFinish={onFinish} text={"In this section, you can adjust financial indicators that affect the life of city residents and business activities. Changing indicators directly affects your income, as well as relationships with residents and businesses. The change in the indicator takes effect 1-4 weeks after its adoption - you will see the effect in the notification."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, windowSize.height - 250] : "top"
    },
    {
      selector: '[data-tut="FinancialIndicatorsMesuare"]',
      content: <StepTour onFinish={onFinish} text={"Everything seems to be in order with our residents - let's increase our quarterly revenues by increasing utility fees."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : [30, 0]
    },
    {
      selector: '[data-tut="Timer"]',
      content: <StepTour onFinish={onFinish} text={"Now let's click on the timer and wait for the change to take effect."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 50] : "top"
    },
    {
      selector: '[data-tut="Notify"]',
      content: <StepTour onNext={onNext} onFinish={onFinish} text={"As you can see, the change in the indicator has already affected the loyalty of people and businesses. Be careful! You may also notice that our upgrade has been successfully implemented."} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 100] : "top"
    }
    ,
    {
      selector: '[data-tut="None"]',
      content: <StepTour isLastStep={true} onFinish={onFinish} text={"Welcome again! You have 8 years to achieve your goals and maximize your city's digital transformation. Now you need to set up to 5 goals and start managing the city yourself. Good luck!"} />,
      stepInteraction: true,
      position: devicesize === DeviceSize.XS ? [0, 0] : "top"
    }
  ]

  return steps;
}