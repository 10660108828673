import { httpPost, httpGet, httpPut, IPageResult } from './common';
import * as queryString from "query-string";

export enum GameStatusEnum {
    CREATED = "CREATED",
    ACTIVE = "ACTIVE",
    DONE = "DONE"
}

export interface ICreateGame {
    state: string;
}

export interface IGameDto {
    id: number,
    beginDate: Date;
    lastActionDate: Date;
    state: string;
    email: string;
}

export interface IGamesFilter {
    userId?: number,
    status?: GameStatusEnum,
    beginDate?: Date
}

export interface IGameStatistic {
    action: string;
    stateBefore: string;
    stateAfter: string;
}

export function create(game: ICreateGame) {
    return httpPost<IGameDto>('/api/games/create', game, { authorize: true });
}

export function save(id: number, game: ICreateGame) {
    return httpPut<IGameDto>(`/api/games/${id}/save`, game, { authorize: true });
}

export function score(id: number, value: number) {
    return httpPost<IGameDto>(`/api/games/${id}/score/${value}`, { authorize: true });
}

export function getById(id: number) {
    return httpGet<IGameDto>(`/api/games/${id}`, { authorize: true });
}

export function getGames(filter: IGamesFilter | null) {
    const query = filter === null ? "" : '?' + queryString.stringify(filter);
    return httpGet<IPageResult<IGameDto>>(`/api/games${query}`, { authorize: true });
}

export function saveStatsApiCall(gameId: number, gameStats: IGameStatistic) {
    return  httpPost<any>(`/api/game/${gameId}/stats`, gameStats, { authorize: true });
}
