import React, { useEffect } from 'react';
import style from './style.module.scss';
import logoBig from '../../icons/logo-big.svg';
import { GameItems } from './GameItems';
import { useLocation } from 'react-router';
import DemoAuthForm from '../DemoAuthForm';
import { openLoginForm } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenAuth } from '../../redux/selectors/forms/openLogin';
import { HseLogoIcon } from '../../icons';

export function MainPage() {
  const dispatch = useDispatch();

  const isOpen = useSelector(getIsOpenAuth);
  const location = useLocation();  

  useEffect(()=>{
    if(location.pathname === '/demo/login' ) {
      dispatch(openLoginForm());
    }
  }, [location, dispatch]);
  
 
  return <>
          <div className={style.mainPage}>
            <div className={style.contentContainer}>
              <div className={style.hseLogo}>
                <HseLogoIcon />
              </div>

              <div className={style.content}>
                <img className={style.gameLogo} src={logoBig} alt='SMARTCITY'/>
                <p className={style.gameInfo} >Try on the role of the mayor of the city and carry out digital transformation in the city without losing the loyalty of the population, entrepreneurs and not wasting the entire city budget.</p>
              
                <GameItems />

                <a className={style.enterGame} href="https://www.coursera.org/learn/hse6/home/welcome" target="_blank" rel="noopener noreferrer">
                  <div>Enter game</div>
                </a>

                <div className={style.footer}>Game by the Higher School of Economics</div>
              
              </div>
              
              <div className={style.panel}>
                <div className={style.panelBlur}>
                  <div className={style.panelBg}>

                  </div>
                </div>
              </div>
            </div>          
          </div>
          {isOpen && <DemoAuthForm />}
        </>;
}

export default MainPage;
