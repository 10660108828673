import React from 'react';
import { IGamePoint } from '../../../models';
import GameScopesPoint from './GameScopesPoint';
import GameDirectionsPoint from './GameDirectionsPoint';
import { ISystemProgress } from '../../RoundProgressBar';

interface IGamePointProps {
    id?: string;
    point: IGamePoint;
    isHighlight?: boolean;
    isPulse: boolean;
    className?: any;
    directionName?: string;
    dtrDirection?: number;
    fromMap?: boolean;
    systemsProgress?: ISystemProgress[];
}

const GamePoint: React.FunctionComponent<IGamePointProps> = (props) => {
    const { id, point, isHighlight, className, directionName, dtrDirection, isPulse, fromMap, systemsProgress } = props;

    return point.type === 'Scope' ?
        <GameScopesPoint id={id} scopeId={point.scopeId} coord={point.coord} isPulse={isPulse} isHighlight={isHighlight} className={className} fromMap={fromMap}/> :
        <GameDirectionsPoint id={id} point={point} isHighlight={isHighlight} isPulse={isPulse}  className={className} directionName={directionName} dtrDirection={dtrDirection} fromMap={fromMap} systemsProgress={systemsProgress} />

}

export default GamePoint;