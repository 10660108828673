import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import moneyImage from '../../../../image/money.png';
import { convertMoneyToString } from '../../../../service/convertorToString';
import { useDispatch } from 'react-redux';
import { openGameDashboard } from '../../../../redux/actions/forms/openGameDashboard';

interface IProps {
    className?: any;
    budget: number;
    onRight?: boolean;
}

export function Buget(props: IProps) {
    const { budget, className, onRight = false } = props;
    const money = Math.round(budget);
    const dispatch = useDispatch();

    const renderIcon = () => {
        return <div className={cn(style.icon, onRight ? style.iconRight : "")}>
            <img alt={moneyImage} src={moneyImage} />
        </div>
    }

    const handleClick = () => {
        dispatch(openGameDashboard(0));
    }

    return (
        <div className={cn(style.buget, className)} onClick={handleClick}>
            <div className={cn(style.card, className)}>
                {!onRight && renderIcon()}
                <div className={style.description}>
                    <span className={style.title}>Budget</span>
                    <span className={style.value}>{convertMoneyToString(money)}</span>
                </div>
                {onRight && renderIcon()}
            </div>
        </div>
    );
}

export default Buget;