import React from 'react';
import style from './style.module.scss';
import { PeopleIcon, BusinessIcon, MoneyIcon } from '../../../icons';

export function GameItems() {

    return <div className={style.gameItems}>

                <div className={style.item}>
                    <PeopleIcon />
                    <p>People  Loyalty</p>
                </div>

                <div className={style.item}>
                    <BusinessIcon />
                    <p>Business  Loyalty</p>
                </div>

                <div className={style.item}>
                    <MoneyIcon />
                    <p>City  Budget</p>
                </div>

            </div>;
}