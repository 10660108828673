import { Action } from "redux";
import { SET_DT_EVENT_ID, ISetCurrentDTEventIdAction } from "../../actions";

export const initial: number[] = [];

export const reducer = (state: number[] = initial, action: Action): number[] => {
    switch (action.type) {
        case SET_DT_EVENT_ID: {
            const { currentDTEventId: currentDTeventId } = action as ISetCurrentDTEventIdAction;
            return [...state, currentDTeventId];
        }
        default:
            return state;
    }
}