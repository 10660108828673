import { Action } from "redux";
import { OPEN_GAME_DASHBOARD, CLOSE_GAME_DASHBOARD, IOpenGameDashboardForm } from "../../actions/forms/openGameDashboard";

export interface IState {
    isOpen: boolean;
    currentBoard?: number;
}

export const initial: IState = {
    isOpen: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_GAME_DASHBOARD:
            const {currentBoard} = action as IOpenGameDashboardForm;
            return {
                ...state,
                currentBoard: currentBoard,
                isOpen: true
            };

        case CLOSE_GAME_DASHBOARD:
            return {
                ...state,
                isOpen: false,
                currentBoard: undefined
            };

        default:
            return state;
    }
}