import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import {authorize, IAuthResult} from "../../api/auth";
import {push} from "react-router-redux";
import { ILoginInitAction, loginDone, loginFail, LOGIN_INIT, LOGOUT } from '../actions';
import { IErrorResponse } from '../../api/common';

export function* demoAuthHandler(action: Action) {
    try {
        const { username, password } = action as ILoginInitAction;
        const result: IAuthResult =  yield call(authorize, username, password);
        yield put(loginDone(result));
        yield put(push('/profile'));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(loginFail(c))
    }
}
export function* demologoutHandler(action: Action) {
        yield put(push(`/`));
}


export function* demoAuthSaga() {
    yield takeLatest(LOGIN_INIT, demoAuthHandler);
    yield takeLatest(LOGOUT, demologoutHandler);
}
