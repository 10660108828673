import React from 'react';
import AbstractInfoCard from './abstract';
import { ICityInformation } from '../../models';

interface IProps {
    cityInfo: ICityInformation;
}

const ClimateInfoCard: React.FunctionComponent<IProps> = (props) => {
    const {cityInfo} = props;

    return <AbstractInfoCard title={"Climate"}
        description={cityInfo.weather}>
    </AbstractInfoCard>
}

export default ClimateInfoCard;
