import React from 'react';
import style from './style.module.scss';
import AbstractInfoCard from './abstract';
import Buget from '../Game/Statistic/Budget';
import { ICityInformation } from '../../models';
import { useSelector } from 'react-redux';
import { getMoney } from '../../redux/selectors';
import { convertMoneyToString } from '../../service/convertorToString';
import { useQuaterlyMoney } from '../../hooks/useQuaterlyMoney';

interface IProps {
    cityInfo: ICityInformation;
}

const BudgetInfoCard: React.FunctionComponent<IProps> = (props) => {
    const { cityInfo } = props;
    const money = useSelector(getMoney);
    const {totalQplus} = useQuaterlyMoney();


    return <AbstractInfoCard title={"Budget"}
        description={cityInfo.moneyText}>
        <div className={style.budgetBody}>
            <Buget className={style.budget} budget={money.budget}/>
        </div>
    </AbstractInfoCard>
}


export default BudgetInfoCard;
