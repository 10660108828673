import { IKpi } from "./kpi";

/**
 *  Характеристика сферы
 */
export interface IScope {
    scopeId: number,
    headId: number, // текущий руководитель
    fireHeads: number[],
    title: string,
    directions?: IDirection[]
}

export enum ScopeEnum {
    EDUCATION = 1,
    HEALTH = 2,
    TRANSPORT = 3,
    E_HOUSING = 4,
    E_SERVICES = 5,
    SECURITY = 6
}

/** 
 * Характеристика направления
 */
export interface IDirection {
    directionId: number,
    title: string,
    commonTargets: ICommonTarget[]
}

/**
 * Общая цель
 */
export interface ICommonTarget {
    commonTargetId: number,
    systemId: number,
    title: string,
    description: string;
    priorityobjective: number[],
    digitalTargets: IDigitalTarget[]
}

/**
 * Цель цифровой трансформации
 */
export interface IDigitalTarget {
    digitalTargetId: number,
    title: string,
    kpi: IKpi
}

/**
 * Взаимосвязь общей цели и цели цифровой трансформации
 */
export interface ITarget {
    scopeId: number,
    directionId: number,
    commonTargetId: number,
    digitalTargetId?: number,
    currentKpi?: number
}