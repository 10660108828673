import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { HelpIcon } from '../../../../../icons';
import ClassicButton from '../../../../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../../../../UiKit/Buttons/common';
import RangeSlider from '../../../../RangeSlider';
import cn from 'classnames';
import { StateFinMetricEnum, IFinMetric, getStateFinMetric, getCurrentMN } from '../../../../../models/finMetric';
import { ISystem } from '../../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { metricChange } from '../../../../../redux/actions';
import { ToolTip } from '../../../../UiKit/ToolTip';
import { getLearningProgress, getLearningCompleted } from '../../../../../redux/selectors';
import { learningProgressNext } from '../../../../../redux/actions/game/learningProgress';
import { closeGameDashboard } from '../../../../../redux/actions/forms/openGameDashboard';

interface IProps {
    finMetric: IFinMetric;
    systems: ISystem[];
    className: any;
    timerLaunched: boolean;
    dataTut: any;
}

export const Indicator = (props: IProps) => {
    const { finMetric, systems, className, timerLaunched, dataTut } = props
    const dispatch = useDispatch();
    const [selectedValue, setValue] = useState<number>();
    const [startValue, setStartValue] = useState<number>();
    const { name, maxMN, minMN, coefmoneyQplusMN, baseMN } = finMetric;
    const [isActive, setActive] = useState<boolean>(false);
    const [state, setState] = useState<StateFinMetricEnum>(StateFinMetricEnum.OFF);
    const { inProgress } = useSelector(getLearningProgress);
    const learningCompleate = useSelector(getLearningCompleted);
    const [currentFinMetric, setFinMetric] = useState<IFinMetric>(finMetric);

    const disable = timerLaunched && finMetric.newMN !== undefined;

    useEffect(() => {
        const state = disable ? StateFinMetricEnum.WAIT : getStateFinMetric(finMetric, systems);
        const currentMN = finMetric.newMN ? finMetric.newMN : getCurrentMN(finMetric, systems);
        setValue(currentMN);
        setStartValue(currentMN);
        setState(state);
        setActive(state === StateFinMetricEnum.ON);
        setFinMetric(finMetric);
    }, [finMetric, systems, disable])

    const handleChange = (val: number) => {
        if (isActive) {
            setValue(val);
        }
    }

    const handleApplyCLick = () => {
        currentFinMetric.newMN = selectedValue;
        dispatch(metricChange(currentFinMetric))
        if (inProgress) {
            !learningCompleate && dispatch(learningProgressNext());
            dispatch(closeGameDashboard());
        }
    }

    const handleResetCLick = () => {
        currentFinMetric.newMN = undefined;
        setValue(startValue);
        dispatch(metricChange(currentFinMetric))
    }

    const handleOnCLick = () => {
        if (selectedValue !== undefined) {            
            currentFinMetric.currentMN = selectedValue;
            currentFinMetric.changable = true;
            dispatch(metricChange(currentFinMetric))
            setActive(true)
        }
    }

    const handleOffCLick = () => {
        if(currentFinMetric.canOff) {
            currentFinMetric.currentMN = baseMN;
            currentFinMetric.changable = false;
            dispatch(metricChange(currentFinMetric))
            setActive(false)
        }
    }

    const renderIcon = () => {
        switch (state) {
            case StateFinMetricEnum.ON:
                return <div className={style.on} onClick={handleOffCLick}></div>
            case StateFinMetricEnum.OFF:
                return <div className={style.off} onClick={handleOnCLick}></div>
            case StateFinMetricEnum.WAIT:
                return <ToolTip text={"Wait to aplly change"} id={`${finMetric.id.toString()}+wait to apply`}>
                    <div className={style.onLocked}></div>
                </ToolTip>
            default:
                return <ToolTip text={"the system is not implemented"} id={`${finMetric.id.toString()}+not implemented`}>
                    <div className={style.onLocked}></div>
                </ToolTip>
        }
    }

    const renderTitleIndicator = () => {
        return <div className={style.title}>
            <div className={style.leftPart}>
                <span className={style.title}>{name.indexOf('(') < 0 ? name : name.substring(0, name.indexOf('('))}</span>
                <ToolTip text={currentFinMetric.description} id={currentFinMetric.id.toString()}>
                    <HelpIcon />
                </ToolTip>
            </div>
            {renderIcon()}
        </div>
    }

    const renderProgress = () => {

        let minMN_ = minMN;
        let selectedValue_ = selectedValue;
        if(state === StateFinMetricEnum.OFF) {
            minMN_ = 0;
            selectedValue_ = 0;
        }

        return selectedValue !== undefined && <RangeSlider title={"Coefficient"} maxValue={maxMN}
            minValue={minMN_} value={selectedValue} isMoney={false}
            onChange={handleChange} />
    }

    const renderBuget = () => {
        return <div className={style.buget}>
            <div className={style.infoBuget}>
                <span className={style.spantTitle}>Budget Income:</span>
                <span className={style.valueBudget}>&nbsp;{coefmoneyQplusMN * (selectedValue ?? 1)}K</span>
            </div>

            {renderBugetButton()}
        </div>
    }

    const renderBugetButton = () => {
        return startValue !== selectedValue && !currentFinMetric.newMN ?
            <ClassicButton label={'APPLY'} size={SizeButton.normal} handleClick={handleApplyCLick} classes={style.actionBudget} />
            : currentFinMetric.newMN && <ClassicButton label={'RESET'} size={SizeButton.normal} handleClick={handleResetCLick} classes={style.actionBudget} />
    }

    return <div className={cn(style.indicator, className)} data-tut={dataTut}>
        {renderTitleIndicator()}
        {renderProgress()}
        {renderBuget()}
    </div>;
}