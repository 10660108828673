import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import GameForm from '../UiKit/GameForm';
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../UiKit/Buttons/common';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import { closeResultForm, openResultForm, gameFinished, learningCompleate, learningProgressPause } from '../../redux/actions';
import { getTimer, getSelectedSystems, getBusinessReputaiton, getSocietyReputaiton, getTargets, getGameId, getMoney, getLearningCompleted } from '../../redux/selectors';
import moment from "moment";
import { getResultForm } from '../../redux/selectors/forms/screens/openScreen';
import InfoFooter from '../UiKit/InfoFooter';
import useScopes from '../../hooks/useScopes';
import { scoreGameInit } from '../../redux/actions/gameAPI/scoreGame';
import { calcDigitalTransformationRate } from '../../service/calcDigitalTransformRate';
import { calcGoalsAchievementRate } from '../../service/calcGoalsAchievementRate';

const GAME_TIME_LIMIT = 8;

interface IText {
    header: string
    description: string
    descriptionAdditionally: string
}

const Result = () => {
    const { isOpen } = useSelector(getResultForm)
    const dispatch = useDispatch();
    const gameId = useSelector(getGameId);
    const scopes = useScopes();
    const targets = useSelector(getTargets);
    const timer = useSelector(getTimer);
    
    const learningCompletedRef = useRef<boolean>(true);
    const learningCompleted = useSelector(getLearningCompleted);
    const selectedSystems = useSelector(getSelectedSystems);
    const businessReputaiton = useSelector(getBusinessReputaiton);
    const societyReputaiton = useSelector(getSocietyReputaiton);
    const money = useSelector(getMoney);

    const [text, setText] = useState<IText>({ header: "", description: "", descriptionAdditionally: "" });
    const [digitalTransformationRate, setDigitalTransformationRate] = useState<number>(0);
    const [goalsAchievementRate, setGoalsAchievementRate] = useState<number>(0);
    const [gameScore, setGameScore] = useState<number>(0);

    const [colorSociety, setColorSociety] = useState<ScoreProgressBarColor>(ScoreProgressBarColor.blue);
    const [colorBusiness, setColorBusiness] = useState<ScoreProgressBarColor>(ScoreProgressBarColor.blue);
    const [colorDigitalTransformation, setColorDigitalTransformation] = useState<ScoreProgressBarColor>(ScoreProgressBarColor.blue);
    const [colorGoalAchievementRate, setGoalAchievementRate] = useState<ScoreProgressBarColor>(ScoreProgressBarColor.blue);

    const [styleSociety, setStyleSociety] = useState<any>("");
    const [styleBusiness, setStyleBusiness] = useState<any>("");
    const [styleDigitalTransformation, setStyleDigitalTransformation] = useState<any>("");
    const [styleGoalAchievementRate, setStyleAchievementRate] = useState<any>("");


    const handleCloseClick = () => {
        dispatch(closeResultForm());
        finish();
    }

    const learningProccess = () => {
        if(!learningCompleted) {
            learningCompletedRef.current = false;
            dispatch(learningCompleate());
            dispatch(learningProgressPause());
        }        
    }

    const getResult = () => {
        const year = moment(timer.curDate).diff(timer.startDate, 'year');

        if (money.budget < 0) {
            setText({
                header: "You Lose",
                description: "Unfortunately, you ran into a budget deficit and the digital transformation program had to end.",
                descriptionAdditionally: "See how much you have accomplished during the game, and try again to achieve more."
            });

            if (businessReputaiton && businessReputaiton < 30) {
                setColorBusiness(ScoreProgressBarColor.red);
                setStyleBusiness(style.red);
            }
            if (societyReputaiton && societyReputaiton < 30) {
                setColorSociety(ScoreProgressBarColor.red);
                setStyleSociety(style.red);
            }

            learningProccess();

            dispatch(gameFinished());
            dispatch(openResultForm());
        }
        else if ((societyReputaiton && societyReputaiton <= 30) || (businessReputaiton && businessReputaiton <= 30)) {
            setText({
                header: "You Lose",
                description: "Unfortunately, your relationships with people/business have dropped dramatically which led to an early resignation.",
                descriptionAdditionally: "See how much you have accomplished during the game, and try again to achieve more."
            });
            if (businessReputaiton && businessReputaiton < 30) {
                setColorBusiness(ScoreProgressBarColor.red);
                setStyleBusiness(style.red);
            }
            if (societyReputaiton && societyReputaiton < 30) {
                setColorSociety(ScoreProgressBarColor.red);
                setStyleSociety(style.red);
            }

            learningProccess();

            dispatch(gameFinished());
            dispatch(openResultForm());
        }

        else if ((goalsAchievementRate < 100 || digitalTransformationRate < 100) && year >= GAME_TIME_LIMIT) {
            setText({
                header: "You Lose",
                description: "Congratulations, you've got to the end of the game! Unfortunately, you were not able to achieve your goals during your tenure as a mayor.",
                descriptionAdditionally: "See how much you have accomplished during the game, and try again to achieve more."
            })

            if (goalsAchievementRate < 100) {
                setGoalAchievementRate(ScoreProgressBarColor.red);
                setStyleAchievementRate(style.red);
            } else {
                setGoalAchievementRate(ScoreProgressBarColor.yellow);
                setStyleAchievementRate(style.yellow);
            }

            if (digitalTransformationRate < 100) {
                setColorDigitalTransformation(ScoreProgressBarColor.red);
                setStyleDigitalTransformation(style.red);
            } else {
                setGoalAchievementRate(ScoreProgressBarColor.yellow);
                setStyleAchievementRate(style.yellow);
            }

            learningProccess();

            dispatch(gameFinished());
            dispatch(openResultForm());
        }

        else if (goalsAchievementRate >= 100 && digitalTransformationRate >= 100 && year <= GAME_TIME_LIMIT) {
            setText({
                header: "You Win!",
                description: "Wow, you’re a genius of digital transformation and urban management! Congratulations, you are one of the few who managed to win this game ahead of schedule. We are sincerely proud of your result.",
                descriptionAdditionally: "Look at your amazing scores and share them with your friends. Or try again and do it even faster."
            })

            setGoalAchievementRate(ScoreProgressBarColor.yellow);
            setColorDigitalTransformation(ScoreProgressBarColor.yellow);
            setStyleAchievementRate(style.yellow);
            setStyleDigitalTransformation(style.yellow);

            dispatch(gameFinished());
            dispatch(openResultForm());
        }
    }

    const finish = () => {
        if(learningCompletedRef.current)
            dispatch(scoreGameInit(gameId, gameScore));

        dispatch(closeResultForm());
        window.location.href='/profile';
    }

    useEffect(() => {
        getResult();

        const dtr = calcDigitalTransformationRate(scopes, selectedSystems) * 100;
        if (dtr !== digitalTransformationRate)
            setDigitalTransformationRate(Math.round(dtr));

        const gar = calcGoalsAchievementRate(targets, scopes, selectedSystems) * 100;
        if (gar !== goalsAchievementRate)
            setGoalsAchievementRate(Math.round(gar));

        const score = (dtr / 100 * gar / 100) * 100;
        if (score !== gameScore)
            setGameScore(Math.round(score));

        // console.log(`Game result. Digital transformation rate: ${Math.round(dtr)}%. Goal achievement rate: ${Math.round(gar)}%. Game score: ${score}`);

    },
        // eslint-disable-next-line
        [timer, targets, selectedSystems, money]);

    const renderResult = () => {
        const business = businessReputaiton ? businessReputaiton : 0;
        const society = societyReputaiton ? societyReputaiton : 0;
        return <div className={style.progress}>
            <div className={style.line}>
                <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={styleGoalAchievementRate} valueStyle={styleGoalAchievementRate} color={colorGoalAchievementRate} title={"Goal Achievement Rate"} value={goalsAchievementRate} displayValue={`${goalsAchievementRate}%`} />
                <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={styleDigitalTransformation} valueStyle={styleDigitalTransformation} color={colorDigitalTransformation} title={"Digital Transformation Rate"} value={digitalTransformationRate} displayValue={`${Math.round(digitalTransformationRate)}%`} />
            </div>
            <div className={style.line}>
                <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={styleSociety} valueStyle={styleSociety} color={colorSociety} title={"Relationship with Society"} value={society} displayValue={`${Math.round(society)}%`} />
                <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={styleBusiness} valueStyle={styleBusiness} color={colorBusiness} title={"Relationship with Business"} value={business} displayValue={`${Math.round(business)}%`} />
            </div>
        </div>
    }

    return (
        isOpen ?
            <GameForm close={handleCloseClick}>
                <div className={style.result}>
                    <div className={style.header}>
                        {text.header}
                    </div>
                    <div className={style.description}>
                        <div className={style.part1}>
                            {text.description}
                        </div>
                        <div className={style.part2}>
                            {text.descriptionAdditionally}
                        </div>
                    </div>
                    {renderResult()}
                    <InfoFooter>Please note: At each new start, the game runs in an unique scenario.</InfoFooter>
                    <ClassicButton label={'FINISH'} size={SizeButton.normal} handleClick={() => finish()} classes={style.action} />
                </div>
            </GameForm> : null
    )
}

export default Result;
