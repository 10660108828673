import { Action } from "redux";
import { IGamePoint } from "../../models";

export const HIGHTLIGHT_POINT_INIT = 'HIGHTLIGHT_POINT_INIT';
export const HIGHTLIGHT_POINT_DONE = 'HIGHTLIGHT_POINT_DONE';

export interface IHichtlightPointInitAction extends Action {
    point: IGamePoint | undefined;
}

export function hightlightPointInit(point: IGamePoint | undefined) {
    return { type: HIGHTLIGHT_POINT_INIT, point } as IHichtlightPointInitAction;
}

export function hightlightPointDone() {
    return { type: HIGHTLIGHT_POINT_DONE };
}