import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import style from './style.module.scss';
import data from '../../../../data';
import { getCurrentTeamMembers } from '../../../../redux/selectors';
import ClassicButton from '../../../UiKit/Buttons/ClassicButton';
import { SizeButton, ColorButton } from '../../../UiKit/Buttons/common';
import { AgancyCardScroll } from '../AgancyCardScroll';
import RangeSliderBudget from '../RangeSliderBudget';
import { ISystem } from '../../../../models';
import { ProcedureState } from '../../../System/Procedures';

interface IProps {
    scopeId: number
    system: ISystem
    onStopClick: () => void
}

export const MarketingRunning = (props: IProps) => {
    const { scopeId, system, onStopClick } = props
    const scopeAgency = data.scopeAgencies.find(x => x.scopeId === scopeId);
    const agencies = scopeAgency?.agencies;
    const team = useSelector(getCurrentTeamMembers);
    const [actionBlock, setBlock] = useState<'Buttons' | 'Agency' | 'Budget'>('Buttons')

    let state:ProcedureState = 'Default';
    if(team.findIndex(t => t.id === 7)>=0) {
        state = 'Advanced'
    }
    
    const handleCloseChanges = () => {
        setBlock('Buttons');
    }

    const renderCampaign = () => {
        return <div className={style.runAgency}>
            <div className={style.name}>{system?.agency?.name}</div>
            <div className={style.campaign}>
                <span className={style.title}>Campaign performance:</span>
                <span className={style.value}>&nbsp;{system.mperformance ? `${Math.round(system.mperformance * 100)}%` : 0}</span>
            </div>
        </div>
    }

    const renderTitle = () => {
        return <div className={style.runTitle}>
            <span className={style.part1}>The campaign is active</span>
            <span className={style.part2}>You can evaluate its effectiveness and make changes.</span>
        </div>
    }

    const renderActions = () => {
        switch (actionBlock) {
            case 'Agency': {
                return renderAgency()
            }
            case 'Budget': {
                return renderBuget()
            }
            default: {
                return renderButtons()
            }
        }
    }

    const renderButtons = () => {
        return <div className={style.runActions}>
            {state !== 'Advanced' && <ClassicButton label={'CHANGE THE AGENCY'} size={SizeButton.small} handleClick={() => { setBlock("Agency") }} classes={style.normal} />}
            <ClassicButton label={'CHANGE THE BUDGET'} size={SizeButton.small} handleClick={() => { setBlock("Budget") }} classes={style.normal} />
        </div>
    }

    const renderAgency = () => {
        return <div className={style.runAgency}>
            {agencies && <AgancyCardScroll agencies={agencies} agencyId={system?.agency?.id} system={system} qualityMarketingMax={0} />}
            <ClassicButton label={'CLOSE'} size={SizeButton.normal} handleClick={handleCloseChanges} classes={style.normal} color={ColorButton.azure} />
        </div>
    }

    const renderBuget = () => {
        return <div className={style.runBuget}>
            <RangeSliderBudget title="Set a Budget" system={system} isShowAction={true} onCancelClick={handleCloseChanges} />
        </div>
    }

    return (
        <>
            {renderTitle()}
            {renderCampaign()}
            {renderActions()}
            <div className={style.runCampaign}>
                <ClassicButton label={'STOP THE CAPMAIGN'} size={SizeButton.large} handleClick={() => { onStopClick()}} classes={style.normal} />
            </div>
        </>
    );
}

export default MarketingRunning;