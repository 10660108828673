import { ISystemKpi } from ".";
import { formatKpiDisplayValue } from "../service/calcDisplayKpi";

/**
 * KPI
 */
export interface IKpi {
    code: KpiCodeEnum,
    value: number 
}

/**
 * Виды KPI
 */
export enum KpiCodeEnum {
    CSI = "CSI", // Customer satisfaction index 
    NPS = "NPS", // Net Promoter Score
    FCR= "FCR", // First Call Resolution
    ER = "ER", // Engagement Rate
    COV = "COV", // Coverage
}

/**
 * KPI системы
 */
export interface IDigitalTargetKpi {
    targetId: number; // идентификатор цели
    directionId: number; // идентифкатор направления
    systemId: number; // идентификатор системы
    kpi: IKpi
}

export interface IKpiList {
    nps: number;
    csi: number;
    fcr: number;
    er: number;
    cov: number;
}

export function getKpiFromList(list: IKpiList, code: KpiCodeEnum) {
    if(code === KpiCodeEnum.COV)
        return list.cov;
    
    if(code === KpiCodeEnum.CSI)
        return list.csi;

    if(code === KpiCodeEnum.FCR)
        return list.fcr;

    if(code === KpiCodeEnum.ER)
        return list.er;

    if(code === KpiCodeEnum.NPS)
        return list.nps;

    return 0;
}

export function getMainKpiProgress(kpis: IKpiList, mainKpi: ISystemKpi) {
    let currentKpiValue = Math.round(getKpiFromList(kpis, mainKpi.code));

    currentKpiValue = formatKpiDisplayValue({value:currentKpiValue, code: mainKpi.code});
    const targetValue = formatKpiDisplayValue({value: mainKpi.targetValue, code: mainKpi.code});

    const progress = `${currentKpiValue}/${targetValue}`;
    
    return progress + getKpiUnit(mainKpi.code);
 }

 export function getKpiUnit(code: KpiCodeEnum) {
    if(code === KpiCodeEnum.COV)
        return '%';
    
    if(code === KpiCodeEnum.CSI)
        return '';

    if(code === KpiCodeEnum.FCR)
        return '%';

    if(code === KpiCodeEnum.ER)
        return '%';

    if(code === KpiCodeEnum.NPS)
        return '';

    return '';
}

