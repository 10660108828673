import React from 'react';
import style from '../style.module.scss';
import { IContracorCost } from '../../../../models';
import ContractorCard from '../../ContractorCard';
import HorzScrollableCard from '../../../UiKit/HorzScrollableCard';

interface IProps {
    scopeId: number;
    contractors: IContracorCost[]
    handleSelectedContract: (ct: IContracorCost) => void;
}

const ContractorStep: React.FunctionComponent<IProps> = (props) => {

    const { scopeId, contractors, handleSelectedContract } = props;

    const contractorsCards = contractors.map((c,i)=><ContractorCard scopeId={scopeId} contractorCost={c} handleClick={() => handleSelectedContract(c)} key={i} />);

    return <div className={style.formCenter} data-tut={"Contractor"}>
                <p className={style.textInfoPadding}>Step 3: Choose a contractor</p>
                <div className={style.textInfoPadding}>
                    <HorzScrollableCard item={contractorsCards} />
                </div>
                
            </div>
    
}

export default ContractorStep;