import React from 'react';
import style from './style.module.scss';
import AbstractInfoCard from './abstract';
import useScopes from '../../hooks/useScopes';
import GamePoint from '../Game/GamePoint';
import { IGamePoint, ICityInformation } from '../../models';
import { ScopeEnum } from '../../models/scope'
import { defineScopePointsColor } from '../../service/scopeService';

interface ISphereProps {
    scopeId: ScopeEnum;
    cityInfo: ICityInformation;
}

const SphereInfoCard: React.FunctionComponent<ISphereProps> = (props) => {
    const { scopeId, cityInfo } = props;
    const scopes = useScopes()[scopeId - 1];

    const point = {
        scopeId: scopeId,
        color: defineScopePointsColor(scopeId),
        type: 'Scope'
    } as IGamePoint;

    return <AbstractInfoCard title={`Situation in ${scopes.title} Sphere`} description={""}>
        <div className={style.sphereBody}>
            <GamePoint point={point} className={style.point} isPulse={false}/>
            <div className={style.sphereDescription}>{cityInfo.sphereSituation[scopeId]}</div>
        </div>
    </AbstractInfoCard>
}


export default SphereInfoCard;
