import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAssistantEvents } from '../../redux/selectors';
import { assistantNotifyRemove } from '../../redux/actions';
import style from './style.module.scss';
import Scrollbars from 'react-custom-scrollbars';
import AssistantNotifyItem from './AssistantNotifyItem';

const AssistantNotify = () => {
    const dispatch = useDispatch();
    const events = useSelector(getAssistantEvents);

    const handleRemoveClick = (id: number) => {
        dispatch(assistantNotifyRemove(id));
    }

    return <div className={style.assistantNotify} data-tut={"Notify"}>
        <Scrollbars style={{ height: '100%' }}>
            {events.map((e, i) => {
                setTimeout(() => {
                    dispatch(assistantNotifyRemove(e.notifyId));
                }, 60000)
                return <AssistantNotifyItem key={i} assistantNotify={e} handleRemoveClick={handleRemoveClick} />
            })}
        </Scrollbars>
    </div>
}

export default AssistantNotify;