import { Action } from "redux";

export const SET_DT_EVENT_ID = 'SET_DT_EVENT_ID';

export interface ISetCurrentDTEventIdAction extends Action {
    currentDTEventId: number;
}

export function currentDTEventIdSet(currentDTEventId: number) {
    return { type: SET_DT_EVENT_ID, currentDTEventId };
}
