import { ProcedureState } from "../Procedures";
import { useSelector } from "react-redux";
import { getCurrentTeamMembers } from "../../../redux/selectors";

export function useAnalysisProcedures(){
    
    const team = useSelector(getCurrentTeamMembers);
    let state:ProcedureState = 'Default';
    if(team.findIndex(t => t.id === 5)>=0) {
        state = 'Advanced'
    }
    
    return state;
}