import { IGamePoint, ScopeEnum } from "../../models";
import { defineScopePointsColor } from '../../service/scopeService'

const gamePoints = [
    {   // 1. School education  //
        scopeId: ScopeEnum.EDUCATION,
        coord: { x: 6643, y: 140 },
        color: defineScopePointsColor(ScopeEnum.EDUCATION),
        type: 'Scope'
    },
    {   // 1. School education — 6823x390 
        scopeId: ScopeEnum.EDUCATION,
        directionId: 11,
        coord: { x: 6784, y: 356 },
        color: defineScopePointsColor(ScopeEnum.EDUCATION),
        type: 'Direction'
    },
    {   // 2. University education — 6507x856
        scopeId: ScopeEnum.EDUCATION,
        directionId: 12,
        coord: { x: 6540, y: 810 },
        color: defineScopePointsColor(ScopeEnum.EDUCATION),
        type: 'Direction'
    },
    {
        // 3. Lifelong learning — 333х670 - may be changed
        scopeId: ScopeEnum.EDUCATION,
        directionId: 13,
        coord: { x: 302, y: 600 },
        color: defineScopePointsColor(ScopeEnum.EDUCATION),
        type: 'Direction'
    },
    {   // 2. Health //
        scopeId: ScopeEnum.HEALTH,
        coord: { x: 2750, y: 855 },
        color: defineScopePointsColor(ScopeEnum.HEALTH),
        type: 'Scope'
    },
    {
        // 4. Citizens healthcare — 3007x269 - may be changed
        scopeId: ScopeEnum.HEALTH,
        directionId: 21,
        coord: { x: 2970, y: 224 },
        color: defineScopePointsColor(ScopeEnum.HEALTH),
        type: 'Direction'
    },
    {
        // 5. Hospital systems — 2826x801
        scopeId: ScopeEnum.HEALTH,
        directionId: 22,
        coord: { x: 2927, y: 873 },
        color: defineScopePointsColor(ScopeEnum.HEALTH),
        type: 'Direction'
    },
    {   // 3. Transport //
        scopeId: ScopeEnum.TRANSPORT,
        coord: { x: 1724, y: 957 },
        color: defineScopePointsColor(ScopeEnum.TRANSPORT),
        type: 'Scope'
    },
    {
        // 6. Automobile transport — 4584x259
        scopeId: ScopeEnum.TRANSPORT,
        directionId: 31,
        coord: { x: 4544, y: 259 },
        color: defineScopePointsColor(ScopeEnum.TRANSPORT),
        type: 'Direction'
    },
    {
        // 7. Public transport — 2443x728
        scopeId: ScopeEnum.TRANSPORT,
        directionId: 32,
        coord: { x: 2480, y: 743 },
        color: defineScopePointsColor(ScopeEnum.TRANSPORT),
        type: 'Direction'
    },
    {
        // 8. Sharing systems — 6871x664 - may be changed
        scopeId: ScopeEnum.TRANSPORT,
        directionId: 33,
        coord: { x: 1599, y: 400 },
        color: defineScopePointsColor(ScopeEnum.TRANSPORT),
        type: 'Direction'
    },
    {
        // 9. Transport ecosystem — 162х306
        scopeId: ScopeEnum.TRANSPORT,
        directionId: 34,
        coord: { x: 130, y: 260 },
        color: defineScopePointsColor(ScopeEnum.TRANSPORT),
        type: 'Direction'
    },
    {   // 4. E-Housing //
        scopeId: ScopeEnum.E_HOUSING,
        coord: { x: 4094, y: 860 },
        color: defineScopePointsColor(ScopeEnum.E_HOUSING),
        type: 'Scope'
    },
    {
        // 10. Houses — 1240x287
        scopeId: ScopeEnum.E_HOUSING,
        directionId: 41,
        coord: { x: 1211, y: 246 },
        color: defineScopePointsColor(ScopeEnum.E_HOUSING),
        type: 'Direction'
    },
    {
        // 11. Streets — 4989x671
        scopeId: ScopeEnum.E_HOUSING,
        directionId: 42,
        coord: { x: 4957, y: 623 },
        color: defineScopePointsColor(ScopeEnum.E_HOUSING),
        type: 'Direction'
    },
    {   // 5. E-Services
        scopeId: ScopeEnum.E_SERVICES,
        coord: { x: 4376, y: 597 },
        color: defineScopePointsColor(ScopeEnum.E_SERVICES),
        type: 'Scope'
    },
    {
        // 12. E-government — 4246x496  
        scopeId: ScopeEnum.E_SERVICES,
        directionId: 51,
        coord: { x: 4124, y: 433 },
        color: defineScopePointsColor(ScopeEnum.E_SERVICES),
        type: 'Direction'
    },
    {
        // 13. E-democracy — 5437x306 - may be changed
        scopeId: ScopeEnum.E_SERVICES,
        directionId: 52,
        coord: { x: 5400, y: 267 },
        color: defineScopePointsColor(ScopeEnum.E_SERVICES),
        type: 'Direction'
    },
    {
        // 14. Budget — 5697x715
        scopeId: ScopeEnum.E_SERVICES,
        directionId: 53,
        coord: { x: 5665, y: 682 },
        color: defineScopePointsColor(ScopeEnum.E_SERVICES),
        type: 'Direction'
    },
    {
        // 6. Security & Safety //
        scopeId: ScopeEnum.SECURITY,
        coord: { x: 2257, y: 200 },
        color: defineScopePointsColor(ScopeEnum.SECURITY),
        type: 'Scope'
    },
    {
        // 15. Tracking — 2464x220
        scopeId: ScopeEnum.SECURITY,
        directionId: 61,
        coord: { x: 2425, y: 141 },
        color: defineScopePointsColor(ScopeEnum.SECURITY),
        type: 'Direction'
    },
    {
        // 16. Emergency services — 660x863
        scopeId: ScopeEnum.SECURITY,
        directionId: 62,
        coord: { x: 630, y: 880 },
        color: defineScopePointsColor(ScopeEnum.SECURITY),
        type: 'Direction'
    }
] as IGamePoint[];

export default gamePoints;