import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import businessImg from '../../../../image/business.png';
import ProgressBar from '../../../Goals/ProgressBar';

interface IProps {
    className?: any;
    businessReputaiton?: number;
    onRight?: boolean;
    showProgress?: boolean;
}

export function Business(props: IProps) {
    const { onRight = false, businessReputaiton, showProgress = false, className } = props
    const business = businessReputaiton ? Math.round(businessReputaiton) : 0;

    const renderIcon = () => {
        return <div className={cn(style.icon, onRight ? style.iconRight : "")}>
            <img alt={businessImg} src={businessImg} />
        </div>
    }

    const renderProgress = () => {
        return showProgress && <ProgressBar completedValue={business} background={style.progressBarBg} />
    }

    const renderCard = () => {
        return <div className={cn(style.card, className)}>
            {!onRight && renderIcon()}
            <div className={style.description}>
                <span className={style.title}>Business</span>
                <span className={style.value}>{`${business} / 100`}</span>
            </div>
            {onRight && renderIcon()}
        </div>
    }

    return (
        <div className={cn(style.business, props.className)}>
            {renderProgress()}
            {renderCard()}
        </div>
    );
}

export default Business;