import { Action } from "redux";
import { IGame } from "../../../models";
import * as timer from './timer';
import * as cityInfo from './cityInfo';
import * as changeStage from './changeStage';
import * as money from './money';
import * as targets from './targets';
import * as scopes from './scopes';
import * as team from './team';
import * as currentCityEvent from './currentCityEvent';
import * as addCurrentCityEventIdToFired from './addHappenedCityEventId';
import * as currentDTEvent from './currentDTEvent';
import * as addCurrentDTEventIdToFired from './addHappenedDTEventId';
import * as system from './systems';
import * as contractors from './contractor';
import * as testMode from './testMode';
import * as finMetrics from './finMetrics';
import * as learningProgress from './learningProgress';
import * as GAME_ACTIONS from "../../actions/game";
import { IGameInitAction, IGameViewChange } from "../../actions";
import * as learning from './learning';

export const initial: IGame = {
    id: 0,
    learningCompleted: false,
    view: 'CityMap',
    timer: timer.initial,
    cityInfo: cityInfo.initial,
    stage: changeStage.initial,
    money: money.initial,
    targets: targets.initial,
    teamMembers: team.initial,
    systems: system.initial,
    contractors: contractors.initial,
    education: scopes.initialEducation,
    health: scopes.initialHealth,
    transport: scopes.initialTransport,
    eService: scopes.initialEservice,
    eHousing: scopes.initialEhousing,
    security: scopes.initialSecurity,
    testModes: testMode.initial,
    currentCityEvent: currentCityEvent.initial.currentCityEvent,
    currentCityEventDate: currentCityEvent.initial.eventDate,
    happenedCityEventsIds: addCurrentCityEventIdToFired.initial,
    currentDTEvent: currentDTEvent.initial.currentDTEvent,
    currentDTEventDate: currentDTEvent.initial.eventDate,
    happenedDTEventsIds: addCurrentDTEventIdToFired.initial,
    finMetrics: finMetrics.initial,
    learningProgress: learningProgress.initial,
    finished: false
}

export const reducer = (state: IGame = initial, action: Action): IGame => {
    switch (action.type) {
        case GAME_ACTIONS.GAME_INIT:
            const { game } = action as IGameInitAction;
            return {
                ...game
            };

        case GAME_ACTIONS.GAME_FINISHED:
            return {
                ...state,
                finished: true
            };

        case GAME_ACTIONS.TARGETS_CHANGE:
            return {
                ...state,
                targets: targets.reducer(state.targets, action)
            };

        case GAME_ACTIONS.CHANGE_VIEW:
            const { view } = action as IGameViewChange;
            return {
                ...state,
                view: view
            };

        case GAME_ACTIONS.SCOPES_CHANGE:
            const newState = scopes.reducer(state, action)
            return newState;

        case GAME_ACTIONS.SYSTEMS_CHANGE:
        case GAME_ACTIONS.SYSTEM_CHANGE:
            return {
                ...state,
                systems: system.reducer(state.systems, action)
            };

        case GAME_ACTIONS.FIN_METRICS_CHANGE:
        case GAME_ACTIONS.FIN_METRIC_CHANGE:
            return {
                ...state,
                finMetrics: finMetrics.reducer(state.finMetrics, action)
            };

        case GAME_ACTIONS.TEAM_CHANGE:
            return {
                ...state,
                teamMembers: team.reducer(state.teamMembers, action)
            };

        case GAME_ACTIONS.CITY_INFO_SET:
        case GAME_ACTIONS.CITY_INFO_PEOPLE_IMPACT:
        case GAME_ACTIONS.CITY_INFO_BUSINESS_IMPACT:
        case GAME_ACTIONS.CITY_INFO_REPUTATION_SET:
            return {
                ...state,
                cityInfo: cityInfo.reducer(state.cityInfo, action)
            };

        case GAME_ACTIONS.MONEY_CHANGE:
            return {
                ...state,
                money: money.reducer(state.money, action)
            }

        case GAME_ACTIONS.CHANGE_STAGE:
            return {
                ...state,
                stage: changeStage.reducer(action)
            };

        case GAME_ACTIONS.CONTRACTOR_CHANGE:
            return {
                ...state,
                contractors: contractors.reducer(state.contractors, action)
            };
        case GAME_ACTIONS.TEST_MODE_CHANGE:
            return {
                ...state,
                testModes: testMode.reducer(state.testModes, action)
            };

        case GAME_ACTIONS.CURRENT_CITY_EVENT:
            const cityEvent = currentCityEvent.reducer(action);
            return {
                ...state,
                currentCityEvent: cityEvent.currentCityEvent,
                currentCityEventDate: cityEvent.eventDate
            };

        case GAME_ACTIONS.CURRENT_DT_EVENT:
            const dtEvent = currentDTEvent.reducer(action);
            return {
                ...state,
                currentDTEvent: dtEvent.currentDTEvent,
                currentCityEventDate: dtEvent.eventDate
            };

        case GAME_ACTIONS.TIMER_START:
        case GAME_ACTIONS.TIMER_PAUSE:
        case GAME_ACTIONS.TIMER_UPDATE:
        case GAME_ACTIONS.TIMER_NEXT_QUATER_UPDATE:
            if(state.finished)
                return {...state};
                
            return {
                ...state,
                timer: timer.reducer(state.timer, action)
            }

        case GAME_ACTIONS.SET_CITY_EVENT_ID:
            return {
                ...state,
                happenedCityEventsIds: addCurrentCityEventIdToFired.reducer(state.happenedCityEventsIds, action)
            }

        case GAME_ACTIONS.SET_DT_EVENT_ID:
            return {
                ...state,
                happenedDTEventsIds: addCurrentDTEventIdToFired.reducer(state.happenedDTEventsIds, action)
            }
        case GAME_ACTIONS.LEARNING_COMPLEATED:
            return {
                ...state,
                learningCompleted: learning.reducer(state.learningCompleted, action)
            }
        case GAME_ACTIONS.LEARNING_PROGRESS_INIT:
        case GAME_ACTIONS.LEARNING_PROGRESS_PAUSE:
        case GAME_ACTIONS.LEARNING_PROGRESS_START:
        case GAME_ACTIONS.LEARNING_PROGRESS_NEXT:
            return {
                ...state,
                learningProgress: learningProgress.reducer(state.learningProgress, action)
            }

        default:
            return state;
    }
}