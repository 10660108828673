import { useEffect } from "react";

export function useScrollComponent(learningCompleted: boolean, element: any) {
    function handleScroll(e: any) {
        e.preventDefault();
    }

    useEffect(() => {
        if (element === null || !element.addEventListener) {
            return;
        }

        if (!learningCompleted) {
            element.addEventListener("onwheel", (e: any) => handleScroll(e), { passive: false })
            element.addEventListener("onmousewheel", (e: any) => handleScroll(e), { passive: false })
            element.addEventListener("scroll", (e: any) => handleScroll(e), { passive: false })
            element.addEventListener("wheel", (e: any) => handleScroll(e), { passive: false })
            element.addEventListener("DOMMouseScroll", (e: any) => handleScroll(e), { passive: false })
            element.addEventListener("touchmove", (e: any) => handleScroll(e), { passive: false })
            console.log("слушаем скролл")
        } else {
            element.removeEventListener("onwheel", (e: any) => handleScroll(e))
            element.removeEventListener("onmousewheel", (e: any) => handleScroll(e))
            element.removeEventListener("scroll", (e: any) => handleScroll(e))
            element.removeEventListener("wheel", (e: any) => handleScroll(e))
            element.removeEventListener("DOMMouseScroll", (e: any) => handleScroll(e))
            element.removeEventListener("touchmove", (e: any) => handleScroll(e))
            console.log("не слушаем скролл")
        }
    }, [learningCompleted, element])
};