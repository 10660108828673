export const OPEN_ADMINISTRATIVE_FORM= 'OPEN_ADMINISTRATIVE_FORM';
export const CLOSE_ADMINISTRATIVE_FORM = 'CLOSE_ADMINISTRATIVE_FORM';

export function openAdministrativeForm() {
    return { type: OPEN_ADMINISTRATIVE_FORM };
}

export function closeAdministrativeForm() {
    return { type: CLOSE_ADMINISTRATIVE_FORM } ;
}
