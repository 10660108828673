import React from 'react';
import style from './style.module.scss';
import { ScopeEnum } from '../../../models';
import { defineScopesLogo, defineScopeName } from '../../../service/scopeService';
import cn from 'classnames';

interface IScopeIconProps {
    id?: string;
    scopeId: ScopeEnum;
    isShowTitle: boolean;
    iconContainer?: any;
    iconClassName?: any;
    titleClassName?: any;
    onClick?: () => void;
}

const ScopeIcon: React.FunctionComponent<IScopeIconProps> = (props) => {

    const { id, scopeId, iconClassName, titleClassName, iconContainer, isShowTitle, onClick } = props;

    const renderIcon = () => {
        const scopeIcon = defineScopesLogo(scopeId)
        return <div className={style.icon}>
            <img src={scopeIcon} alt='scope' />
        </div>
    }

    return <div id={id} className={cn(style.iconContainer, iconContainer)}>
            <div className={cn(iconClassName)}>
                {renderIcon()}
            </div>
            {isShowTitle ? <div className={cn(titleClassName, style.name)} onClick={onClick}>{defineScopeName(scopeId)}</div> : null}
        </div>;
}

export default ScopeIcon;