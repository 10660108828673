import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { PointsColorEnum } from '../../../models';

export enum ScoreProgressBarColor {
    blue,
    orange,
    yellow,
    green,
    red
}

interface IProps {
    color: ScoreProgressBarColor | PointsColorEnum;
    title?: string;
    value: number;
    maxValue?: number,
    displayValue?: string;
    titleStyle?: string;
    valueStyle?: string;
    containerStyle?: string;
    unit?: string;
    isReverse?: boolean;
}

const ScoreProgressBar: React.FunctionComponent<IProps> = (props) => {
    const { title, color, value, maxValue, titleStyle, valueStyle,
        containerStyle, displayValue, unit = "", isReverse = false } = props;

    const completed = () => {
        switch (color) {
            case ScoreProgressBarColor.orange: return style.orange;
            case ScoreProgressBarColor.yellow: return style.yellow;
            case ScoreProgressBarColor.green: return style.green;
            case ScoreProgressBarColor.red: return style.red;
            case PointsColorEnum.Education: return style.education;
            case PointsColorEnum.EHousing: return style.eHousing;
            case PointsColorEnum.EServices: return style.eServices;
            case PointsColorEnum.Health: return style.health;
            case PointsColorEnum.Transport: return style.transport;
            case PointsColorEnum.Security: return style.security;
            default: return style.blue;
        }
    }

    const progressColor = completed();    

    return <div className={cn(style.container, containerStyle)}>
        {title && <div className={style.titleContainer}>
            <div className={cn(titleStyle, style.title)}>{title}</div>
            <div className={cn(valueStyle, style.value)}>
                {(displayValue ? displayValue : Math.round(value)) + unit}
            </div>
        </div>
        }
        <div className={cn(style.barContainer, isReverse ? style.reverse : '')}>
            <span className={cn(style.completed, progressColor)} style={{ width: `${maxValue ? value / maxValue * 100 : value}%` }} />
        </div>
    </div>
}

export default ScoreProgressBar;