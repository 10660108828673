import { useDispatch, useSelector } from "react-redux";
import { getSystemEvent } from "../../../redux/selectors/forms/screens/openScreen";
import { NoneIcon, UpIcon, DownIcon, QuotesIcon, BubbleTailEventIcon } from "../../../icons";
import { convertMoneyToString } from "../../../service/convertorToString";
import GameForm from "../../UiKit/GameForm";
import Header from "../Header";
import ClassicButton from "../../UiKit/Buttons/ClassicButton";
import { SizeButton, ColorButton } from "../../UiKit/Buttons/common";
import { avatar } from "../../../helpers";
import style from '../AbstractEvent/style.module.scss';
import React from "react";
import { closeSystemEventForm, openUpgradeForm } from "../../../redux/actions";
import { getLearningCompleted } from "../../../redux/selectors";
import { learningProgressNext } from "../../../redux/actions/game/learningProgress";

export const SystemEvents: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);
    const { isOpen, text, income, expenses, reputationSociety, reputationBusiness, type, systemTitle, systemId, scopeId } = useSelector(getSystemEvent);

    const handleCloseClick = () => {
        dispatch(closeSystemEventForm());
    }

    const handleSystemClick = () => {
        dispatch(closeSystemEventForm());
        dispatch(openUpgradeForm(systemId, scopeId));
        !learningCompleate && dispatch(learningProgressNext());
    }

    const icon = (value: number) => {
        if (value === 0)
            return <NoneIcon />
        else if (value > 0)
            return <UpIcon />
        else
            return <DownIcon />
    }

    const linkSystem = <span data-tut={"Event"} className={style.link} onClick={handleSystemClick}>{systemTitle}</span>
    const leftBlock = <span>{text.substring(0, text.indexOf('none'))}</span>
    const rightBlock = <span>{text.substring(text.indexOf('none') + 4, text.length)}</span>
    const textBlock = systemTitle === "" ?
        <span>{text}</span> :
        <>{leftBlock}{linkSystem}{rightBlock}</>
    text.replace('none', systemTitle)
    const result = <div className={style.body}>
        <div className={style.message}>
            <QuotesIcon className={style.quotesOpen} />
            <div>
                {textBlock}
                <QuotesIcon className={style.quotesClose} />
            </div>
        </div>
        {<><div className={style.dash}></div>
            <div className={style.decision}>Result</div>
            <div className={style.resultBlocks}>
                <div className={style.block}>
                    {icon(reputationSociety)}
                    <span className={style.label}>Society:</span>
                    <span className={style.value}>{reputationSociety}</span>
                </div>
                <div className={style.block}>
                    {icon(reputationBusiness)}
                    <span className={style.label}>Business:</span>
                    <span className={style.value}>{reputationBusiness}</span>
                </div>
                <div className={style.block}>
                    {icon(income)}
                    <span className={style.label}>{type === 'Quarter' ? 'Income:' : 'Q Income'}</span>
                    <span className={style.value}>{convertMoneyToString(income)}</span>
                </div>
                <div className={style.block}>
                    {icon(-Math.abs(expenses))}
                    <span className={style.label}>{type === 'Quarter' ? 'Expenses:' : 'Q Expenses'}</span>
                    <span className={style.value}>{convertMoneyToString(expenses)}</span>
                </div>
            </div></>}

    </div>;

    return isOpen ? <GameForm close={() => handleCloseClick()}>
        <div className={style.Event}>
            <Header breadcrumbs={[]} headerTitle={type === 'Quarter' ? "End of the quarter" : systemTitle} />

            {result}

            <div className={style.footer}>
                <ClassicButton handleClick={() => handleCloseClick()} size={SizeButton.large} label={"Close"} color={ColorButton.azure} classes={'margin'} />

                {<div className={style.manager}>
                    <div className={style.label}>
                        <BubbleTailEventIcon />
                        <span className={style.name}>Sasha</span>
                        <span className={style.system}>Chief assistant</span>
                    </div>
                    <img className={style.img} src={avatar('/images/chief_assistant_Sasha.png')} alt="avatar" />
                </div>}
            </div>
        </div>
    </GameForm> : null
}
