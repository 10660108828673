import React from 'react';
import style from './style.module.scss';
import SystemProcess from './SystemProcess';
import { useSelector } from 'react-redux';
import { getSelectedSystems } from '../../../../redux/selectors';

const YourProcesses: React.FunctionComponent = () => {
    const systems = useSelector(getSelectedSystems);
    const filterSystems = systems.filter(s => s.isProgress || s.marketingRunning || s.startUpgradeDate);
    return (<div className={style.yourProcesses} data-tut={"YourProcessesBlock"}>
        {filterSystems.length === 0 ? 
            <p className={style.noactive}>No active processes</p> :
            filterSystems.map((s, i) => <SystemProcess key={i} system={s} />)}
    </div>);
}

export default YourProcesses;