import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';
import { IContracorCost } from '../../../models';
import ImplIndicator from '../ImplIndicator';
import { getContractorById } from '../../../data/helpers';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../UiKit/ScoreProgressBar';
import { convertTimeToString, convertMoneyToString } from '../../../service/convertorToString';

interface IProps {
    scopeId: number;
    contractorCost: IContracorCost;
    handleClick: () => void;
}

function nameToIcon(name: string){
    const parts = name.split(' ');
   
    if(parts.length>=2) {
        return parts[0].charAt(0) + parts[1].charAt(0);
    }

    return name.substring(0,2);
}

const ContractorCard: React.FunctionComponent<IProps> = (props) => {

    const { scopeId, contractorCost, handleClick } = props;

    const contractor = getContractorById(scopeId, contractorCost.contractorId);

    if(!contractor) return null;
        
    return <AbstractCard className={style.card} selected={false} clickAndGo={false} handleClick={handleClick} actionOnTop={true}>
            <div className={style.contractor}>
                <div className={style.header}>
                    <div className={style.avatar}>{nameToIcon(contractor.name)}</div>     
                    <div className={style.title}>{contractor.name}</div> 
                </div>
                
                <div className={style.mainScore}>
                    <ScoreProgressBar color={ScoreProgressBarColor.blue} containerStyle={style.progressBar} value={contractor.quality/10*100} displayValue={contractor.quality.toString()} title={'Quality'} />
                    <br/>
                    <ScoreProgressBar color={ScoreProgressBarColor.yellow} containerStyle={style.progressBar} value={contractor.reputation/10*100} displayValue={contractor.reputation.toString()} title={'Reputation '} />
                </div>

                <div className={style.scores}>
                    <ImplIndicator title={'Time costs'} value={convertTimeToString(contractorCost.timecost)} inRow={true}/>
                    <br/>
                    <ImplIndicator title={'Implementation costs'} value={convertMoneyToString(contractorCost.moneyMinus)} inRow={true}/>
                    <br/>
                    <ImplIndicator title={'Service costs per quater'} value={convertMoneyToString(contractorCost.moneyQminus)} inRow={true}/>
                </div>  
            </div>           
        </AbstractCard>;
}

export default ContractorCard;