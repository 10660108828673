import { Action } from "redux";
import { CURRENT_DT_EVENT, ISetCurrentDTEventAction } from "../../actions";
import { IDTEvent } from "../../../models";

interface ICurrentDTEvent {
    currentDTEvent: IDTEvent | null;
    eventDate: number
}

export const initial = {
    currentDTEvent: null,
    eventDate: 0
}

export const reducer = (action: Action): ICurrentDTEvent => {
    switch (action.type) {
        case CURRENT_DT_EVENT: {
            const event = action as ISetCurrentDTEventAction;
            return event;
        }
        default:
            return initial;
    }
}