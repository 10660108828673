import React, { useState } from 'react';
import style from './style.module.scss';
import ScoreProgressBar from '../../../../UiKit/ScoreProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedSystems } from '../../../../../redux/selectors';
import useScopes from '../../../../../hooks/useScopes';
import { CollapseIcon } from '../../../../../icons';
import cn from 'classnames';
import { calcScopeDigitalTransformationRate } from '../../../../../service/calcDigitalTransformRate';
import gamePoints from '../../../gamePoints';
import { hightlightPointInit } from '../../../../../redux/actions';
import { openScopeForm } from '../../../../../redux/actions/forms/openScope';
import { closeGameDashboard, openGameDashboard } from '../../../../../redux/actions/forms/openGameDashboard';
import { defineScopePointsColor } from '../../../../../service/scopeService';
import ScopeIcon from '../../../ScopeIcon';
import { DirectionsProgress } from '../DirectionsProgress';


interface ICollapseState {
    id: number;
    collapse: boolean;
    childs: ICollapseState[];
}


export function SpheresProgress() {

    const scopes = useScopes(); // все имеющиеся сферы
    const implSystems = useSelector(getSelectedSystems); // внедряемые в данный момент системы
    const dispatch = useDispatch();

    const handleScopeClick = (scopeId: number) => {
        dispatch(closeGameDashboard());
        const point = gamePoints.find(x => x.scopeId === scopeId);
        dispatch(hightlightPointInit(point));
        setTimeout(() => {
            dispatch(openScopeForm(scopeId));
        }, 2000);
    }


    // признак свёрнутости/раскрытия
    const [collapseState, setCollapseState] = useState<ICollapseState[]>(
        scopes.map((scope) => {
            return {
                id: scope.scopeId,
                collapse: false,
                childs: scope.directions
                    ? scope.directions?.map((direction) => {
                        return {
                            id: direction.directionId,
                            collapse: false,
                            childs: []
                        }
                    })
                    : []
            }
        })
    );

    //Меняем признак свёрнут/раскрыт
    const handlerScopeCollapse = (scopeIdx: number, directionIdx?: number) => {

        if (directionIdx === undefined)
            collapseState[scopeIdx].collapse = !collapseState[scopeIdx].collapse;
        else
            collapseState[scopeIdx].childs[directionIdx].collapse = !collapseState[scopeIdx].childs[directionIdx].collapse;

        setCollapseState([...collapseState]);
    }


    return <>
        <div className={style.subTitle}>Spheres</div>
        {scopes.map((scope, scopeIdx) => {
            const scopeCollapse = collapseState[scopeIdx].collapse;
            const scopSum = calcScopeDigitalTransformationRate(scope, implSystems) * 100;
            const progressColor = defineScopePointsColor(scope.scopeId);

            return <div key={scope.scopeId} >
                <div className={cn(style.scope, scopeCollapse ? style.rollout : '')}>
                    <CollapseIcon className={scopeCollapse ? style.rollout : ''} onClick={() => { handlerScopeCollapse(scopeIdx) }} />
                    <ScopeIcon scopeId={scope.scopeId} isShowTitle={true} titleClassName={style.scopeName} iconContainer={style.scopeIcon} iconClassName={style.scopeIcon} onClick={() => { handleScopeClick(scope.scopeId) }} />
                    {scopSum >= 0 &&
                        <ScoreProgressBar containerStyle={style.scopeProgress}
                            color={progressColor}
                            isReverse={false}
                            value={scopSum} />}
                </div>
                {scopeCollapse 
                 && scope.directions
                 && <DirectionsProgress scopeId={scope.scopeId} directions={scope.directions} closeCommonForm={() => dispatch(closeGameDashboard())} openCommonForm={() => dispatch(openGameDashboard())}/>}
            </div>
        })}
    </>


}
