import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { ADD_GAME_STATS_INIT, ADD_GAME_STATS_DONE, ADD_GAME_STATS_FAIL } from "../../actions/gameAPI/addGameStats";

export interface IState {
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ADD_GAME_STATS_INIT:
            return {
                ...state,
                inProgress: true,
                error: null
            };

        case ADD_GAME_STATS_DONE:
            return {
                ...state,
                inProgress: false,
                error: null
            };

        case ADD_GAME_STATS_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}