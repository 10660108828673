import React, { useState } from 'react';
import style from './style.module.scss';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import { SizeButton, ColorButton } from '../UiKit/Buttons/common';

interface IRangeSlider {
    title: string;    
    isShowAction?: boolean;
    maxValue: number
    minValue: number
    value: number
    startValue?: number
    isMoney?: boolean
    onSubmit?: () => void;
    onCancelClick?: () => void;
    onChange: (val: any) => void;
}

const DEFAULT_CLASS_NAMES = {
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
};

const RangeSlider: React.FunctionComponent<IRangeSlider> = (props) => {
    const { title, isShowAction, onCancelClick, onSubmit, onChange, isMoney, startValue, maxValue, minValue, value } = props;
    const [isCancel, setCancel] = useState<boolean>(false);



    const handleChange = (value: any) => {
        setCancel(true);
        onChange(value);
    }

    const handleSubmitBugetChanges = () => {
        onSubmit && onSubmit();
    }

    const handleCancelBugetChanges = () => {
        setCancel(false)
        if (onCancelClick) {
            onCancelClick();
        }
    }

    const formatValue = () => {
        if(value < minValue) return minValue;
        if(value > maxValue) return maxValue;

        return value;
    }

    const renderRange = () => {
        return <div className={style.range}>
            <div className={style.choiseValue}>
                <span className={style.title}>{title}</span>
                <span className={style.value}>{formatValue()} {isMoney ? "K" : ""}</span>
            </div>
            <div className={style.active}>
                <InputRange
                    maxValue={maxValue}
                    minValue={minValue}
                    value={formatValue()}
                    onChange={val => handleChange(val)}
                    classNames={{
                        ...DEFAULT_CLASS_NAMES,
                        activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack}, ${style.activeTrack}`,
                        slider: `${DEFAULT_CLASS_NAMES.slider}, ${style.slider}`,
                        valueLabel: `${style.rangeValue}`,
                        minLabel: `${style.rangeValue}`,
                        maxLabel: `${style.rangeValue}`,
                    }} />
            </div>
            {isShowAction && <div className={style.actions}>
                {isShowAction && value !== startValue && isCancel && <ClassicButton label={'SUBMIT CHANGES'} size={SizeButton.normal} handleClick={handleSubmitBugetChanges} classes={style.accept} />}
                {isShowAction && value !== startValue && isCancel && <ClassicButton label={'CANCEL'} size={SizeButton.normal} handleClick={handleCancelBugetChanges} classes={style.cancel} color={ColorButton.azure} />}
            </div>}
        </div>
    }
    
    return renderRange();
}

export default RangeSlider;
