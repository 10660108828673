/**
* Денежные средсва, квартальные приходы и расходы
*/
export interface IMoney {
    budget: number,
    moneyQplus: number,
    moneyQminus: number
}

export const MARKETING_BUDGET_MIN: number = 50;
export const MARKETING_BUDGET_MAX: number = 250;