import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { Buget } from './Budget';
import Separator from './Separator';
import Society from './Society';
import Business from './Business';
import DigitalTransformaionRate from './Digital Transformaion Rate';
import { Detail } from './Detail';
import SwipeUp from './SwipeUp';
import { useSelector, useDispatch } from 'react-redux';
import { getSocietyReputaiton, getBusinessReputaiton, getGameStage, getSelectedSystems, getMoney } from '../../../redux/selectors';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { openGoalsForm } from '../../../redux/actions';
import { getIsOpenGoals } from '../../../redux/selectors/forms/screens/openScreen';
import { SizeButton } from '../../UiKit/Buttons/common';
import { GameStageEnum } from '../../../models';
import { openGameDashboard } from '../../../redux/actions/forms/openGameDashboard';
import useScopes from '../../../hooks/useScopes';
import { calcDigitalTransformationRate } from '../../../service/calcDigitalTransformRate';

export function Statistic() {
  const dispatch = useDispatch();

  const scopes = useScopes();
  const selectedSystems = useSelector(getSelectedSystems);
  const gameStage = useSelector(getGameStage);
  const isOpenGoals = useSelector(getIsOpenGoals);
  const societyReputaiton = useSelector(getSocietyReputaiton);
  const businessReputaiton = useSelector(getBusinessReputaiton);
  const money = useSelector(getMoney);
  const setGoalsToStartBtn = <ClassicButton label={'Set goals to start'} size={SizeButton.large} disabled={isOpenGoals} handleClick={() => dispatch(openGoalsForm())} classes={style.goalsButton} />;
  const success = selectedSystems.length > 0 ? calcDigitalTransformationRate(scopes, selectedSystems) * 100 : 0;
  const buget = money && money.budget ? Math.round(money.budget) : 0;

  return (
    <div id={'statisticContainer'} className={style.statisticContainer}>
      <SwipeUp className={style.swipeUp} onClick={() => { dispatch(openGameDashboard()) }} />
      <DigitalTransformaionRate data={"DigitalMobile"} className={style.rate} rate={success} withSpider={false} isButtonDescription={true} showProgress={true} />
      <div className={style.statistic}>
        <div className={style.leftPanel} data-tut={"Statistic"}>
          <Society societyReputaiton={societyReputaiton} showProgress={true} className={style.leftPanelCard} />
          <Separator />
          <Business businessReputaiton={businessReputaiton} showProgress={true} className={style.leftPanelCard} />
          <Separator />
          <Buget budget={buget} className={style.leftPanelCard} />
          <Separator className={style.cardSeparator} />
        </div>
        <div className={style.rightPanel}>
          <Separator />
          {gameStage === GameStageEnum.STAGE_4_GAME ? <DigitalTransformaionRate data={"Digital"} className={style.card} rate={success} showProgress={true} withSpider={false} isButtonDescription={true} /> : setGoalsToStartBtn}

          <Separator />
          <Detail className={cn(style.detail, style.showOnDesktop)} />
        </div>
        {gameStage !== GameStageEnum.STAGE_4_GAME && <div className={style.showOnMobile}>
          {setGoalsToStartBtn}
        </div>}
      </div>
    </div>
  );
}

export default Statistic;