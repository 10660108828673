import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import people from '../../../../image/people.png';
import ProgressBar from '../../../Goals/ProgressBar';

interface IProps {
    className?: any;
    societyReputaiton?: number;
    onRight?: boolean;
    showProgress?: boolean
}

export function Society(props: IProps) {
    const { onRight = false, societyReputaiton, className, showProgress = false } = props;
    const society = societyReputaiton ? Math.round(societyReputaiton) : 0;

    const renderIcon = () => {
        return <div className={cn(style.icon, onRight ? style.iconRight : "")}>
            <img alt={people} src={people} />
        </div>
    }

    const renderProgress = () => {
        return showProgress && <ProgressBar completedValue={society} background={style.progressBarBg} />
    }

    const renderCard = () => {
        return <div className={cn(style.card, className)}>
            {!onRight && renderIcon()}
            <div className={style.description}>
                <span className={style.title}>Society</span>
                <span className={style.value}>{`${society} / 100`}</span>
            </div>
            {onRight && renderIcon()}
        </div>
    }

    return (
        <div className={cn(style.society, props.className)}>
            {renderProgress()}
            {renderCard()}
        </div>
    );
}

export default Society;