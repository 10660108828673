import { Action } from "redux";

export const ASSISTANT_NOTIFY_ADD = 'ASSISTANT_NOTIFY_INIT';
export const ASSISTANT_NOTIFY_REMOVE = 'ASSISTANT_NOTIFY_REMOVE';

export interface IAssistantNotify{
    notifyId: number,
    message: string | React.ReactNode
}

export interface IAssistantNotifyAddAction extends Action {
    assistantNotifyAdd: IAssistantNotify;
}

export interface IAssistantNotifyRemoveAction extends Action {
    notifyId: number;
}

export function assistantNotifyAdd(assistantNotifyAdd: IAssistantNotify) {
    return { type: ASSISTANT_NOTIFY_ADD, assistantNotifyAdd } as IAssistantNotifyAddAction;
}

export function assistantNotifyRemove(notifyId: number) {
    return { type: ASSISTANT_NOTIFY_REMOVE, notifyId } as IAssistantNotifyRemoveAction;
}