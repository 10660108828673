import React from 'react';
import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { openDirectionForm } from '../../../../redux/actions/forms/openDirection';
import { IGamePoint } from '../../../../models';
import cn from 'classnames';
import ScoreProgressBar from '../../../UiKit/ScoreProgressBar';
import { defineScopePointsColor } from '../../../../service/scopeService';
import RoundProgressBar, { ISystemProgress } from '../../../RoundProgressBar';
import { learningProgressNext } from '../../../../redux/actions/game/learningProgress';
import { getLearningCompleted } from '../../../../redux/selectors';

interface IGamePointProps {
    id?: string;
    point: IGamePoint;
    isHighlight?: boolean;
    isPulse: boolean;
    className?: any;
    directionName?: string;
    dtrDirection?: number;
    fromMap?: boolean;
    systemsProgress?: ISystemProgress[];
}

const GameDirectionsPoint: React.FunctionComponent<IGamePointProps> = (props) => {

    const { id, point, isHighlight, className, directionName, dtrDirection, isPulse, fromMap, systemsProgress } = props;
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);

    const handleClick = () => {
        if (point.directionId) {
            point.directionId === 51 && !learningCompleate && dispatch(learningProgressNext());
            fromMap && dispatch(openDirectionForm(point.scopeId, point.directionId));
        }
    }

    const renderPoint = () => {
        return <div className={cn(style.containerDirection, className)} onClick={handleClick}>
            {isPulse && <div className={cn(style.circle2, isHighlight ? style.circle2Highlight : null)} />}
            {isPulse && <div className={cn(style.circle1, isHighlight ? style.circle1Highlight : null)} />}
            {isPulse && <div className={cn(style.circle3, isHighlight ? style.circle3Highlight : null)} />}
            <div className={cn(style.center, isPulse ? null : style.stopPulse)} style={{ backgroundColor: point.color }} />
            {systemsProgress && <RoundProgressBar systemProgress={systemsProgress} />}
        </div>
    }

    return <div id={id} data-tut={point.directionId === 51 ? "SelectDirection" : ""} className={cn(style.bubble, className)} style={point.coord && { top: point.coord.y, left: point.coord.x }} onClick={handleClick}>
        {renderPoint()}
        <div className={style.description} >
            <span className={style.title}>{directionName}</span>
            <ScoreProgressBar color={defineScopePointsColor(point.scopeId)} value={dtrDirection ?? 0} title={""} maxValue={100} containerStyle={style.progressBar} />
        </div>
    </div>
}

export default GameDirectionsPoint;