import React, { useState, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import style from './style.module.scss';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import Header from '../Header';
import { SizeButton } from '../../UiKit/Buttons/common';
import SystemTypeStep from './1_SystemTypeStep';
import { IDefaultSystem, ISystemType, IContractRevenueMax, IContracorCost } from '../../../models';
import ContractModelStep from './2_ContractStep';
import ContractorStep from './3_ContractorStep';
import BackButton from '../../UiKit/Buttons/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { learningProgressNext } from '../../../redux/actions/game/learningProgress';
import { getLearningCompleted} from '../../../redux/selectors';

enum SystemAndContractorsStepEnum {
    Undef,
    SystemTypeStep,
    ContractModelStep,
    Contractor
}

interface IProps {
    breadcrumbs: string[];
    system: IDefaultSystem;
    businessReputation: number;
    finish: (systemType: ISystemType, contract: IContractRevenueMax, contractor: IContracorCost) => void;
    backClick: () => void;
}

interface ISelectedTypeAndContract {
    systemType: ISystemType,
    contract: IContractRevenueMax
}

const SystemAndContractors: React.FunctionComponent<IProps> = (props) => {
    const { breadcrumbs, system, businessReputation, finish, backClick } = props;
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);

    const results = useRef<ISelectedTypeAndContract>({ systemType: {}, contract: {} } as ISelectedTypeAndContract);

    const [currentStep, setStep] = useState<SystemAndContractorsStepEnum>(SystemAndContractorsStepEnum.Undef);

    const handleSelectedSystemType = (systemType: ISystemType) => {
        results.current.systemType = systemType;
        setStep(SystemAndContractorsStepEnum.ContractModelStep);
        !learningCompleate && dispatch(learningProgressNext());
    }

    const handleSelectedContractModel = (contract: IContractRevenueMax) => {
        results.current.contract = contract;
        setStep(SystemAndContractorsStepEnum.Contractor);
        !learningCompleate && dispatch(learningProgressNext());
    }

    const handleSelectedContractor = (contractor: IContracorCost) => {
        finish(results.current.systemType as ISystemType, results.current.contract as IContractRevenueMax, contractor);
    }

    const startStep = <div className={style.form}>
        <p className={style.textInfo}>This is the most important step in all preparations for the system implementation process.</p>
        <p className={style.textInfo}>You have to go through 3 stages: at the end of each, you should decide which type of system, including unique contractor and conditions, to implement.</p>
        <br />
        <div className={style.action}>
            <BackButton label={'Back'} handleClick={backClick}/>
            <ClassicButton dataTut={"Start"} size={SizeButton.large} label={'Start'} handleClick={() => {setStep(SystemAndContractorsStepEnum.SystemTypeStep); !learningCompleate && dispatch(learningProgressNext()); }} />
        </div>
    </div>;

    const backAction = () => {
        if (currentStep === SystemAndContractorsStepEnum.Contractor) setStep(SystemAndContractorsStepEnum.ContractModelStep);
        if (currentStep === SystemAndContractorsStepEnum.ContractModelStep) setStep(SystemAndContractorsStepEnum.SystemTypeStep);
        if (currentStep === SystemAndContractorsStepEnum.SystemTypeStep) setStep(SystemAndContractorsStepEnum.Undef);
    }

    return <Scrollbars style={{ height: '100%' }}>
        <Header breadcrumbs={breadcrumbs} headerTitle={'System & Contractors'} />

        {currentStep === SystemAndContractorsStepEnum.Undef && startStep}
        {currentStep === SystemAndContractorsStepEnum.SystemTypeStep && <SystemTypeStep types={system.types} handleSelectedType={handleSelectedSystemType} />}
        {currentStep === SystemAndContractorsStepEnum.ContractModelStep && <ContractModelStep contracts={system.contracts} businessReputation={businessReputation} handleSelectedModel={handleSelectedContractModel} />}
        {currentStep === SystemAndContractorsStepEnum.Contractor && <ContractorStep scopeId={system.scopeId} contractors={results.current.contract.contractors} handleSelectedContract={handleSelectedContractor} />}

        {currentStep !== SystemAndContractorsStepEnum.Undef &&
            <div className={style.footer}>
                <div className={style.footerContent}>
                    <BackButton label={'Back'} handleClick={backAction} />
                </div>
            </div>}
    </Scrollbars>;
}

export default SystemAndContractors;