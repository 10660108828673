import React from 'react';
import { IScope } from '../../../../models';
import style from './style.module.scss';
import DigitalTargetItem from './DigitalTargetItem';
import BubbleWithTail from '../../../UiKit/Bubbles/BubbleWithTail';


interface IProps {
    commonTargetId: number;
    digitalTargetId: number;
    scope?: IScope;
    setDigitalTargetId: (targetId: number) => void;
    disabledDigitalTargetId: (targetId: number) => boolean;
}

const DigitalTargetSelection: React.FunctionComponent<IProps> = (props) => {

    const { scope, setDigitalTargetId, disabledDigitalTargetId, commonTargetId, digitalTargetId } = props;

    if (!scope || !scope.directions) return null;

    const direction = scope.directions.find(d => d.commonTargets.findIndex(ct => ct.commonTargetId === commonTargetId) >= 0);

    if (!direction) return null;

    const digitalTargets = direction.commonTargets.find(ct => ct.commonTargetId === commonTargetId)?.digitalTargets?.map((t, i) => <DigitalTargetItem dataTut={t.digitalTargetId === 1 ? "DigitalTarget" : ""} key={i} target={t} setTargetId={setDigitalTargetId} checked={digitalTargetId === t.digitalTargetId} disabled={disabledDigitalTargetId} />)

    return <>
        <BubbleWithTail title={`${scope.title} / ${direction.title}:`} subTitle={direction.commonTargets.find(ct => ct.commonTargetId === commonTargetId)?.title} classes={style.header} />

            <div className={style.targets}>
                <div className={style.title}>{digitalTargets?.length === 1 ? 'Set the KPI:' : 'Choose the KPI:'}</div>
                {digitalTargets}
            </div>
       
    </>
}

export default DigitalTargetSelection;