export const TIMER_START = 'TIMER_START';
export const TIMER_PAUSE = 'TIMER_PAUSE';
export const TIMER_UPDATE = 'TIMER_UPDATE';
export const TIMER_NEXT_QUATER_UPDATE = 'TIMER_NEXT_QUATER_UPDATE';

export function timerStart() {
    return { type: TIMER_START };
}

export function timerPause() {
    return { type: TIMER_PAUSE };
}

export function timerUpdate() {
    return { type: TIMER_UPDATE };
}

export function timerNextQauterUpdate() {
    return { type: TIMER_NEXT_QUATER_UPDATE };
}