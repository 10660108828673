import { Action } from "redux";
import { OPEN_USER_ANALYSIS_FORM, CLOSE_USER_ANALYSIS_FORM } from "../../actions";

export interface IState {
    isOpen: boolean;
}

export const initial: IState = {
    isOpen: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_USER_ANALYSIS_FORM:
            return {
                ...state,
                isOpen: true
            };

        case CLOSE_USER_ANALYSIS_FORM:
            return {
                ...state,
                isOpen: false,
            };

        default:
            return state;
    }
}