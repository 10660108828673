import { Action } from "redux";
import { OPEN_RESULT_FORM, CLOSE_RESULT_FORM, GAME_INIT } from "../../actions";

export interface IState {
    isOpen: boolean;
}

export const initial: IState = {
    isOpen: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_RESULT_FORM:
            return {
                ...state,
                isOpen: true
            };

        case GAME_INIT:
        case CLOSE_RESULT_FORM:
            return {
                ...state,
                isOpen: false,
            };

        default:
            return state;
    }
}