import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimer, getMoney, getSelectedSystems, getCurrentTeamMembers, getFinMetrics, getCityInfo, getLearningCompleted } from "../redux/selectors/game";
import { moneyChange, timerNextQauterUpdate, openSystemEventForm, timerPause } from "../redux/actions";
import { checkQuarter } from "../service/checkQuarter";
import { useQuaterlyMoney } from "./useQuaterlyMoney";
import data from "../data";
import useScopes from "./useScopes";
import { calcReputation } from "../components/Game/GameScoresCalc/scores";

export function useQuarterTimer() {
  const dispatch = useDispatch();
  const team = useSelector(getCurrentTeamMembers);
  const money = useSelector(getMoney)
  const timer = useSelector(getTimer);
  const systems = useSelector(getSelectedSystems);
  const finMetrics = useSelector(getFinMetrics);
  const cityInfo = useSelector(getCityInfo);
  const scopes = useScopes();
  const learningCompleted = useSelector(getLearningCompleted);

  const { totalQminus, totalQplus } = useQuaterlyMoney();

  useEffect(() => {
    const isQuarter = checkQuarter(timer)

    if (isQuarter) {

      dispatch(timerNextQauterUpdate());

      // Маркетинговые расходы
      let marketingCost = 0;
      systems.forEach(s => {
        if (s.marketingRunning) {
          if (s.agency) {
            marketingCost = marketingCost + s.agency.moneyQMinus;
          }
          else if (team.findIndex(t => t.id === 7) >= 0) {
            const scopeId = data.systems.find(r => r.id === s.systemId)?.scopeId;
            const scopeAgency = data.scopeAgencies.find(x => x.scopeId === scopeId);
            const moneyQMinus = scopeAgency?.agencies.sort((a, b) => b.wageMarketing - a.wageMarketing)[0].wageMarketing ?? 0
            marketingCost = marketingCost + moneyQMinus;
          }

          if (s.moneyMarketingBudget)
            marketingCost = marketingCost + s.moneyMarketingBudget;
        }
      });

      // console.log(`Quaterly money changes: date ${moment(timer.curDate).format("DD.MM.YYYY").toLocaleString()} total: ${money.budget}, minus: ${totalQminus}, plus: ${totalQplus}. Marketing cost: ${marketingCost}`);
      money.budget = money.budget - totalQminus - marketingCost + totalQplus;

      if (!cityInfo) return;
      const { changePeople, changeBusiness } = calcReputation(cityInfo, systems, finMetrics, scopes, dispatch);

      if (learningCompleted) {
        dispatch(timerPause());
        dispatch(openSystemEventForm(`Sir, the current quarter of your work has come to an end.`, Math.round(totalQplus), totalQminus, changePeople, changeBusiness, 'Quarter', '', 0, 0));
      }
      dispatch(moneyChange(money));
    }

  }, [timer, team, systems, cityInfo, finMetrics, scopes, money, totalQminus, totalQplus, learningCompleted, dispatch]);
}