import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../UiKit/ScoreProgressBar';
import { IDefaultSystem } from '../../../models';
import ImplIndicator from '../ImplIndicator';
import { convertTimeToString } from '../../../service/convertorToString';

interface IProps {
    system: IDefaultSystem,
    state: "Default" | "Progress",
    valueProgress: number,
    timeLeft: number,
    handleClick: () => void;
    dataTut?: any;
}

const SystemCard: React.FunctionComponent<IProps> = (props) => {

    const { system, state, valueProgress, handleClick, timeLeft, dataTut } = props;

    return <AbstractCard className={style.card} selected={false} hiddenAction={state === 'Progress'} clickAndGo={false} handleClick={handleClick} dataTut={dataTut}>
        <div className={style.system}>
            {state === "Progress" ? <ScoreProgressBar color={ScoreProgressBarColor.yellow} titleStyle={style.title} containerStyle={style.progressBar} title={system.title} value={valueProgress} unit='%' />
                : <div className={style.title}>{system.title}</div>}
            <div className={style.subTitle}>{system?.description}</div>
            {state === "Progress" && timeLeft > 0 ? <div className={style.timeLeft}>
                <ImplIndicator title={'Time left'} value={convertTimeToString(timeLeft)} inRow={true} />
            </div> : null}
        </div>
    </AbstractCard>
}

export default SystemCard;