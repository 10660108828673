import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

interface IProps {
    title: string;
    subTitle: string;
    bold?: boolean;
}

const GoalInfo: React.FunctionComponent<IProps> = (props) => {

    const { title, subTitle, bold = false } = props;

    return <div className={style.goalInfo}>
            <div className={style.title}>{title}</div>
            <div className={cn(style.subTitle, bold ? style.subTitleBold : '')}>{subTitle}</div>
        </div>
}

export default GoalInfo;