import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

interface IProgressBarProps {
    completedValue: number;
    background?: string;
    backgroundCompleted?: string;
}

const ProgressBar: React.FunctionComponent<IProgressBarProps> = (props) => {

    const { completedValue, backgroundCompleted, background } = props;

    return <div className={cn(style.bar, background)}>
            <span className={cn(style.completed, backgroundCompleted)} style={{width: `${completedValue}%`}}/>
    </div>
}

export default ProgressBar;