import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';

interface IProps {
    className?: any
}

export function Separator(props: IProps) {
    return (
        <div className={cn(style.separator, props.className)}>
        </div>
    );
}

export default Separator;