import { initial as initialState, IState } from "../redux/reducers";
import { IStore } from "./configureStore";
import { IUserDto } from "../api/user";

const APP_STATE = 'CTS';
export interface IPersistedState {
    auth: {
        accessToken: string | null;
        authenticationScheme: string | null;
        isAuthorized: boolean;
    }
    currentUser: IUserDto | null
}

function select(state: any) {
    const storedState: IPersistedState = {
        auth: {
            accessToken: state.auth.accessToken,
            authenticationScheme: state.auth.authenticationScheme,
            isAuthorized: !!state.auth.accessToken
        },
        currentUser: state.currentUser.user
    };
    return storedState
}

let currentValue: IPersistedState;

export const saveState = (store: IStore) => {
    let previousValue = currentValue
    currentValue = select(store.getState())

    if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        localStorage.setItem(APP_STATE, JSON.stringify(currentValue));
    }
};

export const loadState = (): IState => {
    const stateJson = localStorage.getItem(APP_STATE);
    if (!stateJson) {
        return initialState;
    }

    try {
        const state = JSON.parse(stateJson) as IPersistedState;
        return {
            game: initialState.game,
            auth: {
                accessToken: state.auth.accessToken,
                authenticationScheme: state.auth.authenticationScheme,
                error: null,
                inProgress: false,
                isAuthorized: !!state.auth.accessToken
            },
            addGameStats: initialState.addGameStats,
            openLogin: initialState.openLogin,
            createGame: initialState.createGame,
            scoreGame: initialState.scoreGame,
            saveGame: initialState.saveGame,
            getGame: initialState.getGame,
            getGames: initialState.getGames,
            openLearning: initialState.openLearning,
            openCityInfo: initialState.openCityInfo,
            openGoals: initialState.openGoals,
            openDirection: initialState.openDirection,
            currentUser: initialState.currentUser,
            openLocalCityInfo: initialState.openLocalCityInfo,
            openScope: initialState.openScope,
            openSystemsScopes: initialState.openSystemsScopes,
            openAdministrative: initialState.openAdministrative,
            openInfrastructure: initialState.openInfrastructure,
            openEmployeeTraining: initialState.openEmployeeTraining,
            openTestMode: initialState.openTestMode,
            openUserAnalysis: initialState.openUserAnalysis,
            openTeam: initialState.openTeam,
            openSystemImpl: initialState.openSystemImpl,
            openKpi: initialState.openKpi,
            openResult: initialState.openResult,
            openHeads: initialState.openHeads,
            openGameDashboard: initialState.openGameDashboard,
            openUpgrade: initialState.openUpgrade,
            assistantNotify: initialState.assistantNotify,
            hightlightPoint: initialState.hightlightPoint,
            openSystemEvent: initialState.openSystemEvent
        };
    } catch {
        return initialState;
    }
}
