import { Action } from "redux";
import { TIMER_START, TIMER_PAUSE, TIMER_UPDATE, TIMER_NEXT_QUATER_UPDATE } from "../../actions/game";
import { ITimer } from "../../../models";
import moment from "moment";
import { QUATER_DAYS } from "../../../service/checkQuarter";

export const initial: ITimer = {
    launch: false,
    startDate: Date.now(),
    nextQuaterDate: moment(Date.now()).add(QUATER_DAYS, 'days').valueOf(),
    curDate: Date.now()
}

export const reducer = (state: ITimer = initial, action: Action): ITimer => {
    switch (action.type) {
        case TIMER_START:
            return {
                ...state,
                startDate: Date.now(),
                launch: true
            };

        case TIMER_UPDATE:
            if (state.launch)
                return {
                    ...state,
                    curDate: moment(state.curDate).add(1, 'days').valueOf()
                };

            return state;
        
        case TIMER_NEXT_QUATER_UPDATE:
            return {
                ...state,
                nextQuaterDate: moment(state.curDate).add(QUATER_DAYS, 'days').valueOf()
            };


        case TIMER_PAUSE:
            return {
                ...state,
                launch: false
            };

        default:
            return state;
    }
}