import React from 'react';
import style from './style.module.scss';
import LinkButton from '../../UiKit/Buttons/LinkButton';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { ColorButton, SizeButton } from '../../UiKit/Buttons/common';

interface IProps {
    startSettingGoals: () => void;
    openCityInfoForm: () => void;
}

const WelcomeForm: React.FunctionComponent<IProps> = (props) => {
    
    const { startSettingGoals, openCityInfoForm } = props;

    return <div className={style.form}>
            <div className={style.title}>To get started, you need <br/> to set global goals & KPIs.</div>

            <p className={style.text}>You need to set global goals to get started. You may select from 2 to 5 goals for your digital transformation program. Consider the data about your city while choosing. Remember, that the top priority of your program is fulfilling stated goals.</p>

            <p className={style.text}>Once you have accepted goals, you are not allowed to change them</p>
            
            <LinkButton handleClick={()=>openCityInfoForm()} label={'Take another look at the city situation'} />

            <ClassicButton size={SizeButton.large} color={ColorButton.default} label={'SET GOALS & KPIs'} handleClick={startSettingGoals} classes={style.setGoalButton} />
    </div>
}

export default WelcomeForm;