import React, { useState, useEffect, useRef } from 'react';
import style from './style.module.scss';
import GameForm from '../../UiKit/GameForm';
import { useSelector, useDispatch } from 'react-redux';
import { getSystemImplForm } from '../../../redux/selectors/forms/screens/openScreen';
import { closeSystemImplForm, systemChange, openDirectionForm, hightlightPointDone } from '../../../redux/actions';
import data from '../../../data';
import useScopes from '../../../hooks/useScopes';
import Header from '../Header';
import NextStepCard from '../NextStepCard';
import ImplIndicator from '../ImplIndicator';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import SystemAndContractors from '../SystemAndContractors';
import { SizeButton } from '../../UiKit/Buttons/common';
import { getBusinessReputaiton, getSelectedSystemById, getCurrentTeamMembers, getLearningCompleted, getGame, getLearningProgress } from '../../../redux/selectors';
import { ISystemType, IContractRevenueMax, IContracorCost, IEmployeeTraining } from '../../../models';
import UserAnalysis from '../UserAnalysis';
import { AdministrativeProcedures, InfrastructureСhanges, EmployeeTraining, TestProcedures } from '../Procedures';
import { IExpert } from '../../../models/option';
import { IState } from '../../../redux/reducers';
import { ITestMode } from '../../../models/testMode';
import { useTestProcedures } from '../Procedures/useTestProcedures';
import { convertMoneyToString, convertTimeToString } from '../../../service/convertorToString';
import { useAnalysisProcedures } from '../UserAnalysis/useAnalysisProcedures';
import { useEmployeeTrainingProcedure } from '../Procedures/useEmployeeTraining';
import BackButton from '../../UiKit/Buttons/BackButton';
import { learningProgressNext, learningProgressPause, learningProgressStart } from '../../../redux/actions/game/learningProgress';
import { useClientRect } from '../../../hooks/useClientRect';
import { useScrollComponent } from '../../../hooks/useScrollComponent';

enum ImplStepEnum {
    Undef,
    SystemAndContractors,
    AdministrativeProcedures,
    InfrastructureChanges,
    EmployeeTraining,
    Tests,
    UserAnalysis
}

const SystemImpl: React.FunctionComponent = () => {

    const dispatch = useDispatch();
    const businessReputation = useSelector(getBusinessReputaiton);
    const learningCompleate = useSelector(getLearningCompleted);
    const learningProgress = useSelector(getLearningProgress);
    const { isOpen, systemId, scopeId, onClose } = useSelector(getSystemImplForm);
    const [implStep, setImplStep] = useState<ImplStepEnum>(ImplStepEnum.Undef);

    // System 
    const system = useSelector(s => getSelectedSystemById(s as IState, systemId))
    const testProcState = useTestProcedures();
    const userAnalysisProcState = useAnalysisProcedures();
    const employeeTrainingProcState = useEmployeeTrainingProcedure();

    const [implementationCost, setImplementationCost] = useState<number>(0);
    const [quaterlyCost, setQuaterlyCost] = useState<number>(0);
    const [timeCost, setTimeCost] = useState<number>(0);
    const [usability, setUsability] = useState<number>(0);
    const userAnalysisFinished = useRef<boolean>(false);
    const game = useSelector(getGame);
    const [rect, ref] = useClientRect();
    const [scroll, setScroll] = useState<number>(0);
    const learningCompleted = useSelector(getLearningCompleted);
    const [scrollComponent, setScrollComponent] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            const scrollComponent = document.getElementById("scroll");
            setScrollComponent(scrollComponent);
        }
    }, [isOpen])
    useScrollComponent(learningCompleted, scrollComponent);

    useEffect(() => {
        if (game.systems.length === 0) {
            userAnalysisFinished.current = false;
        }
    }, [game]);

    useEffect(() => {
        if (!learningCompleate && rect && rect.scrollHeight !== 0 && (system?.infraExpert || learningProgress.step >= 28)) {            
            const currentScroll = scroll + 250;
            setScroll(currentScroll)
            rect.scrollTop = currentScroll;
        }

    }, [implStep, rect, system?.infraExpert, dispatch, learningProgress]);

    // Пропуск опциаональных шагов
    useEffect(() => {
        if(!learningCompleate && !learningProgress.inProgress 
            && (learningProgress.step === 25 || learningProgress.step === 26 || learningProgress.step === 27 || learningProgress.step === 28 || learningProgress.step === 29) 
            && implStep === ImplStepEnum.Undef) {                
                dispatch(learningProgressStart());
                dispatch(learningProgressNext());            
        }
        else if(!learningCompleate && learningProgress.inProgress && implStep === ImplStepEnum.UserAnalysis) {
            dispatch(learningProgressPause());
        }
        else if(!learningCompleate && learningProgress.inProgress && implStep === ImplStepEnum.AdministrativeProcedures) {
            dispatch(learningProgressPause());
        }
        else if(!learningCompleate && learningProgress.inProgress && implStep === ImplStepEnum.InfrastructureChanges) {
            dispatch(learningProgressPause());
        }
        else if(!learningCompleate && learningProgress.inProgress && implStep === ImplStepEnum.EmployeeTraining) {
            dispatch(learningProgressPause());
        }
        else if(!learningCompleate && learningProgress.inProgress && implStep === ImplStepEnum.Tests) {
            dispatch(learningProgressPause());
        }
    }, [implStep, dispatch, learningCompleate, learningProgress, rect])

    // Логика изменения расчетных показателей: затрат времени и денег на внедрение
    useEffect(() => {
        let sumImplCost = 0;
        let sumQuaterCost = 0;
        let sumTimeCost = 0;
        let sumUsability = 0;
        if (system && system.contractor && system.systemType) {
            sumImplCost += system.contractor.moneyMinus;
            sumQuaterCost += system.contractor.moneyQminus;
            sumTimeCost += system.contractor.timecost;

            const quality = data.scopesContractor.flatMap(x => x.contractors).find(x => x.id === system.contractor?.contractorId)?.quality;
            sumUsability += system.systemType.scores.usability01 * (quality ? quality : 0) * 0.1;

            // console.log(`System #${system.systemId}. Step #01. Contractor: ${system.contractor?.contractorId}. Quality: ${system.contractor?.contractorId}. Usability01: ${system.systemType.scores.usability01}. Sum usability: ${sumUsability}`);
        }

        if (system && system.adminExpert) {
            sumImplCost += system.adminExpert.expert.moneyMinus;
            sumTimeCost += system.adminExpert.expert.timeCost;
        }

        if (system && system.infraExpert) {
            sumImplCost += system.infraExpert.expert.moneyMinus;
            sumTimeCost += system.infraExpert.expert.timeCost;
        }

        if (system && system.employeeTraining) {
            sumImplCost += system.employeeTraining.scores.moneyMinus;
            sumTimeCost += system.employeeTraining.scores.timeCost;
            sumUsability += system.employeeTraining.scores.usability04;

            // console.log(`System #${system.systemId}. Step #04. Usability04: ${system.employeeTraining.scores.usability04}. Sum usability: ${sumUsability}`);
        }
        else if (system && employeeTrainingProcState === 'Advanced') {
            sumUsability += 10; // Interactive course
            // console.log(`System #${system.systemId}. Step #04. Usability04: 10. Sum usability: ${sumUsability}`);
        }

        if (system && system.tests) {
            system.tests.forEach(t => {
                sumImplCost += t.moneyMinus;
                sumTimeCost += t.timeCost;
            })

            const usabilityMax = data.systems.find(x => systemId === x.id)?.usabilityMax;
            const testQuality = system.tests.length;
            sumUsability += (usabilityMax ? usabilityMax : 0) * (0.333 * testQuality);
            // console.log(`System #${system.systemId}. Step #05. Test quality: ${testQuality}. UsabilityMax: ${usabilityMax}. Sum usability: ${sumUsability}`);
        }
        else if (testProcState === 'Advanced') {
            const usabilityMax = data.systems.find(x => systemId === x.id)?.usabilityMax;
            const testQuality = 3;
            sumUsability += (usabilityMax ? usabilityMax : 0) * (0.333 * testQuality);
            // console.log(`System #${system?.systemId}. Step #05. Test quality: ${testQuality}. UsabilityMax: ${usabilityMax}. Sum usability: ${sumUsability}`);
        }

        if (userAnalysisFinished.current || userAnalysisProcState === 'Advanced') {
            sumUsability += 7;
            // console.log(`System #${system?.systemId}. Step #06. UsabilityXnYnZn06: 7. Sum usability: ${sumUsability}`);
        }


        setImplementationCost(sumImplCost);
        setQuaterlyCost(sumQuaterCost);
        setTimeCost(sumTimeCost);
        setUsability(sumUsability);

    }, [system]);

    const scopes = useScopes();
    const defaultSystem = data.systems.find(x => systemId === x.id);

    const scope = scopes.find(sc => sc.scopeId === scopeId);
    const direction = scope?.directions?.find(d => defaultSystem?.directionId === d.directionId);
    const team = useSelector(getCurrentTeamMembers);

    if (!scope || !direction || !defaultSystem || !system) return null;

    const handleClose = () => {
        if(learningCompleate) {
            dispatch(closeSystemImplForm());
            setScroll(0);
        }        
    };

    const { systemType, contractModel, adminExpert, infraExpert, employeeTraining, tests } = system;

    const breadcrumbs = [scope?.title, direction?.title];

    const handleStartImplementation = () => {
        dispatch(systemChange({ ...system, usability: usability, moneyQminus: quaterlyCost, moneyminus: implementationCost, isProgress: true }));
        dispatch(closeSystemImplForm());
        !learningCompleate && dispatch(learningProgressNext());
    }

    const backActionHandler = () => {
        dispatch(closeSystemImplForm());
        if (onClose) {
            dispatch(hightlightPointDone());
            onClose && onClose();
        } else {
            dispatch(openDirectionForm(scope.scopeId, direction.directionId));
        }
    }

    let containerStyle = { height: '100%', marginRight: "0px" } as React.CSSProperties;
    if(!learningCompleate) {
        containerStyle = { ...containerStyle, marginRight: "-17px", overflowY: "scroll" };
    }

    const stepSelection = <div ref={ref} style={containerStyle}>
        <Header breadcrumbs={breadcrumbs} headerTitle={defaultSystem?.title} />
        <div className={style.implStepsList}>
            <div className={style.stepsListHeader}>Choose implementation steps:</div>
            <NextStepCard title={'System & Contractors (Required)'} dataTut={"SystemContractor"} selected={systemType !== undefined && contractModel !== undefined} subTitle={'Choose the system variation, contractual model & contractors'} handleClick={() => { setImplStep(ImplStepEnum.SystemAndContractors); !learningCompleate && dispatch(learningProgressNext()); }} />
            <NextStepCard title={'User analysis'} dataTut={"UserAnalysis"} selected={userAnalysisFinished.current || team.flatMap(x => x.id).includes(5)} subTitle={'Analyse system usage preferences'} handleClick={() => { setImplStep(ImplStepEnum.UserAnalysis); }} />
            <NextStepCard title={'Administrative procedures'} dataTut={"Administrative"} selected={adminExpert !== undefined || team.flatMap(x => x.id).includes(1)} subTitle={'Plan changes to administrative procedures & regulations if needed'} handleClick={() => { setImplStep(ImplStepEnum.AdministrativeProcedures); }} />
            <NextStepCard title={'Infrastructure changes'} dataTut={"Infrastructure"} selected={infraExpert !== undefined || team.flatMap(x => x.id).includes(2)} subTitle={'Plan changes to city infrastructure if needed'} handleClick={() => { setImplStep(ImplStepEnum.InfrastructureChanges); }} />
            <NextStepCard title={'Employee training'} dataTut={"Employee"} selected={employeeTraining !== undefined || team.flatMap(x => x.id).includes(3)} subTitle={'Choose the way city employees will be trained to manage the system'} handleClick={() => { setImplStep(ImplStepEnum.EmployeeTraining); }} />
            <NextStepCard title={'Tests'} dataTut={"Tests"} selected={tests !== undefined || team.flatMap(x => x.id).includes(4)} subTitle={'Choose system tests to run before strarting'} handleClick={() => { setImplStep(ImplStepEnum.Tests); }} />
        </div>


        <div className={style.indicatorsListTitle}>Preparation Indicators</div>
        <div className={style.indicatorsList}>
            <ImplIndicator title={'Maximum quarterly revenue'} value={convertMoneyToString(contractModel?.revenueMax)} />

            <div>
                <ImplIndicator title={'Estimated system usability'} value={`${Math.round(usability)} / 100`} />
                <ImplIndicator title={'Estimated implementation cost'} value={convertMoneyToString(implementationCost)} />
                <ImplIndicator title={'Estimated quarterly costs'} value={convertMoneyToString(quaterlyCost)} />
                <ImplIndicator title={'Estimated time costs '} value={convertTimeToString(timeCost)} />
            </div>
        </div>

        <div className={style.footer}>
            <div className={style.footerContent}>
                <BackButton label={'Back'} handleClick={backActionHandler} />
                <ClassicButton size={SizeButton.large} dataTut={"StartImplementation"} label={`${!system.startDate ? 'Start' : 'Continue'} implementation`} disabled={!systemType || system.isProgress || system.fistStageDone} handleClick={handleStartImplementation} />
            </div>
        </div>
    </div>;

    const systemAndContractorsFinish = (systemType: ISystemType, contract: IContractRevenueMax, contractor: IContracorCost) => {
        contractor.moneyQminus = contractor.moneyQminus * systemType.scores.coeftype;
        dispatch(systemChange({ ...system, systemType, implSystemType: systemType, contractModel: contract, contractor }));
        setImplStep(ImplStepEnum.Undef);
        !learningCompleate && dispatch(learningProgressNext());
    }

    const userAnalysisFinish = () => {
        userAnalysisFinished.current = true;
        setImplStep(ImplStepEnum.Undef);
    }

    const selectedAdminProcedureExpert = (expert: IExpert) => {
        dispatch(systemChange({ ...system, adminExpert: { expert, isEdit: false } }));
        setImplStep(ImplStepEnum.Undef);
    }

    const selectedInfraExpert = (expert: IExpert) => {
        dispatch(systemChange({ ...system, infraExpert: { expert, isEdit: false } }));
        setImplStep(ImplStepEnum.Undef);
    }

    const selectedEmployeeTraining = (training: IEmployeeTraining) => {
        dispatch(systemChange({ ...system, employeeTraining: training }));
        setImplStep(ImplStepEnum.Undef);
    }

    const selectedTest = (test: ITestMode) => {
        if (system.tests && system.tests.findIndex(t => t.id === test.id) <= 0)
            dispatch(systemChange({ ...system, tests: [...system.tests, test] }));
        else
            dispatch(systemChange({ ...system, tests: [test] }));

        setImplStep(ImplStepEnum.Undef);
    }

    const anyProcedureFinish = () => {
        setImplStep(ImplStepEnum.Undef);
    }

    return isOpen ? <GameForm close={handleClose} dataTut={"SelectedSystemAllSteps"} scrollId="scroll">
        {implStep === ImplStepEnum.Undef && stepSelection}
        {implStep === ImplStepEnum.SystemAndContractors && <SystemAndContractors businessReputation={businessReputation ? businessReputation : 0} breadcrumbs={breadcrumbs} system={defaultSystem} finish={systemAndContractorsFinish} backClick={anyProcedureFinish} />}
        {implStep === ImplStepEnum.AdministrativeProcedures && <AdministrativeProcedures breadcrumbs={breadcrumbs} scopeId={scopeId} expertClick={selectedAdminProcedureExpert} gotItClick={anyProcedureFinish} backClick={anyProcedureFinish} />}
        {implStep === ImplStepEnum.InfrastructureChanges && <InfrastructureСhanges breadcrumbs={breadcrumbs} scopeId={scopeId} expertClick={selectedInfraExpert} gotItClick={anyProcedureFinish} backClick={anyProcedureFinish} />}
        {implStep === ImplStepEnum.EmployeeTraining && <EmployeeTraining breadcrumbs={breadcrumbs} trainingClick={selectedEmployeeTraining} gotItClick={anyProcedureFinish} backClick={anyProcedureFinish} />}
        {implStep === ImplStepEnum.Tests && <TestProcedures breadcrumbs={breadcrumbs} currentTests={system.tests ? system.tests : []} testClick={selectedTest} gotItClick={anyProcedureFinish} backClick={anyProcedureFinish} />}
        {implStep === ImplStepEnum.UserAnalysis && <UserAnalysis breadcrumbs={breadcrumbs} systemId={defaultSystem.id} analysisDone={userAnalysisFinish} showResult={userAnalysisFinished.current} backClick={anyProcedureFinish} />}
    </GameForm> : null;
}

export default SystemImpl;