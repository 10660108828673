import { Action } from "redux";
import { IDTEvent } from "../../../models/event";

export const CURRENT_DT_EVENT = 'CURRENT_DT_EVENT';

export interface ISetCurrentDTEventAction extends Action {
    currentDTEvent: IDTEvent | null;
    eventDate: number;
}

export function currentDTEventSet(currentDTEvent: IDTEvent | null, eventDate: number) {
    return { type: CURRENT_DT_EVENT, currentDTEvent, eventDate };
}
