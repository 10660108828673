import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import ProgressBar from '../../../Goals/ProgressBar';
import Spider from '../Spider';
import { useDispatch, useSelector } from 'react-redux';
import { openGameDashboard } from '../../../../redux/actions/forms/openGameDashboard';
import { getLearningCompleted} from '../../../../redux/selectors';
import { learningProgressNext } from '../../../../redux/actions/game/learningProgress';

interface IProps {
    className?: any;
    valueStyle?: any;
    titleStyle?: any;
    iconStyle?: any;
    spiderOnLeft?: boolean;
    rate: number;
    showProgress?: boolean;
    withSpider: boolean;
    isButtonDescription?: boolean;
    data?: any;
}

export function DigitalTransformaionRate(props: IProps) {
    const { rate, spiderOnLeft = false, valueStyle, titleStyle, iconStyle, className, showProgress, withSpider, isButtonDescription, data } = props;
    const dispatch = useDispatch();
    const learningCompleate = useSelector(getLearningCompleted);

    const handleClick = () => {
        dispatch(openGameDashboard(2));
        !learningCompleate && dispatch(learningProgressNext()); 
    }

    const spider = <div className={cn(style.spider, iconStyle)}>
        <Spider value={rate} />
    </div>;

    const renderProgress = () => {
        return showProgress && <ProgressBar completedValue={rate} background={style.progressBarBg} />
    }

    const renderButton = () => {
        return <div className={isButtonDescription ? style.buttonContainer : style.container}>
            <div className={style.bubble} onClick={handleClick}>
                <span className={style.title}>Digital transformation rate: &nbsp;</span>
                <span className={style.value}>{`${Math.round(rate)}%`}</span>
            </div>
        </div>
    }

    const renderCard = () => {
        return <div className={cn(style.card, className)}>
            {withSpider && spiderOnLeft && spider}
            <div className={isButtonDescription ? style.descriptionButton : style.description} onClick={handleClick}>
                <span className={cn(style.title, titleStyle)}>Digital Transformaion Rate</span>
                <span className={cn(style.value, valueStyle)}>{`${Math.round(rate)}%`}</span>
            </div>
            {renderButton()}
            {showProgress && <div className={style.progressBarBgXS}>
                <ProgressBar completedValue={rate} background={style.progressBarBgXS} backgroundCompleted={style.progressBarBgCompletedXS} />
            </div>}
            {withSpider && !spiderOnLeft && spider}
        </div>
    }

    return <div className={cn(style.digitalTransformaionRate, className)} data-tut={data} >
        {renderProgress()}
        {renderCard()}
    </div>
}

export default DigitalTransformaionRate