import React from 'react';
import style from './style.module.scss';
import { avatar } from '../../../helpers';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { QuotesIcon } from '../../../icons';
import { ColorButton } from '../../UiKit/Buttons/common';

interface IProps {
    text: string;
    onNext?: () => void;
    onFinish?: () => void;
    isLastStep?: boolean;
}

const StepTour: React.FunctionComponent<IProps> = (props) => {

    const { text, onNext, onFinish, isLastStep = false } = props;

    return <div className={style.card}>
        <div className={style.message}>
            <QuotesIcon className={style.quotesOpen} />
            <div>
                <span>{text}</span>
                <QuotesIcon className={style.quotesClose} />
            </div>
        </div>
        <div className={style.assistant}>
            <div className={style.info}>
                <img src={avatar('/images/chief_assistant_Sasha.png')} alt="avatar" />
                <div className={style.description}>
                    <div className={style.name}>Sasha</div>
                    <div className={style.profession}>Chief assistant</div>
                </div>
            </div>

            <div className={style.actions}>
                <ClassicButton label={isLastStep ? "Finish" : "Skip Tutorial"} handleClick={() => { onFinish && onFinish() }} color={ColorButton.azure} />
                {!isLastStep && <ClassicButton disabled={onNext === undefined} label={"Next"} handleClick={() => { onNext && onNext() }} />}
            </div>
        </div>
    </div>
}

export default StepTour;