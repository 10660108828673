import { IState } from '../../reducers';

export const getGame = (state: IState) => state.game;
export const getGameFinished = (state: IState) => state.game.finished;
export const getGameView = (state: IState) => state.game.view;
export const getGameId = (state: IState) => state.game.id;
export const getGameStage = (state: IState) => state.game.stage;
export const getTimer = (state: IState) => state.game.timer;
export const getLaunchTimer = (state: IState) => state.game.timer.launch;
export const getMoney = (state: IState) => state.game.money;
export const getCityInfo = (state: IState) => state.game.cityInfo;
export const getTargets = (state: IState) => state.game.targets;
export const getCurrentTeamMembers = (state: IState) => state.game.teamMembers;
export const getEducation = (state: IState) => state.game.education;
export const getHealth = (state: IState) => state.game.health;
export const getTransport = (state: IState) => state.game.transport;
export const getEHousing = (state: IState) => state.game.eHousing;
export const getEServices = (state: IState) => state.game.eService;
export const getSecurity = (state: IState) => state.game.security;

export const getSelectedSystems = (state: IState) => state.game.systems;
export const getSelectedSystemById = (state: IState, systemId:number) => state.game.systems.find(s => s.systemId === systemId);

export const getBusinessReputaiton = (state: IState) => state.game.cityInfo?.business;
export const getSocietyReputaiton = (state: IState) => state.game.cityInfo?.people;
export const getContractors = (state: IState) => state.game.contractors;
export const getTests = (state: IState) => state.game.testModes;

export const getCurrentCityEvent = (state: IState) => state.game.currentCityEvent;
export const getCurrentCityEventDate = (state: IState) => state.game.currentCityEventDate;
export const getHappenedCityEventIdArr = (state: IState) => state.game.happenedCityEventsIds;

export const getCurrentDTEvent = (state: IState) => state.game.currentDTEvent;
export const getCurrentDTEventDate = (state: IState) => state.game.currentDTEventDate;
export const getHappenedDTEventIdArr = (state: IState) => state.game.happenedDTEventsIds;
export const getFinMetrics = (state: IState) => state.game.finMetrics;
export const getFinMetricById = (state: IState, finMetricId:number) => state.game.finMetrics.find(fm => fm.id === finMetricId);
export const getLearningCompleted = (state: IState) => state.game.learningCompleted;
export const getLearningProgress = (state: IState) => state.game.learningProgress;
