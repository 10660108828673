import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { addGameStatsFail, addGameStatsDone, ADD_GAME_STATS_INIT, IAddGameStatsInitAction, } from '../../actions/gameAPI/addGameStats';
import { IErrorResponse } from '../../../api/common';
import { saveStatsApiCall } from '../../../api/game';

export function* addGameStatsHandler(action: Action) {
    try {
        const { gameId, curGameStats } = action as IAddGameStatsInitAction;
        yield call(saveStatsApiCall, gameId, curGameStats);
        yield put(addGameStatsDone());
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(addGameStatsFail(c))
    }
}

export function* addGameStatsSaga() {
    yield takeLatest(ADD_GAME_STATS_INIT, addGameStatsHandler);
}
