import React, { useRef, useEffect, useCallback } from 'react';
import style from './style.module.scss';
import BubbleWithTail from '../UiKit/Bubbles/BubbleWithTail';
import BubbleWithoutTail from '../UiKit/Bubbles/BubbleWithoutTail';
import cn from 'classnames'
import { useSelector } from 'react-redux';
import { useScrollComponent } from '../../hooks/useScrollComponent';
import { getLearningCompleted } from '../../redux/selectors';

interface IProps {
    bubbles: IBubble[]
    withShadow?: boolean;
    startDelta?: number;
    dataTut?: any;
}

export interface IBubble {
    isSelected: boolean
    handleClick: () => void;
    title: string;
    subTitle?: string;
}

const stableInitialPosition = (element: HTMLDivElement) => {
    if (element.firstElementChild !== null) {
        const fc = element.getElementsByTagName('div')[0];
        if (fc) {
            element.scrollLeft = fc.offsetWidth - 10;
        }
        else {
            element.scrollLeft = 200;
        }
    }
}

const BubblesSlider: React.FunctionComponent<IProps> = (props) => {

    const slider = useRef<HTMLDivElement>(null);
    const { bubbles, withShadow, dataTut } = props;

    const isDown = useRef<boolean>(false);
    const scrollLeft = useRef<number>(0);
    const startX = useRef<number>(0);
    const scrollComponent = useRef<HTMLDivElement>(null);
    const learningCompleted = useSelector(getLearningCompleted);
    useScrollComponent(learningCompleted, scrollComponent.current);

    const handlerMousedown = useCallback((e: any) => {
        if (slider.current === null) return;
        e.preventDefault();
        isDown.current = true;

        startX.current = e.pageX - slider.current.offsetLeft;
        scrollLeft.current = slider.current.scrollLeft;
    }, [slider]);

    const handlerMousemove = useCallback((e: any) => {
        if (!isDown.current || slider.current == null) return;
        e.preventDefault();
        const x = e.pageX - slider.current.offsetLeft;
        const walk = (x - startX.current) * 2;
        slider.current.scrollLeft = scrollLeft.current - walk;

    }, [slider]);

    const handlerMouseWheel = useCallback((e: any) => {
        if (slider.current == null) return;

        if (e.deltaY !== 0) {
            slider.current.scrollLeft -= (e.deltaY * 2);
            e.preventDefault();
        }

    }, [slider]);

    useEffect(() => {
        if (slider.current) {
            slider.current.addEventListener('mousedown', handlerMousedown);
            slider.current.addEventListener('mouseleave', (e: any) => { isDown.current = false });
            slider.current.addEventListener('mouseup', () => { isDown.current = false });
            slider.current.addEventListener('mousemove', handlerMousemove);
            slider.current.addEventListener('dragstart', (e: any) => { e.preventDefault(); });

            if ('onwheel' in slider.current) {
                slider.current.addEventListener("wheel", handlerMouseWheel);
            }

            if(slider.current.firstElementChild !== null) {
                const fc = slider.current.getElementsByTagName('div')[0];
                if(fc) {
                    slider.current.scrollLeft = fc.offsetWidth;
                }
                else {
                    slider.current.scrollLeft = 150;
                }
                
            }
        }
    }, [slider, handlerMousedown, handlerMousemove, handlerMouseWheel])

    useEffect(() => {
        if (slider.current === null) return;
        stableInitialPosition(slider.current)
    }, [bubbles])

    const renderBubbles = () => {

        const index = bubbles.findIndex(b => b.isSelected);
        const prevIndex = index === 0 ? bubbles.length - 1 : index - 1;

        let bubblesCopy: IBubble[] = [bubbles[prevIndex], bubbles[index]];

        bubbles.forEach((b, i) => {
            if (i !== prevIndex && i !== index)
                bubblesCopy = [...bubblesCopy, b];
        });

        const content = bubblesCopy.map((x, index) => {
            if (x.isSelected)
                return <BubbleWithTail key={index} title={x.title} handleClick={x.handleClick} classes={style.item} dataTut={x.title} />;

            return <BubbleWithoutTail key={index} handleClick={x.handleClick} clicked={false} classes={style.item} dataTut={x.title} >{x.title}</BubbleWithoutTail>;
        })

        return content;
    }

    return <div className={style.slider} ref={scrollComponent}>
        <div ref={slider} data-tut={dataTut} className={cn(style.sliderCover, withShadow ? null : style.sliderCoverWithoutShadow)}>
            {renderBubbles()}
        </div>
        {withShadow && <div className={style.sliderGradient}>
            <div className={style.block1} />
            <div className={style.block2} />
        </div>}
    </div>
}

export default BubblesSlider;