import React, { useState } from 'react';
import { ICityEvent } from '../../../models/event';
import AbstractEvent from '../../System/AbstractEvent';
import data from '../../../data';
import useScopes from '../../../hooks/useScopes';
import { getMoney } from '../../../redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { changeMoneyService } from '../../../service/changeMoneyService';
import { moneyChange, cityInfoPeopleImpact, cityInfoBusinessImpact } from '../../../redux/actions';
import GameForm from '../../UiKit/GameForm';
import style from './style.module.scss';

interface IProps {
  cityEvent: ICityEvent;
  handleOkClick: () => void;
  handleConfirmEvent: () => void;
}

const rndCoeff = Math.random() - 0.5;

const CityEvent: React.FunctionComponent<IProps> = (props) => {
  const { cityEvent, handleOkClick, handleConfirmEvent } = props;

  const dispatch = useDispatch();
  const money = useSelector(getMoney)
  const [income, setIncome] = useState<number>(0);
  const [expenses, setExpenses] = useState<number>(0);
 
  const scopes = useScopes();
  const scope = scopes.find(s => s.scopeId === cityEvent.scopeId);
  const head = data.heads.find(h => h.headId === scope?.headId);

  const handleEvent = (index: number) => {
    const result = cityEvent.options[index];
    setIncome(result.moneyQplusEbase);
    setExpenses(result.moneyQminusEbase);

    const newMoney = changeMoneyService(money, -Math.abs(result.eventCost), result.moneyQplusEbase, result.moneyQminusEbase);
    dispatch(moneyChange(newMoney));
    dispatch(cityInfoPeopleImpact(result.ePeopleBase));
    dispatch(cityInfoBusinessImpact(result.eBusinessBase));

    handleConfirmEvent();
  }

  if(!scope || !head) return null;

  return <GameForm close={() => { }} className={style.form_to_up}>
          <AbstractEvent
            event={cityEvent}
            options={cityEvent.options}
            handleConfirm={handleEvent}
            handleClose={handleOkClick}
            scopeName={scope.title}
            scopeHeader={head.title}
            avatarPath={head.avatar}
            Income={income}
            Expenses={-Math.abs(expenses)}
            rndCoeff={rndCoeff}
          />
      </GameForm>;
}

export default CityEvent;
