import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.scss';
import { closeTeamForm } from '../../redux/actions';
import { getIsOpenTeam } from '../../redux/selectors/forms/screens/openScreen';
import GameForm from '../UiKit/GameForm';
import TeamList from './TeamList';
import TeamHeader from './TeamHeader';
import Scrollbars from 'react-custom-scrollbars';
import { openGameDashboard } from '../../redux/actions/forms/openGameDashboard';


export const Team = () => {
    const isOpen = useSelector(getIsOpenTeam)
    
    
    const dispatch = useDispatch();


    const handleCloseClick = () => {
        dispatch(closeTeamForm())
    };

    const handleBackClick = () => {
        dispatch(closeTeamForm());
        dispatch(openGameDashboard(4));
    };

    return (isOpen ?
        <GameForm close={handleCloseClick} >

            <div className={style.form}>
                <TeamHeader />            

                <Scrollbars style={{ height: '100%' }}>
                    <TeamList handleBackClick={handleBackClick} />  
                </Scrollbars>        
            </div>

        </GameForm> : null
    );
}

export default Team;
