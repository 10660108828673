import { ISystem, ITimer, ITeamMember, IDefaultSystem } from "../models";
import moment from "moment";
import { TIME_COST_ADVANCED } from "../components/ProcessesByTimer";

export const calcTimeLeft = (defaultSystem: IDefaultSystem | undefined, system: ISystem | undefined, timer: ITimer, team: ITeamMember[]) => {
    const curTime = moment(timer.curDate);
    let timeLeft = 0;
    if (!defaultSystem || !system) {
        return 0;
    }

    if (defaultSystem.required02 && !system.fistStageDone) {
        if (system.adminExpert) {
            timeLeft += system.adminExpert.expert.timeCost;
        }
        else if (team.findIndex(t => t.id === 1) >= 0) {
            timeLeft += TIME_COST_ADVANCED;
        }
    }

    if (defaultSystem.required03 && !system.fistStageDone) {
        if (system.infraExpert) {
            timeLeft += system.infraExpert.expert.timeCost;
        }
        else if (team.findIndex(t => t.id === 2) >= 0) {
            timeLeft += TIME_COST_ADVANCED;
        }
    }

    if (!system.fistStageDone) {
        timeLeft += system.contractor && system.contractor.timecost ? system.contractor.timecost : 0;

        timeLeft = timeLeft - curTime.diff(system.startDate, "days");
    }

    if (system.startUpgradeDate) {
        const upgradeTimeCost = system.upgradeEvents?.flatMap(x => x.timeCost).reduce((a, b) => a + b, 0);
        const upgradeSystemTypeTimeCost = system.newSystemType?.scores.timecostuptype

        const startUpgrade = moment(system.startUpgradeDate);
        const timeFromUpdateStart = curTime.diff(startUpgrade, "days");

        if (upgradeTimeCost) timeLeft = upgradeTimeCost - timeFromUpdateStart;
        else if (upgradeSystemTypeTimeCost) timeLeft = upgradeSystemTypeTimeCost - timeFromUpdateStart;
    }

    if (system.marketingRunning) {
        timeLeft = 0;
    }

    return Math.round(timeLeft);
}