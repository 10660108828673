import { Action } from "redux";
import { MONEY_CHANGE, IMoneyChange } from "../../actions/game";
import { IMoney } from "../../../models";

export const initial: IMoney = {
    budget: 0,
    moneyQminus: 0,
    moneyQplus: 0
}

export const reducer = (state: IMoney = initial, action: Action): IMoney => {
    switch (action.type) {
        case MONEY_CHANGE:
            const { money } = action as IMoneyChange;
            return {      
                ...money
            }; 

        default:
            return initial;
    }
}