import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import BubbleWithoutTail from '../../UiKit/Bubbles/BubbleWithoutTail';

interface IProps {
    handleClick: () => void;
    selected: boolean;
    disabled?: boolean;
    clickAndGo?: boolean;
    className?: string;
    actionOnTop?: boolean;
    hiddenAction?: boolean;
    dataTut?: any;
}

const AbstractCard: React.FunctionComponent<IProps> = (props) => {

    const { handleClick, clickAndGo = false, selected, children, className, disabled = false, actionOnTop, hiddenAction = false, dataTut } = props;

    const actionStyle = () => {
        if (disabled) return;

        if (clickAndGo)
            return cn(style.action, style.actionFinished);

        if (selected)
            return style.actionSelected;

        return cn(style.action);
    }

    return <BubbleWithoutTail clicked={false} handleClick={handleClick} classes={cn(style.card, disabled ? style.disabled : '', actionOnTop ? style.cardRelative : '', className)} dataTut={dataTut}>
        <div className={cn(style.content, actionOnTop ? style.contentFullWidth : '')}>{children}</div>
        {!disabled && !hiddenAction && <div className={cn(actionStyle(), actionOnTop ? style.actionOnTop : '')} />}
    </BubbleWithoutTail>
}

export default AbstractCard;