import React, { useEffect } from 'react';
import style from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getTimer, getLearningCompleted, getGameFinished } from '../../redux/selectors';
import { timerStart, timerPause } from '../../redux/actions';
import { timerUpdate } from '../../redux/actions';
import moment from 'moment';
import { ForwardIcon, PauseIcon } from '../../icons';
import cn from 'classnames';
import { useQuarterTimer } from '../../hooks/useQuarterTimer';
import { useSaveGame } from '../../hooks/useSaveGame';

const Timer = () => {
  const timer = useSelector(getTimer);
  const learningCompleted = useSelector(getLearningCompleted);

  const dispatch = useDispatch();
  const interval: any = React.useRef(null);

  useSaveGame();
  useQuarterTimer();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // console.log('timer pause')
      dispatch(timerPause());
      clearInterval(interval.current);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [dispatch, interval]);

  useEffect(() => {
    if (!timer.launch) {
      clearInterval(interval.current);
    }

    if (timer.launch) {
      interval.current = learningCompleted ? setInterval(() => dispatch(timerUpdate()), 200) : setInterval(() => dispatch(timerUpdate()), 1);
    }

  }, [interval, timer.launch, learningCompleted, dispatch])

  const handleStartClick = () => {
    dispatch(timerStart());
  }

  const handlePauseClick = () => {
    clearInterval(interval.current);
    dispatch(timerPause());
  }

  const renderTimerPause = <div className={style.timer} data-tut={"Timer"}>
    <input type="text" className={style.value} readOnly={true} value={moment(timer.curDate).format("DD.MM.YYYY").toLocaleString()} />
    <button className={cn(style.action, style.pause)} onClick={handlePauseClick}>
      <PauseIcon />
    </button>
  </div>;

  const renderTimerStart = <>
    <div className={style.timerWrapper}></div>
    <div className={style.timer} data-tut={"Timer"}>
      <input type="text" className={style.value} readOnly={true} value={moment(timer.curDate).format("DD.MM.YYYY").toLocaleString()} />
      <button className={style.action} onClick={handleStartClick}>
        <ForwardIcon />
      </button>
    </div>
  </>;

  return timer.launch && interval.current !== null ? renderTimerPause : renderTimerStart;
}

export default Timer;
