import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ICreateGame } from "../api/game";
import { saveGameInit } from "../redux/actions/gameAPI/saveGame";
import { getGame } from "../redux/selectors";
import { store } from "../store/configureStore";

export function useSaveGame() {
    const currentGame = useSelector(getGame);
    const dispatch = useDispatch();

    useEffect(() => {
        const saveGame = store.getState().saveGame;        
        if (!saveGame.inProgress && currentGame && currentGame.id > 0) {
            const updatedGame: ICreateGame = { state: JSON.stringify(currentGame) }
            dispatch(saveGameInit(currentGame.id, updatedGame))
        }
    }, [currentGame, dispatch]);
}