import { Action } from "redux";

export const SET_CITY_EVENT_ID = 'SET_CITY_EVENT_ID';

export interface ISetCurrentCityEventIdAction extends Action {
    currentCityEventId: number;
}

export function currentCityEventIdSet(currentCityEventId: number) {
    return { type: SET_CITY_EVENT_ID, currentCityEventId };
}
