import React from 'react';
import { IScope } from '../../../models';
import BubbleWithoutTail from '../../UiKit/Bubbles/BubbleWithoutTail';
import style from './style.module.scss';
import { ToolTip } from '../../UiKit/ToolTip';
import { getCityInfo } from '../../../redux/selectors';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import cn from 'classnames';

interface IProps {
    scopes: IScope[];
    currentScopeId: number;
    setCurrentScopeId: (id: number) => void;
}

const ScopeSelection: React.FunctionComponent<IProps> = (props) => {

    const { scopes, setCurrentScopeId, currentScopeId } = props;
    const cityInfo = useSelector(getCityInfo);

    const renderScopes = scopes.map((x, index) =>
        <ToolTip id={'scope_' + x.scopeId.toString()} key={index} text={cityInfo?.sphereSituation[x.scopeId] ? cityInfo?.sphereSituation[x.scopeId] : ''}>
            <BubbleWithoutTail dataTut={x.scopeId === 5 ? "Scope" : ""} handleClick={() => setCurrentScopeId(x.scopeId)} clicked={x.scopeId === currentScopeId} classes={style.bubble}>
                {x.title}
            </BubbleWithoutTail>
        </ToolTip>);

    // return <div className={style.scopes}>
    //             {renderScopes}
    //         </div>;

    return <>
            <Scrollbars style={{ height: '100%' }} className={style.hidden}>
                <div className={style.scopes}>
                    {renderScopes}
                </div>
            </Scrollbars>

            <div className={cn(style.scopes, style.show)}>
                {renderScopes}
            </div>
        </>;
}

export default ScopeSelection;