import React from 'react';
import style from './style.module.scss';
import { NoteIcon } from '../../../icons'
import cn from 'classnames'

interface IProps {
    className?: any;
}

const InfoFooter: React.FunctionComponent<IProps> = (props) => {
    const {className, children} = props;

    return <div className={cn(style.infoFooter, className)}>
        <NoteIcon />
        <p className={style.text}>{children}</p>
    </div>
}

export default InfoFooter;