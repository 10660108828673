export const OPEN_RESULT_FORM = 'OPEN_RESULT_FORM';
export const CLOSE_RESULT_FORM = 'CLOSE_RESULT_FORM';

export function openResultForm() {
    return { type: OPEN_RESULT_FORM };
}

export function closeResultForm() {
    return { type: CLOSE_RESULT_FORM };
}
