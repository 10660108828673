import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';
import CostItem from './CostItem';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../UiKit/ScoreProgressBar';
import cn from 'classnames'
import { convertMoneyToString, convertTimeToString } from '../../../service/convertorToString';

interface IProps {
    lable: string;
    money: number;
    time: number;
    selected: boolean;
    efficiency?: number;
    handleClick: () => void;
}

const TestCard: React.FunctionComponent<IProps> = (props) => {

    const { lable, money, time, selected, efficiency, handleClick } = props;

    return <AbstractCard selected={selected} clickAndGo={false} handleClick={handleClick}>
        <div className={style.expertInfo}>
            <div className={style.name}>{lable}</div>
            <div className={cn(style.costs, efficiency ? style.titleBlock : '')}>
                <CostItem title={'Procedure Cost:'} value={convertMoneyToString(money)} />
                <CostItem title={'Time Cost:'} value={convertTimeToString(time)} />
                {efficiency && <ScoreProgressBar color={ScoreProgressBarColor.orange} title={'User efficiency'}
                    value={efficiency} maxValue={10}
                />}
            </div>
        </div>

    </AbstractCard>;
}

export default TestCard;