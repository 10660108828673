import { Action } from "redux";
import { OPEN_SYSTEM_IMPL_FORM, CLOSE_SYSTEM_IMPL_FORM, IOpenSystemsImplAction } from "../../actions";

export interface IState {
    isOpen: boolean;
    systemId: number;
    scopeId: number;
    onClose?: () => void;
}

export const initial: IState = {
    isOpen: false,
    systemId: 0,
    scopeId: 0
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_SYSTEM_IMPL_FORM:
            const { systemId, scopeId, onClose } = action as IOpenSystemsImplAction
            return {
                ...state,
                isOpen: true,
                systemId,
                onClose,
                scopeId
            };

        case CLOSE_SYSTEM_IMPL_FORM:
            return {
                ...state,
                isOpen: false,
                systemId: 0,
                onClose: () => {},
                scopeId: 0
            };

        default:
            return state;
    }
}