import React from 'react';
import style from './style.module.scss';
import { CloseFormIcon } from '../../icons';
import cn from 'classnames';

interface IProps {
    className?: any
    error?: string | null;
    onSubmit: (event: any) => void
    close: (event: any) => void
}
const Form: React.FunctionComponent<IProps> = (props) => {

  const handleSubmit = (event: any) => {
    props.onSubmit(event);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseClick = (event: any) => {
    props.close(event);
  };

  const renderErrorMessage = () =>{
      if (props.error !== null){
      return <span>{props.error}</span>
      }
  }

  return (
        <div className={cn(style.container)}>
            <div className={cn(props.className, style.form)}>
                <div className={style.header}>
                    <CloseFormIcon onClick={handleCloseClick}/>
                </div>
                <div className={style.content}>
                    <form onSubmit={handleSubmit} className={style.authForm}>
                            {props.children}
                            {renderErrorMessage()}
                    </form>
                </div>
            </div>
       </div>
  );
}

export default Form;