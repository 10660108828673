import { Action } from "redux";
import { OPEN_DIRECTION_FORM, CLOSE_DIRECTION_FORM, IOpenDirectionForm } from "../../actions";

export interface IState {
    isOpen: boolean;
    scopeId: number;
    directionId: number;
    onClose?: () => void;
}

export const initial: IState = {
    isOpen: false,
    scopeId: -1,
    directionId: -1,
    onClose: undefined,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_DIRECTION_FORM:
            const { scopeId, directionId, onClose } = action as IOpenDirectionForm;
            return {
                ...state,
                scopeId,
                directionId,
                onClose,
                isOpen: true
            };

        case CLOSE_DIRECTION_FORM:
            return {
                ...state,
                isOpen: false,
                scopeId: -1,
                onClose: () => {},
                directionId: -1
            };

        default:
            return state;
    }
}