export const OPEN_GOALS_FORM = 'OPEN_GOALS_FORM';
export const CLOSE_GOALS_FORM = 'CLOSE_GOALS_FORM';

export function openGoalsForm() {
    return { type: OPEN_GOALS_FORM };
}

export function closeGoalsForm() {
    return { type: CLOSE_GOALS_FORM } ;
}
