import React from 'react';
import style from './style.module.scss';
import { SpheresProgress } from './SpheresProgress';
import { GoalsProgress } from './GoalsProgress';



export function Progress() {

    return <div className={style.form}>
        <GoalsProgress />
        <br /><br />
        <SpheresProgress />
    </div>
}