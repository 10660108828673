import React from 'react';
import style from './style.module.scss';
import ClassicButton from '../UiKit/Buttons/ClassicButton';

interface IProps {
    manageTeamAction?: () => void; 
    backgroundColor?: string;
}

const TeamHeader: React.FunctionComponent<IProps> = (props) => {

    const { manageTeamAction, backgroundColor } = props;

    let styleCss = {};
    if(backgroundColor) styleCss = {...styleCss, backgroundColor};

    return <header className={style.header} style={styleCss}>               
                <h1>Permanent Team &amp; Contractors</h1>
                <div className={style.info}>Hire a team member or a permanent contractor to reduce your time and money expenses in particular processes.</div>               
                <br/>
                {manageTeamAction && <ClassicButton label={'Manage Team'} handleClick={manageTeamAction} />}
            </header>;
}

export default TeamHeader;