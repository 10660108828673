export enum SizeButton {
    normal = 'normal',
    large = 'large',
    small = 'small'
}

export enum ColorButton {
    default, // yellow
    azure
}

export interface IButtonProps {
    label: string;
    handleClick: () => void;
    classes?: string;
    disabled?: boolean;
}