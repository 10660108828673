import React from 'react';
import style from './style.module.scss';
import GameCard from './GameCard';
import { useSelector } from 'react-redux';
import { getGames } from '../../../redux/selectors';

export function GameList() {
  const gameList = useSelector(getGames)

  function renderGameCard() {
    if (gameList) {
      return gameList.map((x, index) => { return <div key={index}><div  className={style.gameCardDelimeter}/><GameCard game={x}/> </div>});
    } else {
      return null;
    }
  }

  return <div className={style.gameList}>
    {renderGameCard()}
  </div>
}

export default GameList;
