import React from 'react';
import style from './style.module.scss';
import AbstractCard from '../AbstractCard';
import { IExpert } from '../../../models/option';
import CostItem from './CostItem';
import { convertMoneyToString, convertTimeToString } from '../../../service/convertorToString';

interface IExpertCard {
   expert: IExpert;
   handleClick: () => void;
}

const ExpertCard: React.FunctionComponent<IExpertCard> = (props) => {

    const { expert, handleClick } = props;

    return <AbstractCard selected={false} clickAndGo={true} handleClick={handleClick}>
            <div className={style.expertInfo}>
                <div className={style.name}>{expert.name}</div>
                <div className={style.costs}>
                    <CostItem title={'Procedure Cost:'} value={convertMoneyToString(expert.moneyMinus)}/>
                    <CostItem title={'Time Cost:'}  value={convertTimeToString(expert.timeCost)}/>
                </div>
            </div>
           
        </AbstractCard>;
}

export default ExpertCard;