import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { getGamesFail, GET_GAMES_INIT, IGetGamesInitAction, getGamesDone } from '../../actions/gameAPI/getGames';
import { IPageResult, IErrorResponse } from '../../../api/common';
import { getGames, IGameDto } from '../../../api/game';

export function* gamesHandler(action: Action) {
    try {
        const { filter } = action as IGetGamesInitAction;
        const result: IPageResult<IGameDto> =  yield call(getGames, filter);
        yield put(getGamesDone(result));
    } catch (e) {
        const error = e as IErrorResponse;
        yield put(getGamesFail(error))
    }
}


export function* getGamesSaga() {
    yield takeLatest(GET_GAMES_INIT, gamesHandler);
}
