import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import style from './style.module.scss';
import { useLtiAuth } from '../../hooks';
import { useSelector } from 'react-redux';
import { ICreateGame } from '../../api/game';
import { getAuth } from '../../redux/selectors';
import { getGamesInit, createGameInit } from '../../redux/actions';
import { initial } from '../../redux/reducers/game';
import logoBig from '../../icons/logo-big.svg';
import { HseOutlineLogoIcon } from '../../icons';
import { GameList } from './GameList';
import ClassicButton from '../UiKit/Buttons/ClassicButton';


export function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);

  const isLti = useLtiAuth();

  useEffect(() => {
    if(auth) {
      dispatch(getGamesInit(null));
      history.push('/profile');
    }      
    else if(!isLti) {
      history.push('/');
    }      
  }, [dispatch, auth, isLti, history]);

  const handleNewGame = () => {
    const gameDefault: ICreateGame = {
      state: JSON.stringify(initial)
    };

    dispatch(createGameInit(gameDefault));
  }

  return <div className={style.profile}>
                
                <div className={style.content}>
                  <div className={style.hseLogo}>
                    <HseOutlineLogoIcon />
                  </div>
                  <img className={style.gameLogo} src={logoBig} alt='SMARTCITY' />

                  <div className={style.panelContainer}>
                    <div className={style.panelHeader}>
                      <div className={style.panelBlur + " " + style.panelBg} />
                      <div className={style.panelBlur}/>                      
                    </div>

                    <div className={style.panelContent}>
                      <h2>ABOUT</h2>
                      <p>You have just been elected mayor of a big city. The essence of your election campaign was the digital transformation of the city. People have been waiting for so long to enter the era of modern technology, don't let them down.</p>
                      <ClassicButton label="Start new game" handleClick={handleNewGame}/>

                      <GameList />
                        
                    </div>

                    <div className={style.footer}>2020  © Highter School of Economics</div>

                  </div>

                </div>

                
                

            </div>;
}

export default Profile;
