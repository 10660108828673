import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

export enum IconPosition {
    left,
    right
}

interface IButtonProps {
    label: string;
    icon: React.ReactNode;
    disabled?: boolean;
    iconPosition: IconPosition;
    handleClick: () => void;
    classes?: string;
}

const IconButton: React.FunctionComponent<IButtonProps> = (props) => {

    const { label, handleClick, iconPosition, icon, classes, disabled = false } = props;

    return <button disabled={disabled} className={cn(style.iconButton, iconPosition === IconPosition.right ? style.right : '', classes)} onClick={handleClick}>
        {iconPosition === IconPosition.left && icon}
        {label}
        {iconPosition === IconPosition.right && icon}
    </button>
}

export default IconButton;