import { Action } from "redux";

export const OPEN_SYSTEMS_SCORES_FORM = 'OPEN_SYSTEMS_SCORES_FORM';
export const CLOSE_SYSTEMS_SCORES_FORM = 'CLOSE_SYSTEMS_SCORES_FORM';

export interface IOpenSystemsScoresAction extends Action {
    scopeId: number;
    systemId: number;
}

export function openSystemsScoresForm(scopeId: number, systemId: number) {
    return { type: OPEN_SYSTEMS_SCORES_FORM, scopeId, systemId };
}

export function closeSystemsScoresForm() {
    return { type: CLOSE_SYSTEMS_SCORES_FORM };
}
