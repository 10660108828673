import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { IGameDto } from "../../../api/game";
import { CREATE_GAME_INIT, CREATE_GAME_DONE, CREATE_GAME_FAIL, ICreateGameDoneAction } from "../../actions/gameAPI/createGame";

export interface IState {
    inProgress: boolean;
    error: string | null;
    createdGame: IGameDto | null;
}

export const initial: IState = {
    createdGame: null,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case CREATE_GAME_INIT:
            return {
                ...state,
                createdGame: null,
                inProgress: true,
                error: null
            };

        case CREATE_GAME_DONE:

            const { createdGame } = action as ICreateGameDoneAction;
            return {
                ...state,
                createdGame,
                inProgress: false,
                error: null
            };

        case CREATE_GAME_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                createdGame: null,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}