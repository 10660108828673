import React, { useState } from 'react';
import style from './style.module.scss';
import BackButton from '../UiKit/Buttons/BackButton';
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import AboutInfoCard from './about';
import { SizeButton } from '../UiKit/Buttons/common';
import ClimateInfoCard from './climate';
import PoliticalInfoCard from './political';
import BudgetInfoCard from './budget';
import SphereInfoCard from './sphere';
import { ScopeEnum } from '../../models/scope';
import { ICityInformation } from '../../models';
import Scrollbars from 'react-custom-scrollbars';

interface IProps {
    cityInfo: ICityInformation;
    setProgress: (value: number) => void;
    handleSubmit: () => void;
    isOpenAgain: boolean;
}

const CityInfoCard: React.FunctionComponent<IProps> = (props) => {

    const { handleSubmit, cityInfo, setProgress } = props;
    const [currentStep, setStep] = useState<number>(props.isOpenAgain ? 1 : 0);
    const stepsList = [
        <div className={style.cards} key={1}>
            <Scrollbars>
                <div className={style.cardsScroll}>
                    <AboutInfoCard cityInfo={cityInfo} />
                    <ClimateInfoCard cityInfo={cityInfo} />
                </div>
            </Scrollbars>
        </div>,
        <div className={style.cards} key={2}>
            <Scrollbars>
                <div className={style.cardsScroll}>
                    <PoliticalInfoCard cityInfo={cityInfo} />
                    <BudgetInfoCard cityInfo={cityInfo} />
                </div>
            </Scrollbars>
        </div>,
        <div className={style.cards} key={3}>
            <Scrollbars>
                <div className={style.cardsScroll}>
                    <SphereInfoCard scopeId={ScopeEnum.EDUCATION} cityInfo={cityInfo} />
                    <SphereInfoCard scopeId={ScopeEnum.HEALTH} cityInfo={cityInfo} />
                </div>
            </Scrollbars>
        </div>,
        <div className={style.cards} key={4}>
            <Scrollbars>
                <div className={style.cardsScroll}>
                    <SphereInfoCard scopeId={ScopeEnum.TRANSPORT} cityInfo={cityInfo} />
                    <SphereInfoCard scopeId={ScopeEnum.E_HOUSING} cityInfo={cityInfo} />
                </div>
            </Scrollbars>
        </div>,
        <div className={style.cards} key={5}>
            <Scrollbars>
                <div className={style.cardsScroll}>
                    <SphereInfoCard scopeId={ScopeEnum.E_SERVICES} cityInfo={cityInfo} />
                    <SphereInfoCard scopeId={ScopeEnum.SECURITY} cityInfo={cityInfo} />
                </div>
            </Scrollbars>
        </div>
    ];

    const confirmClick = () => {
        if (currentStep < stepsList.length - 1) {
            setStep(currentStep + 1);
            setProgress((currentStep + 2) / 5 * 100);
        }           
        else
            handleSubmit();
    };

    const backClick = () => {
        if (currentStep > 0)
            setStep(currentStep - 1);
    };

    return <div className={style.info}>
        {stepsList[currentStep]}
        <div className={style.footer}>
            {currentStep > 0 ? <BackButton label={'Back'} handleClick={backClick} classes={'margin'} /> : <div />}
            <ClassicButton handleClick={confirmClick} size={SizeButton.normal} label={currentStep < stepsList.length - 1 ? 'Proceed' : 'End'} />
        </div>
    </div>
}

export default CityInfoCard;