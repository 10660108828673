import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from './style.module.scss';
import GameCanvas from './GameCanvas';
import { getGameId, getGameView, getLearningProgress, getLearningCompleted } from '../../redux/selectors/game';
import { useDefineGameStage } from './useDefineGameStage';
import { useContinueGame } from './useContinueGame';
import { HeaderGame } from './HeaderGame';
import { Statistic } from './Statistic';
import Team from '../Team';
import Goals from '../Goals';
import Direction from '../Direction';
import CityEvents from '../CityEvents';
import DTEvents from '../DTEvents';
import SystemImpl from '../System/SystemImpl';
import Scope from '../Scope';
import { Heads } from '../Heads';
import { Upgrade } from '../Upgrade';
import ProcessesByTimer from '../ProcessesByTimer';
import CityInfo from '../CityInfo';
import GameScoresCalc from './GameScoresCalc';
import GameDashboard from './Dashboard';
import AssistantNotify from '../AssistantNotify';
import Result from '../Result';
import { SystemEvents } from '../System/SystemEvents';
import Tour, { ReactourStep } from 'reactour';
import MindMapView from './MindMapView';
import { learningProgressInit, learningProgressNext, learningProgressPause } from '../../redux/actions/game/learningProgress';
import { learningCompleate, gameInit, gameStageChange, assistantNotifyRemove, openGoalsForm } from '../../redux/actions';
import { initial } from '../../redux/reducers/game';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { GameStageEnum } from '../../models';
import { useWindowSize } from '../../hooks';
import { getAssistantEvents } from '../../redux/selectors';
import { getSteps } from '../../service/steps';

export function Game() {

  const devicesize = useDeviceSize();
  const windowSize = useWindowSize(() => { });
  const steps: ReactourStep[] = getSteps(devicesize, windowSize, () => handleNextClick(), () => handleCloseClick());
  const gameId = useSelector(getGameId);
  const { step, inProgress } = useSelector(getLearningProgress);
  const currentView = useSelector(getGameView)
  const learningCompleted = useSelector(getLearningCompleted);
  const events = useSelector(getAssistantEvents);
  const dispatch = useDispatch();
  useContinueGame();
  useDefineGameStage();

  const renderScreen = () => {
    return <>
      <GameDashboard />
      <SystemImpl />
      <ProcessesByTimer />
      <GameScoresCalc />
      <Team />
      <CityInfo />
      <Scope />
      <Heads />
      <Goals />
      <Direction />
      <Upgrade />
      <AssistantNotify />
      <Result />
      <CityEvents />
      <DTEvents />
      <SystemEvents />
    </>;
  }

  const getView = () => <>
    <GameCanvas />
    {currentView === 'MindMap' && <MindMapView />}
  </>;

  const handleCurrentClick = (step: number) => {
    dispatch(learningProgressInit(step));
  }

  const handleNextClick = () => {
    dispatch(learningProgressNext());
  }

  const handleCloseClick = () => {
    const game = initial;
    game.id = gameId;
    dispatch(gameInit(game));
    dispatch(learningCompleate());
    dispatch(learningProgressPause());
    dispatch(gameStageChange(GameStageEnum.STAGE_1_LEARNING));
    events.map(x => dispatch(assistantNotifyRemove(x.notifyId)));
    dispatch(openGoalsForm());
  }

  return <>
    <HeaderGame />
    {gameId > 0 && getView()}
    {gameId > 0 && renderScreen()}
    {gameId > 0 && <Statistic />}
    <Tour
      accentColor={"#00326b"}
      steps={steps}
      className={style.tour}
      isOpen={!learningCompleted && inProgress}
      rounded={13}
      goToStep={step}
      getCurrentStep={() => handleCurrentClick}
      nextStep={handleNextClick}
      prevStep={() => { }}
      showNumber={false}
      startAt={step}
      closeWithMask={false}
      showNavigation={false}
      showButtons={false}
      onRequestClose={handleCloseClick}>
    </Tour>
  </>;
}

export default Game;
