import React from 'react';
import ReactTooltip from 'react-tooltip';
import style from './style.module.scss';

interface IProps {
    id: string;
    text: string
}

export const ToolTip: React.FunctionComponent<IProps> = (props) => {
    const { text, children, id } = props
    const color = "#007ac4"
    return <div>
        <div data-tip data-for={id}>
            {children}
        </div>

        <ReactTooltip id={id} place="top" effect="solid" className={style.toolTip} arrowColor={color} multiline={true}>
            {text}
        </ReactTooltip>
    </div>
}