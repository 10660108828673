import React, { useState } from 'react';
import { BreadcrumpsCollapseIcon, BreadcrumpsExpandIcon } from '../../../icons';
import style from './style.module.scss';


interface IProps {
    path: string[];
}

const Breadcrumps: React.FunctionComponent<IProps> = (props) => {

    const { path } = props;

    const [isShowFull, setShowFull] = useState<boolean>(false);

    if (path.length === 0) return <div className={style.breadcrumps} />
    if (path.length === 1) return <div className={style.breadcrumps}>{path}</div>

    const fullpath = path.join(' / ');

    return isShowFull ?
        <div className={style.breadcrumps} onClick={() => setShowFull(false)}><BreadcrumpsCollapseIcon />{fullpath}</div>
        : <div className={style.breadcrumps} onClick={() => setShowFull(true)}><BreadcrumpsExpandIcon />{path[path.length - 1]}</div>
}

export default Breadcrumps;