export const OPEN_LEARNING_FORM = 'OPEN_LEARNING_FORM';
export const CLOSE_LEARNING_FORM = 'CLOSE_LEARNING_FORM';

export function openLearningForm() {
    return { type: OPEN_LEARNING_FORM };
}

export function closeLearningForm() {
    return { type: CLOSE_LEARNING_FORM } ;
}
