import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
import { convertMoneyToString } from '../../../../../service/convertorToString';
import { INameValue } from '../../../../../models';

interface IProps {
    data: INameValue[];
    summary?: number;
}

const Summary: React.FunctionComponent<IProps> = (props) => {
    const { data, summary = 0 } = props;

    return (
        <div className={style.summary}>
            <div className={style.header}>
                <div className={style.headerSummary}>Summary</div>
                <div className={style.headerSummaryValue}>{convertMoneyToString(summary)}</div>
            </div>

            <div className={style.data}>
                {data.map((e: INameValue, index: number) => (
                    <div key={index} className={style.row}>

                        <div className={cn(style.name, e.value === undefined ? style.topic : '' )}>{e.name}</div>                        
                        {e.value !== undefined && <div className={style.value}>{convertMoneyToString(e.value)}</div>}

                    </div>)
                )}
            </div>

        </div>);
}
export default Summary;