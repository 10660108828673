export const OPEN_INFRASTRUCTURE_FORM= 'OPEN_INFRASTRUCTURE_FORM';
export const CLOSE_INFRASTRUCTURE_FORM = 'CLOSE_INFRASTRUCTURE_FORM';

export function openInfrastructureForm() {
    return { type: OPEN_INFRASTRUCTURE_FORM };
}

export function closeInfrastructureForm() {
    return { type: CLOSE_INFRASTRUCTURE_FORM } ;
}
