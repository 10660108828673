import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import style from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getDirectionForm } from '../../redux/selectors/forms/screens/openScreen';
import GameForm from '../UiKit/GameForm';
import { closeDirectionForm, openSystemImplForm, systemsChange, openUpgradeForm, hightlightPointDone } from '../../redux/actions';
import Header from '../System/Header';
import useScopes from '../../hooks/useScopes';
import ImplIndicator from '../System/ImplIndicator';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import data from '../../data';
import { IDefaultSystem, ISystem } from '../../models';
import { getSelectedSystems, getHightlightPoint, getCurrentTeamMembers, getTimer, getLearningCompleted } from '../../redux/selectors';
import SystemCard from '../System/SystemCard';
import { IState } from '../../redux/reducers';
import { convertMoneyToString } from '../../service/convertorToString';
import { calcDirectionDigitalTransformationRate } from '../../service/calcDigitalTransformRate';
import BackButton from '../UiKit/Buttons/BackButton';
import { calcTimeLeft } from '../../service/calcTimeLeft';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';
import { useScrollComponent } from '../../hooks/useScrollComponent';

const Direction: React.FunctionComponent = () => {

    const dispatch = useDispatch();
    const { isOpen, scopeId, directionId, onClose } = useSelector(getDirectionForm);
    const point = useSelector(getHightlightPoint);
    const selectedSystems = useSelector(getSelectedSystems);
    const currentSystems = useSelector(s => getSelectedSystems(s as IState));
    const team = useSelector(getCurrentTeamMembers);
    const timer = useSelector(getTimer);
    const learningCompleted = useSelector(getLearningCompleted);
    const [scrollComponent, setScrollComponent] = useState<any>(null);

    useEffect(() => {
        if (isOpen) {
            const scrollComponent = document.getElementById("scroll");
            setScrollComponent(scrollComponent);
        }
    }, [isOpen])
    useScrollComponent(learningCompleted, scrollComponent);

    const scopes = useScopes();

    const scope = scopes.find(s => s.scopeId === scopeId);
    if (!scope || !scope.directions) return null;

    const direction = scope.directions.find(d => d.directionId === directionId);
    if (!direction) return null;

    const systemsIds = data.systems.filter(s => s.directionId === directionId).map(s => s.id);
    const systems = data.systems.filter(s => systemsIds.findIndex(id => id === s.id) >= 0);
    if (!systems) return null;

    const close = () => {
        dispatch(closeDirectionForm());
    }

    const handleBackClick = () => {
        dispatch(closeDirectionForm());
        dispatch(hightlightPointDone());
        onClose && onClose();
    }

    const handleClickOnSystem = (system: IDefaultSystem) => {
        dispatch(closeDirectionForm());
        !learningCompleted && dispatch(learningProgressNext());

        if (selectedSystems.findIndex(ss => ss.systemId === system.id) < 0) {
            const newSystem = { systemId: system.id, revenue: 0, success: 0, usedSystemTypes: [] as string[], usedUpgradeEvents: [] as number[] } as ISystem;
            dispatch(systemsChange([...selectedSystems, newSystem]));
        }

        if (system.id === 512) {
            !learningCompleted && dispatch(learningProgressNext());
        }

        currentSystems.find(x => x.systemId === system.id)?.fistStageDone ?
            dispatch(openUpgradeForm(system.id, system.scopeId))
            : dispatch(openSystemImplForm(system.id, system.scopeId));

    }

    const systemByDirection = selectedSystems.filter(s => systemsIds.findIndex(id => id === s.systemId) >= 0);

    const revenue = Math.round(systemByDirection.map(s => s.revenue).reduce((a, b) => a + b, 0));
    const success = Math.round(calcDirectionDigitalTransformationRate(direction, systemByDirection) * 100);

    return isOpen ?
        <GameForm close={close} scrollId="scroll">
            <Header breadcrumbs={[scope.title]} headerTitle={direction.title} />

            <div className={style.mainScores} data-tut={"SystemScores"}>
                <ImplIndicator title={'Systems Revenue per Quarter'} value={convertMoneyToString(revenue)} />
                <br />
                <ScoreProgressBar containerStyle={style.progressBar} title={'Digital Transformaion Rate'} value={success} color={ScoreProgressBarColor.yellow} unit='%' />
            </div>

            <div style={{ height: 'calc(100% - 40px)', backgroundColor: '#063f83' }}>
                <Scrollbars>
                    <div className={style.systems} data-tut={"Systems"}>
                        <div className={style.title}>Choose the system:</div>

                        {systems.map((s, i) => {
                            const ss = selectedSystems.find(si => si.systemId === s.id);
                            const isProgress = ss ? ss.startDate !== undefined : false;
                            const progressValue = Math.round(ss && ss.success ? ss.success * 100 : 0);
                            const timeLeft = calcTimeLeft(s, ss, timer, team);
                            return <SystemCard key={i} dataTut={s.id === 512 ? "SelectedSystem" : ""} state={isProgress === true ? 'Progress' : 'Default'} valueProgress={progressValue} timeLeft={timeLeft} system={s} handleClick={() => handleClickOnSystem(s)} />
                        })}
                        {point && <BackButton label="BACK" handleClick={handleBackClick} classes={style.back} />}
                    </div>
                </Scrollbars>
            </div>

        </GameForm> : null;
}

export default Direction;