import React, { useState } from 'react';
import FormContainer from './FormContainer';
import WelcomeForm from './WelcomeForm';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenGoals } from '../../redux/selectors/forms/screens/openScreen';
import { closeGoalsForm, openCityInfoForm, gameStageChange, learningProgressStart } from '../../redux/actions';
import GoalSelection from './GoalSelection';
import { getTargets, getLearningCompleted } from '../../redux/selectors';
import { GameStageEnum } from '../../models';

enum GoalsStage {
    Welcome = 1,
    GoalsSelection = 2
}

const Goals: React.FunctionComponent = (props) => {
    const dispatch = useDispatch();

    const isOpen = useSelector(getIsOpenGoals);
    const learningCompleated = useSelector(getLearningCompleted);
    const targets = useSelector(getTargets);

    const [stage, setStage] = useState<GoalsStage>(GoalsStage.Welcome);
    const [progress, setProgress] = useState<number>(0);

    const handleCloseClick = () => {
        if (targets.length >= 2) {
            dispatch(gameStageChange(GameStageEnum.STAGE_4_GAME));
            dispatch(closeGoalsForm());
        }
    };

    const backToCityInfo = () => {
        dispatch(closeGoalsForm());
        dispatch(openCityInfoForm(true));
        handleCloseClick();
    }

    return isOpen ? <FormContainer close={handleCloseClick} propgressCompletedValue={progress}>

        {stage === GoalsStage.Welcome && <WelcomeForm startSettingGoals={() => { setStage(GoalsStage.GoalsSelection); !learningCompleated && dispatch(learningProgressStart()); }} openCityInfoForm={backToCityInfo} />}
        {stage === GoalsStage.GoalsSelection && <GoalSelection setProgress={setProgress} backToWelcome={() => setStage(GoalsStage.Welcome)} />}

    </FormContainer> : null;
}

export default Goals;