import React from 'react';
import IconButton, { IconPosition } from './IconButton';
import { BackButtonIcon } from '../../../icons';
import { IButtonProps } from './common';

const BackButton: React.FunctionComponent<IButtonProps> = (props) => {

    const { label, handleClick, classes, disabled = false } = props;

    return <IconButton disabled={disabled} label={label} handleClick={handleClick} classes={classes} icon={<BackButtonIcon />} iconPosition={IconPosition.left} />
}

export default BackButton;

