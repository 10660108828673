import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getHeadsForm } from '../../redux/selectors/forms/screens/openScreen';
import GameForm from '../UiKit/GameForm';
import ScoreProgressBar, { ScoreProgressBarColor } from '../UiKit/ScoreProgressBar';
import data from '../../data';
import { useDefineScope } from '../../service/defineScope';
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../UiKit/Buttons/common';
import { closeHeadsForm, scopesChange, moneyChange } from '../../redux/actions';
import HorzScrollableCard from '../UiKit/HorzScrollableCard';
import { SelectedIcon } from '../../icons';
import BackButton from '../UiKit/Buttons/BackButton';
import { IScope } from '../../models';
import { getMoney, getLearningCompleted } from '../../redux/selectors';
import { changeMoneyService } from '../../service/changeMoneyService';
import { avatar } from '../../helpers';
import { convertMoneyToString } from '../../service/convertorToString';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';
import { closeScopeForm } from '../../redux/actions/forms/openScope';

export const Heads = () => {
    const { scopeId, isOpen } = useSelector(getHeadsForm)
    const money = useSelector(getMoney);
    const learningCompleted = useSelector(getLearningCompleted);
    const scope = useDefineScope(scopeId)
    const [selectedHeadId, setHead] = useState<number>(scope.headId)

    useEffect(() => {
        setHead(scope.headId)
    }, [scope])

    const heads = data.heads.filter(x => x.scopeId === scopeId);
    const dispatch = useDispatch();

    const handleCloseClick = () => {
        dispatch(closeHeadsForm())
    }

    const handleSelectedClick = (headId: number) => {
        setHead(headId);
        !learningCompleted && dispatch(learningProgressNext()); 
    }

    const handleBackClick = () => {
        dispatch(closeHeadsForm())
    }

    const handleConfirmClick = () => {
        changeBuget();
        changeHead();
        dispatch(closeHeadsForm())
        if (!learningCompleted) {
            dispatch(learningProgressNext()); 
            dispatch(closeScopeForm());
        }
    }

    const changeHead = () => {
        const newScope: IScope = {
            scopeId: scopeId,
            headId: selectedHeadId,
            title: scope.title,
            fireHeads: [...scope.fireHeads, scope.headId],
            directions: scope.directions
        }
        // console.log(newScope)
        dispatch(scopesChange(scopeId, newScope))
    }

    const changeBuget = () => {
        const head = heads.find(x => x.headId === selectedHeadId)
        if (head) {
            const newMoney = changeMoneyService(money, -head?.hireheadcost, 0, head.moneyQminus)
            dispatch(moneyChange(newMoney));
        } 
    }

    const renderHeader = () => {
        return <div className={style.header}>
            <div className={style.title}>{`Manage Head of ${scope.title}`}</div>
            <div className={style.description}>{`Learn about the current Head of ${scope.title} or replace them with one from the available candidates.`}</div>
        </div>
    }

    const renderSelectedButton = () => {
        return <div className={style.selected}>
            <SelectedIcon />
            <div className={style.title}>HIRED</div>
        </div>
    }

    const renderHeadButton = (headId: number) => {
        return <div className={style.button}>
            {headId === selectedHeadId ?
                renderSelectedButton() :
                <ClassicButton label={'HIRE'} size={SizeButton.normal} handleClick={() => handleSelectedClick(headId)} classes={style.normal} />}
        </div>
    }

    const renderHeadsScrollBar = () => {
        const cards = heads.map(x => {
            return <div className={style.headScrollbar}>
                <div className={style.card}>
                    <div className={style.avatar}>
                        <img src={avatar(x.avatar)} alt='Head Avatar' />
                        <div className={style.name}>{x.name}</div>
                    </div>
                    <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={style.progress} color={ScoreProgressBarColor.blue} title={"Reputation"} value={x.reputation} maxValue={10}/>
                    <ScoreProgressBar containerStyle={style.progressContainer} titleStyle={style.progress} color={ScoreProgressBarColor.yellow} title={"Expertise in Digital Transformation"} value={x.expertise}  maxValue={10}/>
                    <div className={style.wage}>
                        <div className={style.title}>Quarterly Wage:</div>
                        <div className={style.value}>{convertMoneyToString(x.moneyQminus)}</div>
                    </div>
                    {renderHeadButton(x.headId)}
                </div>
            </div>
        })
        return <HorzScrollableCard item={cards} height={350} dataTut={"Head"}/>
    }

    const renderAction = () => {
        return <div className={style.action}>
            <BackButton label={'BACK'} handleClick={handleBackClick} classes={style.back} />
            <ClassicButton dataTut={"HeadConfirm"} label={'CONFIRM'} disabled={scope.headId===selectedHeadId} size={SizeButton.normal} handleClick={handleConfirmClick} classes={style.confirm} />
        </div>
    }

    return (
        isOpen ?
            <GameForm close={handleCloseClick}>
                <div className={style.heads}>
                    {renderHeader()}
                    {renderHeadsScrollBar()}
                    {renderAction()}
                </div>
            </GameForm> : null
    )
}

export default Heads;
