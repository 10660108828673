import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { ITarget, IScope } from '../../../../models';
import GoalItem from './GoalItem';
import style from './style.module.scss';
import cn from 'classnames';

interface IProps {
    targets: ITarget[];
    scopes: IScope[];
    handelEditItem: (id: number) => void;
    handelDeleteItem: (id: number) => void;
}

const GoalsList: React.FunctionComponent<IProps> = (props) => {

    const { targets, scopes, handelEditItem, handelDeleteItem } = props;

    const goals = targets.map((t, i) => <GoalItem key={i} id={i} target={t} scopes={scopes} handelEdit={handelEditItem} handelDelete={handelDeleteItem} />);

    // return <Scrollbars>
    //     <div className={style.goalsList}>
    //         {goals}
    //     </div>
    // </Scrollbars>;

    return <>
            <Scrollbars style={{ height: '100%' }} className={style.hidden}>
                <div className={style.goalsList}>
                    {goals}
                </div>
            </Scrollbars>

            <div className={cn(style.goalsList, style.show)}>
                {goals}
            </div>
        </>;
}

export default GoalsList;