import { ICityInformation } from "../../../models";
import { Action } from "redux";

export const CITY_INFO_SET = 'CITY_INFO_SET';
export const CITY_INFO_PEOPLE_IMPACT = 'CITY_INFO_PEOPLE_IMPACT';
export const CITY_INFO_BUSINESS_IMPACT = 'CITY_INFO_BUSINESS_IMPACT';
export const CITY_INFO_REPUTATION_SET = 'CITY_INFO_REPUTATION_SET';


export interface ICityInfoSet extends Action {
    cityInfo: ICityInformation;
}

export interface ICityInfoImpactCitizens extends Action {
    impactDelta: number;
}

export interface ICityInfoReputationSet extends Action {
    people: number;
    business: number;
}

export function cityInfoSet(cityInfo: ICityInformation) {
    return { type: CITY_INFO_SET, cityInfo};
}

export function cityInfoPeopleImpact(peopleImpDelta: number) {
    return { type: CITY_INFO_PEOPLE_IMPACT, impactDelta: peopleImpDelta};
}

export function cityInfoBusinessImpact(businessImpDelta: number) {
    return { type: CITY_INFO_BUSINESS_IMPACT, impactDelta: businessImpDelta};
}

export function setReputation(people: number, business: number) {
    return { type: CITY_INFO_REPUTATION_SET, people, business};
}

