import React from 'react';
import style from './style.module.scss';
import Header from '../System/Header';
import { QuotesIcon, BubbleTailEventIcon } from '../../icons';
import ClassicButton from '../UiKit/Buttons/ClassicButton';
import { SizeButton } from '../UiKit/Buttons/common';
import { avatar } from '../../helpers';
import GameForm from '../UiKit/GameForm';
import InfoFooter from '../UiKit/InfoFooter';

interface IProps {
    message: string;
    note?: string | React.ReactNode;
    action: () => void;
}

const AssistantEvent: React.FunctionComponent<IProps> = (props) => {

    const { message, action, note } = props;

    return <GameForm close={() => { }} className={style.form_to_up}>
            <div className={style.Event}>
            <Header breadcrumbs={['Assistant message']} headerTitle={"We've got a situation!"} />

            <div className={style.body}>
                <div className={style.message}>
                    <QuotesIcon className={style.quotesOpen} />                  
                    <span>{message}</span>
                    <QuotesIcon className={style.quotesClose} />
                </div>                


                {note && <div className={style.bodyFooter}>
                    <InfoFooter>{note}</InfoFooter>
                </div>}
            </div>

            <div className={style.footer}>
                <ClassicButton handleClick={action} size={SizeButton.large} label={"Do it"} />

                <div className={style.manager}>
                    <div className={style.label}>
                        <BubbleTailEventIcon />
                        <span className={style.name} >Sasha</span>
                        <span className={style.system}>Chief assistant</span>
                    </div>
                    <img className={style.img} src={avatar('/images/chief_assistant_Sasha.png')} alt="avatar" />
                </div>
            </div>
        </div>
    </GameForm>
}

export default AssistantEvent;