import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { getSelectedSystems, getTimer, getCurrentTeamMembers, getFinMetrics, getCityInfo, getLearningCompleted } from '../../redux/selectors';
import { systemChange, timerPause, openSystemImplForm, assistantNotifyAdd, metricChange, moneyChange, openSystemEventForm } from '../../redux/actions';
import data from "../../data";
import { deepCopy } from '../../helpers';
import AssistantEvent from '../AssistantEvent';
import { MARKETING_BUDGET_MAX, IMoney } from '../../models';
import { calcGameKpi, calcRevenueMax } from '../../service/calcGameScores';
import PermanentTeamNotice from './PermanentTeamNotice';
import { store } from '../../store/configureStore';
import { calcReputation } from '../Game/GameScoresCalc/scores';
import useScopes from '../../hooks/useScopes';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';

const LOGGING = false;
export const TIME_COST_ADVANCED = 14;

function log(message: string) {
    LOGGING && console.log(message);
}

interface IAssitentEvent {
    message: string;
    note?: string | React.ReactNode;
    action: () => void;
}

export default function ProcessesByTimer() {

    const dispatch = useDispatch()
    const [assistantEvent, setAssistantEvent] = useState<IAssitentEvent>();
    const team = useSelector(getCurrentTeamMembers);
    const learningCompleted = useSelector(getLearningCompleted);
    const timer = useSelector(getTimer);
    const systems = useSelector(getSelectedSystems);
    const finMetrics = useSelector(getFinMetrics);
    const cityInfo = useSelector(getCityInfo);
    const scopes = useScopes();

    useEffect(() => {

        // if (systems.length === 0) return;

        const curTime = moment(timer.curDate);

        // Какие-либо изменения в системах
        systems.forEach(s => {
            // console.log(s)
            let mperformance = 0;
            // Идут маркетинговые мероприятия по системе            
            if (s.fistStageDone && s.marketingRunning) {
                const defaultSystem = data.systems.find(ss => ss.id === s.systemId);

                const calcQualitymarketing = (qualitymarketingMax: number): number => {
                    if (s.agency) {
                        const agency = data.scopeAgencies.flatMap(x => x.agencies).find(x => x.id === s.agency?.id)
                        return agency?.qualityMarketing ? agency?.qualityMarketing : 0;
                    } else if (team.findIndex(t => t.id === 7) >= 0) {
                        return qualitymarketingMax;
                    }

                    return 0;
                }
                if (defaultSystem) {
                    const scopeAgency = data.scopeAgencies.find(x => x.scopeId === defaultSystem.scopeId);
                    const qualitymarketingMax = scopeAgency?.qualityMarketingMax ? scopeAgency?.qualityMarketingMax : 0;
                    const moneyQminusBudget = s.moneyMarketingBudget ? s.moneyMarketingBudget : 0;
                    const mbudgetMax = MARKETING_BUDGET_MAX;
                    const coefagency = 0.75
                    const coefbudget = 0.25
                    const qualitymarketing = calcQualitymarketing(qualitymarketingMax);
                    mperformance = qualitymarketing > 0 && mbudgetMax > 0 ? (qualitymarketing / qualitymarketingMax) * coefagency + (moneyQminusBudget / mbudgetMax) * coefbudget : 0;
                    if (s.mperformance !== mperformance)
                        dispatch(systemChange({ ...s, mperformance: mperformance }));
                }
            }
            else if (s.mperformance !== mperformance)
                dispatch(systemChange({ ...s, mperformance: mperformance }));

            // Система не внедряется
            if (s.isProgress && !s.startDate && !s.fistStageDone) {
                log(`System ${s.systemId}: Start implementation: ${s.startDate}`)
                dispatch(systemChange({ ...s, startDate: timer.curDate }));

                // Изменениям бюджет
                const { money } = store.getState().game;
                const newMoney: IMoney = {
                    ...money,
                    budget: money.budget - s.moneyminus
                }
                dispatch(moneyChange(newMoney));

            }
            // Система внедряется, но первый этап не закончен
            else if (s.startDate && s.isProgress && !s.fistStageDone && s.contractor && timer.launch) {
                log(`System ${s.systemId}: Implementation`);

                let updatedFlag = false;
                const updatedSystem = deepCopy(s);

                const startTime = moment(s.startDate);

                const defaultSystem = data.systems.find(ss => ss.id === s.systemId);
                if (!defaultSystem) return;

                // 01s. Signing the contract
                const signingTimeCost = Math.round(s.contractor?.timecost * 0.33)
                const developmentTimeCost = s.contractor?.timecost - signingTimeCost;
                if (curTime.diff(startTime, "days") <= signingTimeCost && !s.required01Done) {
                    log(`System ${s.systemId}: 01. Signing the contract. Time cost ${signingTimeCost}`);

                    if (curTime.diff(startTime, "days") === signingTimeCost) {
                        updatedFlag = true;
                        updatedSystem.lastStepDate = timer.curDate;
                        updatedSystem.required01Done = true;
                    }
                }
                // 02. Administrative procedures
                else if (defaultSystem.required02 && !s.required02Done && learningCompleted) {
                    log(`System ${s.systemId}: 02. Administrative procedures`);
                    // console.log(team.findIndex(t => t.id === 1), s.adminExpert)

                    if (team.findIndex(t => t.id === 1) >= 0 || s.adminExpert) {
                        if (team.findIndex(t => t.id === 1) >= 0 && curTime.diff(s.lastStepDate, "days") <= TIME_COST_ADVANCED) {
                            log(`System ${s.systemId}: 02. Administrative procedures: Use team member.`);
                            if (curTime.diff(s.lastStepDate, "days") === TIME_COST_ADVANCED) {
                                updatedFlag = true;
                                updatedSystem.lastStepDate = timer.curDate;
                                updatedSystem.required02Done = true;
                            }
                        }
                        else if (s.adminExpert && curTime.diff(s.lastStepDate, "days") <= s.adminExpert.expert.timeCost) {
                            log(`System ${s.systemId}: 02. Administrative procedures: Use expert.`);
                            if (curTime.diff(s.lastStepDate, "days") === s.adminExpert.expert.timeCost) {
                                updatedFlag = true;
                                updatedSystem.lastStepDate = timer.curDate;
                                updatedSystem.required02Done = true;
                            }
                        }
                    }
                    else {
                        log(`System ${s.systemId}: 02. Administrative procedures: required, no expert/team, pause.`);
                        // Остановить процесс внедрения, таймер на паузу, показать уведомление от помощника:
                        dispatch(timerPause());
                        setAssistantEvent({
                            message: `Choose the expert to take care of administrative procedures change needed for the system "${defaultSystem.title}".`,
                            note: <PermanentTeamNotice closeParent={() => setAssistantEvent(undefined)} />,
                            action: () => {
                                dispatch(openSystemImplForm(s.systemId, defaultSystem.scopeId));
                                dispatch(systemChange({ ...s, isProgress: false }));
                                setAssistantEvent(undefined);
                            }
                        });
                    }
                }
                // 03. Infrastructure changes
                else if (defaultSystem.required03 && !s.required03Done && learningCompleted) {

                    log(`System ${s.systemId}: 03. Infrastructure changes`);

                    if (team.findIndex(t => t.id === 2) >= 0 || s.infraExpert) {
                        if (team.findIndex(t => t.id === 2) >= 0 && curTime.diff(s.lastStepDate, "days") <= TIME_COST_ADVANCED) {
                            log(`System ${s.systemId}: 03. Infrastructure changes: Use team member.`);
                            if (curTime.diff(s.lastStepDate, "days") === TIME_COST_ADVANCED) {
                                updatedFlag = true;
                                updatedSystem.lastStepDate = timer.curDate;
                                updatedSystem.required03Done = true;
                            }
                        }
                        else if (s.infraExpert && curTime.diff(s.lastStepDate, "days") <= s.infraExpert.expert.timeCost) {
                            log(`System ${s.systemId}: 03. Infrastructure changes: Use expert.`);
                            if (curTime.diff(s.lastStepDate, "days") === s.infraExpert.expert.timeCost) {
                                updatedFlag = true;
                                updatedSystem.lastStepDate = timer.curDate;
                                updatedSystem.required03Done = true;
                            }
                        }
                    }
                    else {
                        // Остановить процесс внедрения, таймер на паузу, показать уведомление от помощника.
                        log(`System ${s.systemId}: 03. Infrastructure changes: required, no expert/team, pause.`);
                        dispatch(timerPause());
                        setAssistantEvent({
                            message: `Choose the expert to take care of infrastructure changes needed for the system "${defaultSystem.title}".`,
                            note: <PermanentTeamNotice closeParent={() => setAssistantEvent(undefined)} />,
                            action: () => {
                                dispatch(openSystemImplForm(s.systemId, defaultSystem.scopeId));
                                dispatch(systemChange({ ...s, isProgress: false }));
                                setAssistantEvent(undefined);
                            }
                        });
                    }
                }
                // Check contractor fault
                else if (!updatedSystem.contractorSuccess && learningCompleted) {

                    if (s.contractorFaultDelay && curTime.diff(s.lastStepDate, "days") <= s.contractorFaultDelay) {
                        log(`System ${s.systemId}: Contractor fault: Waiting.`);

                        if (curTime.diff(s.lastStepDate, "days") === s.contractorFaultDelay) {
                            updatedFlag = true;
                            updatedSystem.required01Done = false;
                            updatedSystem.isProgress = false;
                            updatedSystem.contractorSuccess = undefined;
                            updatedSystem.contractorFaultDelay = undefined;
                            // Остановить процесс внедрения, таймер на паузу, показать уведомление от помощника
                            log(`System ${s.systemId}: 04. Contractor fault, pause.`);
                            dispatch(timerPause());
                            setAssistantEvent({
                                message: `Choose a new contractor to implement the system "${defaultSystem.title}".`,
                                action: () => {
                                    dispatch(openSystemImplForm(s.systemId, defaultSystem.scopeId));
                                    setAssistantEvent(undefined);
                                }
                            });
                        }
                    }
                    else {
                        const reputation = data.scopesContractor.flatMap(x => x.contractors).find(x => x.id === s.contractor?.contractorId)?.reputation
                        if (reputation) {
                            const supplieroff = (1 - reputation * 0.1) * 0.33 > 0.5;
                            updatedFlag = true;
                            if (supplieroff) {
                                // console.log(`System ${s.systemId}: Contractor fault.`);
                                const delay = 0.25 * 0.67 * s.contractor.timecost;
                                updatedSystem.contractorFaultDelay = delay;
                                updatedSystem.contractorSuccess = false;
                            }
                            else {
                                updatedSystem.contractorSuccess = true;
                            }
                        }
                    }
                    // 0.67
                }
                else if (curTime.diff(s.lastStepDate, "days") <= developmentTimeCost) {
                    log(`System ${s.systemId}: 01d. Development.`);

                    if (curTime.diff(s.lastStepDate, "days") === developmentTimeCost) {
                        updatedFlag = true;
                        updatedSystem.fistStageDone = true;
                        updatedSystem.isProgress = false;

                        const kpis = calcGameKpi(s);
                        updatedSystem.kpis = { ...kpis };
                        const updatedSystems = systems.filter(x => x.systemId !== s.systemId)
                        updatedSystems.push(updatedSystem);

                        const { revenue } = calcRevenueMax(updatedSystem, kpis?.cov, kpis?.er)

                        // console.log(`System ${s.systemId}: KPIs after impl:`, { ...kpis });
                        if (!cityInfo) return;
                        const { changePeople, changeBusiness } = calcReputation(cityInfo, updatedSystems, finMetrics, scopes, dispatch);

                        dispatch(timerPause());
                        !learningCompleted && dispatch(learningProgressNext());
                        dispatch(openSystemEventForm(`Sir, I am pleased to notify that system 'none' implementation has been completed`, revenue, s.moneyQminus, changePeople, changeBusiness, 'EndOfSystem', defaultSystem.title, defaultSystem.id, defaultSystem.scopeId));
                    }
                }

                if (updatedFlag)
                    dispatch(systemChange(updatedSystem));
            }
            // Система обновляется
            else if (s.startDate && s.fistStageDone && timer.launch && s.startUpgradeDate) {

                const startUpgrade = moment(s.startUpgradeDate);
                const curTime = moment(timer.curDate);
                const upgradeTimeCost = s.upgradeEvents?.flatMap(x => x.timeCost).reduce((a, b) => a + b, 0);
                const upgradeSystemTypeTimeCost = s.newSystemType?.scores.timecostuptype

                const defaultSystem = data.systems.find(ss => ss.id === s.systemId);
                if (!defaultSystem) return;

                const timeFromUpdateStart = curTime.diff(startUpgrade, "days");

                if (upgradeTimeCost && upgradeTimeCost - timeFromUpdateStart >= 0) {
                    log(`System ${s.systemId}: Run Upgrade. Time left: ${upgradeTimeCost - timeFromUpdateStart}`);

                    if (upgradeTimeCost - timeFromUpdateStart === 0 && s.upgradeEvents) {
                        log(`System ${s.systemId}: Done Upgrade. Current usability: ${s.usability}. Usability delta: ${s.upgradeEvents.flatMap(x => x.upUsability).reduce((a, b) => a + b, 0)}`);

                        const newSystem = {
                            ...s,
                            usability: s.usability + s.upgradeEvents.flatMap(x => x.upUsability).reduce((a, b) => a + b),
                            startUpgradeDate: undefined,
                            upgradeEvents: undefined,
                            usedUpgradeEvents: [...s.usedUpgradeEvents, ...s.upgradeEvents.flatMap(x => x.id)]
                        };
                        const kpis = calcGameKpi(newSystem);
                        newSystem.kpis = { ...kpis };

                        dispatch(systemChange(newSystem));
                        if (!learningCompleted){
                            dispatch(learningProgressNext());
                            dispatch(timerPause());
                        }
                        dispatch(assistantNotifyAdd({ message: `Sir, I am pleased to notify that 'System ${defaultSystem?.title} upgrade' has been completed.`, notifyId: moment().valueOf() }));
                    }
                }

                if (upgradeSystemTypeTimeCost && upgradeSystemTypeTimeCost - timeFromUpdateStart >= 0) {
                    log(`System ${s.systemId}: Run Upgrade SystemType. Time left: ${upgradeSystemTypeTimeCost - timeFromUpdateStart}`);

                    if (upgradeSystemTypeTimeCost - timeFromUpdateStart === 0 && s.newSystemType) {
                        log(`System ${s.systemId}: Done Upgrade SystemType`);
                        const usedTypes = [...s.usedSystemTypes, s.newSystemType.title];
                        const newSystemType = {
                            ...s,
                            systemType: { ...s.newSystemType },
                            startUpgradeDate: undefined,
                            newSystemType: undefined,
                            usedSystemTypes: usedTypes
                        };
                        const kpis = calcGameKpi(newSystemType);
                        newSystemType.kpis = { ...kpis };

                        dispatch(assistantNotifyAdd({ message: `Sir, I am pleased to notify that 'System ${defaultSystem?.title} upgrade' has been completed.`, notifyId: moment().valueOf() }));
                        dispatch(systemChange(newSystemType));
                    }
                }
            }

        });

        // Какие-либо изменения в Финансовых метриках
        finMetrics.forEach(fm => {

            if (fm.newMN !== undefined && !fm.newMNStartDate) {
                dispatch(metricChange({ ...fm, newMNStartDate: timer.curDate }));
            }

            if (fm.newMN && fm.newMNStartDate) {
                const startTime = moment(fm.newMNStartDate);
      
                if (curTime.diff(startTime, "days") === fm.timecostMN) {

                    const changeMN = { ...fm, currentMN: fm.newMN, newMN: undefined, newMNStartDate: undefined };
                    dispatch(metricChange(changeMN));
                    dispatch(assistantNotifyAdd({ message: `Sir, I am pleased to notify that '${changeMN.name}' has been changed. New value is ${changeMN.currentMN}.`, notifyId: moment().valueOf() }))
                }
            }

        });

    }, [dispatch, systems, timer, team, finMetrics, cityInfo, scopes, learningCompleted]);

    return <>
        <div />
        {assistantEvent && learningCompleted && <AssistantEvent message={assistantEvent.message} action={assistantEvent.action} note={assistantEvent.note} />}
    </>;
}