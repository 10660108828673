import { useState, useCallback } from "react";

export function useClientRect() {
    const [rect, setRect] = useState<any>(null);
    const ref = useCallback(node => {
        if (node !== null && rect === null) {
            setRect(node);
        }
    }, []);
    return [rect, ref];
} 