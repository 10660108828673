import React, { useState } from 'react';
import style from './style.module.scss';
import data from '../../../data';
import { useDispatch, useSelector } from 'react-redux';
import { getMoney, getLearningCompleted} from '../../../redux/selectors';
import { moneyChange } from '../../../redux/actions';
import { changeMoneyService } from '../../../service/changeMoneyService';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { SizeButton, ColorButton } from '../../UiKit/Buttons/common';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import Header from '../Header';
import { convertMoneyToString } from '../../../service/convertorToString';
import { useAnalysisProcedures } from './useAnalysisProcedures';
import BackButton from '../../UiKit/Buttons/BackButton';
import { learningProgressNext } from '../../../redux/actions/game/learningProgress';

const moneyminusXnYnZn06 = 200; // III. Переменные, стр. 93
const COLORS = ['#00aaff', '#ffd400', '#cc4033', '#FF8042'];

export interface IProps {
    systemId: number;
    breadcrumbs: string[];
    showResult?: boolean;
    analysisDone: () => void;
    backClick: () => void;
}
export const UserAnalysis = (props: IProps) => {
    const { systemId, breadcrumbs, showResult, analysisDone, backClick } = props;

    const state = useAnalysisProcedures();

    const dispatch = useDispatch();
    const money = useSelector(getMoney);

    const curSystem = data.systems.filter(s => s.id === systemId)
    const types = curSystem[0].types;

    const diagramData = types.map((type) => ({ name: type.title, value: type.quizPercent }));

    const sum = types.map(m => m.quizPercent)
        .reduce((e: any, current: number) => current + e);

    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

    const handleClick = () => {
        const newMoney = changeMoneyService(money, -moneyminusXnYnZn06, 0, 0)
        dispatch(moneyChange(newMoney));
        setIsFirstStep(false);
    };
    const defaultView = (
        <>
            <div className={style.info}>This is a part of the preparation that runs before the start of the implementation process.
            You can request a user analysis — then wait until it is ready.
            The analysis will determine an optimal type of system for the user and improve future user experience.
            </div>
            <div className={style.menu}>
                <div className={style.procedureCostName}>
                    Procedure Cost:
                </div>
                <div className={style.procedureCostValue}>
                    {convertMoneyToString(moneyminusXnYnZn06)}
                </div>
            </div>
            <div className={style.runTestBtn}>
                <BackButton label={'Back'} handleClick={backClick} />
                <ClassicButton handleClick={handleClick} size={SizeButton.large} label={"Run analysis"} />
            </div>
        </>);

    const advancedView = (<>
        <div className={style.info}>Your permanent analyst has already done the analysis. </div>
        <div className={style.gotItBtn}>
            <ClassicButton handleClick={handleClick} size={SizeButton.large} label={"Got it"} />
        </div>
    </>);

    const legendView = () => <div id={style.legendContainer}>
        {diagramData.map((e: any, index: number) => <div key={index} className={style.legendItem}>
            <span className={style.legendColor} style={{
                background: `${COLORS[index % COLORS.length]}`
            }}>&nbsp;</span >

            <div className={style.legendInfo} >
                <div className={style.legendPercent}>{`${((e.value / sum) * 100).toFixed(0)}%`}</div>
                <div className={style.legendText}>{e.name}</div>
            </div>

        </div>)}
    </div>;

    const firstStepView = <> {state === 'Default' ? defaultView : advancedView}</>;

    const secondStepView = <>
        <div className={style.info}>Your permanent analyst has already done the analysis.
         </div>
        <div className={style.diagramMenu}>

            <ResponsiveContainer width={'100%'} height={'100%'}>
                <PieChart onMouseEnter={() => { }}>
                    <Pie dataKey="value"
                        data={diagramData}
                        cx={75}
                        cy={78}
                        labelLine={false}
                        innerRadius={54} outerRadius={80}
                        fill="#8884d8">
                        {
                            diagramData.map((entry: any, index: number) => <Cell key={index} fill={COLORS[index % COLORS.length]} stroke={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Legend content={legendView} />s

                </PieChart>

            </ResponsiveContainer>
        </div>
        <div className={style.closeBtn}>
            <ClassicButton label={'Close'} size={SizeButton.large} handleClick={() => { analysisDone(); }} color={ColorButton.azure} classes={'margin'} />
        </div>
    </>

    return (<>
        <div className={style.header}>
            <Header breadcrumbs={breadcrumbs} headerTitle={"User analysis"} />

        </div>
        <div className={style.userAnalysis}>
            {isFirstStep && !showResult ? firstStepView : secondStepView}
        </div>
    </>);
}

export default UserAnalysis;
