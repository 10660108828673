import { Action } from "redux";
import { OPEN_KPI_FORM, CLOSE_KPI_FORM, IOpenKpiAction } from "../../actions";

export interface IState {
    isOpen: boolean;
    systemId: number
    scopeId: number
}

export const initial: IState = {
    isOpen: false,
    systemId: 0,
    scopeId: 0
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case OPEN_KPI_FORM:
            const { systemId, scopeId } = action as IOpenKpiAction
            return {
                ...state,
                isOpen: true,
                systemId,
                scopeId
            };

        case CLOSE_KPI_FORM:
            return {
                ...state,
                isOpen: false,
            };

        default:
            return state;
    }
}