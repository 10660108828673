import React from 'react';
import style from './style.module.scss';
import SpecialistView from './SpecialistView';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTeamMembers } from '../../../../redux/selectors';
import { closeGameDashboard } from '../../../../redux/actions/forms/openGameDashboard';
import { openTeamForm } from '../../../../redux/actions';
import TeamHeader from '../../../Team/TeamHeader';
import TeamList from '../../../Team/TeamList';

const PermanentTeam: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const team = useSelector(getCurrentTeamMembers);

    const manageTeam = () => {
        dispatch(closeGameDashboard());
        dispatch(openTeamForm());
    }

    return (<>
        {team.length > 0 ? <TeamHeader backgroundColor={'#003c80'} manageTeamAction={manageTeam} /> : <TeamHeader backgroundColor={'#003c80'} />}
        <div className={style.permanentTeam}>
            {team.length > 0 ?
                <div className={style.team}>
                    <div className={style.teamInfo}>
                        {team.map((tm, i) =>
                            <SpecialistView key={i} specialist={tm} />)}
                    </div>
                </div> : 
                <TeamList paddingReset={true} />}
        </div>
    </>);
}

export default PermanentTeam;