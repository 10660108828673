import { createStore, applyMiddleware, Store } from "redux";
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga'
import * as root from "../redux/reducers";
import { History } from 'history';
import { saveState, loadState } from "./persistedStore";
import { createBrowserHistory as createHistory } from 'history';
import { configureSaga } from "../redux/sagas";
import { saveStats, /*logger as loggerMiddleware*/ } from './../helpers';

const sagaMiddleware = createSagaMiddleware();
export const history = createHistory();
export const saveStatsMiddleware = saveStats;

function configureStore(history: History) {
    const routersMiddleware = routerMiddleware(history)

    const initialState = loadState();

    const store = createStore(
        root.appReducer,
        initialState,
        applyMiddleware(sagaMiddleware, routersMiddleware, /*loggerMiddleware,*/ saveStatsMiddleware)
    );
    sagaMiddleware.run(configureSaga);

    store.subscribe(() => saveState(store));

    return store;
};

export interface IStore extends Store<root.IState> { }

export const store = configureStore(history);