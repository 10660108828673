import React from 'react';
import { ITeamMember } from '../../../models';
import style from './style.module.scss';
import ClassicButton from '../../UiKit/Buttons/ClassicButton';
import { avatar } from '../../../helpers';
import { convertMoneyToString } from '../../../service/convertorToString';

interface ISpecialistInfoProsps {
    specialist: ITeamMember;
    isHired: boolean;
    handelHire: (id: number) => void;
    handelFire: (id: number) => void;
    dataTutHire: any;
    dataTut: any;
}

const SpecialistInfo: React.FunctionComponent<ISpecialistInfoProsps> = (props) => {
    const { specialist, isHired, handelHire,  handelFire, dataTutHire, dataTut} = props;

    return (
        <div className={style.specialistInfo} data-tut={dataTut}>
            <div className={style.specialistInfoLeft}>
                <h1>{specialist.position}</h1>
                <div className={style.description}>{specialist.description}</div>
                <div className={style.infoContainter}>
                    <div className={style.quarterlyWage}>
                        <div className={style.quarterlyWageName}>
                            Quarterly Wage:
                        </div>
                        <div className={style.quarterlyWageValue}>
                            {convertMoneyToString(specialist.quarterlyWage)}
                        </div>
                    </div>

                    <div className={style.hiringCost}>
                        <div className={style.hiringCostName}>
                            Hiring Cost:
                        </div>
                        <div className={style.hiringCostValue}>
                            {convertMoneyToString(specialist.hiringCost)}
                        </div>
                    </div>

                    <div className={style.quarterlyWage}>
                        <div className={style.quarterlyWageName}>
                            Quarterly Wage:
                        </div>
                        <div className={style.quarterlyWageValue}>
                            {convertMoneyToString(specialist.quarterlyWage)}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={style.specialistInfoRight}>
                <img src={avatar(specialist.avatar)} alt="Specialist avatar" />

                <div className={style.hireButton}>
                    <ClassicButton dataTut={dataTutHire} label={isHired ? 'Fire' : 'Hire'} handleClick={() => {isHired ? handelFire(specialist.id) : handelHire(specialist.id)}} />
                </div>
            </div>
        </div>
    );
}

export default SpecialistInfo;
