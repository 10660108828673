import { ILoginDoneAction} from "../actions/auth";
import { Action } from "redux";
import { LOGIN_INIT, LOGIN_DONE, LOGIN_FAIL, LOGOUT } from "../actions";
import { printErrorResponse } from "../../api/common";
import { IFailAction } from "../../models/common";

export interface IState {
    isAuthorized: boolean;
    inProgress: boolean;
    error: string | null;
    accessToken: string | null;
    authenticationScheme: string | null;
}

export const initial: IState = {
    isAuthorized: false,
    inProgress: false,
    error: null,
    accessToken: null,
    authenticationScheme: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case LOGIN_INIT:
            return {
                ...state,
                isAuthorized: false,
                inProgress: true,
                error: null
            };

        case LOGIN_DONE:

            const { result } = action as ILoginDoneAction;
            return {
                ...state,
                isAuthorized: true,
                inProgress: false,
                accessToken: result.accessToken,
                authenticationScheme: result.authenticationScheme,
                error: null
            };

        case LOGIN_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                isAuthorized: false,
                inProgress: false,
                error: printErrorResponse(error)
            };

        case LOGOUT:
            return initial;

        default:
            return state;
    }
}