import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import "react-input-range/lib/css/index.css"
import { useDispatch } from 'react-redux';
import { systemChange } from '../../../../redux/actions';
import RangeSlider from '../../../RangeSlider';
import { ISystem, MARKETING_BUDGET_MIN, MARKETING_BUDGET_MAX } from '../../../../models';

interface IRangeSlider {
    title: string;
    system: ISystem;
    isShowAction?: boolean;
    onCancelClick?: () => void;
}

const RangeSliderBudget: React.FunctionComponent<IRangeSlider> = (props) => {
    const { title, system, isShowAction, onCancelClick } = props;
    const dispatch = useDispatch();
    const [value, setValue] = useState<any>(system.moneyMarketingBudget);
    const [startValue, setStartValue] = useState<any>();

    useEffect(() => {
        if (system.moneyMarketingBudget) {
            if(system.moneyMarketingBudget >= MARKETING_BUDGET_MIN) 
                setValue(system.moneyMarketingBudget);
            else
                setValue(MARKETING_BUDGET_MIN);

            setStartValue(system.moneyMarketingBudget);
        }
        else {
            setValue(MARKETING_BUDGET_MIN);
            setStartValue(MARKETING_BUDGET_MIN);
        }
    }, [system])

    const handleChange = (value: any) => {
        setValue(value);
        if (!isShowAction) {
            dispatch(systemChange({...system, moneyMarketingBudget: value}));
        }
    }

    const handleSubmitBugetChanges = () => {
        dispatch(systemChange({...system, moneyMarketingBudget: value}));
    }

    const handleCancelBugetChanges = () => {
        setValue(startValue)
        if (onCancelClick) {
            onCancelClick()
        }
    }

    const renderRange = () => {
        return <div className={style.range}>
            {value && <RangeSlider title={title} isShowAction={isShowAction} maxValue={MARKETING_BUDGET_MAX}
                minValue={MARKETING_BUDGET_MIN} value={value} startValue={startValue} isMoney={true}
                onChange={handleChange} onSubmit={handleSubmitBugetChanges} onCancelClick={handleCancelBugetChanges} />}
        </div>
    }

    return renderRange();
}

export default RangeSliderBudget;
