import { ISystem } from "./system";

/**
 * Финансовые метрики (VI. Финансовые метрики)
 */
export interface IFinMetric {
    id: number;
    name: string;
    description: string;
    availableAfterSystemId: number;
    changable: boolean;
    canOff: boolean;
    minMN: number;
    maxMN: number;
    baseMN: number;
    currentMN: number;
    newMN?: number;
    newMNStartDate?: number;
    offMN: number;
    coefpeopleMN: number;
    coefbusinessMN: number;
    timecostMN: number;
    coefmoneyQplusMN: number;
    coefmoneyQminusMN: number;
}

export enum StateFinMetricEnum {
    ON, OFF, LOCK, WAIT
}

export function getCurrentMN(fm: IFinMetric, systems: ISystem[]) {
    let currentMN = fm.baseMN;
    if ((fm.availableAfterSystemId > 0 && systems.findIndex(s => s.systemId === fm.availableAfterSystemId) < 0) || !fm.changable) currentMN = fm.offMN;
    else if (fm.currentMN && fm.currentMN > 0) currentMN = fm.currentMN;

    return currentMN;
}

export function getStateFinMetric(fm: IFinMetric, systems: ISystem[]) {
    if (fm.availableAfterSystemId > 0 && systems.findIndex(s => s.systemId === fm.availableAfterSystemId) < 0)
        return StateFinMetricEnum.LOCK;
    else if (fm.changable) {
        return StateFinMetricEnum.ON
    } else {
        return StateFinMetricEnum.OFF
    }
}