import React from 'react';
import style from './style.module.scss';

interface IProps {
    title: string;
    value: string;
}

const CostItem: React.FunctionComponent<IProps> = (props) => {

    const { title, value } = props;

    return <div className={style.costItem}>
            <span className={style.costTitle}>{title}</span>
            <span className={style.costValue}>{value}</span>
        </div>;
}

export default CostItem;