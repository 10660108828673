import React from 'react';
import style from './style.module.scss';
import AgencyCard from '../AgencyCard';
import HorzScrollableCard from '../../../UiKit/HorzScrollableCard';
import { IAgency } from '../../../../models/agency';
import { ISystem } from '../../../../models/system';

interface IProps {
    agencies: IAgency[]
    qualityMarketingMax: number;
    agencyId?: number
    system: ISystem;
}

export const AgancyCardScroll = (props: IProps) => {
    const { agencies, agencyId, qualityMarketingMax, system } = props;
    const renderAgency = () => {
        const cards =
            agencies.map((x, index) => {
                return <div key={index} className={style.agencyScrollbar}>
                    <AgencyCard agency={x} isSelected={x.id === system.agency?.id} qualityMarketingMax={qualityMarketingMax} system={system} isShowBuget={agencyId === undefined} />
                </div>
            })

        return cards && <HorzScrollableCard item={cards} height={350} dataTut={"MarketingBlock"} />
    }

    return renderAgency()
}

export default AgancyCardScroll;