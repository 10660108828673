import { ITarget, IScope, getKpiFromList, ISystem } from "../models";

export function calcGoalsAchievementRate(targets: ITarget[], scopes: IScope[], systems: ISystem[]) {
    let resultAchievement = 0
    targets.forEach(goal => {
        resultAchievement += calcGoalAchievementRate(goal, scopes, systems);
    });

    return resultAchievement > 0 ? Math.min(resultAchievement / targets.length, 1) : 0;
}

export function calcGoalAchievementRate(target: ITarget, scopes: IScope[], systems: ISystem[]) {
    let resultAchievement = 0

    const goalSys = scopes.find(si => si.scopeId === target.scopeId);
    if (!goalSys) return 0;

    const goalDirection = goalSys.directions?.find(dir => dir.directionId === target.directionId);
    if (!goalDirection) return 0;

    const commonTarget = goalDirection.commonTargets.find(ct => ct.commonTargetId === target.commonTargetId);
    if (!commonTarget) return 0;

    const digitalTarget = commonTarget.digitalTargets.find(dt => dt.digitalTargetId === target.digitalTargetId);
    if (!digitalTarget) return 0;

    const sys = systems.find(sys => sys.systemId === commonTarget.systemId);
    if (sys && sys.kpis) {
        resultAchievement += Math.min(getKpiFromList(sys.kpis, digitalTarget.kpi.code) / digitalTarget.kpi.value, 1);
    }

    return resultAchievement > 0 ? resultAchievement : 0;
}