import React from 'react';
import style from '../style.module.scss';
import { ISystemType } from '../../../../models';
import NextStepCard from '../../NextStepCard';

interface IProps {
    types: ISystemType[];
    handleSelectedType: (st: ISystemType) => void;
}

const SystemTypeStep: React.FunctionComponent<IProps> = (props) => {

    const { types, handleSelectedType } = props;
    return <div className={style.formCenter} data-tut={"SystemTypeStep"}>
        <p className={style.textInfoPadding}>Step 1: Choose the type of system to implement</p>

        {types.map((t,i)=><NextStepCard key={i} title={t.title} subTitle={t.description} handleClick={()=>handleSelectedType(t)} />)}

    </div>
}

export default SystemTypeStep;