import React from 'react';
import cn from 'classnames';
import data from '../../data';
import style from './style.module.scss';
import { ITeamMember, IMoney } from '../../models';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTeamMembers, getMoney, getLearningCompleted} from '../../redux/selectors';
import SpecialistInfo from './SpecialistInfo';
import { teamChange, moneyChange } from '../../redux/actions';
import BackButton from '../UiKit/Buttons/BackButton';
import { learningProgressNext } from '../../redux/actions/game/learningProgress';

interface IProps {
    handleBackClick?: () => void;
    paddingReset?: boolean;
}

const TeamList: React.FunctionComponent<IProps> = (props) => {

    const { handleBackClick, paddingReset = false } = props;
    const dispatch = useDispatch();
    const money = useSelector(getMoney);
    const learningCompleate = useSelector(getLearningCompleted);
    const candidates: ITeamMember[] = data.teamMembers;
    const currentTeam = useSelector(getCurrentTeamMembers);

    const hire = (id: number) => {
        const hireMember = candidates.find(c => c.id === id);
        if(hireMember) {
            changeBuget(hireMember);
            const newTeam = [...currentTeam, hireMember];
            dispatch(teamChange(newTeam));
            !learningCompleate && dispatch(learningProgressNext()); 
        }
    }

    const fire = (id: number) => {
        const fireMemberIndex = currentTeam.findIndex(tm => tm.id === id);
        if(fireMemberIndex >= 0) {
            const newTeam = [...currentTeam];
            newTeam.splice(fireMemberIndex, 1);
            dispatch(teamChange(newTeam));
        }
    }

    const changeBuget = (member: ITeamMember) => {
        const budget = money.budget - member.hiringCost;      
        const newMoney: IMoney = {
            ...money,
            budget
        }
        dispatch(moneyChange(newMoney));
    }
    
    return <div className={cn(style.team, paddingReset ? style.paddingReset : '')}>
            {candidates.map((tm, i) => <SpecialistInfo dataTut={i === 0 ? "PermanentTeamFirst" : ""} dataTutHire={i === 0 ? "PermanentTeamHire" : ""} key={i} specialist={tm} handelHire={hire} handelFire={fire} isHired={currentTeam.findIndex(t => t.id === tm.id) >= 0} />)}
                
            {handleBackClick && <BackButton label={'Back'} handleClick={handleBackClick}  /> } 
        </div>;
}

export default TeamList;