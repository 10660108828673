import React from 'react';
import style from './style.module.scss';
import Scrollbars from 'react-custom-scrollbars';

interface IProps {
    item: React.ReactNode[];
    height?: number;
    dataTut?: any;
}

const HorzScrollableCard: React.FunctionComponent<IProps> = (props) => {
    const { item, height, dataTut } = props;
    return <div className={style.horzScrollableCard} data-tut={dataTut}>

        <Scrollbars style={{ height: height ? `${height}px` : '360px', minWidth: 'inherit' }}
            renderTrackHorizontal={props => <span {...props} className={style.scrollbarsScroller} />}
            renderView={props => <span {...props} className={style.scrollbarContent} />}>
            {item.map((card: any, i: number) => <div className={style.card} key={i} >
                <div className={style.cardContent}>
                    {card}
                </div>
            </div>)}
        </Scrollbars>


    </div >;
}

export default HorzScrollableCard;