import { Action } from "redux";
import { ICityInformation } from "../../../models";
import { CITY_INFO_SET, CITY_INFO_PEOPLE_IMPACT, CITY_INFO_BUSINESS_IMPACT, CITY_INFO_REPUTATION_SET, ICityInfoImpactCitizens, ICityInfoReputationSet, ICityInfoSet } from "../../actions/game/cityInfo";

export const initial: ICityInformation = {
    cityId: -1,
    history: '',
    people: 0,
    peopleBase: 0,
    peopleText: "",
    business: 0,
    businessBase: 0,
    businessText: "",
    money: {
        budget: 0,
        moneyQplus: 0,
        moneyQminus: 0
    },
    moneyText: "",
    weather: "",
    sphereSituation:[],
    transportTypes: ''
}

export const reducer = (state: ICityInformation = initial, action: Action): ICityInformation => {
  
    switch (action.type) {
        case CITY_INFO_SET:
            const { cityInfo } = action as ICityInfoSet;
            return {        
                ...state,       
                ...cityInfo,
                money: {...cityInfo.money}
            };  
            
        case CITY_INFO_PEOPLE_IMPACT:
                const peopleImpact = action as ICityInfoImpactCitizens;
                return {               
                    ...state,
                    peopleBase: state.peopleBase + peopleImpact.impactDelta
                };    

        case CITY_INFO_BUSINESS_IMPACT:
            const businessImpact = action as ICityInfoImpactCitizens;
                return {               
                    ...state,
                    businessBase: state.businessBase + businessImpact.impactDelta
                }; 

        case CITY_INFO_REPUTATION_SET:
            const reputation = action as ICityInfoReputationSet;
            return {               
                ...state,
                business: reputation.business,
                people: reputation.people
            }; 
        
        default:
            return initial;
    }
};