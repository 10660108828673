import { Action } from "redux";

export const OPEN_CITYINFO_FORM = 'OPEN_CITYINFO_FORM';
export const CLOSE_CITYINFO_FORM = 'CLOSE_CITYINFO_FORM';

export interface IOpenCityForm extends Action {
    isOpenAgain?: boolean
}

export function openCityInfoForm(isOpenAgain?: boolean) {
    return { type: OPEN_CITYINFO_FORM, isOpenAgain } as IOpenCityForm;
}

export function closeCityInfoForm() {
    return { type: CLOSE_CITYINFO_FORM };
}
