import { Action } from "redux";

export const OPEN_UPGRADE_FORM = 'OPEN_UPGRADE_FORM';
export const CLOSE_UPGRADE_FORM = 'CLOSE_UPGRADE_FORM';

export interface IOpenUpgradeAction extends Action {
    systemId: number;
    scopeId: number
}

export function openUpgradeForm(systemId: number, scopeId: number) {
    return { type: OPEN_UPGRADE_FORM, systemId, scopeId };
}

export function closeUpgradeForm() {
    return { type: CLOSE_UPGRADE_FORM } ;
}
