import { Action } from "redux";
import { SYSTEMS_CHANGE, SYSTEM_CHANGE, ISystemsChangeAction, ISystemChangeAction } from "../../actions";
import { ISystem } from "../../../models/system";

export const initial: ISystem[] = []

export const reducer = (state: ISystem[] = initial, action: Action): ISystem[] => {
    switch (action.type) {
        case SYSTEMS_CHANGE: {
            const { systems } = action as ISystemsChangeAction;
            return [...systems]
        }

        case SYSTEM_CHANGE: {
            const { system } = action as ISystemChangeAction;

            return state.map(item => {
                if (item.systemId !== system.systemId) {
                  return item
                }
                return {
                  ...item,
                  ...system
                }
              });
        }
        default:
            return state;
    }
}