import { Action } from "redux";

export const GAME_FINISHED = 'GAME_FINISHED';


export interface IGameFinished extends Action {
}

export function gameFinished() {
    return { type: GAME_FINISHED };
}