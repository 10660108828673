import { ISystem, IKpiList } from "../models";
import data from "../data";

export interface IRevenue {
    revenueXnYnZnMax: number
    revenue: number
}

export const initial: IKpiList = {
    nps: 0,
    csi: 0,
    fcr: 0,
    er: 0,
    cov: 0
}

export const initialRevenue: IRevenue = {
    revenueXnYnZnMax: 0,
    revenue: 0
}

function getCoverageBase(system: ISystem | undefined) {
    if(!system || !system.systemType) return 0;

    return system.systemType.scores.coverageBase;
}

export const calcGameKpi = (system: ISystem | undefined): IKpiList => {
    if (!system) return initial;

    const systemKpiInfo = data.kpiCoeffs.find(si => si.systemId === system.systemId);
    if (!systemKpiInfo) return initial;

    

    const fcr = system.usability * systemKpiInfo.usabilityxFCR + (system.kpisImpact && system.kpisImpact.fcr ? system.kpisImpact.fcr : 0);
    const csi = system.usability * systemKpiInfo.usabilityxCSI + fcr * systemKpiInfo.FCRxCSI + (system.kpisImpact && system.kpisImpact.csi ? system.kpisImpact.csi : 0);
    const nps = csi * systemKpiInfo.CSIxNPS + (system.kpisImpact && system.kpisImpact.nps ? system.kpisImpact.nps : 0);
    const er = csi * systemKpiInfo.CSIxER + (system.kpisImpact && system.kpisImpact.er ? system.kpisImpact.er : 0);
    const cov = nps * systemKpiInfo.COVpeople * systemKpiInfo.NPSxCOV + (system.mperformance ? system.mperformance : 0) * systemKpiInfo.marketingxCOV 
        + getCoverageBase(system) + calcCoverageByEvents(system) + (system.kpisImpact && system.kpisImpact.cov ? system.kpisImpact.cov : 0);
  

    return { 
        fcr: fcr ? Math.min(fcr, 100) : 0, 
        csi: csi ? Math.min(csi, 100) : 0, 
        nps: nps ? Math.min(nps, 100) : 0, 
        er: er ? Math.min(er, 100) : 0, 
        cov: cov ? Math.min(cov, 100) : 0 
    }
}

function calcCoverageByEvents(system: ISystem) {
    const events = data.systems.find(x => x.id === system.systemId)?.events;
    if(!events || events.length === 0) return 0;
    

    const usedEvents  = events.filter(e => system.usedUpgradeEvents.findIndex(id => id === e.id) >= 0);
    if(usedEvents.length === 0) return 0;

    const result = usedEvents.map(e => e.upCoverage).reduce((a, b) => a + b, 0);

    return result;
}

export const calcRevenueMax = (system: ISystem | undefined, cov: number, er: number): IRevenue => {
    if (!system) return initialRevenue;

    const systemKpiInfo = data.kpiCoeffs.find(si => si.systemId === system.systemId);
    if (!systemKpiInfo) return initialRevenue;

    const revenueXnYnZnMax = system.contractModel?.revenueMax ? system.contractModel?.revenueMax : 0;
    const revenue = system.revenueBase ? system.revenueBase : 0 + revenueXnYnZnMax * (cov * systemKpiInfo.COVrevenue / 100 + er * systemKpiInfo.ERrevenue / 100);

    return { revenueXnYnZnMax, revenue }
}