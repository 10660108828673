import React from 'react';
import { useSelector } from 'react-redux';
import style from './style.module.scss';
import ScoreProgressBar, { ScoreProgressBarColor } from '../../UiKit/ScoreProgressBar';
import { ISystem, KpiCodeEnum } from '../../../models';
import data from '../../../data';
import { calcDisplayCsi, calcDisplayNps } from '../../../service/calcDisplayKpi';
import { getLearningCompleted } from '../../../redux/selectors';

interface IProps {
    system: ISystem
}

export const KpisDashboard = (props: IProps) => {
    const { system } = props
    const learningCompleted = useSelector(getLearningCompleted);
    const { csi, nps, fcr, er, cov } = system.kpis ? system.kpis : { csi: 0, nps: 0, fcr: 0, er: 0, cov: 0 };
    const kpis = data.systems.find(x => x.id === system.systemId)?.systemKpies.flatMap(x => x.code)

    return (
        <div className={style.kpisDashboard} style={learningCompleted ? {} : { overflow: 'hidden' }}>
            <div className={style.description}>
                Improve your system’s key performance indicators to achieve a high level of digital transformation
            </div>
            <div className={style.progress} data-tut={"KPI"}>
                {kpis?.includes(KpiCodeEnum.CSI) && <ScoreProgressBar color={ScoreProgressBarColor.blue} title={'CSI'} containerStyle={style.block} value={calcDisplayCsi(csi)} valueStyle={style.dtrValue} maxValue={10} />}
                {kpis?.includes(KpiCodeEnum.NPS) && <ScoreProgressBar color={ScoreProgressBarColor.yellow} title={'NPS'} containerStyle={style.block} value={calcDisplayNps(nps)} valueStyle={style.dtrValue} />}
                {kpis?.includes(KpiCodeEnum.FCR) && <ScoreProgressBar color={ScoreProgressBarColor.red} title={'FCR'} containerStyle={style.block} value={fcr} valueStyle={style.dtrValue} displayValue={`${Math.round(fcr)}%`} />}
                {kpis?.includes(KpiCodeEnum.ER) && <ScoreProgressBar color={ScoreProgressBarColor.orange} title={'ER'} containerStyle={style.block} value={er} valueStyle={style.dtrValue} displayValue={`${Math.round(er)}%`} />}
                {kpis?.includes(KpiCodeEnum.COV) && <ScoreProgressBar color={ScoreProgressBarColor.green} title={'COV'} containerStyle={style.block} value={cov} valueStyle={style.dtrValue} displayValue={`${Math.round(cov)}%`} />}
            </div>
        </div>
    );
}

export default KpisDashboard;
